import { AsyncSwitcher } from 'components/ui/async-switcher';
import { type LoginConfig, useLoginConfig } from 'data/users';
import { SamlLoginWidget } from 'pages/login/saml-login-widget';
import { type FC } from 'react';
import { OktaSignInWidget } from './okta-sign-in-widget';

export const SignInWidget: FC<React.PropsWithChildren<unknown>> = () => {
  const tenant = window.location.hostname.replace(/(\.staging|\.preprod)?\.drivetrain.ai/, '');
  const query = useLoginConfig(tenant);

  return (
    <AsyncSwitcher {...query}>
      {(data: LoginConfig) => {
        return data.type === 'SSO' ? (
          <SamlLoginWidget url={data?.config?.url} />
        ) : (
          <OktaSignInWidget />
        );
      }}
    </AsyncSwitcher>
  );
};
