import { useUsers } from 'data/users';
import { type RefObject, useState, type ReactElement, type Component, useEffect } from 'react';
import {
  MentionsInput,
  Mention,
  type MentionsInputProps,
  type OnChangeHandlerFunc,
} from 'react-mentions';
import { styles } from './styles';

const { Wrapper, inputStyles, mentionStyle, Footer } = styles;

interface Props {
  value?: string;
  readOnly?: boolean;
  placeholder?: string;
  footerContent?: ReactElement;
  onChange?: OnChangeHandlerFunc;
  ref?: RefObject<Component<MentionsInputProps>>;
}

export const MentionsEditor = ({
  value,
  readOnly,
  placeholder,
  footerContent,
  onChange,
  ref,
}: Props): ReactElement => {
  const [state, setState] = useState(value);
  const { data: users } = useUsers();
  const mensionsData = (users || []).map((user) => ({
    id: user.username,
    display: user.firstName,
  }));

  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <Wrapper readOnly={readOnly}>
      <MentionsInput
        ref={ref}
        placeholder={placeholder}
        readOnly={readOnly}
        style={inputStyles(readOnly)}
        value={state}
        onChange={(...props) => {
          setState(props[0].target.value);
          onChange?.(...props);
        }}
      >
        <Mention
          data={mensionsData}
          displayTransform={(_, display) => `${display} `}
          markup="<mention id='__id__'>@__display__</mention>"
          renderSuggestion={(suggestion) => {
            return (
              <>
                <div>{suggestion.display}</div>
                <div>{suggestion.id}</div>
              </>
            );
          }}
          style={mentionStyle}
          trigger="@"
        />
      </MentionsInput>

      {!readOnly && state && footerContent && <Footer>{footerContent}</Footer>}
    </Wrapper>
  );
};
