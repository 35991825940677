import { ReactComponent as PlusIcon } from 'assets/v2/plus.svg';
import { VersionAccessModes } from 'components/modules/modelling/model/version-view-banner/constants';
import { isInVersionMode } from 'components/modules/modelling/model/version-view-banner/utils';
import { Button } from 'components/ui/atomic-components/button';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { SearchBox } from 'components/ui/atomic-components/searchbox';
import { type Dispatch, type SetStateAction, type ReactElement, useState } from 'react';
import styled from 'styled-components';
import { CreateNewListModal } from '../../crud-components/create-list-modal';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
`;

const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 28px;
`;

interface Props {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}

export const SearchSection = ({ searchQuery, setSearchQuery }: Props): ReactElement => {
  const [openCreateNewListModal, setOpenCreateNewListModal] = useState(false);
  const isReadOnly = isInVersionMode([VersionAccessModes.View, VersionAccessModes.ScopedEdit]);

  return (
    <Wrapper>
      <SearchBox value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />

      <AddButtonWrapper>
        <Button
          data-testid="@lists/createNewList"
          disabled={isReadOnly}
          icon={<IconShell icon={PlusIcon} />}
          size="small"
          type="primary"
          onClick={() => setOpenCreateNewListModal(true)}
        />
      </AddButtonWrapper>

      <CreateNewListModal
        closeModal={() => setOpenCreateNewListModal(false)}
        open={openCreateNewListModal}
      />
    </Wrapper>
  );
};
