import { type InputRef } from 'antd';
import { useAskPromptMutation } from 'data/delorian/hooks';
import { type AiNavigateData } from 'data/delorian/types';
import { AnimatePresence, motion, useAnimate } from 'framer-motion';
import { lazy, Suspense, useCallback, useEffect, useRef, useState, type ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useChatWidgetStore } from 'store/ai-chat-widget';
import styled from 'styled-components';
import { generateRandomNumber } from 'utils';
import { MinimisedChatButton } from '../minimised-chart-button';
import { EmptyBlock } from './body/response-block/empty-block';
import { Footer } from './footer';
import { TitleBar } from './title-bar';
import { type ChatOpenState, type AiMessage } from './types';

const LazyChatBody = lazy(() => import('./body'));

const Wrapper = styled.div`
  display: flex;
  width: 500px;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  position: fixed;
  bottom: 36px;
  right: 36px;
  border-radius: ${({ theme }) => theme.borderRadius.xxl};
  border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
  background: ${({ theme }) => theme.colors.gray00};
  box-shadow: ${({ theme }) => theme.shadow.softMedium};
  opacity: 0;
  visibility: hidden;
`;

const BodyWrapper = styled(motion.div)`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  flex-direction: column-reverse; /* Col-reverse is used to enable automatic scrolling as content populates the div */
  gap: ${({ theme }) => theme.spacing[20]};
  padding: ${({ theme: { spacing } }) => `${spacing[16]} ${spacing[16]}`};
`;

interface Props {
  chatOpenState: ChatOpenState;
  onClose: () => void;
  onMinimise: () => void;
  onOpen: () => void;
}

export const ChatBox = ({ chatOpenState, onOpen, onClose, onMinimise }: Props): ReactElement => {
  const [messages, setMessages] = useState<AiMessage[]>([]);

  const [chartBodyScope, animateChatBody] = useAnimate();

  const inputRef = useRef<InputRef>(null);
  const messagesDivRef = useRef<HTMLDivElement>(null);
  const convId = useRef<string>();
  const navigate = useNavigate();

  const { mutateAsync: askPrompt, isPending: askPromptIsLoading } = useAskPromptMutation();

  const onMessageSubmit = useCallback(
    (message: string) => {
      scrollToBottom();

      setMessages((prev) => [
        {
          id: generateRandomNumber(),
          markdown: message,
          role: 'user',
        },
        ...prev,
      ]);

      askPrompt({ message, convId: convId.current }).then((response) => {
        convId.current = response.convId;

        setMessages((prev) => [
          {
            id: generateRandomNumber(),
            role: 'system',
            createdAt: new Date(),
            ...response,
          },
          ...prev,
        ]);

        if (response.intentType === 'nav' && (response.data as AiNavigateData)?.navigateTo) {
          navigate((response.data as AiNavigateData)?.navigateTo);
        } else if (response.intentType === 'bye') {
          onClose();
        }
      });
    },
    [askPrompt, navigate, onClose],
  );

  const scrollToBottom = () => {
    if (messagesDivRef.current) {
      messagesDivRef.current.scrollTop = messagesDivRef.current.scrollHeight;
    }
  };

  const restConversation = () => {
    setMessages([]);
    convId.current = undefined;
  };

  useEffect(() => {
    if (chatOpenState === 'closed') {
      animateChatBody(chartBodyScope.current, {
        opacity: 0,
        visibility: 'hidden',
      });
      restConversation();
      useChatWidgetStore.setState({ minimisedTime: null, isLastMessageStreaming: false });
    } else if (chatOpenState === 'minimised') {
      animateChatBody(chartBodyScope.current, {
        opacity: 0,
        visibility: 'hidden',
        scale: 0.6,
        originX: 1,
        originY: 1,
      });
      useChatWidgetStore.setState({ minimisedTime: new Date(), isLastMessageStreaming: false });
    } else {
      animateChatBody(chartBodyScope.current, {
        opacity: 1,
        visibility: 'visible',
        scale: 1,
        originX: 1,
        originY: 1,
      });
    }
  }, [animateChatBody, chatOpenState, chartBodyScope]);

  return (
    <>
      <Wrapper ref={chartBodyScope}>
        <Suspense>
          <TitleBar onClose={onClose} onMinimise={onMinimise} onReset={restConversation} />

          <AnimatePresence>
            {chatOpenState === 'open' && (
              <BodyWrapper
                ref={messagesDivRef}
                animate={{ height: 510 }}
                exit={{ height: 420 }}
                initial={{ height: 420 }}
                transition={{
                  duration: 0.3,
                  damping: 20,
                  stiffness: 80,
                }}
              >
                {!messages.length && <EmptyBlock />}

                <LazyChatBody messages={messages} onMessageSubmit={onMessageSubmit} />
              </BodyWrapper>
            )}
          </AnimatePresence>

          <Footer
            inputRef={inputRef}
            isLoading={askPromptIsLoading}
            isOpen={chatOpenState === 'open'}
            onSubmit={onMessageSubmit}
          />
        </Suspense>
      </Wrapper>

      {chatOpenState === 'minimised' && <MinimisedChatButton onClick={onOpen} />}
    </>
  );
};
