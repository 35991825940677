import { AgGrid } from 'components/ui/atomic-components/grid';
import styled from 'styled-components';
import { FontS, FontXs, SingleLineEllipsis } from 'styles/typography';

export default {
  StyledGrid: styled(AgGrid)`
    border-top: 1px solid ${({ theme }) => theme.colors.outlineDefault};

    .ag-header-cell-text {
      ${FontXs}
    }

    .ag-cell-value {
      ${FontS}

      padding: ${({ theme: { spacing } }) => `${spacing[6]} ${spacing[12]}`};

      a {
        display: flex;
      }
    }

    .ag-cell-label-container {
      ${SingleLineEllipsis}
    }
  `,
};
