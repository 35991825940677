import { ReactComponent as AiArrowIcon } from 'assets/v2/ai-arrow_24.svg';
import { type ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  bottom: 36px;
  right: 36px;
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
  background: ${({ theme }) => theme.colors.bgNone};
  box-shadow: ${({ theme }) => theme.shadow.softMedium};
  padding: ${({ theme }) => theme.spacing[12]};
  cursor: pointer;

  svg {
    flex-shrink: 0;
  }
`;

interface Props {
  onClick: () => void;
}

export const MinimisedChatButton = ({ onClick }: Props): ReactElement => {
  return (
    <Wrapper onClick={onClick}>
      <AiArrowIcon />
    </Wrapper>
  );
};
