import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { getCallbackUrl, getReauthState, getState } from '../util';

export const xeroLogin = ({
  item,
  instanceId,
}: {
  item: IntegrationModalContent;
  instanceId?: number;
}): void => {
  const { clientId, id } = item;
  const state = instanceId ? getReauthState(id, instanceId) : getState(id);

  window.open(
    `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${getCallbackUrl()}&scope=offline_access accounting.transactions.read accounting.reports.read accounting.journals.read accounting.contacts.read&state=${state}`,
    '_self',
  );
};
