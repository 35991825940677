import { useForm } from 'antd/es/form/Form';
import { ReactComponent as Share3Icon } from 'assets/v2/share-3.svg';
import { Form, IconText } from 'components/ui/atomic-components';
import { Analytics } from 'config/analytics';
import { type ID } from 'data';
import { ContainerType, ConversationAccessType, type Comment } from 'data/conversations';
import { useConversationCommentMutation, useConversationMutations } from 'data/conversations/hooks';
import { difference, isEmpty } from 'lodash';
import { useState, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConversationsContext } from '../../context';
import { MentionsEditor } from '../../mentions-editor';
import { getAllMentions } from '../utils';
import { AddNewMembersConfirmationModal } from './add-new-members-confirmation-modal';
import { FooterContent } from './footer-content';
import { type FormType } from './types';

interface Props {
  conversationId: ID;
  conversationAccessType: ConversationAccessType;
  conversationMembers: string[];
}

export const ReplyTo = ({
  conversationId,
  conversationAccessType,
  conversationMembers,
}: Props): ReactElement => {
  const intl = useIntl();
  const [form] = useForm<FormType>();

  const { containerType } = useConversationsContext();
  const { createCommentMutation } = useConversationCommentMutation();
  const { updateConversationMutation } = useConversationMutations();

  const [openAddNewMembersConfirmationModal, setOpenAddNewMembersConfirmationModal] =
    useState(false);
  const [newMembers, setNewMembers] = useState<string[]>([]);

  const onReply = async () => {
    const { reply } = await form.validateFields();

    if (conversationAccessType === ConversationAccessType.Private) {
      const allMentions = getAllMentions(reply);

      const newMembers = difference(allMentions, conversationMembers);

      if (!isEmpty(newMembers)) {
        setNewMembers(newMembers);
        setOpenAddNewMembersConfirmationModal(true);

        return;
      }
    }

    form.resetFields();

    await createCommentMutation.mutateAsync({
      conversationId,
      comment: { message: reply } as Comment,
    });

    Analytics.track('Comment added', {
      category: containerType === ContainerType.Boards ? 'Reports' : 'Models',
      meta: 'Reply',
    });
  };

  const handleAddNewMembers = async () => {
    const { reply } = await form.validateFields();

    form.resetFields();

    createCommentMutation.mutateAsync({
      conversationId,
      comment: { message: reply } as Comment,
    });

    await updateConversationMutation.mutateAsync({
      id: conversationId,
      members: [...conversationMembers, ...newMembers],
    });

    setNewMembers([]);
    setOpenAddNewMembersConfirmationModal(false);
  };

  return (
    <>
      <Form form={form}>
        <Form.Item name="reply" noStyle>
          <MentionsEditor
            footerContent={<FooterContent onReply={onReply} />}
            placeholder={intl.formatMessage({ id: 'conversations.add_a_reply' })}
            onChange={(e) => form.setFieldValue('reply', e.target.value)}
          />
        </Form.Item>
      </Form>

      <AddNewMembersConfirmationModal
        newMembers={newMembers}
        okButtonProps={{ loading: updateConversationMutation.isPending }}
        okText={<FormattedMessage id="yes_continue" />}
        open={openAddNewMembersConfirmationModal}
        title={
          <IconText
            icon={Share3Icon}
            text={<FormattedMessage id="conversations.new_members.title" />}
          />
        }
        onCancel={() => setOpenAddNewMembersConfirmationModal(false)}
        onOk={handleAddNewMembers}
      />
    </>
  );
};
