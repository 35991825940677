import { Banner } from 'components/ui/atomic-components';
import { useModelVersion } from 'data/modelling/model/versions';
import { type ModelTemplate } from 'data/templates';
import Cookies from 'js-cookie';
import { isEmpty, keyBy } from 'lodash';
import { type ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { BannerTitle } from './banner-title';
import { DEMO_MODEL_TEMPLATE, PREVIEW_MODEL_TEMPLATE, VersionAccessModes } from './constants';
import { useVersionMode } from './hooks/use-version-mode';
import { LaunchingVersionAlert } from './launching-version-alert';
import { PreSavePromptModal } from './presave-prompt-modal';

export const VersionViewBanner = (): ReactNode => {
  const [confirmDiscard, setConfirmDiscard] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const { data } = useModelVersion({ includeArchived: true });
  const intl = useIntl();

  const { isInModelPage, isLoading, isLoadingPreview, versionId, accessMode, exitCurrentMode } =
    useVersionMode();

  const { label, versionName, previewModelTemplate } = useMemo(() => {
    if (versionId && !isEmpty(data)) {
      const lookup = keyBy(data, 'id');

      if (lookup[versionId] && accessMode) {
        const versionName = lookup[versionId]?.displayName;

        const previewModelTemplateCookieData = Cookies.get(PREVIEW_MODEL_TEMPLATE);
        const previewModelTemplate = previewModelTemplateCookieData
          ? (JSON.parse(previewModelTemplateCookieData) as ModelTemplate)
          : null;

        if (previewModelTemplate?.name === lookup[versionId].name) {
          return {
            label: `${intl.formatMessage({ id: 'preview' })}: ${versionName}`,
            versionName,
            previewModelTemplate,
          };
        }

        const demoModelTemplate = Cookies.get(DEMO_MODEL_TEMPLATE);

        if (demoModelTemplate === lookup[versionId].name) {
          return {
            label: `${intl.formatMessage({
              id: 'model.templates.live_demo_banner',
            })}: ${versionName}`,
            versionName,
          };
        }

        return {
          label: intl.formatMessage(
            {
              id: `model.versions.${
                accessMode === VersionAccessModes.View ? 'view' : 'edit'
              }.page_ribbon_label`,
            },
            { versionName },
          ),
          versionName,
        };
      }
    }

    return { label: '' };
  }, [versionId, accessMode, data, intl]);

  if (isLoadingPreview) {
    return null;
  }

  if (isLoading) {
    return <LaunchingVersionAlert isOpen={isLoading} />;
  }

  if (!versionId || !isInModelPage) {
    return <></>;
  }

  return (
    <>
      <Banner
        title={<BannerTitle label={label} previewModelTemplate={previewModelTemplate} />}
        onClose={() => {
          if (accessMode && accessMode !== VersionAccessModes.View) {
            setConfirmDiscard(true);
            setConfirmSave(false);
          } else {
            exitCurrentMode();
          }
        }}
        onSave={
          accessMode && accessMode !== VersionAccessModes.View
            ? () => {
                setConfirmDiscard(false);
                setConfirmSave(true);
              }
            : undefined
        }
      />
      <PreSavePromptModal
        close={() => {
          setConfirmDiscard(false);
          setConfirmSave(false);
        }}
        confirmDiscard={confirmDiscard}
        confirmSave={confirmSave}
        versionName={versionName}
      />
    </>
  );
};
