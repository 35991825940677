import { useMutation } from '@tanstack/react-query';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { SystemApi } from './api';

export const useSystemAdminAction = () => {
  const systemData = useMutation({
    mutationFn: ({
      classification,
      operation,
      queryParams,
    }: {
      classification: string;
      operation: string;
      queryParams?: Record<string, string>;
    }) => {
      return SystemApi.postAction({ classification, operation, queryParams });
    },

    onError: defaultApiErrorHandler,
  });

  return systemData;
};
