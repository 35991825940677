import { type CellClassParams, type CellStyle, type CellStyleFunc } from 'ag-grid-community';
import { type TableFormattingRuleStyle } from 'data/boards/charts/types/table';
import { type Pivot } from 'data/page-template';
import { generateDataLookupKey, getDimsInRowHierarchy } from 'data/reports/utils';
import { isNil } from 'lodash';
import { type MutableRefObject } from 'react';
import { defaultTheme } from 'styles/theme';
import { getBackgroundColor } from './get-background-color';

export const transformIntoStyleType = (
  ruleStyle?: TableFormattingRuleStyle,
): CellStyle | undefined => {
  if (!ruleStyle) {
    return undefined;
  }

  const { fontWeight } = defaultTheme;
  const { bold, italic, underline, fontColor, backgroundColor } = ruleStyle || {};

  const style: CellStyle = {};

  if (bold) {
    style.fontWeight = fontWeight.medium;
  }

  if (italic) {
    style.fontStyle = 'italic';
  }

  if (underline) {
    style.textDecoration = 'underline';
  }

  if (fontColor) {
    style.color = fontColor;
  }

  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }

  return style;
};

export const styleGetter = ({
  columnDimensions,
  firstVersion,
  pivot,
  styleMap,
}: {
  columnDimensions: Record<string, string>;
  firstVersion: string;
  pivot: Pivot;
  styleMap: MutableRefObject<Record<string, TableFormattingRuleStyle>>;
}): CellStyleFunc => {
  return ({ data, value }: CellClassParams) => {
    const { rowDimensions, isDataLeaf } = data;

    const dimsInHierarchy: string[] =
      getDimsInRowHierarchy({
        hierarchyClanList: data?.hierarchyClan,
        rowDimensions: pivot.dimensions.rows,
      }) || [];

    const dimensions = { ...rowDimensions, ...columnDimensions };

    const key = generateDataLookupKey({
      dimensions,
      rowsOrder: dimsInHierarchy || [],
      columnsOrder: pivot.dimensions.columns || [],
      firstVersion,
    });

    const style = { ...styleMap.current[key] };

    if (isDataLeaf && style?.scale && !isNil(value)) {
      style.backgroundColor = getBackgroundColor(style.scale, Number(value));
    }

    return transformIntoStyleType(style);
  };
};
