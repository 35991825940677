import { useQuery } from '@tanstack/react-query';
import { BootstrapApi } from '../api';
import { BootstrapStoreKey } from '../constants';

export const useBootstrap = () => {
  return useQuery({
    queryKey: BootstrapStoreKey,
    queryFn: () => BootstrapApi.fetchBootstrapData(),
    staleTime: Infinity,
  });
};
