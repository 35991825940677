import { Popover } from 'components/ui/atomic-components';
import { useEffect, useRef, type ReactElement, type ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { AllConnectorsPage } from '../../all-connectors-page';

export const IntegrationsPopover = ({
  children,
  open,
  setOpen,
}: {
  children?: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}): ReactElement => {
  // The following code with useEffect is for the linear - ENG-45386
  const location = useLocation();
  const urlRef = useRef(location.pathname);

  useEffect(() => {
    if (urlRef.current !== location.pathname) {
      setOpen(false);
    }
    urlRef.current = location.pathname;
  }, [location.pathname, setOpen]);

  return (
    <Popover
      content={<AllConnectorsPage isParentOpen={open} setOpen={setOpen} />}
      open={open}
      type={'full-width'}
    >
      {children}
    </Popover>
  );
};
