import { ReactComponent as TickIcon } from 'assets/v2/tick.svg';
import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';
import { FontXs, SingleLineEllipsis } from 'styles/typography';
import { IconShell } from '../icon-shell';

const Wrapper = styled.div`
  display: flex;
  white-space: initial;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  ${SingleLineEllipsis};

  margin-right: ${({ theme }) => theme.spacing[16]};
`;

const GroupWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const SubTitle = styled.div`
  ${FontXs}

  color: ${({ theme }) => theme.colors.textDisabled};
`;

const SelectedIcon = styled(TickIcon).attrs({
  className: 'dropdown-item-label-icon',
})`
  display: none;
`;

export const DropdownItemLabel: FC<
  React.PropsWithChildren<{
    title: ReactNode | string;
    subTitle?: ReactNode | string;
  }>
> = ({ title, subTitle }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <GroupWrapper>
        <SubTitle>{subTitle}</SubTitle>
        <IconShell color="primary500" icon={SelectedIcon} />
      </GroupWrapper>
    </Wrapper>
  );
};
