import { Toggle } from 'components/ui/atomic-components';
import { FormattedMessage } from 'react-intl';
import { useShouldFetchUnread, useNotificationActions } from 'store/notifications';
import { styles } from '../../styles';

const { Container, Label } = styles;

export const ToggleUnreadOnlyNotifications = () => {
  const shouldFetchUnread = useShouldFetchUnread();
  const { setShouldFetchUnread } = useNotificationActions();

  return (
    <Container>
      <Label>
        <FormattedMessage id="notification.filter.unread_only" />
      </Label>
      <Toggle checked={shouldFetchUnread} size="small" onChange={(c) => setShouldFetchUnread(c)} />
    </Container>
  );
};
