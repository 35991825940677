import { Avatar } from 'components/ui/atomic-components';
import { isEmpty } from 'lodash';
import { type ReactElement } from 'react';
import { styles } from './styles';
import { type ConversationElementProps } from './types';

const {
  Wrapper,
  StyledAvatar,
  ContentWrapper,
  Content,
  TopSection,
  TitleWrapper,
  Title,
  SubTitle,
  TopRightSection,
  AccessInfo,
  Pill,
  AccessIcon,
  MembersAvatarGroup,
  TextFooter,
  SubElements,
} = styles;

export const ConversationElement = ({
  title,
  subTitle,
  topRightContent,
  pillContent,
  accessIcon,
  members,
  content,
  textFooter,
  subElements,
  footerContent,
  overrideContent,
  hideAccessSelector,
  onClickTextFooter,
}: ConversationElementProps): ReactElement => {
  if (overrideContent) {
    return <Wrapper>{overrideContent}</Wrapper>;
  }

  return (
    <Wrapper>
      <StyledAvatar name={title} size="medium" />

      <ContentWrapper>
        <Content>
          <TopSection>
            <TitleWrapper>
              <Title>{title}</Title>

              {subTitle && <SubTitle>{subTitle}</SubTitle>}
            </TitleWrapper>

            {topRightContent && <TopRightSection>{topRightContent}</TopRightSection>}
          </TopSection>

          {!hideAccessSelector && (
            <AccessInfo>
              {pillContent && <Pill>{pillContent}</Pill>}

              <AccessIcon>{accessIcon}</AccessIcon>

              {!isEmpty(members) && (
                <MembersAvatarGroup maxCount={2} size="small">
                  {members?.map((member) => (
                    <Avatar key={member} name={member} size="small" />
                  ))}
                </MembersAvatarGroup>
              )}
            </AccessInfo>
          )}

          {content && <div>{content}</div>}
        </Content>

        {textFooter && (
          <TextFooter
            onClick={(event) => {
              if (onClickTextFooter) {
                event.stopPropagation();
                onClickTextFooter();
              }
            }}
          >
            {textFooter}
          </TextFooter>
        )}

        {!isEmpty(subElements) && (
          <SubElements>
            {subElements?.map((subElement) => (
              <ConversationElement key={subElement.key} {...subElement} />
            ))}
          </SubElements>
        )}

        {footerContent}
      </ContentWrapper>
    </Wrapper>
  );
};
