import { useQuery } from '@tanstack/react-query';
import { MonitoringMetricsKeys } from 'data/metrics';
import { MetricBuilderApi } from '../api';

export const useMonitoringMetrics = ({
  query,
  onEmptyLoadTopItems,
  options,
}: {
  query?: string | null;
  onEmptyLoadTopItems?: boolean;
  options?: { enabled?: boolean; staleTime?: number };
}) => {
  return useQuery({
    queryKey: MonitoringMetricsKeys.MetricsByQuery(query),

    queryFn: () => {
      if (query || onEmptyLoadTopItems) {
        return MetricBuilderApi.searchMonitoringMetrics(query || '');
      }

      return MetricBuilderApi.getAllMonitoringMetrics();
    },
    ...options,
  });
};
