import axios from 'axios';
import { type NotificationType } from './types';

const BASE_URL = 'v1/notifications';

const PAGE_NUMBER = 1;
const PAGE_SIZE = 100;

export const NotificationsApi = {
  fetchNotifications: (
    shouldFetchUnread: boolean,
    type?: NotificationType,
  ): Promise<Notification[]> => {
    return axios
      .get(BASE_URL, {
        params: {
          page: PAGE_NUMBER,
          size: PAGE_SIZE,
          fetchUnread: shouldFetchUnread,
          type,
        },
      })
      .then(({ data }) => data);
  },
  markAsRead: (notificationId?: number) => {
    const URL = notificationId ? `${BASE_URL}/${notificationId}` : BASE_URL;

    return axios.patch<void>(URL);
  },
  deleteAll: () => {
    return axios.delete<void>(BASE_URL);
  },
};
