const scrollHorizontal = (left: number, gridId: string) => {
  const query = `[grid-id="${gridId}"] .ag-body-horizontal-scroll-viewport`;
  const viewport = document.querySelector(query);

  if (!viewport) {
    return;
  }
  viewport.scrollLeft = left;
};

export const scrollUsingHeader = (gridId: string): void => {
  const headerViewport = document.querySelector(`[grid-id="${gridId}"] .ag-header-viewport`);

  if (headerViewport) {
    headerViewport.addEventListener('wheel', (e: Event) => {
      e.preventDefault();
      const left = headerViewport.scrollLeft + (e as WheelEvent).deltaX;

      scrollHorizontal(left, gridId);
    });
  }
};
