import { Granularity, PeriodRangeState, type FormattedPeriod, type NewPeriod } from 'data';
import { type ModelVersion } from 'data/modelling/model/versions';
import { type PageTemplateRelativeTableHeaderCustomisations } from 'data/page-template';
import { type ColGroup } from 'data/page-template/grid';
import { ACTUAL } from 'data/versions';
import { periodFormatter } from 'utils/data-formatter';
import { isPeriodInPastPresentOrFuture } from 'utils/periods';

type Group = { name: string; displayName?: string };

const constructVersionOrder = (cols: string[], versions?: ModelVersion[]) => {
  return cols.map((col) => ({
    name: col,
    displayName: versions?.find((v) => v.name === col)?.displayName,
  }));
};
const constructColumnOrder = ({
  period,
  cols,
  versions,
  overriddenCurrentDate,
  versionOverrides,
}: {
  period: NewPeriod;
  cols: string[];
  versions?: ModelVersion[];
  overriddenCurrentDate?: string;
  versionOverrides?: PageTemplateRelativeTableHeaderCustomisations;
}): {
  primaryCol: ColGroup | string;
  group: Group[];
} => {
  const periodRangeState = isPeriodInPastPresentOrFuture(period, overriddenCurrentDate);
  const versionOrder = constructVersionOrder(cols, versions);

  if (periodRangeState === PeriodRangeState.Past) {
    return {
      primaryCol: versionOverrides?.past || (cols.includes(ACTUAL) ? ACTUAL : cols[0]),
      group: versionOrder,
    };
  }

  if (periodRangeState === PeriodRangeState.Present) {
    return {
      primaryCol: versionOverrides?.current || cols[0],
      group: versionOrder,
    };
  }

  // For Future
  return {
    primaryCol: versionOverrides?.future || cols[0],
    group: versionOrder,
  };
};

export const getFormattedPeriods = ({
  periods,
  cols,
  versions,
  overriddenCurrentDate,
  versionOverrides,
}: {
  periods: NewPeriod[];
  cols: string[];
  versions?: ModelVersion[];
  overriddenCurrentDate?: string;
  versionOverrides?: PageTemplateRelativeTableHeaderCustomisations;
}): FormattedPeriod[] =>
  periods.map((period) => {
    return {
      ...period,
      cellClass:
        period.type !== Granularity.Monthly
          ? 'ag-right-aligned-cell ag-grid-col-group-rollup'
          : 'ag-right-aligned-cell',
      headerCellClass:
        period.type !== Granularity.Monthly
          ? 'ag-right-aligned-header ag-grid-col-group-rollup'
          : 'ag-right-aligned-header',
      groupId: period.key,
      id: period.key,
      order: constructColumnOrder({
        period,
        cols,
        versions,
        overriddenCurrentDate,
        versionOverrides,
      }),
      value: periodFormatter(period),
    };
  });
