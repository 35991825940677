/* eslint-disable @calm/react-intl/missing-formatted-message */
import { Button, notification } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { getAssetUrl } from 'utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 315px;
  margin-bottom: ${({ theme }) => theme.spacing[56]};
  border: 1px solid ${({ theme }) => theme.colors.gray300};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 315px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
  padding: ${({ theme }) => theme.spacing[40]};

  img {
    margin: 0 auto;
    max-height: 40px;
    max-width: 200px;
  }
`;

const Content = styled.div`
  padding: ${({ theme: { spacing } }) => `${spacing[40]} ${spacing[40]}`};
`;

export const SamlLoginWidget = ({ url }: { url: string }): ReactElement => {
  const onSignIn = () => {
    if (url) {
      window.location.replace(url);
    } else {
      notification.warning({ message: 'SSO url not configured' });
    }
  };

  return (
    <Wrapper>
      <Box>
        <Header>
          <img alt="" src={getAssetUrl('drivetrain-logo-text.svg')} />
        </Header>

        <Content>
          <Button block size="extraLarge" type="primary" onClick={onSignIn}>
            Sign in with SSO
          </Button>
        </Content>
      </Box>
    </Wrapper>
  );
};
