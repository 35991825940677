import { type QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { useConversationsContext } from 'components/ui/collaboration/context';
import { type ID } from 'data';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { type Atleast } from 'utils/typescript';
import {
  type Comment,
  type ContainerType,
  type Conversation,
  ConversationApi,
  type ConversationId,
} from '..';
import { ConversationsStoreKeys } from '../constants';
import { getConversationIdentifier } from '../utils';

const updateStores = ({
  queryClient,
  containerType,
  containerId,
  conversationId,
  updatorCallback,
}: {
  queryClient: QueryClient;
  containerType: ContainerType;
  containerId: ID;
  conversationId: ConversationId;
  updatorCallback: (state?: Conversation[]) => Conversation[];
}) => {
  queryClient.setQueryData(
    ConversationsStoreKeys.AllRecords({ container: containerType, containerId }),
    updatorCallback,
  );

  const conv = queryClient
    .getQueryData<Conversation[]>(
      ConversationsStoreKeys.AllRecords({ container: containerType, containerId }),
    )
    ?.find((conv) => conv.id === conversationId);

  if (!conv) {
    return;
  }

  const identifier = getConversationIdentifier(conv);

  queryClient.setQueryData(
    ConversationsStoreKeys.AllRecordsWithIdentifier({
      container: containerType,
      containerId,
      identifier,
    }),
    updatorCallback,
  );
};

export const useConversationCommentMutation = () => {
  const queryClient = useQueryClient();
  const { containerType, containerId } = useConversationsContext();

  const createCommentMutation = useMutation({
    mutationFn: ({
      conversationId,
      comment,
    }: {
      conversationId: ConversationId;
      comment: Partial<Comment>;
    }) => {
      return ConversationApi.createComment(conversationId, comment);
    },
    onSuccess(comment, { conversationId }) {
      const updatorCallback = (state: Conversation[] = []) =>
        state.map((conv) => {
          if (conv.id === conversationId) {
            return {
              ...conv,
              comments: [...conv.comments, comment],
            };
          }

          return conv;
        });

      updateStores({ queryClient, containerType, containerId, conversationId, updatorCallback });
    },
    onError: defaultApiErrorHandler,
  });

  const updateCommentMutation = useMutation({
    mutationFn: ({
      conversationId,
      comment,
    }: {
      conversationId: ConversationId;
      comment: Atleast<Comment, 'id'>;
    }) => {
      return ConversationApi.updateComment(conversationId, comment);
    },
    onSuccess(comment, { conversationId }) {
      const updatorCallback = (state: Conversation[] = []) =>
        state.map((conv) => {
          if (conv.id === conversationId) {
            return {
              ...conv,
              comments: conv.comments.map((c) => {
                if (c.id === comment.id) {
                  return comment;
                }

                return c;
              }),
            };
          }

          return conv;
        });

      updateStores({ queryClient, containerType, containerId, conversationId, updatorCallback });
    },
    onError: defaultApiErrorHandler,
  });

  const deleteCommentMutation = useMutation({
    mutationFn: ({
      conversationId,
      commentId,
    }: {
      conversationId: ConversationId;
      commentId: ID;
    }) => {
      return ConversationApi.deleteComment(conversationId, commentId);
    },
    onSuccess(_, { conversationId, commentId }) {
      const updatorCallback = (state: Conversation[] = []) => {
        const conv = state.find((conv) => conv.id === conversationId);

        // if deleted comment is the parent comment, remove the whole conversation
        if (conv?.comments?.[0].id === commentId) {
          return state.filter((conv) => conv.id !== conversationId);
        }

        return state.map((conv) => {
          if (conv.id === conversationId) {
            return {
              ...conv,
              comments: conv.comments.filter((c) => c.id !== commentId),
            };
          }

          return conv;
        });
      };

      updateStores({ queryClient, containerType, containerId, conversationId, updatorCallback });
    },
    onError: defaultApiErrorHandler,
  });

  return {
    createCommentMutation,
    updateCommentMutation,
    deleteCommentMutation,
  };
};
