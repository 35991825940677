import { PeriodRangeState } from 'data';
import { DimensionAllMarker } from 'data/dimension';
import { type FrameElement, FrameType } from 'data/reports/types';
import { ACTUAL } from 'data/versions';

export const getCollapsedStateColumn = (
  columns: FrameElement[],
  periodRangeState: PeriodRangeState | null,
  showColumnSummary: boolean,
  incompletePeriodsShowForecast: boolean,
): FrameElement => {
  const summaryColumn = columns.find((col) => col.key === DimensionAllMarker);
  const defaultColumn = summaryColumn || columns[0];

  if (!showColumnSummary || !defaultColumn) {
    return columns[0];
  }

  if (defaultColumn.type === FrameType.Version) {
    if (!periodRangeState) {
      return defaultColumn;
    }

    if (
      incompletePeriodsShowForecast
        ? [PeriodRangeState.Present, PeriodRangeState.Future].includes(periodRangeState)
        : [PeriodRangeState.Future].includes(periodRangeState)
    ) {
      if (defaultColumn.key === ACTUAL && columns.length > 1) {
        return (
          columns.find((col, index) => index !== 0 && col.type === FrameType.Version) ||
          defaultColumn
        );
      }
    }

    return defaultColumn;
  }

  return defaultColumn;
};
