import { ReactComponent as DeleteIcon } from 'assets/v2/delete.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { isEmpty } from 'lodash';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext, useListStoreInstance } from 'store/lists';
import { type ListGridRow } from '../grid/types';

interface Props {
  onDeleteListRows: (rowNode: ListGridRow[]) => void;
}

export const DeleteAction = ({ onDeleteListRows }: Props): ReactElement => {
  const selectedRowIds = useListStoreContext((s) => s.selectedRowIds);
  const setSelectedRowIds = useListStoreContext((s) => s.setSelectedRowIds);
  const config = useListStoreContext((s) => s.config);
  const store = useListStoreInstance();

  const onDelete = () => {
    const rowNodeToDelete: ListGridRow[] = [];

    store.getState().rows.forEach((row) => {
      if (selectedRowIds.includes(row.id)) {
        rowNodeToDelete.push(row);
      }
    });

    onDeleteListRows(rowNodeToDelete);
    setSelectedRowIds([]);
  };

  return (
    <Button
      danger
      disabled={!isEmpty(config.lockedColumns)}
      icon={<IconShell color="textNegative" icon={DeleteIcon} />}
      size="small"
      onClick={onDelete}
    >
      <FormattedMessage id="delete" />
    </Button>
  );
};
