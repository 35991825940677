import { useForm, useWatch } from 'antd/es/form/Form';
import { useListMutations } from 'components/modules/modelling/lists/hooks';
import { Popover } from 'components/ui/atomic-components';
import { ListDataValidationCondition, type ListConfig } from 'data/modelling/lists';
import { type ReactElement, type RefObject, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { formatName } from 'utils/data-formatter';
import { getColumnNameFromRawDimensionName } from 'utils/dimensions';
import { queueMacroTask } from 'utils/queue-macro-task';
import { useUpdateDataValidationPermissibleValuesMap } from './hooks/use-update-data-validation-permissible-values-map';
import { PopoverContent } from './popover-content';
import { useConditionOptions } from './popover-content/use-condition-options';
import { RemoveButton } from './remove-button';
import { type FormType } from './types';

interface Props {
  columnName: string;
  isOpen: boolean;
  closeOpenPopup: (val: boolean) => void;
  columnWrapperDomRef: RefObject<HTMLDivElement>;
}

export const DataValidation = ({
  columnName,
  isOpen,
  columnWrapperDomRef,
  closeOpenPopup,
}: Props): ReactElement => {
  const { updateListMutation } = useListMutations();

  const [form] = useForm<FormType>();

  const listId = useListStoreContext((s) => s.id);
  const config = useListStoreContext((s) => s.config);
  const setConfig = useListStoreContext((s) => s.setConfig);
  const gridApi = useListStoreContext((s) => s.gridApi);

  const updateDataValidationPermissibleValuesMap = useUpdateDataValidationPermissibleValuesMap();

  const conditionOptions = useConditionOptions();

  const sourceColumn = useWatch('sourceColumn', form);

  const columnWidth = (columnWrapperDomRef.current?.clientWidth || 0) + 8;

  const handleConfirm = async () => {
    const data = await form.validateFields();

    if (
      config.dataValidation?.[columnName]?.condition === data.condition?.value &&
      config.dataValidation?.[columnName]?.source === data.sourceColumn?.value
    ) {
      closeOpenPopup(false);

      return;
    }

    if (!data.sourceColumn?.value) {
      return;
    }

    const updatedConfig = {
      ...config,
      dataValidation: {
        ...config.dataValidation,
        [columnName]: {
          condition: data.condition?.value,
          source: data.sourceColumn?.value,
        },
      },
    } as ListConfig;

    await updateListMutation.mutateAsync({
      id: listId,
      list: { config: updatedConfig },
    });

    setConfig(updatedConfig);

    closeOpenPopup(false);

    await updateDataValidationPermissibleValuesMap(updatedConfig.dataValidation);

    queueMacroTask(() =>
      gridApi?.refreshCells({
        columns: [columnName],
        force: true,
      }),
    );
  };

  useEffect(() => {
    const sourceColumn = config?.dataValidation?.[columnName]?.source;

    form.setFieldsValue({
      condition: conditionOptions.find(
        (option) => option.value === ListDataValidationCondition.ExistingColumn,
      ),
      sourceColumn: {
        label: sourceColumn
          ? formatName(getColumnNameFromRawDimensionName(sourceColumn))
          : undefined,
        value: sourceColumn,
      },
    });
  }, [columnName, conditionOptions, config?.dataValidation, form]);

  return (
    <Popover
      align={{ targetOffset: [columnWidth, 0] }}
      cancelText={<FormattedMessage id="cancel" />}
      confirmDisabled={!sourceColumn?.value}
      confirmLoading={updateListMutation.isPending}
      confirmText={<FormattedMessage id="add" />}
      content={<PopoverContent form={form} />}
      footerExtra={
        sourceColumn?.value && (
          <RemoveButton
            closePopup={() => closeOpenPopup(false)}
            columnName={columnName}
            form={form}
          />
        )
      }
      open={isOpen}
      placement="leftTop"
      onCancel={() => closeOpenPopup(false)}
      onConfirm={handleConfirm}
      onOpenChange={(val) => {
        if (document.querySelector('.column-select__menu-portal')) {
          return;
        }

        closeOpenPopup(val);
      }}
    >
      <></>
    </Popover>
  );
};
