export const VERSION_MODE_COOKIE_KEY = 'version-id';
export const VERSION_ACCESS_COOKIE_KEY = 'version-access-mode';
export const PREVIEW_MODEL_TEMPLATE = 'preview-model-template';
export const DEMO_MODEL_TEMPLATE = 'demo-model-template';

export enum VersionAccessModes {
  View = 'view',
  ScopedEdit = 'scoped-edit',
  FullEdit = 'full-edit',
}
