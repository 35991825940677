/* eslint-disable @typescript-eslint/naming-convention */
import {
  type CellClassParams,
  type GridOptions,
  type GroupCellRendererParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { MetricTreeNodeTypes } from 'data/metrics/types';
import { isEmpty } from 'lodash';
import { onBodyScroll } from './utils/on-body-scroll';

export const GridConfiguration: GridOptions = {
  autoGroupColumnDef: {
    headerName: '',
    width: 264,
    minWidth: 264,
    cellRendererParams: {
      suppressCount: true,
    },
    pinned: 'left',
    lockPinned: true,
    valueGetter: (params: ValueGetterParams): string => {
      return [
        MetricTreeNodeTypes.Metric,
        MetricTreeNodeTypes.Section,
        MetricTreeNodeTypes.SectionMetric,
        MetricTreeNodeTypes.Blank,
        MetricTreeNodeTypes.TempRow,
      ].includes(params.data.viewNodeType)
        ? params.data.displayName
        : params.data.displayValue;
    },
    onCellClicked: (params) => {
      const { api, node, event } = params;

      if (!(event?.target as Element)?.classList?.contains('header-cell-click-listener')) {
        return;
      }

      api.setRowNodeExpanded(node, !node?.expanded);
    },
    resizable: true,
  },
  defaultColDef: {
    menuTabs: [],
    minWidth: 120,
    width: 120,
    resizable: false,
    sortable: false,
    suppressMovable: true,
    cellRendererParams: ({
      colDef,
    }: GroupCellRendererParams): {
      period: string;
      col: string;
    } => {
      const [period, col] = colDef?.colId?.split('~~') || [];

      return {
        period,
        col,
      };
    },
    cellClassRules: {
      'ag-has-open-conversations': ({ colDef, data }: CellClassParams): boolean => {
        const [period, col] = String(colDef.colId).split('~~');

        const hasConversation =
          !isEmpty(data.data?.[period]?.conversations?.[col]?.conversationIds) &&
          !data.data[period]?.conversations?.[col]?.allResolved;

        return hasConversation;
      },
      'ag-all-resolved-conversations': ({ colDef, data }: CellClassParams): boolean => {
        const [period, col] = String(colDef.colId).split('~~');

        const allResolvedConversations =
          !isEmpty(data.data?.[period]?.conversations?.[col]?.conversationIds) &&
          data.data?.[period]?.conversations?.[col]?.allResolved;

        return allResolvedConversations;
      },
    },
  },
  getDataPath: (data) => data.hierarchy,
  groupDefaultExpanded: 1,
  overlayLoadingTemplate: '<span className="ag-overlay-loading-center"></span>',
  treeData: true,
  onBodyScroll,
};
