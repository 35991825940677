import { ReactComponent as ArrowUpIcon } from 'assets/v2/arrow-up.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: ${({ theme }) => theme.spacing[6]};
`;

interface Props {
  isReplying?: boolean;
  onReply?: () => void;
}

export const FooterContent = ({ isReplying, onReply }: Props): ReactElement => {
  return (
    <Wrapper>
      <Button
        icon={<IconShell icon={ArrowUpIcon} />}
        loading={isReplying}
        size="extraSmall"
        type="primary"
        onClick={onReply}
      />
    </Wrapper>
  );
};
