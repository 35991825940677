import { ReactComponent as CircleLinkIcon } from 'assets/v2/circle-arrow-up-right.svg';
import { ReactComponent as DimensionIcon } from 'assets/v2/cube.svg';
import { ReactComponent as DatasetIcon } from 'assets/v2/database-12.svg';
import { ReactComponent as ListIcon } from 'assets/v2/menu-2.svg';
import {
  metricDimAggregationTypes,
  metricTimeAggregationTypes,
} from 'components/modules/metrics/constants';
import { DateTimeFormats } from 'config/constants';
import { type Granularity } from 'data';
import { useCurrency } from 'data/currencies/hooks/use-currency';
import { DimensionAllMarker, type DimensionQueryParamsObject } from 'data/dimension';
import { stripMathFunctionPrefix } from 'data/formula-editor/utils';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty, keyBy } from 'lodash';
import { type ReactElement, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { numberFormatter } from 'utils/data-formatter';
import {
  BlankValueType,
  DataFormattingType,
  DisplayUnitType,
  NegativeValueType,
  ZeroFormatType,
} from 'utils/data-formatter/types';
import { useUser } from 'utils/hooks';
import { BackButton, Button, IconShell, Spinner } from '../atomic-components';
import { useFormulaEditorMetrics } from '../atomic-components/formula-editor-v2/hooks/use-formula-editor-metrics';
import { ReadOnlyFormula } from '../atomic-components/formula-editor-v2/readonly-formula';
import { useShowBreakup } from '../breakup-query';
import { BreakupQueryDrawer } from '../breakup-query/underlying-data-drawer';
import { useSummarizeBy } from '../chart-components-v2/chart-types/hooks';
import { constructUnderlyingDataEndDate } from '../chart-components-v2/chart-types/table/utils/construct-end-date-with-actuals-till';
import { Grid } from './grid';
import { useFormulaTraceStore } from './store';
import { styles } from './styles';
import { determineType } from './utils/determine-value-type';
import { getDimsLabelForCell } from './utils/get-dims-label';

const {
  ContentWrapper,
  CalculationInfoItem,
  CalculationInfoTitle,
  CalculationInfoContent,
  CalculationInfoWrapper,
  ValueWrapper,
  ValueItem,
  ValueInfoLabel,
  FormulaContainer,
  ComponentBreakup,
  BackButtonWrapper,
  DebugViewWrapper,
  DebugInfoButton,
} = styles;

export const TraceContent = (): ReactElement => {
  const domain = 'all';
  const traceNode = useFormulaTraceStore((state) => state.traceNode);
  const setTraceNode = useFormulaTraceStore((state) => state.setTraceNode);
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  const { isDtUser } = useUser();

  const { showFutureActualsTess: showFutureActuals } = useFlags();

  const { data: metrics, isLoading: isMetricsLoading } = useFormulaEditorMetrics(domain);

  const currency = useCurrency();

  const { summarizeByObj, setSummarizeByLocal } = useSummarizeBy(
    traceNode?.payload?.resourceInfo?.chartId,
  );

  const {
    breakupQueryDrawerVisible,
    setBreakupQueryDrawerVisible,
    breakupQueryDrawerParams,
    setBreakupQueryDrawerParams,
    closeBreakupQueryDrawer,
  } = useShowBreakup();

  const onClickUnderlyingData = () => {
    const f: DimensionQueryParamsObject = {};

    Object.keys(traceNode?.term?.dims || {}).forEach((dim) => {
      if (traceNode?.term?.dims?.[dim] === DimensionAllMarker) {
        return;
      }
      f[dim] = [traceNode?.term?.dims?.[dim] || ''];
    });

    const { actualsTillDate, actualsTillDateOption } =
      useFormulaTraceStore.getState().breakupQueryParams || {};

    const endDate = constructUnderlyingDataEndDate({
      actualsTillDate,
      actualsTillDateOption,
      endDate: traceNode?.term?.endDate || '',
      granularity: traceNode?.term?.granularity as Granularity,
      showFutureActuals,
      version: traceNode?.term?.version || '',
    });

    setBreakupQueryDrawerParams({
      metricName: traceNode?.term?.sourceInfo?.metricName || '',
      f,
      startDate: traceNode?.term?.t || '',
      endDate,
      granularity: traceNode?.term?.granularity as Granularity,
      version: traceNode?.term?.version,
      displayName: traceNode?.term?.metricDisplayName || '',
      displayPeriodKey: traceNode?.term?.t || '',
      value: traceNode?.term?.value as number,
    });
    setBreakupQueryDrawerVisible(true);
  };

  const metricsLookup = useMemo(() => {
    if (metrics) {
      return keyBy(metrics, 'name');
    }

    return {};
  }, [metrics]);

  const value = useMemo(() => {
    let value = traceNode?.term?.value;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const type = (
      metricsLookup?.[traceNode?.term?.metricName || ''] as { type: DataFormattingType }
    )?.type;

    if (value) {
      const valueType = determineType(value);

      if (valueType === 'number') {
        value = numberFormatter({
          value: +value,
          type: traceNode?.term?.dataFormat?.type || DataFormattingType.Number,
          digitsAfterDecimal: traceNode?.term?.dataFormat?.digits_after_decimal || 0,
          currency: traceNode?.term?.dataFormat?.currency || currency,
          blankValueFormat: traceNode?.term?.dataFormat?.blank_value_format || BlankValueType.Zero,
          displayUnit: traceNode?.term?.dataFormat?.display_unit || DisplayUnitType.Full,
          negativeValueFormat:
            traceNode?.term?.dataFormat?.negative_value_format || NegativeValueType.Sign,
          zeroFormat: traceNode?.term?.dataFormat?.zero_format || ZeroFormatType.Number,
        });
      } else if (valueType === 'date') {
        value = dayjs(value).format(DateTimeFormats.HumanReadableDate);
      }
    }

    return value;
  }, [
    currency,
    metricsLookup,
    traceNode?.term?.dataFormat,
    traceNode?.term?.metricName,
    traceNode?.term?.value,
  ]);

  const metricName = traceNode?.term?.metricDisplayName;

  const dimsLabel = useMemo(() => {
    if (!traceNode?.term) {
      return '';
    }

    return getDimsLabelForCell(
      traceNode?.term,
      traceNode?.payload?.resourceInfo?.requestSource !== 'MMPP',
    );
  }, [traceNode?.payload?.resourceInfo?.requestSource, traceNode?.term]);

  const terms = traceNode?.term?.terms || [];

  const dimSummaryLabel = useMemo(
    () =>
      metricDimAggregationTypes.find((agg) => agg.value === traceNode?.term?.dimensionSummary)
        ?.label,
    [traceNode?.term?.dimensionSummary],
  );

  const timeSummaryLabel = useMemo(
    () =>
      metricTimeAggregationTypes.find((agg) => agg.value === traceNode?.term?.timeSummary)?.label,
    [traceNode?.term?.timeSummary],
  );

  let fromSourceLabel = '';
  let fromSourceIcon = undefined;
  const fromSourceLabelValue = traceNode?.term?.sourceInfo?.displayName;

  if (traceNode?.term?.sourceInfo?.type === 'list') {
    fromSourceLabel = 'List';
    fromSourceIcon = ListIcon;
  } else if (traceNode?.term?.sourceInfo?.type === 'dataset') {
    fromSourceLabel = 'Dataset';
    fromSourceIcon = DatasetIcon;
  }
  const fromSourceType = traceNode?.term?.sourceInfo?.type;
  const fromSourceId = traceNode?.term?.sourceInfo?.id;
  let fromSourceLink = '';

  if (fromSourceType === 'list' && fromSourceId) {
    fromSourceLink = `/lists/${fromSourceId}`;
  } else if (fromSourceType === 'dimgroup' && fromSourceId) {
    fromSourceLink = `/lists/dg-${fromSourceId}`;
  }

  const isLoading = isMetricsLoading || traceNode?.isLoading;

  const metricSupportsBreakup =
    traceNode?.term?.sourceInfo?.type === 'dataset' && !!traceNode?.term?.sourceInfo?.metricName;

  return (
    <>
      <ContentWrapper>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <ValueWrapper>
              {traceNode?.previousNode && (
                <BackButtonWrapper>
                  <BackButton
                    size="small"
                    onClick={() => traceNode?.previousNode && setTraceNode(traceNode?.previousNode)}
                  >
                    <FormattedMessage id="back" />
                  </BackButton>
                </BackButtonWrapper>
              )}
              <ValueItem>{value}</ValueItem>
              {metricName && (
                <ValueInfoLabel>
                  <FormattedMessage
                    id="formula_trace.value_dims_info.label"
                    values={{ metric: <b>{metricName}</b>, dims: dimsLabel }}
                  />
                </ValueInfoLabel>
              )}
            </ValueWrapper>

            <CalculationInfoWrapper>
              <CalculationInfoTitle>
                <FormattedMessage id="formula_trace.calculation_info.title" />
              </CalculationInfoTitle>
              <CalculationInfoContent>
                {dimSummaryLabel && (
                  <CalculationInfoItem
                    icon={DimensionIcon}
                    text={
                      <>
                        <FormattedMessage id="planning.metric.dimension_aggregation" />
                        {': '}
                        <b>{dimSummaryLabel}</b>
                      </>
                    }
                  />
                )}
                {timeSummaryLabel && (
                  <CalculationInfoItem
                    icon={DimensionIcon}
                    text={
                      <>
                        <FormattedMessage id="planning.metric.time_aggregation" />
                        {': '}
                        <b>{timeSummaryLabel}</b>
                      </>
                    }
                  />
                )}
                {fromSourceLabel && fromSourceIcon && (
                  <CalculationInfoItem
                    icon={fromSourceIcon}
                    text={
                      <>
                        {fromSourceLabel}
                        {': '} <b>{fromSourceLabelValue}</b>
                      </>
                    }
                  />
                )}
                {fromSourceLink && (
                  <div>
                    <Link rel="noopener noreferrer" target="_blank" to={fromSourceLink}>
                      <Button icon={<IconShell icon={CircleLinkIcon} />} size="extraSmall">
                        <FormattedMessage id="formula_trace.leaf_term.open_list" />
                      </Button>
                    </Link>
                  </div>
                )}

                {metricSupportsBreakup && (
                  <div>
                    <Button size="small" onClick={onClickUnderlyingData}>
                      <FormattedMessage id="charts.point_data.context_menu.underlying_data" />
                    </Button>
                  </div>
                )}

                {traceNode?.term?.formula && (
                  <FormulaContainer>
                    <ReadOnlyFormula
                      domain={domain}
                      grayOut={false}
                      value={stripMathFunctionPrefix(traceNode?.term?.formula || '')}
                    />
                  </FormulaContainer>
                )}
              </CalculationInfoContent>
            </CalculationInfoWrapper>

            {traceNode?.term && !isEmpty(terms) && (
              <ComponentBreakup>
                <Grid
                  isLoading={traceNode?.isLoading || false}
                  parentTerm={traceNode.term}
                  terms={terms}
                />
              </ComponentBreakup>
            )}

            {isDtUser && traceNode?.term?.debug && (
              <>
                <DebugInfoButton
                  size="small"
                  type="text"
                  onClick={() => setShowDebugInfo((s) => !s)}
                >
                  {'Debug info '}
                  {showDebugInfo ? '▼' : '►'}
                </DebugInfoButton>
                {showDebugInfo && (
                  <DebugViewWrapper>
                    <pre>{JSON.stringify(traceNode?.term?.debug, undefined, 4)}</pre>
                  </DebugViewWrapper>
                )}
              </>
            )}
          </>
        )}
      </ContentWrapper>

      {breakupQueryDrawerVisible && metricSupportsBreakup && (
        <BreakupQueryDrawer
          breakupQueryDrawerParams={breakupQueryDrawerParams}
          breakupQueryDrawerVisible={breakupQueryDrawerVisible}
          closeBreakupQueryDrawer={closeBreakupQueryDrawer}
          dontParseBasedOnVersion
          setSummarizeByLocal={setSummarizeByLocal}
          summarizeByObj={summarizeByObj}
        />
      )}
    </>
  );
};
