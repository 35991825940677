import { useMutation, useQueryClient } from '@tanstack/react-query';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { ModelVersionApi } from '../../../api';
import { type ModelVersion } from '../../../types';
import { removeVersion } from '../utils';

export const useDeleteVersionMutation = () => {
  const queryClient = useQueryClient();

  const deleteModelVersion = useMutation({
    mutationFn: (version: ModelVersion) => ModelVersionApi.delete(version.id),

    onSuccess: (_, data) => {
      removeVersion(queryClient, data);
    },

    onError: defaultApiErrorHandler,
  });

  return { deleteModelVersion };
};
