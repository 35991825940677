import { useMutation, useQueryClient } from '@tanstack/react-query';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { type Atleast } from 'utils/typescript';
import { ConversationApi } from '../api';
import { ConversationsStoreKeys } from '../constants';
import { type Conversation } from '../types';
import { getConversationIdentifier } from '../utils';

export const useConversationMutations = () => {
  const queryClient = useQueryClient();

  const createConversationMutation = useMutation({
    mutationFn: (conv: Partial<Conversation>) => {
      return ConversationApi.createConversation(conv);
    },

    onSuccess: (data) => {
      const { container, containerId } = data;

      queryClient.setQueryData(
        ConversationsStoreKeys.AllRecords({ container, containerId }),
        (state: Conversation[] = []) => [data, ...state],
      );

      const identifier = getConversationIdentifier(data);

      queryClient.setQueryData(
        ConversationsStoreKeys.AllRecordsWithIdentifier({
          container,
          containerId,
          identifier,
        }),
        (oldData: Conversation[] = []) => [data, ...oldData],
      );
    },

    onError: defaultApiErrorHandler,
  });

  const updateConversationMutation = useMutation({
    mutationFn: (conv: Atleast<Conversation, 'id'>) => ConversationApi.updateConversation(conv),

    onSuccess: (data) => {
      const { container, containerId } = data;

      queryClient.setQueryData(
        ConversationsStoreKeys.AllRecords({ container, containerId }),
        (state: Conversation[] = []) => [data, ...state.filter((conv) => conv.id !== data.id)],
      );

      const identifier = getConversationIdentifier(data);

      queryClient.setQueryData(
        ConversationsStoreKeys.AllRecordsWithIdentifier({
          container,
          containerId,
          identifier,
        }),
        (state: Conversation[] = []) => [data, ...state.filter((conv) => conv.id !== data.id)],
      );
    },

    onError: defaultApiErrorHandler,
  });

  const deleteConversationMutation = useMutation({
    mutationFn: (conv: Atleast<Conversation, 'id'>) => ConversationApi.deleteConversation(conv.id),

    onSuccess: (_, data) => {
      const { container, containerId } = data;

      if (!container || !containerId) {
        return;
      }

      queryClient.setQueryData(
        ConversationsStoreKeys.AllRecords({ container, containerId }),
        (state: Conversation[] = []) => state.filter((conv) => conv.id !== data.id),
      );

      const identifier = getConversationIdentifier(data as Conversation);

      queryClient.setQueryData(
        ConversationsStoreKeys.AllRecordsWithIdentifier({
          container,
          containerId,
          identifier,
        }),
        (state: Conversation[] = []) => state.filter((conv) => conv.id !== data.id),
      );
    },

    onError: defaultApiErrorHandler,
  });

  return {
    createConversationMutation,
    updateConversationMutation,
    deleteConversationMutation,
  };
};
