import { type ICellRendererParams } from 'ag-grid-community';
import { Popover } from 'components/ui/atomic-components';
import { Triangle } from 'components/ui/atomic-components/comment-triangle';
import { Analytics } from 'config/analytics';
import { ContainerType, type Comment, type ConversationAccessType } from 'data/conversations';
import { useConversationMutations, useConversationsWithIdentifier } from 'data/conversations/hooks';
import { isEmpty } from 'lodash';
import { memo, type PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useConversationsContext } from '../context';
import { ConversationPopoverContent } from '../conversation-popover-content';
import { frameConversationCreationPayload } from './utils/frame-conversation-creation-payload';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

interface Props extends PropsWithChildren<ICellRendererParams> {
  isPopoverOpen: boolean;
  cellIdentifier: string;
  onOpenChange: (open: boolean) => void;
  onClickMarker: () => void;
}

const GridCommentCell = memo(
  ({
    node,
    colDef = {},
    children,
    value,
    isPopoverOpen,
    cellIdentifier,
    onOpenChange,
    onClickMarker,
  }: Props) => {
    const { containerId, containerType } = useConversationsContext();

    const { data: convData = [] } = useConversationsWithIdentifier(
      containerType,
      Number(containerId),
      cellIdentifier,
    );

    const openComments = convData.filter((comment) => !comment.resolved);

    const { createConversationMutation } = useConversationMutations();

    const conversationCreationPayload = frameConversationCreationPayload({
      node,
      colDef,
      containerId,
      containerType,
      dataValue: value,
    });

    const allResolved = !isEmpty(convData) && isEmpty(openComments);

    const handleAddComment = async ({
      comment,
      access,
      members,
    }: {
      comment: string;
      access: ConversationAccessType;
      members: string[];
    }) => {
      await createConversationMutation.mutateAsync({
        ...conversationCreationPayload,
        comments: [{ message: comment } as Comment],
        members,
        access,
      });

      Analytics.track('Comment added', {
        category: containerType === ContainerType.Boards ? 'Reports' : 'Models',
        meta: 'Cell level',
      });
    };

    return (
      <Popover
        content={<ConversationPopoverContent data={openComments} onAddComment={handleAddComment} />}
        destroyTooltipOnHide
        open={isPopoverOpen}
        placement="bottomRight"
        onOpenChange={onOpenChange}
      >
        <Wrapper>
          {children}

          {!isEmpty(openComments) && <Triangle allResolved={allResolved} onClick={onClickMarker} />}
        </Wrapper>
      </Popover>
    );
  },
);

GridCommentCell.displayName = 'GridCommentCell';

export { GridCommentCell };
