import { type ValueFormatterFunc, type ValueFormatterParams } from 'ag-grid-community';
import { type FormattedPeriod } from 'data';
import { PercentComparisionPeriods } from 'data/boards';
import { MASKING_PLACEHOLDER } from 'data/constants';
import { type Currency } from 'data/currencies';
import { TimeDimensionName } from 'data/dimension';
import { type Metric, MetricTreeNodeTypes, type DataFormatType } from 'data/metrics';
import { type ModuleMetric } from 'data/modelling/metric';
import { PIVOT_DIMENSION_METRIC_ROW_NAME } from 'data/page-template';
import { VaryingColGroup } from 'data/page-template/grid';
import { type FrameElement } from 'data/reports/types';
import { TimeValueKeyDelimiter } from 'data/reports/utils';
import { ACTUAL, VERSION } from 'data/versions';
import { type Dictionary } from 'lodash';
import { getChartDataFormat } from 'utils/charts/get-chart-data-format';
import { getChartMetricType } from 'utils/charts/get-chart-metric-type';
import { numberFormatter } from 'utils/data-formatter';
import { BlankValueType, DataFormattingType } from 'utils/data-formatter/types';
import { isPeriodInPastPresentOrFuture } from 'utils/periods';

export const valueFormatter = ({
  column,
  columnDimensions,
  metricsMap,
  periodRangesMap,
  tenantCurrency,
  chartCurrency,
  chartDataFormat,
  chartMetricTypeMap,
}: {
  column: FrameElement;
  columnDimensions: Record<string, string>;
  metricsMap: Dictionary<ModuleMetric | Metric>;
  periodRangesMap: Dictionary<FormattedPeriod>;
  chartDataFormat?: { [metric: string]: DataFormatType };
  chartMetricTypeMap?: Record<string, DataFormattingType>;
  chartCurrency?: Currency;
  tenantCurrency: Currency;
}): ValueFormatterFunc => {
  return ({ value, data }: ValueFormatterParams) => {
    if (value === MASKING_PLACEHOLDER) {
      return value;
    }

    if (
      [
        MetricTreeNodeTypes.Blank,
        MetricTreeNodeTypes.Section,
        MetricTreeNodeTypes.TempRow,
      ].includes(data.viewNodeType)
    ) {
      return value;
    }

    const { rowDimensions } = data;
    const allDims = {
      ...rowDimensions,
      ...columnDimensions,
    };
    const metricName = allDims[PIVOT_DIMENSION_METRIC_ROW_NAME];
    const metricInfo = metricsMap[metricName];
    const type =
      column.statistic &&
      [VaryingColGroup.Attainment, VaryingColGroup.VariancePercentage].includes(column.statistic)
        ? DataFormattingType.Percentage
        : getChartMetricType(
            metricInfo?.type || DataFormattingType.Number,
            chartMetricTypeMap?.[metricName],
          );
    const dataFormat = chartDataFormat?.[metricName] || metricInfo?.dataFormat || {};

    const versionDimensionValue = allDims[VERSION];

    const period = columnDimensions[TimeDimensionName]?.split(TimeValueKeyDelimiter)?.[0];

    // Comparision values (WoW/MoM/QoQ/YoY) are shown as a percentage
    if (Object.values(PercentComparisionPeriods).includes(period as PercentComparisionPeriods)) {
      return numberFormatter({
        value,
        type: DataFormattingType.Percentage,
        ...getChartDataFormat({
          chartLevelFormat: chartDataFormat?.[metricName],
          metricLevelFormat: metricInfo?.dataFormat,
          chartCurrency,
          tenantCurrency,
        }),
      });
    }

    // if the blankValueFormat is set to BlankValueType.Zero, don't apply this format for future actual cells
    if (versionDimensionValue === ACTUAL && dataFormat.blankValueFormat === BlankValueType.Zero) {
      const timeDimensionValue = allDims[TimeDimensionName];
      const period = periodRangesMap[timeDimensionValue];
      const periodRangeState = period ? isPeriodInPastPresentOrFuture(period) : null;
      const blankValueFormat =
        periodRangeState === 'future' ? BlankValueType.Blank : BlankValueType.Zero;

      return numberFormatter({
        value,
        type,
        ...getChartDataFormat({
          chartLevelFormat: chartDataFormat?.[metricName],
          metricLevelFormat: metricInfo?.dataFormat,
          chartCurrency,
          tenantCurrency,
        }),
        blankValueFormat,
      });
    }

    return numberFormatter({
      value,
      type,
      ...getChartDataFormat({
        chartLevelFormat: chartDataFormat?.[metricName],
        metricLevelFormat: metricInfo?.dataFormat,
        chartCurrency,
        tenantCurrency,
      }),
    });
  };
};
