import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HEIGHT_TITLE_CONTENT } from '../styles';
import { FilterNotificationsPopover } from './filter/filter-popover';
import { NotificationsMoreActions } from './more-actions';

const Wrapper = styled.div`
  width: ${HEIGHT_TITLE_CONTENT};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing[8]} 0`};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.l};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const PopoverTitle = (): ReactElement => {
  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="notification.title" />
      </Title>

      <ButtonWrapper>
        <FilterNotificationsPopover />
        <NotificationsMoreActions />
      </ButtonWrapper>
    </Wrapper>
  );
};
