import { type DimensionQueryParamsObject } from 'data/dimension';

export enum PivotViewType {
  Compact = 'COMPACT',
  Table = 'TABLE',
}

export enum PivotDimensionsSummaryType {
  Top = 'TOP',
  Bottom = 'BOTTOM',
  None = 'NONE',
}

export enum PivotTimeSummaryType {
  TotalInPeriod = 'TOTAL_IN_PERIOD',
  TillDate = 'TILL_DATE',
}

export const PIVOT_DIMENSION_METRIC_ROW_NAME = '__metrics__';
type PivotDimensionRowType = typeof PIVOT_DIMENSION_METRIC_ROW_NAME;

export type PivotDimensionRows = (PivotDimensionRowType | string)[];

export interface Pivot {
  dimensions: {
    page: string[];
    rows: PivotDimensionRows;
    columns?: string[];
    versions?: string[];
    filters?: DimensionQueryParamsObject;
    lastFilterDimName?: string;
    properties?: string[];
  };
  viewType?: PivotViewType;
  showTotals?: PivotDimensionsSummaryType;
  tillDate?: boolean;
  showRowsAsFilters?: boolean;
  nestingDepth?: number;
  versionToBaselineMap?: Record<string, string> | null;
  baselineVersion?: string | null;
  baselineScenario?: string | null;
}
