import { type DimensionQueryParamsObject } from 'data/dimension';
import { isArray, sortBy } from 'lodash';

const DIM_VALUE_DELIMITER = '~*~';

export const convertToSingleValueFilters = (
  filters: DimensionQueryParamsObject,
): Record<string, string> => {
  const result: Record<string, string> = {};

  for (const [key, value] of Object.entries(filters)) {
    if (isArray(value)) {
      result[key] = sortBy(value).join(DIM_VALUE_DELIMITER);
    } else {
      result[key] = value as string;
    }
  }

  return result;
};
