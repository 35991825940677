import { ReactComponent as ArrowBackIcon } from 'assets/v2/arrow-back.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontS } from 'styles/typography';

const Wrapper = styled.div`
  ${FontS}

  color: ${({ theme }) => theme.colors.textPlaceholder};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing[8]} 0;
  align-items: center;
  width: 100%;
`;

interface Props {
  onClickUndo?: () => void;
}

export const UndoDeleteContent = ({ onClickUndo }: Props): ReactElement => {
  return (
    <Wrapper>
      <div>
        <FormattedMessage id="conversations.deletion.reply_deleted" />
      </div>
      <Button
        icon={<IconShell icon={ArrowBackIcon} />}
        size="large"
        type="link"
        onClick={onClickUndo}
      >
        <FormattedMessage id="undo" />
      </Button>
    </Wrapper>
  );
};
