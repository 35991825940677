import { Currency } from 'data/currencies';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  BlankValueType,
  DisplayUnitType,
  MetricDirection,
  NegativeValueType,
  ZeroFormatType,
} from 'utils/data-formatter/types';

const LabelWrapper = styled.div`
  width: 52px;
`;

export const displayUnitOptions = [
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.full_number.label" />,
    value: DisplayUnitType.Full,
    currencies: Object.keys(Currency) as Currency[],
  },
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.lakhs.label" />,
    value: DisplayUnitType.Lac,
    currencies: [Currency.INR],
  },
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.crores.label" />,
    value: DisplayUnitType.Cr,
    currencies: [Currency.INR],
  },
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.thousands.label" />,
    value: DisplayUnitType.K,
    currencies: Object.keys(Currency) as Currency[],
  },
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.compact_thousands.label" />,
    value: DisplayUnitType.CompactK,
    currencies: Object.keys(Currency) as Currency[],
  },
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.millions.label" />,
    value: DisplayUnitType.M,
    currencies: Object.keys(Currency) as Currency[],
  },
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.compact_millions.label" />,
    value: DisplayUnitType.CompactM,
    currencies: Object.keys(Currency) as Currency[],
  },
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.short_number.label" />,
    value: DisplayUnitType.Short,
    currencies: Object.keys(Currency) as Currency[],
  },
];

export const zeroFormatOptions = [
  {
    label: (
      <LabelWrapper>
        <FormattedMessage id="data_format.modal.zero_format_options.number.label" />
      </LabelWrapper>
    ),
    value: ZeroFormatType.Number,
  },
  {
    label: (
      <LabelWrapper>
        <FormattedMessage id="data_format.modal.zero_format_options.dash.label" />
      </LabelWrapper>
    ),
    value: ZeroFormatType.Dash,
  },
];

export const negativeValueOptions = [
  {
    label: (
      <LabelWrapper>
        <FormattedMessage id="data_format.modal.negative_value_options.default.label" />
      </LabelWrapper>
    ),
    value: NegativeValueType.Sign,
  },
  {
    label: (
      <LabelWrapper>
        <FormattedMessage id="data_format.modal.negative_value_options.brackets.label" />
      </LabelWrapper>
    ),
    value: NegativeValueType.Brackets,
  },
];

export const blankValueOptions = [
  {
    label: (
      <LabelWrapper>
        <FormattedMessage id="data_format.modal.zero_format_options.number.label" />
      </LabelWrapper>
    ),
    value: BlankValueType.Zero,
  },
  {
    label: (
      <LabelWrapper>
        <FormattedMessage id="data_format.modal.blank_value_options.blank.label" />
      </LabelWrapper>
    ),
    value: BlankValueType.Blank,
  },
];

export const directionOptions = [
  {
    label: <FormattedMessage id="data_format.modal.metric_direction.higher_is_better.label" />,
    value: MetricDirection.HigherIsBetter,
  },
  {
    label: <FormattedMessage id="data_format.modal.metric_direction.lower_is_better.label" />,
    value: MetricDirection.LowerIsBetter,
  },
];
