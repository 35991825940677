import { type InternalAxiosRequestConfig } from 'axios';
import { VERSION_MODE_COOKIE_KEY } from 'components/modules/modelling/model/version-view-banner/constants';
import Cookies from 'js-cookie';
import { Pages } from '../constants';

export const sendPageContextViaHeader = (config: InternalAxiosRequestConfig) => {
  // Check if the URL includes any of the specified endpoints
  if (window.location.pathname) {
    let identifiedPage = Pages.find((page) => window.location?.pathname?.startsWith(page.url));
    const cookieValue = Cookies.get(VERSION_MODE_COOKIE_KEY);

    if (identifiedPage?.name === 'METRICS' && window.location.search?.includes('moduleName=')) {
      identifiedPage = { name: 'METRICS_MMPP', url: '/metrics/metricName?moduleName=X' };
    }

    config.headers['Page-Context'] = identifiedPage?.name || 'OTHER';

    if (cookieValue) {
      config.headers['Version-ID-Cookie'] = cookieValue;
    }
  }

  return config;
};
