import { Avatar, AvatarGroup } from 'components/ui/atomic-components';
import styled from 'styled-components';
import { FontM, FontS, FontXs, FontXxs, SingleLineEllipsis } from 'styles/typography';

const TopRightSection = styled.div`
  display: none;
`;

export const styles = {
  Wrapper: styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[8]};
    align-items: center;

    :hover {
      ${TopRightSection} {
        display: flex;
      }
    }
  `,

  StyledAvatar: styled(Avatar)`
    align-self: flex-start;
  `,

  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: ${({ theme }) => theme.spacing[12]};
    width: 292px;
  `,

  Content: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[8]};
  `,

  TopSection: styled.div`
    display: flex;
    justify-content: space-between;
    height: 24px;
  `,

  TitleWrapper: styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[8]};
    overflow: hidden;
  `,

  Title: styled.div`
    ${FontM};
    ${SingleLineEllipsis};

    color: ${({ theme }) => theme.colors.textTitle1};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    white-space: nowrap;
  `,

  SubTitle: styled.div`
    ${FontS};
    ${SingleLineEllipsis};

    color: ${({ theme }) => theme.colors.textPlaceholder};
    max-width: fit-content;
  `,

  TopRightSection,

  AccessInfo: styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[8]};
    align-items: center;
  `,

  AccessIcon: styled.div`
    ${FontXs}

    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};
    color: ${({ theme }) => theme.colors.textPlaceholder};
  `,

  MembersAvatarGroup: styled(AvatarGroup)`
    .ant-avatar:not([maxcount]) {
      background-color: ${({ theme }) => theme.colors.bgMedium};
      color: ${({ theme }) => theme.colors.textBody2};
      border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    }
  `,

  Pill: styled.div`
    ${FontXxs}

    background-color: ${({ theme }) => theme.colors.gray100};
    border-radius: 40px;
    padding: 0 ${({ theme }) => theme.spacing[4]};
    color: ${({ theme }) => theme.colors.textLabel};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,

  TextFooter: styled.div`
    ${FontS}

    color: ${({ theme }) => theme.colors.textPrimary};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    cursor: pointer;
  `,

  SubElements: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[12]};
  `,
};
