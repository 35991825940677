import { type Currency } from 'data/currencies';

export enum DataFormattingType {
  Currency = 'CURRENCY',
  Number = 'NUMBER',
  Percentage = 'PERCENTAGE',
}

export enum DisplayUnitType {
  K = 'K',
  M = 'M',
  Lac = 'LAC',
  Cr = 'CR',
  Short = 'SHORT',
  Full = 'FULL',
  CompactK = 'COMPACT_K',
  CompactM = 'COMPACT_M',
}

export enum ZeroFormatType {
  Number = 'NUMBER',
  Dash = 'DASH',
}

export enum NegativeValueType {
  Sign = 'DEFAULT',
  Brackets = 'BRACKETS',
}

export enum BlankValueType {
  Blank = 'BLANK',
  Zero = 'ZERO',
}

export enum MetricDirection {
  HigherIsBetter = 'HIGHER_IS_BETTER',
  LowerIsBetter = 'LOWER_IS_BETTER',
}

export interface FormatterProps {
  valAsInt: number;
  type: DataFormattingType;
  currency: Currency;
  displayUnit: DisplayUnitType;
  isCompact: boolean;
  defaultOptions: Intl.NumberFormatOptions;
}
