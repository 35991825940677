import { ReactComponent as DefaultSortIcon } from 'assets/v2/default_sort.svg';
import { ReactComponent as DotsVerticalIcon } from 'assets/v2/dots-vertical.svg';
import { ReactComponent as SortAscendingIcon } from 'assets/v2/sort-ascending.svg';
import { ReactComponent as SortDescendingIcon } from 'assets/v2/sort-descending.svg';
import { Button, Dropdown, IconShell } from 'components/ui/atomic-components';
import { DropdownItemLabel } from 'components/ui/atomic-components/dropdown/dropdown-item-label';
import { useUnderlyingDataContext } from 'components/ui/breakup-query/context/use-underlying-data-context';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  colName: string;
}

export const OverflowMenu = ({ colName }: Props): ReactElement => {
  const { sortedColumn, sortOrder, setSortedColumn, setSortOrder, setCurrentPage } =
    useUnderlyingDataContext();

  const menuItems = [
    {
      key: 'sort',
      type: 'group',
      label: <FormattedMessage id="sort" />,
      children: [
        {
          key: 'default',
          label: <DropdownItemLabel title={<FormattedMessage id="default" />} />,
          icon: <IconShell color="iconDefault" icon={DefaultSortIcon} />,
          onClick: () => {
            if (!colName) {
              return;
            }

            setSortedColumn(undefined);
            setSortOrder(undefined);
            setCurrentPage(1);
          },
        },
        {
          key: 'ASC',
          label: <DropdownItemLabel title={<FormattedMessage id="sort_ascending" />} />,
          icon: <IconShell color="iconDefault" icon={SortAscendingIcon} />,
          onClick: () => {
            if (!colName) {
              return;
            }

            setSortedColumn(colName);
            setSortOrder('ASC');
            setCurrentPage(1);
          },
        },
        {
          key: 'DESC',
          label: <DropdownItemLabel title={<FormattedMessage id="sort_descending" />} />,
          icon: <IconShell color="iconDefault" icon={SortDescendingIcon} />,
          onClick: () => {
            if (!colName) {
              return;
            }

            setSortedColumn(colName);
            setSortOrder('DESC');
            setCurrentPage(1);
          },
        },
      ],
    },
  ];

  return (
    <>
      <Dropdown
        menu={{
          items: menuItems,
          selectedKeys: sortOrder && sortedColumn === colName ? [sortOrder] : undefined,
          activeKey: sortedColumn === colName ? sortOrder : undefined,
        }}
        trigger={['click']}
      >
        <Button
          icon={<IconShell icon={DotsVerticalIcon} size="sm" />}
          size="extraSmall"
          type="text"
        />
      </Dropdown>
    </>
  );
};
