import styled from 'styled-components';
import { defaultTheme } from 'styles/theme';

export const styles = {
  Wrapper: styled.div<{ readOnly?: boolean }>`
    background-color: ${({ theme, readOnly }) => (!readOnly ? theme.colors.gray00 : 'inherit')};
    border: ${({ readOnly, theme }) => {
      return readOnly ? 'none' : `1px solid ${theme.colors.outlineDefault}`;
    }};
    border-radius: ${({ theme }) => theme.borderRadius.xs};
  `,
  inputStyles: (readOnly?: boolean): unknown => ({
    control: {
      fontFamily: defaultTheme.font.text,
      minHeight: !readOnly ? 38 : undefined,
    },

    highlighter: {
      minHeight: !readOnly ? 38 : undefined,
      padding: !readOnly ? defaultTheme.spacing[8] : 0,
      fontSize: defaultTheme.fontSize.s,
      lineHeight: defaultTheme.lineHeight.s,
      border: 'none',
      outline: 'none',
    },

    input: {
      outline: 'none',
      overflow: 'auto',
      border: 'none',
      padding: !readOnly ? defaultTheme.spacing[8] : 0,
      fontSize: defaultTheme.fontSize.s,
      color: defaultTheme.colors.textBody1,
      lineHeight: defaultTheme.lineHeight.s,
      minHeight: !readOnly ? 38 : undefined,
    },
    suggestions: {
      marginTop: defaultTheme.spacing[36],
      minWidth: 200,
      zIndex: 10,
      list: {
        display: 'flex',
        flexDirection: 'column',
        background: defaultTheme.colors.gray00,
        border: `solid 1px ${defaultTheme.colors.outlineDefault}`,
        borderRadius: defaultTheme.borderRadius.s,
        marginLeft: '-4px',
        padding: defaultTheme.spacing[4],
        gap: defaultTheme.spacing[4],
      },
      item: {
        padding: defaultTheme.spacing[8],
        borderRadius: defaultTheme.borderRadius.s,
        background: 'transparent',

        '&focused': {
          backgroundColor: defaultTheme.colors.bgMedium,
        },
      },
    },
  }),
  mentionStyle: {
    color: defaultTheme.colors.textPrimary,
    fontWeight: defaultTheme.fontWeight.medium,
    zIndex: 1,
    position: 'relative',
    margin: '-0.016rem',
  },
  Footer: styled.div`
    display: flex;
  `,
};
