import { type ID } from 'data';
import { ListUpdateType, type ListUpdate } from 'data/modelling/lists';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { type ListGridRow } from '../../../../grid/types';
import { DiffFields } from './diff-fields';
import { EssentialFields } from './essential-fields';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[2]};
`;

interface Props {
  listUpdate: ListUpdate;
  isRecommendation: boolean;
  pickedRecommendationRowId?: ID | null;
  matchedRow?: ListGridRow;
}

export const Updates = ({
  listUpdate,
  isRecommendation,
  pickedRecommendationRowId,
  matchedRow,
}: Props): ReactElement => {
  return (
    <Wrapper>
      {[ListUpdateType.Create, ListUpdateType.Delete, ListUpdateType.Terminate].includes(
        listUpdate.cardType,
      ) &&
        !isRecommendation && <EssentialFields listUpdate={listUpdate} matchedRow={matchedRow} />}
      {(listUpdate.cardType === ListUpdateType.Update || isRecommendation) && (
        <DiffFields
          isRecommendation={isRecommendation}
          listUpdate={listUpdate}
          matchedRow={matchedRow}
          pickedRecommendationRowId={pickedRecommendationRowId}
        />
      )}
    </Wrapper>
  );
};
