import { ReactComponent as CheckIcon } from 'assets/v2/checkbox.svg';
import { Avatar } from 'components/ui/atomic-components';
import { useUsers } from 'data/users';
import styled from 'styled-components';
import { styles } from './../../styles';

const Wrapper = styled.div`
  position: relative;
`;

const { NotificationTypeIcon, Icon } = styles;

export const NotificationAvatar = ({ senderEmail }: { senderEmail: string }) => {
  const { data: allUsers = [] } = useUsers();
  const currentUser = allUsers.find((u) => u.username === senderEmail);
  const name = currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : senderEmail;

  return (
    <Wrapper>
      <Avatar name={name} showTooltip={false} size="medium" />
      <NotificationTypeIcon className="notification-type-icon">
        <Icon icon={CheckIcon} />
      </NotificationTypeIcon>
    </Wrapper>
  );
};
