import { DeleteConfirmationModal } from 'components/ui/atomic-components';
import { MoreActionsMenu } from 'components/ui/collaboration/conversation-popover-content/more-actions-menu';
import { type ID } from 'data';
import { type Comment, ConversationAccessType, type Conversation } from 'data/conversations';
import { useConversationCommentMutation, useConversationMutations } from 'data/conversations/hooks';
import { type User } from 'data/users';
import dayjs from 'dayjs';
import { type Dictionary } from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getUserFullName } from 'utils';
import { AccessIcon } from '../../components/access-icon';
import { Content } from '../../components/content';
import { TopRightContent } from '../../components/top-right-content';
import { UndoDeleteContent } from '../../components/undo-delete-content';
import { type ConversationElementProps } from '../../types';

interface Props {
  conversationItem: Conversation;
  usersMap: Dictionary<User>;
}

interface ContentStateType {
  type: 'view' | 'edit' | 'delete';
  value?: string;
  deletionTimer?: NodeJS.Timeout;
}

export const useConversationElementDefaultProps = ({
  conversationItem,
  usersMap,
}: Props): ConversationElementProps => {
  const intl = useIntl();
  const { updateCommentMutation, deleteCommentMutation } = useConversationCommentMutation();
  const { deleteConversationMutation } = useConversationMutations();

  const { access, comments, resolved } = conversationItem;
  const [comment, ...replies] = comments;

  const [contentState, setContentState] = useState<Record<ID, ContentStateType>>(
    Object.fromEntries(
      comments.map((comment) => [comment.id, { type: 'view', value: comment.message }]),
    ),
  );
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const isPrivateComment = access === ConversationAccessType.Private;

  const handleMenuClick = (key: string, id: ID) => {
    if (key === 'edit') {
      setContentState((state) => ({ ...state, [id]: { ...state[id], type: 'edit' } }));
    } else if (key === 'delete') {
      setOpenDeleteConfirmation(true);
    }
  };

  const handleReplyMenuClick = (key: string, id: ID) => {
    if (key === 'edit') {
      setContentState((state) => ({ ...state, [id]: { ...state[id], type: 'edit' } }));
    } else if (key === 'delete') {
      const deletionTimer = setTimeout(() => {
        deleteCommentMutation.mutateAsync({ conversationId: conversationItem.id, commentId: id });
      }, 3000);

      setContentState((state) => ({
        ...state,
        [id]: { ...state[id], type: 'delete', deletionTimer },
      }));
    }
  };

  const generateSubtitle = (comment: Comment) => {
    if (dayjs(comment.createdAt).isSame(dayjs(comment.updatedAt))) {
      return dayjs(comment.createdAt).fromNow();
    }

    return `${intl.formatMessage({ id: 'edited' })} ${dayjs(comment.updatedAt).fromNow()}`;
  };

  useEffect(() => {
    setContentState(
      Object.fromEntries(
        comments.map((comment) => [comment.id, { type: 'view', value: comment.message }]),
      ),
    );
  }, [comments]);

  return {
    key: comment.id,
    accessIcon: <AccessIcon isPrivateComment={isPrivateComment} />,
    members: isPrivateComment
      ? conversationItem.members.map((member) => getUserFullName(usersMap[member]))
      : [],
    content: (
      <>
        <Content
          readOnly={contentState[comment.id]?.type === 'view'}
          value={contentState[comment.id]?.value}
          onCancelEdit={() => {
            setContentState((state) => ({
              ...state,
              [comment.id]: { ...state[comment.id], type: 'view', value: comment.message },
            }));
          }}
          onChange={(value) => {
            setContentState((state) => ({
              ...state,
              [comment.id]: { ...contentState[comment.id], value },
            }));
          }}
          onConfirmEdit={async () => {
            const { message } = await updateCommentMutation.mutateAsync({
              conversationId: conversationItem.id,
              comment: { ...comment, message: contentState[comment.id]?.value },
            });

            setContentState((state) => ({
              ...state,
              [comment.id]: { ...state[comment.id], type: 'view', value: message },
            }));
          }}
        />

        <DeleteConfirmationModal
          deleteButtonProps={{ loading: deleteConversationMutation.isPending }}
          open={openDeleteConfirmation}
          title={<FormattedMessage id="conversations.deletion.confimation_modal.title" />}
          onCancel={() => setOpenDeleteConfirmation(false)}
          onDelete={async () => {
            await deleteConversationMutation.mutateAsync(conversationItem);
            setOpenDeleteConfirmation(false);
          }}
        >
          <div className="comment-deletion-confirmation-modal">
            <FormattedMessage id="conversations.deletion.confimation_modal.body" />
          </div>
        </DeleteConfirmationModal>
      </>
    ),
    textFooter: !resolved ? <FormattedMessage id="conversations.see_all_comments" /> : undefined,
    subTitle: `∙ ${generateSubtitle(comment)}`,
    title: getUserFullName(comment.createdBy),
    topRightContent: (
      <TopRightContent
        comment={comment}
        conversationId={conversationItem.id}
        isResolved={conversationItem.resolved}
        onClickMenu={(key) => handleMenuClick(key, comment.id)}
      />
    ),
    subElements: replies.map((reply) => ({
      key: reply.id,
      title: getUserFullName(reply.createdBy),
      subTitle: `∙ ${generateSubtitle(reply)}`,
      overrideContent:
        contentState[reply.id]?.type === 'delete' ? (
          <UndoDeleteContent
            onClickUndo={() => {
              clearTimeout(contentState[reply.id].deletionTimer);

              setContentState((state) => ({
                ...state,
                [reply.id]: { ...state[reply.id], type: 'view' },
              }));
            }}
          />
        ) : undefined,
      content: (
        <Content
          readOnly={contentState[reply.id]?.type === 'view'}
          value={contentState[reply.id]?.value}
          onCancelEdit={() => {
            setContentState((state) => ({
              ...state,
              [reply.id]: { ...state[reply.id], type: 'view', value: reply.message },
            }));
          }}
          onChange={(value) => {
            setContentState((state) => ({
              ...state,
              [reply.id]: { ...contentState[reply.id], value },
            }));
          }}
          onConfirmEdit={async () => {
            const { message } = await updateCommentMutation.mutateAsync({
              conversationId: conversationItem.id,
              comment: { ...reply, message: contentState[reply.id]?.value },
            });

            setContentState((state) => ({
              ...state,
              [reply.id]: { ...state[reply.id], type: 'view', value: message },
            }));
          }}
        />
      ),
      topRightContent: (
        <MoreActionsMenu
          comment={reply}
          isReply
          onClick={(key) => handleReplyMenuClick(key, reply.id)}
        />
      ),
    })),
  };
};
