import { type ValueFormatterParams, type ValueGetterParams } from 'ag-grid-community';
import { type Dimension } from 'data/dimension';
import { PivotDimensionsSummaryType } from 'data/page-template';
import { isEqual } from 'lodash';

interface Props {
  params: ValueGetterParams | ValueFormatterParams;
  dimension: Pick<Dimension, 'name' | 'displayName' | 'displayNamePlural'>;
  columnIndex: number;
  repeatRowLabels?: boolean;
  dimensionSummary?: PivotDimensionsSummaryType;
  decendantRowIndexHierarchyToShowHeader?: number[];
}

const BlankLabel = '(Blank)';

export const headerCellValueGetter = ({
  params,
  dimension,
  columnIndex,
  repeatRowLabels,
  dimensionSummary,
  decendantRowIndexHierarchyToShowHeader,
}: Props): string | undefined => {
  if (columnIndex >= params.data.indexHierarchy.length) {
    return `${dimension.displayName}: All`;
  }

  if (dimensionSummary === PivotDimensionsSummaryType.Bottom) {
    if (
      params.data.isLeaf &&
      params.data.indexHierarchy.slice(columnIndex + 1).every((val: number) => val === 0)
    ) {
      return params.data.hierarchyClan[columnIndex].displayValue || BlankLabel;
    }
  } else {
    if (
      isEqual(
        params.data.indexHierarchy.slice(columnIndex + 1),
        decendantRowIndexHierarchyToShowHeader,
      )
    ) {
      return params.data.hierarchyClan[columnIndex].displayValue || BlankLabel;
    }
  }

  return repeatRowLabels ? params.data.hierarchyClan[columnIndex].displayValue || BlankLabel : '';
};
