import { type VisualQueryFilterRule } from 'data/big-query';
import { DTQL_VISUAL_QUERY_OPERATOR_LIST } from 'data/big-query/constants/dtql-visual';
import { type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { formatName } from 'utils/data-formatter';
import { getColumnNameFromRawDimensionName } from 'utils/dimensions';
import { ActionButtons } from './action-button';
import styles from './styles';
import { ValueRenderer } from './value-renderer';

const { Wrapper, CardWrapper, RuleContainer, Operator } = styles;

interface Props {
  selectedDimensions: string[];
  rule: VisualQueryFilterRule;
  onDelete: () => void;
  onUpdate: (rule: VisualQueryFilterRule) => void;
  isReadOnly?: boolean;
}

export const RuleDisplay = ({
  selectedDimensions,
  rule,
  onDelete,
  onUpdate,
  isReadOnly,
}: Props): ReactElement => {
  const intl = useIntl();
  const labelId = DTQL_VISUAL_QUERY_OPERATOR_LIST.find((o) => o.value === rule.operator)?.label;

  return (
    <Wrapper>
      <CardWrapper className="column-based-filters-rule-card-item">
        <RuleContainer>
          <div>{formatName(getColumnNameFromRawDimensionName(rule.column))}</div>
          <div>
            <Operator>{labelId && intl.formatMessage({ id: labelId })}</Operator>{' '}
            <ValueRenderer rule={rule} />
          </div>
        </RuleContainer>
        {!isReadOnly && (
          <ActionButtons
            rule={rule}
            selectedDimensions={selectedDimensions}
            onDelete={onDelete}
            onUpdate={onUpdate}
          />
        )}
      </CardWrapper>
    </Wrapper>
  );
};
