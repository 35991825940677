import { useQueryClient } from '@tanstack/react-query';
import { ReactComponent as BellIcon } from 'assets/v2/bell.svg';
import { Popover } from 'components/ui/atomic-components';
import { Badge } from 'components/ui/atomic-components/badge';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { notificationQueryOptions } from 'data/notifications/hooks/use-notifications';
import { NotificationType } from 'data/notifications/types';
import { useEffect, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  useNotificationActions,
  useNotificationsCount,
  useShowNotificationsPopover,
} from 'store/notifications';
import styled from 'styled-components';
import { LeftNavWrapper } from '../../left-nav/left-nav-item';
import { Content } from './content';
import { PopoverTitle } from './title';

const Wrapper = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconAndBadgeWrapper = styled.div`
  position: relative;
`;

const BadgeWrapper = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
`;

export const NotificationButton = (): ReactElement => {
  const showNotificationsPopover = useShowNotificationsPopover();
  const { setShowNotificationsPopover } = useNotificationActions();
  const count = useNotificationsCount();
  const { setNotificationsCount } = useNotificationActions();

  const queryClient = useQueryClient();

  useEffect(() => {
    const prefetchNotifications = async () => {
      const notficationData = await queryClient.fetchQuery({
        ...notificationQueryOptions({ shouldFetchUnread: true, type: NotificationType.ALL }),
      });

      if (notficationData) {
        setNotificationsCount(notficationData.length);
      }
    };

    prefetchNotifications();
  }, [queryClient, setNotificationsCount]);

  return (
    <LeftNavWrapper>
      <Popover
        align={{ offset: [-15] }}
        content={<Content />}
        open={showNotificationsPopover}
        placement="leftBottom"
        title={<PopoverTitle />}
        trigger={['click']}
        onOpenChange={setShowNotificationsPopover}
      >
        <Wrapper>
          <IconAndBadgeWrapper>
            <IconShell color="iconDefault" icon={BellIcon}></IconShell>
            <BadgeWrapper>
              {count ? (
                <Badge size="extraSmall" type="inverted-red">
                  {count}
                </Badge>
              ) : null}
            </BadgeWrapper>
          </IconAndBadgeWrapper>
          <span>
            <FormattedMessage id="notification.title" />
          </span>
        </Wrapper>
      </Popover>
    </LeftNavWrapper>
  );
};
