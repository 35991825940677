import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/ui/atomic-components';
import { Analytics } from 'config/analytics';
import { type IntegrationConnectionItem, IntegrationsApi } from 'data/integrations/fivetran';
import { IntegrationKeys } from 'data/integrations/fivetran/constants';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { getCallbackUrl, getState, GoogleSheetScopes } from '../../../../../common/util';

export const useGoogleFlow = (item: IntegrationConnectionItem) => {
  const [queryParams] = useQueryParams({
    code: StringParam,
    scope: StringParam,
  });
  const navigate = useNavigate();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [instanceId, setInstanceId] = useState<number>();
  const {
    mutate: connectService,
    isPending,
    isError,
  } = useMutation({
    mutationFn: ({ code }: { code: string }) =>
      IntegrationsApi.connectIntegration({
        redirectUrl: getCallbackUrl(),
        service: item.id,
        code,
      }),

    onSuccess: async (data) => {
      setInstanceId(data?.data?.id);
      const { code, scope } = queryParams;

      if (code && !isError && scope && !scope.includes('spreadsheets.readonly')) {
        // google spreadsheet reopen oauth when spreadsheet scope is missing
        Analytics.track('Gsheets reauth', {
          category: 'Data',
        });
        notification.warning({
          message: intl.formatMessage({
            id: 'integrations.gsheets.modal.gsheets_permission_missing',
          }),
        });

        return IntegrationsApi.deleteConnectedIntegration(data?.data?.id as number).then(() => {
          window.open(
            `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
              item.clientId
            }&response_type=code&state=${getState(item.id)}&scope=${GoogleSheetScopes.join(
              ' ',
            )}&redirect_uri=${getCallbackUrl()}&prompt=consent&include_granted_scopes=true&access_type=offline`,
            '_self',
          );
        });
      }

      await queryClient.invalidateQueries({
        queryKey: IntegrationKeys.ConnectedIntegrations,
      });

      navigate(`/data/connected/${data?.data?.id}?pending=add-data`);

      return;
    },

    onError: defaultApiErrorHandler,
  });

  const debouncedConnect = useRef(
    debounce(({ code }) => {
      connectService({ code });

      Analytics.track('New source', {
        category: 'Data',
        meta: 'Source name - Gsheet',
      });
    }, 200),
  );

  useEffect(() => {
    const { code } = queryParams;

    if (item && code && !isError) {
      // react 18 useEffect runs twice, debounced only called once
      debouncedConnect.current({ code });
    }
  }, [connectService, isError, item, queryParams]);

  return {
    instanceId,
    isLoading: isPending,
  };
};
