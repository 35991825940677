import { ReactComponent as FilterIcon } from 'assets/v2/filter_dash.svg';
import { Button, IconShell, Popover } from 'components/ui/atomic-components';
import { useState } from 'react';
import { FilterPopoverContent } from './filter-popover-content';

export const FilterNotificationsPopover = () => {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      content={<FilterPopoverContent />}
      destroyTooltipOnHide
      open={open}
      overlayInnerStyle={{ padding: 0 }}
      placement="bottomLeft"
      onOpenChange={(s) => setOpen(s)}
    >
      <Button
        icon={<IconShell color="textBody2" icon={FilterIcon} size="md" />}
        size="small"
        type="text"
      />
    </Popover>
  );
};
