import {
  loadLocalSummarizeBy,
  saveLocalSummarizeBy,
} from 'components/ui/breakup-query/underlying-data-drawer/load-local-summarize-by';
import { useEffect, useState } from 'react';

export const useSummarizeBy = (
  chartId?: number,
): {
  summarizeByObj: Record<string, string>;
  setSummarizeByLocal: (metricName: string, summarizeBy: string) => void;
} => {
  const [summarizeByObj, setSummarizeByObj] = useState<Record<string, string>>({});

  useEffect(() => {
    loadLocalSummarizeBy(chartId).then((data) => data && setSummarizeByObj(data));
  }, [chartId]);

  const setSummarizeByLocal = (metricName: string, summarizeBy: string): void => {
    saveLocalSummarizeBy({
      id: chartId,
      summarizeObj: { ...summarizeByObj, [metricName]: summarizeBy },
    });
    setSummarizeByObj((oldState) => ({ ...oldState, [metricName]: summarizeBy }));
  };

  return { summarizeByObj, setSummarizeByLocal };
};
