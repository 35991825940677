import { ReactComponent as ArrowDownIcon } from 'assets/v2/arrow-down-s-fill_16.svg';
import { Dropdown, IconShell } from 'components/ui/atomic-components';
import { FilterDropdownButton } from 'components/ui/dropdown-button';
import { useReportChartContext } from 'data/boards/context';
import { type DimensionQueryParamsObject } from 'data/dimension';
import { type MenuInfo } from 'rc-menu/lib/interface';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { formatName } from 'utils/data-formatter';

interface Props {
  metricName?: string;
  dimensions: DimensionQueryParamsObject;
  setSummarizeBy: (summarizeBy: string) => void;
  summarizeBy: string;
}

const TextWrapper = styled.div`
  width: 168px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

export const SummarizeByNone = 'None';

export const SummarizeByDimension = ({
  metricName,
  dimensions,
  setSummarizeBy,
  summarizeBy = SummarizeByNone,
}: Props): ReactElement => {
  const { chart } = useReportChartContext();
  const options = [
    {
      key: SummarizeByNone,
      label: SummarizeByNone,
    },
    ...(chart?.metrics?.find((m) => m.name === metricName)?.applicableDimensions || [])
      .filter((v) => !dimensions[v])
      .map((d) => ({
        key: d,
        label: formatName(d),
      })),
  ];

  const handleMenuItemClick = (menuInfo: MenuInfo) => {
    setSummarizeBy(menuInfo.key);
  };

  return (
    <Dropdown
      menu={{
        items: options,
        selectable: true,
        selectedKeys: [summarizeBy],
        onClick: handleMenuItemClick,
      }}
      trigger={['click']}
    >
      <FilterDropdownButton size="small">
        <TextWrapper>
          <FormattedMessage id="metrics.summary_editor.modal.summarize_by" />
          {`: ${summarizeBy}`}
        </TextWrapper>
        <IconShell color="iconDefault" icon={ArrowDownIcon} />
      </FilterDropdownButton>
    </Dropdown>
  );
};
