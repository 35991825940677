import { type Currency } from 'data/currencies';
import { type ReactElement } from 'react';
import { Select } from '../atomic-components';
import { useCurrencyOptions } from './hooks';
import { DropdownIndicator } from './select-components/dropdown-indicator';
import { Option } from './select-components/option';
import { SingleValue } from './select-components/single-value';
import { type CurrencySelectOption } from './types';

interface Props {
  value?: Currency;
  isDisabled?: boolean;
  enableAllCurrenciesOptions?: boolean;
  hidePrefixLabel?: boolean;
  onChange?: (currency: Currency) => void;
}

export const CurrencySelector = ({
  value,
  isDisabled,
  enableAllCurrenciesOptions,
  hidePrefixLabel,
  onChange,
}: Props): ReactElement => {
  const currencyOptions = useCurrencyOptions({ enableAllCurrenciesOptions, hidePrefixLabel });

  return (
    <Select<CurrencySelectOption>
      components={{ Option, SingleValue, DropdownIndicator }}
      isDisabled={isDisabled}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      options={currencyOptions}
      size="small"
      styles={{
        menu: (base) => ({ ...base, minWidth: 150 }),
        valueContainer: (base) => ({ ...base, margin: 0, marginRight: 4, padding: 0 }),
        control: () => ({ border: 'none', display: 'flex' }),
        container: () => ({
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }),
        dropdownIndicator: (base) => ({ ...base, width: 'fitContent', padding: 0 }),
      }}
      value={currencyOptions.find((option) => option.value === value)}
      onChange={(currency) => onChange?.(currency?.value)}
    />
  );
};
