import {
  DimensionAllMarker,
  TimeDimensionName,
  type DimensionQueryParamsObject,
} from 'data/dimension';
import { PIVOT_DIMENSION_METRIC_ROW_NAME } from 'data/page-template';
import { VERSION } from 'data/versions';
import { intersection, isArray, isEmpty } from 'lodash';

export const constructUnderlyingDataDimsForTable = ({
  cellDims,
  boardDims,
  chartDims,
  chartLevelFixedFilters,
  chartFilterDimNames,
}: {
  cellDims: Record<string, string>;
  boardDims: Record<string, string[]>;
  chartDims: Record<string, string[]>;
  chartLevelFixedFilters: DimensionQueryParamsObject;
  chartFilterDimNames: string[];
}): Record<string, string[]> => {
  const cleanedCellDims: Record<string, string[]> = {};

  // Cell dims might have * from ag grid or be a simple dimName=dimValue. Need to remove * and convert to name=[value]
  for (const [dimName, dimValue] of Object.entries(cellDims)) {
    if (dimValue !== DimensionAllMarker) {
      cleanedCellDims[dimName] = isArray(dimValue) ? dimValue : [dimValue];
    }
  }

  return constructUnderlyingDataDims({
    pointOrCellDims: cleanedCellDims,
    boardDims,
    chartDims,
    chartLevelFixedFilters,
    chartFilterDimNames,
  });
};

export const constructUnderlyingDataDims = ({
  pointOrCellDims,
  boardDims,
  chartDims,
  chartLevelFixedFilters,
  chartFilterDimNames,
}: {
  pointOrCellDims: Record<string, string[]>;
  boardDims: Record<string, string[]>;
  chartDims: Record<string, string[]>;
  chartLevelFixedFilters: DimensionQueryParamsObject;
  chartFilterDimNames: string[];
}): Record<string, string[]> => {
  const out = { ...chartDims, ...pointOrCellDims };

  // Hard filters should strictly apply on the chart, hence we take intersection if there are any extra/left over values of a particular dimension in chart dimMap
  for (const [dimName, dimValue] of Object.entries(chartLevelFixedFilters)) {
    if (isEmpty(dimValue)) {
      continue;
    }

    if (out[dimName]) {
      out[dimName] = intersection(out[dimName], dimValue);
    } else {
      out[dimName] = dimValue;
    }
  }

  // Chart filters take priority over board filters. Also if chart filters have dimName=All and board has dimName=X, dimName=All takes precedence.
  for (const [dimName, dimValue] of Object.entries(boardDims)) {
    if (!out[dimName] && !chartFilterDimNames.includes(dimName)) {
      out[dimName] = dimValue;
    }
  }

  delete out[TimeDimensionName];
  delete out[PIVOT_DIMENSION_METRIC_ROW_NAME];
  delete out[VERSION];

  return out;
};
