import { useQuery } from '@tanstack/react-query';
import { type AxiosRequestConfig } from 'axios';
import { ScenarioApi } from '../api';
import { ScenarioStoreKeys } from '../constants';

export const useScenarios = (config?: AxiosRequestConfig) => {
  const { data = [], isLoading } = useQuery({
    queryKey: ScenarioStoreKeys.AllRecords(config),

    queryFn: () => ScenarioApi.fetchScenarios(config),
  });

  return {
    scenarios: data,
    isScenarioFeatureActive: data.length > 1,
    isLoading,
  };
};
