import { create } from 'zustand';

type ConnectionStore = {
  isUsingConnectionParam: boolean;

  actions: {
    setIsUsingConnectionParam: (isUsingConnectionParam: boolean) => void;
  };
};

const useConnectionStore = create<ConnectionStore>()((set) => ({
  isUsingConnectionParam: false,

  actions: {
    setIsUsingConnectionParam: (isUsingConnectionParam: boolean) => {
      set({ isUsingConnectionParam });
    },
  },
}));

export const useIsUsingConnectionParam = () =>
  useConnectionStore((state) => state.isUsingConnectionParam);

export const useConnectionActions = () => useConnectionStore((state) => state.actions);
