import { useQuery } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { NotificationsApi } from '../api';
import { type NotificationType } from '../types';

type QueryFunctionParams = {
  shouldFetchUnread: boolean;
  type?: NotificationType;
};

export const notificationQueryKeys = ({ shouldFetchUnread, type }: QueryFunctionParams) => [
  'notifications',
  shouldFetchUnread,
  type,
];

export const notificationQueryOptions = ({ shouldFetchUnread, type }: QueryFunctionParams) => ({
  queryKey: notificationQueryKeys({ shouldFetchUnread, type }),
  queryFn: () => NotificationsApi.fetchNotifications(shouldFetchUnread, type),
  staleTime: StaleTime.Last,
});

export const useNotifications = ({ shouldFetchUnread, type }: QueryFunctionParams) => {
  return useQuery({
    ...notificationQueryOptions({ shouldFetchUnread, type }),
  });
};
