import { create } from 'zustand';

interface ChatWidgetStore {
  isLastMessageStreaming: boolean;
  minimisedTime: Date | null;
  pauseLastMessageStreaming: () => void;
}

export const useChatWidgetStore = create<ChatWidgetStore>()((set) => ({
  isLastMessageStreaming: false,
  minimisedTime: null,
  pauseLastMessageStreaming: () => null,
}));
