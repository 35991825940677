import { type Granularity } from 'data';
import { DimensionAllMarker, TimeDimensionName } from 'data/dimension';
import { PIVOT_DIMENSION_METRIC_ROW_NAME } from 'data/page-template';
import { TimeValueKeyDelimiter } from 'data/reports/utils';
import { VERSION } from 'data/versions';
import { extractGranularityAndPeriod } from 'utils';

interface ReturnType {
  metricName: string;
  versionName: string;
  periodStartDate: string | null;
  periodGranularity: Granularity;
  time?: string;
  period: string | null;
  rest: Record<string, string>;
}

export const extractSpecialDims = (cellDims: Record<string, string>): ReturnType => {
  const dimensionsWithoutAll: Record<string, string> = {};

  for (const [dimName, dimValue] of Object.entries(cellDims)) {
    if (dimValue !== DimensionAllMarker) {
      dimensionsWithoutAll[dimName] = dimValue;
    }
  }

  const {
    [PIVOT_DIMENSION_METRIC_ROW_NAME]: metricName,
    [VERSION]: versionName,
    [TimeDimensionName]: timeValue = null,
    ...rest
  } = dimensionsWithoutAll;

  const [periodGranularity, periodStartDate] = extractGranularityAndPeriod(timeValue || '');

  const [period, time] = timeValue?.split(TimeValueKeyDelimiter) || [];

  return {
    metricName,
    versionName,
    periodStartDate,
    periodGranularity,
    time: time ?? timeValue,
    period: time ? period : null,
    rest,
  };
};
