import { type DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

export const applySort = <T>(key: keyof T, items: T[], event: DragEndEvent): T[] | undefined => {
  const { active, over } = event;

  const result = [...items].map((i) => ({
    ...i,
    id: `${i[key]}`,
  }));

  if (active.id !== over?.id) {
    const oldIndex = result.findIndex((i) => i.id === active.id);
    const newIndex = result.findIndex((i) => i.id === over?.id);

    return arrayMove(result, oldIndex, newIndex);
  }

  return;
};

export const applySortStrings = (items: string[], event: DragEndEvent): string[] | undefined => {
  const { active, over } = event;

  if (active.id !== over?.id) {
    const oldIndex = items.findIndex((i) => i === active.id);
    const newIndex = items.findIndex((i) => i === over?.id);

    return arrayMove(items, oldIndex, newIndex);
  }

  return;
};
