import { type RelativeRangeType } from 'components/ui/atomic-components/time-range-panel/v2/relative-panel/types';
import { type DateFilterPresetName } from 'data/bootstrap/types';
import { type Currency } from 'data/currencies';
import { createContext } from 'react';
import { type DataContextHandler, type Chart, type ChartFilterParams } from '../charts/types';

export interface ReportChartContextParams {
  chart?: Chart;
  undo?(): void;
  dataContextHandler: DataContextHandler;
  onFilterChange({ chartFilters, filteredDimensions }: ChartFilterParams): void;
  onGranularityChange(granularity: string): void;
  onTimeFilterChange(
    val?: [string, string],
    dateOption?: DateFilterPresetName,
    relativeRange?: RelativeRangeType,
  ): void;
  onCurrencyChange(currency: Currency | null): void;
  reset(): void;
  isPreviewChart: boolean;
  isRenderedInAiChat?: boolean;
}

export const ReportChartContext = createContext<ReportChartContextParams>(
  {} as ReportChartContextParams,
);
