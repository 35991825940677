import { ReactComponent as Lock12Icon } from 'assets/v2/lock_12.svg';
import { ReactComponent as World12Icon } from 'assets/v2/world_12.svg';
import { IconShell } from 'components/ui/atomic-components';
import { ConversationAccessType } from 'data/conversations';

export const conversationAccessOptions = [
  {
    label: <IconShell icon={Lock12Icon} />,
    value: ConversationAccessType.Private,
  },
  {
    label: <IconShell icon={World12Icon} />,
    value: ConversationAccessType.Public,
  },
];
