import { useMarkAsRead } from 'data/notifications/hooks/use-mark-as-read-notification';
import { NotificationStatus, type Notification } from 'data/notifications/types';
import { useNavigate } from 'react-router-dom';
import { useNotificationActions } from 'store/notifications';
import { styles } from './../../styles';
import { NotificationAvatar } from './notification-avatar';
import { NotificationContent } from './notification-content';

const { UnreadBadge, Wrapper, SubWrapper, Status } = styles;

export const TaskNotification = ({ notification }: { notification: Notification }) => {
  const navigate = useNavigate();
  const { setShowNotificationsPopover } = useNotificationActions();
  const markAsRead = useMarkAsRead();
  const { actionLink } = notification.attributes;

  return (
    <Wrapper
      onClick={() => {
        if (!actionLink) {
          return;
        }

        const baseUrl = window.location.origin;
        const pathPart = actionLink.slice(baseUrl.length);

        navigate(pathPart);
        setShowNotificationsPopover(false);
        markAsRead.mutate(notification.notificationReceiptId);
      }}
    >
      <SubWrapper>
        <Status>
          <UnreadBadge show={notification.status === NotificationStatus.ACTIVE} />
        </Status>
        <NotificationAvatar senderEmail={notification.attributes.from} />
      </SubWrapper>
      <NotificationContent notification={notification} />
    </Wrapper>
  );
};
