import { type Conversation } from 'data/conversations';
import { type User } from 'data/users';
import { type Dictionary } from 'lodash';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ConversationElement } from '../../conversation-element';
import { useConversationElementDefaultProps } from '../../conversation-element/hooks/use-conversation-element-default-props';
import { type ConversationElementProps } from '../../conversation-element/types';
import { ReplyTo } from '../reply-to';

const Wrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.outlineDefault};
  padding: ${({ theme }) => theme.spacing[12]};

  :hover {
    background-color: ${({ theme }) => theme.colors.bgLight};
  }
`;

interface Props {
  conversationItem: Conversation;
  usersMap: Dictionary<User>;
  hideAccessSelector?: boolean;
}

export const PopoverConversationElement = ({
  conversationItem,
  usersMap,
  hideAccessSelector,
}: Props): ReactElement => {
  const [showPreviousReplies, setShowPreviousReplies] = useState(false);

  const defaultProps = useConversationElementDefaultProps({
    conversationItem,
    usersMap,
  });

  const isTextButtonVisible =
    !showPreviousReplies && defaultProps.subElements && defaultProps.subElements.length > 1;

  const textFooter = isTextButtonVisible ? (
    <FormattedMessage id="conversations.show_prev_replies" />
  ) : undefined;
  const subElements =
    isTextButtonVisible && defaultProps.subElements
      ? [defaultProps.subElements.at(-1) as ConversationElementProps]
      : defaultProps.subElements;
  const footerContent = !conversationItem.resolved ? (
    <ReplyTo
      conversationAccessType={conversationItem.access}
      conversationId={conversationItem.id}
      conversationMembers={conversationItem.members}
    />
  ) : undefined;

  return (
    <Wrapper>
      <ConversationElement
        {...defaultProps}
        footerContent={footerContent}
        hideAccessSelector={hideAccessSelector}
        subElements={subElements}
        textFooter={textFooter}
        onClickTextFooter={() => setShowPreviousReplies(true)}
      />
    </Wrapper>
  );
};
