import { ReactComponent as ArrowRightIcon } from 'assets/v2/arrow-right.svg';
import { Button, IconText } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { type IntegrationAuthFlow } from '../types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[20]};
  gap: ${({ theme }) => theme.spacing[12]};
  margin: auto;
  width: 500px;
`;

const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

interface Props {
  flows: IntegrationAuthFlow[];
  onSelect: (flow: IntegrationAuthFlow) => void;
}

export const AuthFlowSelector = ({ flows, onSelect }: Props): ReactElement => {
  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="integrations.connector_auth_modal.select_auth_option" />
      </Title>

      {flows.map((flow) => (
        <Button key={flow?.name} block onClick={() => onSelect(flow)}>
          <IconText icon={ArrowRightIcon} iconPosition="after">
            {flow?.name}
          </IconText>
        </Button>
      ))}
    </Wrapper>
  );
};
