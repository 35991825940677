import { type QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { type ID } from 'data';
import { BoardsApi } from 'data/boards/api';
import { FoldersApi } from 'data/folder/api';
import { type Folder, FolderType, type ItemMoveRequest } from 'data/folder/types';
import { ModuleApi } from 'data/modelling/modules';
import { omit } from 'lodash';
import { useParams } from 'react-router-dom';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { type Atleast } from 'utils/typescript';

const MoveRequestMap: { [key in FolderType]: (req: ItemMoveRequest) => Promise<void> } = {
  [FolderType.Module]: ModuleApi.move,
  [FolderType.Board]: BoardsApi.moveBoard,
  [FolderType.Folder]: FoldersApi.moveItem,
};

export const useFolderMutations = () => {
  const queryClient = useQueryClient();
  const { folderId } = useParams() as { folderId: string };

  const createFolder = useMutation({
    mutationFn: (folder: Folder) => FoldersApi.createFolder(omit(folder, 'id')),
    onSuccess: () => invalidationOfDataOnCurrentPage(queryClient, folderId),
    onError: defaultApiErrorHandler,
  });
  const updateFolder = useMutation({
    mutationFn: (folder: Atleast<Folder, 'id'>) => FoldersApi.updateFolder(folder),
    onSuccess: () => invalidationOfDataOnCurrentPage(queryClient, folderId),
  });
  const moveItem = useMutation({
    mutationFn: (itemMoveRequest: ItemMoveRequest) => {
      return MoveRequestMap[itemMoveRequest.folderType]?.(itemMoveRequest);
    },

    onSuccess: () => invalidationOfDataOnCurrentPage(queryClient, folderId),
    onError: defaultApiErrorHandler,
  });
  const deleteFolder = useMutation({
    mutationFn: (folderId: ID) => FoldersApi.delete(folderId),
    onSuccess: () => invalidationOfDataOnCurrentPage(queryClient, folderId),
  });

  return {
    createFolder,
    deleteFolder,
    updateFolder,
    moveItem,
  };
};

const invalidationOfDataOnCurrentPage = (queryClient: QueryClient, folderId?: string) => {
  if (folderId) {
    queryClient.invalidateQueries({
      queryKey: ['folders', Number(folderId)],
    });
  } else {
    queryClient.invalidateQueries({
      queryKey: ['model'],
    });
  }
};
