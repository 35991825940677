import { type SortOrder, type BreakupQueryDrawerParams } from 'data/big-query';
import { TimeDimensionName } from 'data/dimension';
import { PIVOT_DIMENSION_METRIC_ROW_NAME } from 'data/page-template';
import { SCENARIO } from 'data/scenarios';
import { useState, type ReactElement, useMemo } from 'react';
import { UnderlyingDataContext } from '../context';
import { useBreakupQueryDrawerHook } from '../hooks';
import { UnderlyingDataDrawer } from './drawer';
import { SummarizeByNone } from './summarize';

interface Props {
  breakupQueryDrawerVisible: boolean;
  closeBreakupQueryDrawer: () => void;
  breakupQueryDrawerParams?: BreakupQueryDrawerParams;
  summarizeByObj: Record<string, string>;
  setSummarizeByLocal: (metricName: string, summarizeBy: string) => void;
  dontParseBasedOnVersion?: boolean;
}

export const BreakupQueryDrawer = ({
  breakupQueryDrawerVisible,
  closeBreakupQueryDrawer,
  breakupQueryDrawerParams,
  summarizeByObj,
  setSummarizeByLocal,
  dontParseBasedOnVersion,
}: Props): ReactElement => {
  const [sortedColumn, setSortedColumn] = useState<string>();
  const [sortOrder, setSortOrder] = useState<SortOrder>();
  const [columnFilters, setColumnFilters] = useState<Record<string, string[]>>({});
  const [currentPage, setCurrentPage] = useState(1);
  const {
    f: dimensions = {},
    startDate,
    endDate,
    metricName,
    granularity,
    version,
  } = breakupQueryDrawerParams || {};

  delete dimensions?.[TimeDimensionName];
  delete dimensions?.[PIVOT_DIMENSION_METRIC_ROW_NAME];
  delete dimensions?.[SCENARIO];

  const summarizeBy = summarizeByObj[metricName || ''] || SummarizeByNone;
  const underlyingDataContextParams = useMemo(
    () => ({
      dimensions,
      startDate,
      endDate,
      metricName,
      granularity,
      version,
      sortedColumn,
      sortOrder,
      columnFilters,
      setSortedColumn,
      setSortOrder,
      setColumnFilters,
      setCurrentPage,
    }),
    [
      dimensions,
      startDate,
      endDate,
      metricName,
      granularity,
      version,
      sortedColumn,
      sortOrder,
      columnFilters,
      setSortedColumn,
      setSortOrder,
      setColumnFilters,
      setCurrentPage,
    ],
  );

  const { breakupData, breakupQueryIsLoading, breakupQueryIsError, breakupErrorMessage } =
    useBreakupQueryDrawerHook({
      metricName,
      dimensions: { ...columnFilters, ...dimensions },
      startDate,
      endDate,
      granularity,
      version,
      sortedColumn,
      sortOrder,
      page: currentPage,
      summarizeBy,
      dontParseBasedOnVersion,
    });

  return (
    <UnderlyingDataContext.Provider value={underlyingDataContextParams}>
      <UnderlyingDataDrawer
        breakupData={breakupData}
        breakupErrorMessage={breakupErrorMessage}
        breakupQueryDrawerParams={breakupQueryDrawerParams}
        breakupQueryDrawerVisible={breakupQueryDrawerVisible}
        breakupQueryIsError={breakupQueryIsError}
        breakupQueryIsLoading={breakupQueryIsLoading}
        closeBreakupQueryDrawer={closeBreakupQueryDrawer}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSummarizeBy={(summarizeBy: string) => setSummarizeByLocal(metricName || '', summarizeBy)}
        summarizeBy={summarizeBy}
      />
    </UnderlyingDataContext.Provider>
  );
};
