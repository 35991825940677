import { ACTUAL, VERSION } from 'data/versions';
import { type Pivot } from './types';

export const getFirstFilteredVersion = (pivot?: Pivot): string => {
  if (!pivot) {
    return ACTUAL;
  }

  const pivotFilters = pivot.dimensions.filters || {};
  const selectedVersionFilters = pivotFilters[VERSION];

  if (selectedVersionFilters) {
    return selectedVersionFilters[0];
  }

  return pivot.dimensions.versions?.[0] || ACTUAL;
};
