import dayjs from 'dayjs';
import { isEmpty, isNumber, isString, trim } from 'lodash';

export const determineType = (value: string | number): 'number' | 'string' | 'date' => {
  if (isNumber(value) || (!isEmpty(trim(value.toString())) && isFinite(Number(value)))) {
    return 'number';
  }

  if (isString(value) && dayjs(value, 'YYYY-MM-DD', true).isValid()) {
    return 'date';
  }

  return 'string';
};
