import { type Dimension } from 'data/dimension';
import { PivotDimensionsSummaryType } from 'data/page-template';
import { isEmpty, isUndefined } from 'lodash';

interface Props {
  pivotHeaderDimensions: Dimension[];
  showSummaryOn?: PivotDimensionsSummaryType;
  hideAggregatesFor?: string[];
}

export const getDecendantRowIndexHierarchyToShowHeader = ({
  pivotHeaderDimensions,
  hideAggregatesFor,
  showSummaryOn,
}: Props): ((level: number) => number[]) => {
  const levelWiseDimSumm = pivotHeaderDimensions.map((dim) => {
    return !isEmpty(hideAggregatesFor) && hideAggregatesFor?.includes(dim.name)
      ? PivotDimensionsSummaryType.None
      : showSummaryOn;
  });

  const memoizationLookup: Record<number, number[]> = {};

  const decendantRowIndexHierarchyToShowHeader = (level: number): number[] => {
    if (!isUndefined(memoizationLookup[level])) {
      return memoizationLookup[level];
    }

    if (level === pivotHeaderDimensions.length - 1) {
      memoizationLookup[level] = [];

      return memoizationLookup[level];
    }

    if (levelWiseDimSumm[level + 1] !== PivotDimensionsSummaryType.None) {
      memoizationLookup[level] = [];

      return memoizationLookup[level];
    }

    memoizationLookup[level] = [0, ...decendantRowIndexHierarchyToShowHeader(level + 1)];

    return memoizationLookup[level];
  };

  return decendantRowIndexHierarchyToShowHeader;
};
