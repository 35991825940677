import { ConfirmationModal, type ConfirmationModalProps } from 'components/ui/atomic-components';
import { useUsers } from 'data/users';
import { keyBy } from 'lodash';
import { useMemo, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getUserFullName } from 'utils';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
`;

const Users = styled.div`
  display: flex;
`;

const User = styled.div`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const AddNewMembersConfirmationModal = (
  props: ConfirmationModalProps & { newMembers: string[] },
): ReactElement => {
  const { data: users = [] } = useUsers();

  const usersMap = useMemo(() => keyBy(users, 'username'), [users]);

  return (
    <ConfirmationModal {...props}>
      <Content className="add-new-members-confirmation-modal">
        <div>
          <FormattedMessage id="conversations.new_members.warning" />
        </div>
        <div>
          <FormattedMessage id="conversations.new_members.continue_message" />
        </div>
        <Users>
          {props.newMembers.map((member) => (
            <User key={member}>{getUserFullName(usersMap[member])}</User>
          ))}
        </Users>
      </Content>
    </ConfirmationModal>
  );
};
