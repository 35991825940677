import { ReactComponent as ArrowUpIcon } from 'assets/v2/arrow-up.svg';
import { ReactComponent as CloseIcon } from 'assets/v2/close.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { MentionsEditor } from '../../mentions-editor';

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing[6]};
  display: flex;
  gap: ${({ theme }) => theme.spacing[6]};
  justify-content: flex-end;
  width: 100%;
`;

interface Props {
  readOnly?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  onConfirmEdit?: () => void;
  onCancelEdit?: () => void;
}

export const Content = ({
  readOnly,
  value,
  onChange,
  onCancelEdit,
  onConfirmEdit,
}: Props): ReactElement => {
  return (
    <div
      aria-hidden="true"
      onClick={(event) => {
        if (!readOnly) {
          event.stopPropagation();
        }
      }}
    >
      <MentionsEditor
        footerContent={
          <Wrapper>
            <Button
              icon={<IconShell color="iconDefault" icon={CloseIcon} />}
              size="extraSmall"
              onClick={onCancelEdit}
            />

            <Button
              icon={<IconShell icon={ArrowUpIcon} />}
              size="extraSmall"
              type="primary"
              onClick={onConfirmEdit}
            />
          </Wrapper>
        }
        readOnly={readOnly}
        value={value}
        onChange={(e) => {
          onChange?.(e.target.value);
        }}
      />
    </div>
  );
};
