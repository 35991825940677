import { type FormInstance, useWatch } from 'antd/es/form/Form';
import { ReactComponent as ArrowUpIcon } from 'assets/v2/arrow-up.svg';
import { Button, Form, IconShell, Segmented } from 'components/ui/atomic-components';
import { ConversationAccessType } from 'data/conversations';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { conversationAccessOptions } from './conversation-access-options';
import { type FormType } from './types';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing[6]};
  width: 100%;
`;

const SegmentedWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[8]};
`;

const StyledSegmented = styled(Segmented)`
  .ant-segmented-item-selected {
    svg {
      color: ${({ theme }) => theme.colors.primary500};
    }
  }
`;

const Text = styled.div`
  ${FontXs}

  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.textPlaceholder};
`;

interface Props {
  form: FormInstance<FormType>;
  isPosting?: boolean;
  hideAccessSelector?: boolean;
  onPost?: () => void;
}

export const FooterContent = ({
  form,
  isPosting,
  hideAccessSelector,
  onPost,
}: Props): ReactElement => {
  const access = useWatch('access', form);

  return (
    <Wrapper>
      {!hideAccessSelector ? (
        <SegmentedWrapper>
          <Form.Item name="access" noStyle>
            <StyledSegmented options={conversationAccessOptions} size="small" type="icon" />
          </Form.Item>

          <Text>
            {access === ConversationAccessType.Public ? (
              <FormattedMessage id="conversations.visible_to_all" />
            ) : (
              <FormattedMessage id="conversations.visible_to_tagged" />
            )}
          </Text>
        </SegmentedWrapper>
      ) : (
        <div />
      )}

      <Button
        icon={<IconShell icon={ArrowUpIcon} />}
        loading={isPosting}
        size="extraSmall"
        type="primary"
        onClick={onPost}
      />
    </Wrapper>
  );
};
