import { type FC } from 'react';
import styled from 'styled-components';

const Element = styled.div<{ $allResolved?: boolean }>`
  cursor: pointer;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: ${({ theme: { colors }, $allResolved }) => {
      return $allResolved ? colors.gray400 : colors.yellow400;
    }}
    transparent transparent transparent;
`;

export const Triangle: FC<
  React.PropsWithChildren<{ allResolved: boolean; onClick?: () => void }>
> = ({ allResolved, onClick }) => {
  return <Element $allResolved={allResolved} onClick={onClick} />;
};
