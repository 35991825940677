import {
  Granularity,
  GranularityDayJsMap,
  type NewPeriod,
  PeriodRangeState,
  type FormattedPeriod,
} from 'data';
import { TimeValueKeyDelimiter } from 'data/reports/utils';
import dayjs from 'dayjs';

export const filterPeriodsByGranularity = (
  periods: NewPeriod[],
  granularity: Granularity,
): NewPeriod[] => {
  return periods?.filter((p) => p.type === granularity);
};

export const isPeriodInPastPresentOrFuture = (
  period: NewPeriod | FormattedPeriod,
  overriddenCurrentDate?: string,
): PeriodRangeState => {
  const currentDate = overriddenCurrentDate ? dayjs(overriddenCurrentDate) : dayjs();

  if (dayjs(period.endDate).isBefore(currentDate, GranularityDayJsMap.DAILY)) {
    return PeriodRangeState.Past;
  }

  // https://day.js.org/docs/en/plugin/is-between
  if (currentDate.isBetween(period.startDate, period.endDate, GranularityDayJsMap.DAILY, '[]')) {
    return PeriodRangeState.Present;
  }

  return PeriodRangeState.Future;
};

export const mapFramePeriodTypeToGranularity = (periodType?: string): Granularity => {
  if (periodType) {
    if (periodType === Granularity.YTD) {
      return Granularity.YTD;
    }
    if (periodType === Granularity.QTD) {
      return Granularity.QTD;
    }
    if (periodType.startsWith('D')) {
      return Granularity.Daily;
    }
    if (periodType.startsWith('W')) {
      return Granularity.Weekly;
    }
    if (periodType.startsWith('M')) {
      return Granularity.Monthly;
    }
    if (periodType.startsWith('Q')) {
      return Granularity.Quarterly;
    }
    if (periodType.startsWith('H')) {
      return Granularity.HalfYearly;
    }
    if (periodType.startsWith('Y')) {
      return Granularity.Yearly;
    }
  }

  return Granularity.Monthly;
};

export const extractGranularityAndPeriod = (tValue: string): [Granularity, string] => {
  const tValueSplit = tValue.split(TimeValueKeyDelimiter);

  //monthly keys don't have period type in them
  if (tValueSplit.length === 1) {
    return [Granularity.Monthly, tValueSplit[0]];
  }

  return [mapFramePeriodTypeToGranularity(tValueSplit[0]), tValueSplit[1]];
};
