import { type OpUnitType, type Dayjs } from 'dayjs';
import { type ReactText } from 'react';
import { type ObjectWithConversation } from './conversations/types';
import { type FormulaId } from './modelling/metric/types';
import type { ColGroup, ColGroupData } from './page-template/grid';

/**
 * @deprecated This type should no more be used. Discuss with API and use NewPeriod as much as possible.
 */
export type Period = string;

export interface PeriodFragment extends Required<ColGroupData> {
  formulaId: FormulaId;
  conversations?: ConversationPeriodFragment;
}

type ConversationPeriodFragment = {
  [key in ColGroup]?: ObjectWithConversation;
};

export type PeriodData = {
  [date: string]: PeriodFragment;
};

export enum Granularity {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  HalfYearly = 'HALF_YEARLY',
  Yearly = 'YEARLY',
  YTD = 'YTD',
  QTD = 'QTD',
}

export enum ActualPeriod {
  Weekly = 'W',
}

export const GranularityDayJsMap: {
  [key in Granularity]: OpUnitType;
} = {
  [Granularity.Daily]: 'day',
  [Granularity.Weekly]: 'week',
  [Granularity.Monthly]: 'month',
  [Granularity.Quarterly]: 'month',
  [Granularity.HalfYearly]: 'month',
  [Granularity.Yearly]: 'year',
  [Granularity.QTD]: 'month',
  [Granularity.YTD]: 'year',
};

export type NewPeriod = {
  startDate: Dayjs;
  endDate: Dayjs;
  type: Granularity;
  key: string;
  periodBreakupType: string;
};

export enum Status {
  Inactive = 'Inactive',
  Active = 'Active',
  Deleted = 'Deleted',
  Draft = 'Draft',
  Saving = 'Saving',
  Saved = 'Saved',
  Error = 'Error',
  Resolved = 'Resolved',
}

// These should match BE DB entity status
export enum EntityStatus {
  Active = 1,
  Resolved = 3,
}

export enum DataViz {
  Table = 'TABLE',
  Chart = 'CHART',
}

export enum PlanningStatus {
  NotPlanned = 'NOT_PLANNED',
  Planned = 'PLANNED',
}

export type ID = number;
export type UUID = string;
export const ID = (id: ReactText): ID => Number(id);

export enum SharedType {
  Private = 0,
  Public = 1,
}

export interface DateTimeFormatsType {
  HumanReadableMonthYear: string;
  HumanReadableMonthDate: string;
  HumanReadableTime: string;
  HumanReadableShortTime: string;
  APIDate: string;
  APIYearMonth: string;
  HumanReadableDate: string;
  HumanReadableDateTime: string;
  HumanReadableFullYearDate: string;
  HumanReadableFullYearDate2: string;
  DateInputFormat: string;
}

export enum PeriodRangeState {
  Past = 'past',
  Present = 'present',
  Future = 'future',
}

export enum ComputedComparisons {
  WoW = 'WOW',
  MoM = 'MOM',
  QoQ = 'QOQ',
  YoY = 'YOY',
}

export enum ComparisonValueType {
  Absolute = 'ABSOLUTE',
  Percentage = 'PERCENTAGE',
}

type Group = { name: string; displayName?: string };

export type FormattedPeriod = NewPeriod & {
  cellClass: string;
  headerCellClass: string;
  groupId: string;
  id: string;
  value: string;
  order: {
    primaryCol: ColGroup | string;
    group: Group[];
  };
};
