import { type TraceNode } from 'data/formula-trace/types';
import { type ActualsTillDatePreset } from 'data/modelling/model';
import { create } from 'zustand';

export type BreakupParams = {
  actualsTillDate?: string;
  actualsTillDateOption?: ActualsTillDatePreset;
};

export interface FormulaTraceStore {
  traceNode?: TraceNode;
  breakupQueryParams?: BreakupParams;
  setTraceNode: (node: TraceNode) => void;
  setBreakupQueryParams: (params: BreakupParams) => void;
  clear: () => void;
}

export const useFormulaTraceStore = create<FormulaTraceStore>()((set, get) => ({
  traceNode: undefined,

  setTraceNode: (node) => set((prev) => ({ traceNode: node })),
  setBreakupQueryParams: (params) => set((prev) => ({ breakupQueryParams: params })),
  clear: () => set(() => ({ traceNode: undefined })),
}));
