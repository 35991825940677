import { ReactComponent as ForbiddenIcon } from 'assets/empty/403.svg';
import { Button, notification, Result } from 'components/ui/atomic-components';
import { PermissionList } from 'data/roles/permissions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { type FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { useHasScope, useUser } from 'utils/hooks';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const InitialPageRedirect: FC<React.PropsWithChildren<unknown>> = () => {
  const hasScope = useHasScope();
  const intl = useIntl();
  const { isDtUser, user } = useUser();

  const { trainsPage } = useFlags();

  if (trainsPage) {
    return <Navigate replace to="/trains" />;
  } else if (hasScope(PermissionList.Boards)) {
    return <Navigate replace to="/reports" />;
  } else if (hasScope(PermissionList.Model)) {
    return <Navigate replace to="/models" />;
  } else if (hasScope(PermissionList.Metrics)) {
    return <Navigate replace to="/metrics" />;
  } else if (hasScope(PermissionList.Datasets)) {
    return <Navigate replace to="/data" />;
  }

  const onCopyTenantId = () => {
    navigator.clipboard.writeText(`${user.tenant}`).then(() => {
      notification.success({ message: 'Copied to clipboard' });
    });
  };

  // if no permission to pages, show no permission message
  return (
    <Wrapper>
      <Result
        icon={<ForbiddenIcon />}
        subTitle={
          <Content>
            <FormattedMessage id="403.sub_title" />

            {isDtUser && (
              <div>
                {/* eslint-disable-next-line @calm/react-intl/missing-formatted-message */}
                <Button size="extraSmall" onClick={onCopyTenantId}>
                  Copy tenant id
                </Button>
              </div>
            )}
          </Content>
        }
        title={intl.formatMessage({ id: '403.title' })}
      />
    </Wrapper>
  );
};
