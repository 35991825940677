import { type QueryClient } from '@tanstack/react-query';
import { type ProcessDataFromClipboardParams } from 'ag-grid-community';
import { notification } from 'components/ui/atomic-components';
import { type CSVPreviewRowItem } from 'data/lists';
import { ListsApi, type ListRow } from 'data/modelling/lists';
import { cloneDeep, isEmpty, last } from 'lodash';
import { type IntlShape } from 'react-intl';
import { type ListsStoreApi } from 'store/lists/store';
import { getApiError } from 'utils/error-handler';
import { fastTrunc } from 'utils/fast-trunc';
import { type StoreApi } from 'zustand';
import { updateListWithPastedValues } from '../../../bulk-paste-flow/utils';

// copying on windows excel adds an extra empty string array at the end
// https://github.com/ag-grid/ag-grid/blob/553cc15c45f14c4fad4a53a50392588335cd4e33/enterprise-modules/clipboard/src/clipboard/clipboardService.ts#L212
const removeLastLineIfBlank = (data: string[][]) => {
  const lastLine = last(data);
  const lastLineIsBlank = lastLine && lastLine.length === 1 && lastLine[0] === '';

  if (!lastLineIsBlank) {
    return data;
  }

  // do not remove the last empty line when that is the only line pasted
  if (data.length === 1) {
    return data;
  }

  // remove last empty [''] item
  data.splice(data.length - 1, 1);

  return data;
};

// remove once we have support for bulk copy paste in lists
export const processListDataFromClipboard = (
  { data }: ProcessDataFromClipboardParams,
  storeApi: StoreApi<ListsStoreApi>,
  queryClient: QueryClient,
  intl: IntlShape,
): string[][] | null => {
  const processedData = removeLastLineIfBlank(data);

  const { setBulkPasteRows, filteredRows, gridApi, config, id } = storeApi.getState();

  const cellRanges = gridApi?.getCellRanges();

  const targetColCount = cellRanges?.[0]?.columns?.length || 1;
  const targetRowCount =
    fastTrunc(
      (cellRanges?.[0]?.endRow?.rowIndex || 0) - (cellRanges?.[0]?.startRow?.rowIndex || 0),
    ) + 1;
  const sourceColCount = processedData?.[0]?.length || 1;
  const sourceRowCount = processedData.length;

  // Allow the source values repeatedly copied into the target selection
  // If the target selection is more than the source
  const rowFactor = fastTrunc(targetRowCount / sourceRowCount) || 1;
  const colFactor = fastTrunc(targetColCount / sourceColCount) || 1;

  const startColumn = cellRanges?.[0]?.columns?.[0]?.getColDef()?.headerComponentParams?.colName;
  const startRowIndex = cellRanges?.[0]?.startRow?.rowIndex || 0;

  const startColIndex = config.columnOrder.findIndex((col) => col === startColumn);
  const formulaColumnLookup = new Set(config.columnFormulae?.map((col) => col.colName));

  // combine rows with clipboard data and create the payload
  const bulkPasteRows: CSVPreviewRowItem[] = [];

  for (let i = 0; i < rowFactor * sourceRowCount; i += 1) {
    const row = cloneDeep(filteredRows?.[startRowIndex + i]);

    const pastedValues: ListRow['data'] = {};

    for (let j = 0; j < colFactor * sourceColCount; j += 1) {
      const colName = config.columnOrder[startColIndex + j];

      if (!colName || formulaColumnLookup.has(colName)) {
        continue;
      }
      pastedValues[colName] = processedData?.[i % sourceRowCount][j % sourceColCount];
    }

    if (isEmpty(pastedValues)) {
      return null;
    }

    bulkPasteRows.push({
      values: {
        ...(row?.data || {}),
        ...pastedValues,
      },
      // Adding a negative rowId -(i + 1), for identification in FE, this will be ignored by BE
      rowId: row?.backendId || -(i + 1),
      position: row?.position,
    });
  }

  if (isEmpty(bulkPasteRows)) {
    return null;
  }

  notification.spinner({
    key: 'pasting-cells',
    message: intl.formatMessage({ id: 'lists.bulk_paste_rows.notification.pasting_cells' }),
  });

  storeApi.setState({ cellUpdateInProgress: true });

  ListsApi.bulkPaste(id, bulkPasteRows)
    .then((pastedRows) => {
      storeApi.setState({ cellUpdateInProgress: false });

      if (pastedRows?.some((row) => !isEmpty(row.errors))) {
        setBulkPasteRows?.(pastedRows);
        notification.error({
          key: 'pasting-cells',
          message: intl.formatMessage({ id: 'lists.bulk_paste_rows.notification.error' }),
        });
      } else {
        updateListWithPastedValues(pastedRows, storeApi);
        notification.success({
          key: 'pasting-cells',
          message: intl.formatMessage({ id: 'lists.bulk_paste_rows.notification.success' }),
        });
      }
    })
    .catch((error) => {
      storeApi.setState({ cellUpdateInProgress: false });

      notification.error({
        key: 'pasting-cells',
        message: getApiError(error),
      });
    });

  return null;
};
