import { VaryingColGroup } from 'data/page-template/grid';
import { type FrameElement } from 'data/reports/types';

export const getStatisticalColLabel = (
  baselineVersionDisplayName: string,
  comparedVersionDisplayName: string,
  statistic?: FrameElement['statistic'],
): string => {
  return statistic === VaryingColGroup.Attainment
    ? `${baselineVersionDisplayName} / ${comparedVersionDisplayName}`
    : `${baselineVersionDisplayName} vs ${comparedVersionDisplayName}`;
};
