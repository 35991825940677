import axios from 'axios';
import { type DateRange } from 'data/boards';
import { type ChartSummaryContext, type Chart } from 'data/boards/charts/types';
import { type DimensionQueryParamsObject } from 'data/dimension';
import { type AiCommentaryRequest, type AiCommentary, type AiPromptResponse } from './types';

export const DeLoreanApi = {
  async getSummary({
    reportId,
    chartId,
    chart,
    granularity,
    dateRange,
    boardFilters,
  }: {
    reportId: number;
    chartId: number;
    chart?: Chart;
    granularity?: string;
    dateRange: DateRange;
    boardFilters: DimensionQueryParamsObject;
  }): Promise<string> {
    const { data } = await axios.post(`/v1/reports/${reportId}/chart-summary/${chartId}`, {
      chart,
      boardFilters,
      boardStartDate: dateRange.startDate,
      boardEndDate: dateRange.endDate,
      boardDateOption: dateRange.dateOption,
      boardId: chart?.boardId,
      granularity,
    });

    return data.summary;
  },

  async sendChartSummaryFeedback(
    reportId: number,
    chartId: number,
    feedback: boolean,
    summary: string,
  ): Promise<void> {
    return axios.post(`/v1/reports/${reportId}/chart-summary-feedback/${chartId}`, {
      summary,
      sentiment_positive: feedback,
    });
  },

  async updateChartContext(
    chartId: number | undefined,
    context: ChartSummaryContext[],
  ): Promise<void> {
    return await axios.post(`v1/reports/${chartId}/chart-context`, context);
  },

  async askPrompt({
    message,
    convId,
    messageId,
  }: {
    message: string;
    convId?: string;
    messageId?: string;
  }): Promise<AiPromptResponse> {
    const path = window.location.pathname;
    const queryString = window.location.search;

    const { data } = await axios.post('/v1/ai/chat', {
      msg: message,
      convId,
      messageId,
      urlPath: path + queryString,
    });

    return data;
  },

  async getChartCommentary(request: AiCommentaryRequest): Promise<AiCommentary> {
    const { data } = await axios.post('/v1/ai/chart-commentary', request);

    return data;
  },
};
