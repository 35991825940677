import { type Granularity, type NewPeriod } from 'data';
import dayjs, { type Dayjs } from 'dayjs';
import { isNull } from 'lodash';
import { periodFormatter } from 'utils/data-formatter';

type ReturnType = {
  startDate: Dayjs;
  endDate: Dayjs;
  granularity: Granularity;
  periodDisplayValue: string;
} | null;

export const getCurrentPeriodRange = ({
  periodStartDate,
  periodGranularity,
  periodRanges,
}: {
  periodStartDate: string | null;
  periodGranularity: Granularity;
  periodRanges: NewPeriod[];
}): ReturnType => {
  if (isNull(periodStartDate)) {
    return null;
  }

  const currentPeriod = periodRanges.find(
    (p) => dayjs(p.startDate).isSame(periodStartDate) && p.type === periodGranularity,
  ) as NewPeriod;

  const { startDate, endDate, type: granularity } = (currentPeriod || {}) as NewPeriod;

  return {
    startDate,
    endDate,
    granularity,
    periodDisplayValue: currentPeriod ? periodFormatter(currentPeriod) : '',
  };
};
