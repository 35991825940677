import { ReactComponent as DeleteIcon } from 'assets/v2/delete.svg';
import { ReactComponent as EditIcon } from 'assets/v2/edit.svg';
import { IconShell, type ItemType } from 'components/ui/atomic-components';
import { type Comment } from 'data/conversations';
import { FormattedMessage } from 'react-intl';
import { useUser } from 'utils/hooks';

interface Props {
  comment: Comment;
  onMenuItemClick?: (key: string) => void;
}

export const useMenuItems = ({ comment, onMenuItemClick }: Props): ItemType[] => {
  const { user: currentUser } = useUser();

  if (comment.createdBy.username !== currentUser.username) {
    return [];
  }

  return [
    {
      key: 'edit',
      label: <FormattedMessage id="edit" />,
      icon: <IconShell icon={EditIcon} />,
      onClick: (menuInfo) => {
        menuInfo.domEvent.stopPropagation();
        onMenuItemClick?.('edit');
      },
    },
    { type: 'divider' },
    {
      key: 'delete',
      label: <FormattedMessage id="delete" />,
      icon: <IconShell icon={DeleteIcon} />,
      onClick: (menuInfo) => {
        menuInfo.domEvent.stopPropagation();
        onMenuItemClick?.('delete');
      },
      danger: true,
    },
  ];
};
