import styled from 'styled-components';
import { FontS, FontXl } from 'styles/typography';
import { Button, IconText } from '../atomic-components';

export const styles = {
  ContentWrapper: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[16]};
  `,

  ValueWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[8]};
  `,

  ValueItem: styled.div`
    ${FontXl}

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.textTitle1};
  `,

  ValueInfoLabel: styled.div`
    ${FontS}

    color: ${({ theme }) => theme.colors.textBody1};

    b {
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    }
  `,

  CalculationInfoWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[8]};
  `,

  CalculationInfoTitle: styled.div`
    ${FontS}

    color: ${({ theme }) => theme.colors.textPlaceholder};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,

  CalculationInfoContent: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing[16]};
  `,

  CalculationInfoItem: styled(IconText)`
    b {
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    }
  `,

  ComponentBreakup: styled.div`
    flex: 1;
  `,

  FormulaContainer: styled.div`
    border-radius: ${({ theme }) => theme.borderRadius.l};
    border: 0.8px solid ${({ theme }) => theme.colors.outlineDefault};
    height: 120px;
    overflow-y: auto;
  `,

  BackButtonWrapper: styled.div`
    align-self: flex-start;
    margin-left: -${({ theme }) => theme.spacing[16]};
  `,
  DebugViewWrapper: styled.div`
    padding: ${({ theme }) => theme.spacing[16]};
    border: 0.8px solid ${({ theme }) => theme.colors.outlineDefault};
    max-height: 40vh;
    overflow-y: auto;
  `,
  DebugInfoButton: styled(Button)`
    align-self: flex-start;
  `,
};
