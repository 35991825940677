import { ReactComponent as ColumnsIcon } from 'assets/v2/columns-2.svg';
import { Button, IconShell, Popover } from 'components/ui/atomic-components';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Content } from './content';

interface Props {
  columnsList?: string[];
  selectedColumns: string[];
  onApply: (cols: string[]) => void;
  disabled: boolean;
}

export const ColumnSelector = ({
  columnsList,
  selectedColumns,
  onApply,
  disabled,
}: Props): ReactElement => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      content={
        <Content
          columnsList={columnsList}
          initialSelectedCols={selectedColumns}
          onApply={(cols) => {
            setIsPopoverOpen(false);
            onApply(cols);
          }}
        />
      }
      destroyTooltipOnHide
      open={isPopoverOpen}
      placement="bottomRight"
      onOpenChange={setIsPopoverOpen}
    >
      <Button disabled={disabled} icon={<IconShell icon={ColumnsIcon} />} type="text">
        <FormattedMessage id="col_config" />
      </Button>
    </Popover>
  );
};
