import { type VaryingColGroup } from 'data/page-template/grid';

const DELIMITER = '~~';

export const constructStatisticColumnKey = ({
  base,
  current,
  statistic,
}: {
  base: string;
  current: string;
  statistic: VaryingColGroup;
}) => {
  return `${base}${DELIMITER}${current}${DELIMITER}${statistic}`;
};

export const parseStatisticColumnKey = (key: string) => {
  const [base, current, statistic] = key?.split(DELIMITER) || [];

  return {
    base,
    current,
    statistic,
  };
};
