import { QueryTableColumnDataType } from 'data/big-query';
import { isNumericBigQueryType } from 'utils/big-query/is-numeric-big-query-type';
import { numberFormatter } from 'utils/data-formatter';
import { DataFormattingType } from 'utils/data-formatter/types';
import { isFraction } from 'utils/validators';

export const formatTitle = ({
  type,
  value,
}: {
  type: QueryTableColumnDataType;
  value: string;
}): string => {
  if (!value) {
    return '(Blank)';
  }

  if (isNumericBigQueryType(type)) {
    return numberFormatter({
      value,
      type: DataFormattingType.Number,
      digitsAfterDecimal: type === QueryTableColumnDataType.Float || isFraction(value) ? 2 : 0,
    });
  }

  return value;
};
