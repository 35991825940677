import { type ColDef, type IRowNode } from 'ag-grid-community';
import { type ID } from 'data';
import { type ContainerType, type Conversation } from 'data/conversations';
import { PIVOT_DIMENSION_METRIC_ROW_NAME } from 'data/page-template';
import { BASE_CASE_NAME, SCENARIO } from 'data/scenarios';
import { ACTUAL, VERSION } from 'data/versions';

interface Props {
  node: IRowNode;
  colDef: ColDef;
  containerType: ContainerType;
  containerId: ID;
  dataValue?: number | string;
  firstVersion?: string;
}

export const frameConversationCreationPayload = ({
  node,
  colDef,
  containerType,
  containerId,
  dataValue,
  firstVersion = ACTUAL,
}: Props): Partial<Conversation> => {
  const {
    colDims: colDimensions = [],
    baselineVersion = ACTUAL,
    pageFilters = [],
    chartId,
    columnType = VERSION,
  } = colDef?.headerComponentParams || {};

  const { rowDimensions } = node.data;

  const allDimensions = {
    ...rowDimensions,
    ...colDimensions,
    ...pageFilters,
  };

  const {
    [PIVOT_DIMENSION_METRIC_ROW_NAME]: metricName = node?.data?.metricInHierarchy,
    [VERSION]: version = firstVersion,
    [SCENARIO]: scenario = BASE_CASE_NAME,
    ...restDimensions
  } = allDimensions;

  const data: Partial<Conversation> = {
    metricName,
    container: containerType,
    containerId,
    meta: {
      dataValue,
      reference: {
        columnType,
        dimensions: restDimensions,
        version,
        baselineVersion,
        scenario,
        chartId,
      },
    },
  };

  return data;
};
