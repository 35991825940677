import { type PivotDimensionsSummaryType, type PivotViewType } from 'data/page-template';
import { type VaryingColGroup } from 'data/page-template/grid';

export interface TableChartConfig {
  viewType?: PivotViewType;
  cellSize?: TableChartCellSize;
  cellEditable?: boolean;
  rowSummaryPosition?: PivotDimensionsSummaryType;
  rowsExpanded?: boolean;
  showEmptyRows?: boolean;
  repeatRowLabels?: boolean;
  columnsExpanded?: boolean;
  alternateRowColor?: string;
  coloredColumnHeader?: boolean;
  showCellGrids?: boolean;
  versionToBaselineMap?: Record<string, string> | null;
  /**
   * @deprecated Move to versionToBaselineMap
   */
  baselineVersion?: string | null;
  compareStatistic?: VaryingColGroup;
  showCompareStatisticAs?: ('magnitude' | 'percentage')[];
  baselineScenario?: string | null;
  scenarioCompareStatistic?: VaryingColGroup;
  showScenarioCompareStatisticAs?: ('magnitude' | 'percentage')[];
  formattingRules?: TableFormattingRule[];
  blankRowsPlacement?: { [key: string]: TableChartRowType[] };
  columnWidthPreference?: Record<string, number>;
  columnHeaderWrap?: TableColumnHeaderWrap;
  expandedRowNodeIds?: string[];
  expandedColNodeIds?: string[];
}

export type TableColumnHeaderWrap = 'wrap' | 'clip';

export enum TableChartRowType {
  Element = 'ELEMENT',
  Blank = 'BLANK',
}

export enum TableChartCellSize {
  Compact = 'COMPACT',
  Comfortable = 'COMFORTABLE',
}

export enum TableFormattingOperator {
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  LesserThan = 'LESSER_THAN',
  LesserThanOrEqualTo = 'LESSER_THAN_OR_EQUAL_TO',
  EqualTo = 'EQUAL_TO',
  Between = 'BETWEEN',
  Empty = 'EMPTY',
  NotEmpty = 'NOT_EMPTY',
}

export interface TargetedDimensionEntry {
  name: string;
  values: string[];
}

export const FormattingDimensionEntryAll = 'Dt-All';

export interface TableFormattingCondition {
  operator: TableFormattingOperator;
  data: string[];
}

export interface TableFormattingRuleStyle {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  fontColor?: string;
  backgroundColor?: string;
  scale?: TableFormattingRuleScale;
}

export interface TableFormattingRuleScale {
  min?: number;
  max?: number;
  minColor: string;
  maxColor: string;
  colorStrip?: string[]; // internal - FE use only, not saved in BE
}

export interface TableFormattingRule {
  targetedDimensionEntries?: TargetedDimensionEntry[];
  condition?: TableFormattingCondition;
  style?: TableFormattingRuleStyle;
  isHideRule?: boolean;
  isEditing?: boolean; // internal - FE use only, not saved in BE
  value?: string; // internal - FE use only, not saved in BE
}
