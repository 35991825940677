import { useQuery } from '@tanstack/react-query';
import { IntegrationsApi } from '../api';
import { IntegrationKeys } from '../constants';

export const useGetTableMeta = (tableName: string, enabled: boolean) => {
  return useQuery({
    queryKey: IntegrationKeys.TableMeta(tableName),
    queryFn: () => IntegrationsApi.getTableMeta(tableName),
    enabled,
  });
};
