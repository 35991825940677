import { useMutation } from '@tanstack/react-query';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { DeLoreanApi } from '../api';

export const useAskPromptMutation = () => {
  const useAskPromptMutation = useMutation({
    mutationFn: ({
      message,
      convId,
      messageId,
    }: {
      message: string;
      convId?: string;
      messageId?: string;
    }) => DeLoreanApi.askPrompt({ message, convId, messageId }),
    onError: (e) => {
      defaultApiErrorHandler(e);
    },
  });

  return useAskPromptMutation;
};
