import { type NewPeriod } from 'data';
import { useBootstrap } from 'data/bootstrap';
import { DateFilterPresetName } from 'data/bootstrap/types';
import { useMemo } from 'react';
import { useBoardDateRange } from './use-board-date-range';

export const useBoardDateSelection = (): NewPeriod => {
  const { data: { dateFilterOptions = [] } = {} } = useBootstrap();
  const { dateOption, startDate, endDate } = useBoardDateRange();

  return useMemo(() => {
    const currentDateFilterOption = dateFilterOptions.find((option) => option.name === dateOption);

    return (dateOption === DateFilterPresetName.CustomRange
      ? {
          startDate,
          endDate,
        }
      : {
          startDate: currentDateFilterOption?.value[0],
          endDate: currentDateFilterOption?.value[1],
        }) as unknown as NewPeriod;
  }, [dateFilterOptions, dateOption, endDate, startDate]);
};
