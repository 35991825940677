import { Button, Form } from 'components/ui/atomic-components';
import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIsUsingConnectionParam } from 'store/connections';
import styled from 'styled-components';
import { type IntegrationInputFlowCompV2, type IntegrationInputFlowComp } from '../../types';
import { useConnectInputFlow } from './hooks/use-connect-input-flow';
import { FormInput, FormPassword } from './input-blocks';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing[8]};
  gap: ${({ theme }) => theme.spacing[16]};
  background: ${({ theme }) => theme.colors.gray100};
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

interface Props {
  flow: IntegrationInputFlowComp | IntegrationInputFlowCompV2;
  onConnectionSuccess: () => void;
  item: IntegrationModalContent;
}

const isGenericInputFlow = (
  flow: IntegrationInputFlowComp | IntegrationInputFlowCompV2,
): flow is IntegrationInputFlowCompV2 => {
  return flow.type === 'input';
};

export const ConnectForm = ({ flow, item, onConnectionSuccess }: Props): ReactElement => {
  const [form] = Form.useForm();

  const isUsingConnectionParam = useIsUsingConnectionParam();
  const { mutate, isPending } = useConnectInputFlow(item, onConnectionSuccess);

  const onFinish = async () => {
    const data = await form.validateFields();

    (isGenericInputFlow(flow) ? flow.input : flow.inputs).forEach((input) => {
      const value = data[input.name];

      if (input.outputFormatter && value) {
        data[input.name] = input.outputFormatter(value);
      }
    });

    if (isUsingConnectionParam) {
      const processedData = Object.entries(data).map(([name, value]) => ({
        name,
        value,
      }));

      // Todo: Improve typing
      // @ts-expect-error - This is the new format for generic input flows
      mutate({ inputFields: processedData });
    } else {
      mutate(data);
    }
  };

  return (
    <Wrapper>
      <span>{flow.inputTitle}</span>

      <StyledForm form={form} onFinish={onFinish}>
        {(isGenericInputFlow(flow) ? flow.input : flow.inputs).map((input) => {
          if (input.type === 'input') {
            return <FormInput key={input.name} input={input} />;
          } else if (input.type === 'password') {
            return <FormPassword key={input.name} input={input} />;
          }
        })}
        <Form.Item>
          <Button $mt={12} block htmlType="submit" loading={isPending} type="primary">
            <FormattedMessage id="submit" />
          </Button>
        </Form.Item>
      </StyledForm>
    </Wrapper>
  );
};
