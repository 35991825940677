import { useQuery } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ListsApi } from 'data/modelling/lists/api';

export const useListRowValues = ({ listId }: { listId: number }) => {
  const listRowValuesQuery = useQuery({
    queryKey: ['list-row-values', String(listId)],
    queryFn: () => ListsApi.getListRowValues(listId),
    staleTime: StaleTime.Short,
  });

  return listRowValuesQuery;
};
