import { Dropdown as AntdDropdown, type DropdownProps } from 'antd';
import { type FC } from 'react';
import styled from 'styled-components';
import { DropdownButton, type AtlasDropdownButtonProps } from './dropdown-button';

export const Dropdown = styled(AntdDropdown)<{ disabled?: boolean }>`
  && {
    background: ${({ disabled, theme }) => (disabled ? theme.colors.bgMedium : 'initial')};
  }
` as FC<DropdownProps> & {
  Button: FC<AtlasDropdownButtonProps>;
};

Dropdown.Button = DropdownButton;
