import { useBoardContext } from 'components/modules/boards-v2/custom-board/contexts';
import { useBoardQueryParams } from 'data/boards/hooks/use-board-query-params';
import { type DateRange } from 'data/boards/types';
import { DateFilterPresetName } from 'data/bootstrap/types';

export const useBoardDateRange = (): DateRange => {
  const [query] = useBoardQueryParams();

  const { board: boardData } = useBoardContext();

  const dateOption =
    query.dateOption || boardData?.attributes?.dateOption || DateFilterPresetName.LastTwelveMonths;

  if (dateOption === DateFilterPresetName.CustomRange) {
    const defaultDate = boardData?.dateFilterOptions?.find((option) => option.isDefault)?.value || [
      null,
      null,
    ];
    const startDate = query.startDate || boardData?.attributes?.startDate || defaultDate[0];
    const endDate = query.endDate || boardData?.attributes?.endDate || defaultDate[1];

    return {
      startDate,
      endDate,
      dateOption,
    };
  }

  if (dateOption === DateFilterPresetName.RelativeRange) {
    return {
      dateOption,
      relativeRange: query.relativeRange || boardData?.attributes?.relativeRange,
    };
  }

  return {
    dateOption,
  };
};
