import axios from 'axios';
import { downloadResponseData } from 'utils';
import {
  type UnderlyingDataRequest,
  type BigQueryResponse,
  type BigQueryResponseSchema,
  type BreakupMetric,
  type UnderlyingDataFilterRequest,
} from './types';

const VERSION = '/v1/';

export const DtqlQueryApi = {
  getBreakup: (
    underlyingDataRequest: UnderlyingDataRequest,
  ): Promise<BigQueryResponse<Record<string, string>>> => {
    const apiPath = `${VERSION}query/breakup`;

    return axios
      .post<BigQueryResponse<Record<string, string>>>(apiPath, underlyingDataRequest)
      .then(({ data }) => data);
  },

  getColumnFilterValues: (
    underlyingDataFilterRequest: UnderlyingDataFilterRequest,
  ): Promise<string[]> => {
    const apiPath = `${VERSION}query/breakup/filter-values`;

    return axios.post<string[]>(apiPath, underlyingDataFilterRequest).then(({ data }) => data);
  },

  downloadBreakupAsExcel: (underlyingDataRequest: UnderlyingDataRequest): Promise<void> => {
    const apiPath = `${VERSION}query/breakup/download/excel`;

    return axios.post(apiPath, underlyingDataRequest, { responseType: 'blob' }).then((response) => {
      downloadResponseData({
        data: response.data,
        fileName: `${response.headers.filename}`,
      });
    });
  },

  getBreakupAvailable: (): Promise<Record<string, BreakupMetric>> =>
    axios
      .get<Record<string, BreakupMetric>>(`${VERSION}query/breakup-available`)
      .then(({ data }) => data),

  findAllTableSchema: (): Promise<BigQueryResponseSchema> =>
    axios.get<BigQueryResponseSchema>(`${VERSION}query/schema`).then(({ data }) => data),
};
