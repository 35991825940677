import { Checkbox } from 'components/ui/atomic-components';
import { FilterPopupSkeleton } from 'components/ui/filter-popup-skeleton';
import { useState, type ReactElement } from 'react';
import styled from 'styled-components';
import { FontS } from 'styles/typography';
import { formatName } from 'utils/data-formatter';
import { BREAK_UP_URL_FIELD_NAME, CLICKTHROUGH_URL_FIELD_NAME } from '../../constants';

const ListWrapper = styled.div`
  padding: ${({ theme: { spacing } }) => spacing[4]};
  max-height: 280px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  min-height: 32px;
  padding: ${({ theme: { spacing } }) => `0 ${spacing[4]}`};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.bgMedium};
  }

  & .ant-checkbox + span {
    ${FontS};

    color: ${({ theme }) => theme.colors.textTitle1};
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface Props {
  columnsList?: string[];
  initialSelectedCols: string[];
  onApply: (columns: string[]) => void;
}

export const Content = ({ columnsList, initialSelectedCols, onApply }: Props): ReactElement => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedColumns, setSelectedColumns] = useState<string[]>(initialSelectedCols);

  const filteredColumns = (columnsList || []).filter(
    (col) =>
      formatName(col).toLowerCase().includes(searchQuery.toLowerCase()) &&
      ![BREAK_UP_URL_FIELD_NAME, CLICKTHROUGH_URL_FIELD_NAME].includes(col),
  );

  const onSelectColumn = (selectedCol: string) => {
    if (selectedColumns.includes(selectedCol)) {
      setSelectedColumns((prev) => prev.filter((col) => col !== selectedCol));
    } else {
      setSelectedColumns((prev) => [...prev, selectedCol]);
    }
  };

  const onClear = () => {
    setSelectedColumns((prev) => prev.filter((col) => !filteredColumns.includes(col)));
  };

  const onSelectAll = () => {
    setSelectedColumns(filteredColumns);
  };

  return (
    <FilterPopupSkeleton
      searchQuery={searchQuery}
      onApply={() => onApply(selectedColumns)}
      onClear={onClear}
      onSearchChange={(val) => setSearchQuery(val)}
      onSelectAll={onSelectAll}
    >
      <ListWrapper>
        {filteredColumns.map((col) => (
          <ListItem
            key={col}
            onClick={(e) => {
              e.preventDefault();
              onSelectColumn(col);
            }}
          >
            <Checkbox checked={selectedColumns.includes(col)} size="extraSmall">
              {formatName(col)}
            </Checkbox>
          </ListItem>
        ))}
      </ListWrapper>
    </FilterPopupSkeleton>
  );
};
