import { useContext } from 'react';
import { BoardContext, type BoardContextParams } from './board-context';

export const useBoardContext = (): BoardContextParams => {
  const context = useContext(BoardContext);

  if (!context) {
    throw new Error('useBoardContext to be used within Board context');
  }

  return context;
};
