import { type ColDef } from 'ag-grid-community';
import { Granularity, type NewPeriod } from 'data';
import { type BreakupMetric, type BreakupQueryDrawerParams } from 'data/big-query';
import { type Metric } from 'data/metrics';
import { type ActualsTillDatePreset } from 'data/modelling/model';
import { ACTUAL, VERSION } from 'data/versions';
import { isNil, type Dictionary } from 'lodash';
import { type MutableRefObject } from 'react';
import { constructUnderlyingDataEndDate } from './construct-end-date-with-actuals-till';
import { extractSpecialDims } from './extract-special-dims';
import { getCurrentPeriodRange } from './get-current-period-range';
import { isComparisonColumn } from './is-comparison-column';

const isBreakupAvailable = (
  metricNamesSupportingBreakupRef: React.MutableRefObject<
    Record<string, BreakupMetric> | undefined
  >,
  metricName: string | null,
  version: string,
): boolean => {
  if (metricNamesSupportingBreakupRef.current && metricName) {
    if (version === ACTUAL) {
      return !!metricNamesSupportingBreakupRef.current?.[metricName];
    }
    const dual = metricNamesSupportingBreakupRef.current?.[metricName]?.dual;

    return !!dual && !!metricNamesSupportingBreakupRef.current?.[dual];
  }

  return false;
};

export const constructUnderlyingDataParams = ({
  actualsTillDate,
  actualsTillDateOption,
  cellDims,
  underlyingDataDims,
  defaultStartDate,
  defaultEndDate,
  periodRanges,
  metricsMap,
  showFutureActuals,
  value,
}: {
  actualsTillDate?: string;
  actualsTillDateOption?: ActualsTillDatePreset;
  cellDims: Record<string, string>;
  underlyingDataDims: Record<string, string[]>;
  defaultStartDate?: string;
  defaultEndDate?: string;
  periodRanges: NewPeriod[];
  metricsMap: Dictionary<Metric>;
  showFutureActuals: boolean;
  value?: number;
}): BreakupQueryDrawerParams => {
  const { metricName, versionName, periodStartDate, periodGranularity } =
    extractSpecialDims(cellDims);

  const {
    startDate = defaultStartDate,
    endDate = defaultEndDate,
    granularity,
    periodDisplayValue = '',
  } = getCurrentPeriodRange({ periodStartDate, periodGranularity, periodRanges }) || {};

  const metric = metricsMap[metricName];

  return {
    f: underlyingDataDims,
    startDate,
    endDate: constructUnderlyingDataEndDate({
      actualsTillDate,
      actualsTillDateOption,
      endDate: endDate as string,
      granularity,
      showFutureActuals,
      version: versionName,
    }),
    metricName,
    displayName: metric.displayName,
    displayPeriodKey: periodDisplayValue,
    granularity: granularity ?? Granularity.Monthly,
    version: versionName,
    value,
    metricDataFormat: metric.dataFormat,
    metricType: metric.type,
  } as BreakupQueryDrawerParams;
};

export const hideShowUnderlyingDataForCell = ({
  metricNamesSupportingBreakupRef,
  metricName,
  version,
  value,
  columnDef,
  timeDimensionValue,
  disableForReadOnlyUser,
}: {
  metricNamesSupportingBreakupRef: MutableRefObject<Record<string, BreakupMetric> | undefined>;
  metricName: string | null;
  version: string;
  value?: number;
  columnDef?: ColDef;
  timeDimensionValue?: string;
  disableForReadOnlyUser: boolean;
}): boolean => {
  const isUnderlyingDataAvailableForMetric = isBreakupAvailable(
    metricNamesSupportingBreakupRef,
    metricName,
    version,
  );

  return (
    disableForReadOnlyUser ||
    isNil(value) ||
    columnDef?.headerComponentParams?.columnType !== VERSION ||
    !isUnderlyingDataAvailableForMetric ||
    isComparisonColumn(timeDimensionValue)
  );
};
