import { type ContainerType, type ConversationId } from 'data/conversations';
import { useConversations } from 'data/conversations/hooks/use-conversations';
import { createContext, type FC, useContext } from 'react';

interface ConversationsContextProps {
  containerType: ContainerType;
  containerId: ConversationId;
}

const ConversationsContext = createContext<ConversationsContextProps>(
  {} as ConversationsContextProps,
);

export const ConversationsContextProvider: FC<
  React.PropsWithChildren<{ value: ConversationsContextProps }>
> = ({ value, children }) => {
  const { containerId, containerType } = value;

  useConversations(containerType, containerId);

  return <ConversationsContext.Provider value={value}>{children}</ConversationsContext.Provider>;
};

export const useConversationsContext = (): ConversationsContextProps =>
  useContext(ConversationsContext);
