import { useQuery } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { DtqlQueryApi, type UnderlyingDataFilterRequest } from 'data/big-query';

export const useGetFilterValues = (underlyingDataFilterRequest: UnderlyingDataFilterRequest) => {
  const { data: columnFilterValues = [], isLoading } = useQuery({
    queryKey: ['breakup-filter-values', underlyingDataFilterRequest],
    queryFn: () => DtqlQueryApi.getColumnFilterValues(underlyingDataFilterRequest),
    staleTime: StaleTime.Short,
  });

  return { columnFilterValues, filterValuesAreLoading: isLoading };
};
