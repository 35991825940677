import { DateTimeFormats } from 'config/constants';
import { Granularity, type NewPeriod } from 'data';
import dayjs from 'dayjs';

export const periodFormatter = (period: NewPeriod): string => {
  switch (period.type) {
    case Granularity.Weekly:
      return dayjs(period.endDate).format(DateTimeFormats.HumanReadableDate);

    case Granularity.Monthly:
      return dayjs(period.endDate).format(DateTimeFormats.HumanReadableMonthYear);

    default:
      return period.key;
  }
};
