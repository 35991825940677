import { DateTimeFormats } from 'config/constants';
import { Granularity } from 'data';
import dayjs from 'dayjs';

export const getPeriodDisplayValue = ({
  startDate,
  endDate,
  granularity = Granularity.Monthly,
  displayPeriodKey,
}: {
  startDate?: string;
  endDate?: string;
  granularity?: Granularity;
  displayPeriodKey?: string;
}): string => {
  if (!startDate || !endDate) {
    return '-';
  }

  if ([Granularity.Weekly, Granularity.Daily].includes(granularity)) {
    return dayjs(endDate).format(DateTimeFormats.HumanReadableDate);
  }

  if (displayPeriodKey) {
    return displayPeriodKey;
  }

  const humanReadableStartDate = dayjs(startDate).format(DateTimeFormats.HumanReadableMonthYear);
  const humanReadableEndDate = dayjs(endDate).format(DateTimeFormats.HumanReadableMonthYear);

  return humanReadableStartDate === humanReadableEndDate
    ? humanReadableEndDate
    : `${humanReadableStartDate} - ${humanReadableEndDate}`;
};
