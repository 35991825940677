import axios from 'axios';
import { type ID } from 'data';
import { type Atleast } from 'utils/typescript';
import { type ContainerType, type Comment, type Conversation, type ConversationId } from './types';

const API_PATH = '/v1/conversations';

export const ConversationApi = {
  findRecord: async (convIds: ConversationId[]): Promise<Conversation[]> => {
    const { data } = await axios.get(`${API_PATH}/${convIds}`);

    return data;
  },

  findAll: async (container: ContainerType, containerId: ID): Promise<Conversation[]> => {
    const { data } = await axios.get(
      `${API_PATH}?container=${container}&containerId=${containerId}`,
    );

    return data;
  },

  createConversation: async (conversation: Partial<Conversation>): Promise<Conversation> => {
    const { data } = await axios.post(`${API_PATH}`, conversation);

    return data;
  },

  updateConversation: async (conversation: Atleast<Conversation, 'id'>): Promise<Conversation> => {
    const { data } = await axios.patch(`${API_PATH}/${conversation.id}`, conversation);

    return data;
  },

  deleteConversation: async (conversationId: ConversationId): Promise<void> => {
    const { data } = await axios.delete(`${API_PATH}/${conversationId}`);

    return data;
  },

  createComment: async (convId: ConversationId, comment: Partial<Comment>): Promise<Comment> => {
    const { data } = await axios.post<Comment>(`${API_PATH}/${convId}/comments`, comment);

    return data;
  },

  updateComment: async (
    convId: ConversationId,
    comment: Atleast<Comment, 'id'>,
  ): Promise<Comment> => {
    const { data } = await axios.put<Comment>(
      `${API_PATH}/${convId}/comments/${comment.id}`,
      comment,
    );

    return data;
  },

  deleteComment: (convId: ConversationId, commentId: number): Promise<void> => {
    return axios.delete(`${API_PATH}/${convId}/comments/${commentId}`);
  },
};
