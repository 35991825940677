import { useBoardContext } from 'components/modules/boards-v2/custom-board/contexts';
import { useBoardQueryParams } from 'data/boards/hooks/use-board-query-params';

export const useSelectedBoardGranularity = (): string | undefined => {
  const [query] = useBoardQueryParams();
  const { board: boardData } = useBoardContext();

  const pageGranularity = boardData?.attributes?.granularity;

  return pageGranularity ? query.granularity || pageGranularity : undefined;
};
