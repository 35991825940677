import { type ICellRendererParams } from 'ag-grid-community';
import { Typography } from 'components/ui/atomic-components';
import { type CSSProperties, type FC } from 'react';
import styled from 'styled-components';

const { Paragraph } = Typography;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const HeaderDisplayNameWrapper = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.textBody2};

  &.ant-typography {
    margin-bottom: ${({ theme }) => theme.spacing[0]};
  }
`;

interface Props extends ICellRendererParams {
  displayName: string;
  styles?: CSSProperties;
}

export const GroupChildHeader: FC<React.PropsWithChildren<Props>> = ({
  displayName,
  styles,
  context,
}) => {
  const { headerColMaxNoOfRowsOfText } = context || {};

  return (
    <Wrapper style={styles}>
      <HeaderDisplayNameWrapper ellipsis={{ rows: headerColMaxNoOfRowsOfText || 1 }}>
        {displayName}
      </HeaderDisplayNameWrapper>
    </Wrapper>
  );
};
