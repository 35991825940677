import { useOktaAuth } from '@okta/okta-react';
import { ReactComponent as LogoutIcon } from 'assets/v2/logout.svg';
import { ReactComponent as SettingsIcon } from 'assets/v2/settings.svg';
import { ReactComponent as TasksIcon } from 'assets/v2/task-done.svg';
import { Avatar, IconShell } from 'components/ui/atomic-components';
import { Analytics } from 'config/analytics';
import { PermissionList } from 'data/roles/permissions';
import { signout } from 'data/users';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';
import { useHasScope, useUser } from 'utils/hooks';
import { HealthStatusBadge } from '../health-status-badge';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  padding: ${({ theme: { spacing } }) => `${spacing[4]} ${spacing[4]}`};
  gap: ${({ theme }) => theme.spacing[4]};
  overflow: hidden;
`;

const UserProfileInfo = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};
  align-items: center;
  padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[8]}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.outlineDefault};
`;

const UserTitle = styled.div`
  ${FontXs}

  color: ${({ theme }) => theme.colors.textPlaceholder};
`;

const MenuItem = styled.div`
  ${FontXs};

  display: flex;
  align-items: center;
  padding: ${({ theme: { spacing } }) => `${spacing[6]}`};
  margin: ${({ theme: { spacing } }) => `0 ${spacing[4]}`};
  gap: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.textTitle1};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.bgMedium};
  }
`;

const Icon = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing[2]};
`;

declare global {
  interface Window {
    Beamer?: {
      show: () => void;
    };
  }
}

export const Content = ({ close }: { close: () => void }): ReactElement => {
  const hasScope = useHasScope();
  const { user } = useUser();
  const { tempWorkflows } = useFlags();

  const { oktaAuth } = useOktaAuth();

  const logout = useCallback(() => {
    signout(oktaAuth);
    close();
    Analytics.track('Logout');
  }, [close, oktaAuth]);

  return (
    <Wrapper>
      <UserProfileInfo>
        <Avatar name={user.username} size="small" />
        <UserTitle>{user.username}</UserTitle>
      </UserProfileInfo>

      {tempWorkflows && (
        <Link to="/tasks">
          <MenuItem
            onClick={() => {
              close();
            }}
          >
            <Icon>
              <IconShell color="textBody2" icon={TasksIcon} size="sm" />
            </Icon>
            <FormattedMessage id="tasks" />
          </MenuItem>
        </Link>
      )}

      {hasScope(PermissionList.All) && (
        <Link to="/settings">
          <HealthStatusBadge>
            <MenuItem
              onClick={() => {
                close();
              }}
            >
              <Icon>
                <IconShell color="textBody2" icon={SettingsIcon} size="sm" />
              </Icon>
              <FormattedMessage id="settings" />
            </MenuItem>
          </HealthStatusBadge>
        </Link>
      )}

      <MenuItem onClick={logout}>
        <Icon>
          <IconShell color="textBody2" icon={LogoutIcon} size="sm" />
        </Icon>
        <FormattedMessage id="logout" />
      </MenuItem>
    </Wrapper>
  );
};
