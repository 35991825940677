import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FormulaTrace } from 'components/ui/formula-trace';
import { PorscheThemeWrapper } from 'components/ui/porsche-theme-wrapper';
import { TesseractCellDebugTool } from 'components/ui/tesseract-cell-debug-tool';
import { setupAxios } from 'config/axios';
import { DISABLE_REACT_QUERY_DEV_TOOLS, FEATURE_FLAG_API_KEY } from 'config/environment';
import { oktaClientConfig } from 'config/okta';
import { setupQueryClient } from 'config/query-client';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { parse, stringify } from 'query-string';
import { type FC, useLayoutEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';
import { GlobalStylesWrapper } from 'styles/global-styles-wrapper';
import translations from 'translations/en-us.json';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { setupLocaleDate } from 'utils/setup-dayjs';

import 'antd/dist/reset.css';
import 'styles/typography/fonts-styles.css';

const oktaAuth = new OktaAuth(oktaClientConfig);

oktaAuth.start();

const queryClient = setupQueryClient();

setupLocaleDate();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HelmetProviderProxy: any = HelmetProvider;

const App: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const ldClient = useLDClient();

  const customAuthHandler = () => {
    navigate('/login');
  };

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  useLayoutEffect(() => {
    if (ldClient) {
      setupAxios(oktaAuth, queryClient, ldClient);
    }
  }, [ldClient]);

  return (
    <IntlProvider defaultLocale="en" locale={navigator.language} messages={translations}>
      <HelmetProviderProxy>
        <PorscheThemeWrapper>
          <GlobalStylesWrapper />
          <QueryParamProvider
            adapter={ReactRouter6Adapter}
            options={{
              searchStringToObject: parse,
              objectToSearchString: stringify,
            }}
          >
            <Security
              oktaAuth={oktaAuth}
              restoreOriginalUri={restoreOriginalUri}
              onAuthRequired={customAuthHandler}
            >
              <QueryClientProvider client={queryClient}>
                <Outlet />

                {!DISABLE_REACT_QUERY_DEV_TOOLS && <ReactQueryDevtools />}

                <TesseractCellDebugTool />
                <FormulaTrace />
              </QueryClientProvider>
            </Security>
          </QueryParamProvider>
        </PorscheThemeWrapper>
      </HelmetProviderProxy>
    </IntlProvider>
  );
};

export default withLDProvider({
  clientSideID: FEATURE_FLAG_API_KEY,
  context: {
    key: window.location.hostname,
    kind: 'subdomain',
  },
})(App);
