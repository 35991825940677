import { useQueryClient, useMutation } from '@tanstack/react-query';
import { notification } from 'components/ui/atomic-components';
import { useIntl } from 'react-intl';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { type Atleast } from 'utils/typescript';
import { ModelVersionApi } from '../../../api';
import { type ModelVersion } from '../../../types';
import { updateVersion } from '../utils';

export const useUpdateVersionMutation = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();

  const updateModelVersion = useMutation({
    mutationFn: (version: Atleast<ModelVersion, 'id'>) => {
      return ModelVersionApi.update(version);
    },

    onSuccess: (data: ModelVersion) => {
      notification.success({
        message: intl.formatMessage({
          id: 'model.versions.update.success.title',
        }),
      });

      updateVersion(queryClient, data);
    },

    onError: defaultApiErrorHandler,
  });

  return { updateModelVersion };
};
