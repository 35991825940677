import { type ButtonProps } from 'antd';
import { type FC, type MouseEvent as ReactMoustEvent, type ReactNode } from 'react';
import { ConfirmationModal } from './confirmation-modal';
import { type ModalProps } from './index';

export interface DeleteConfirmationProps
  extends Omit<ModalProps, 'okText' | 'confirmLoading' | 'okButtonProps' | 'onOk'> {
  deleteText?: ReactNode;
  titleIcon?: ReactNode;
  deleteLoading?: boolean;
  deleteButtonProps?: ButtonProps;
  onDelete?(event: ReactMoustEvent<HTMLElement, MouseEvent>): void;
}

export const DeleteConfirmationModal: FC<React.PropsWithChildren<DeleteConfirmationProps>> = ({
  children,
  cancelButtonProps,
  cancelText,
  deleteButtonProps,
  deleteLoading,
  deleteText,
  maskClosable = true,
  title,
  titleIcon,
  onCancel,
  onDelete,
  ...props
}) => (
  <ConfirmationModal
    cancelButtonProps={cancelButtonProps}
    cancelText={cancelText}
    confirmLoading={deleteLoading}
    maskClosable={maskClosable}
    okButtonProps={deleteButtonProps}
    title={title}
    titleIcon={titleIcon}
    type="delete"
    onCancel={onCancel}
    onOk={onDelete}
    {...props}
  >
    {children}
  </ConfirmationModal>
);
