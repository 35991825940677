import { useQueryClient } from '@tanstack/react-query';
import { pusher } from 'config/pusher';
import { useEffect, useCallback } from 'react';
import { useNotificationActions, useNotificationsCount } from 'store/notifications';
import { useUser } from 'utils/hooks';
import { NOTIFICATION_PUSHER_EVENT } from '../constants';
import { type NotificationWSData } from '../types';

export const useLiveNotifications = (): void => {
  const { user } = useUser();
  const queryClient = useQueryClient();
  const { setNotificationsCount } = useNotificationActions();
  const count = useNotificationsCount();

  const onMessage = useCallback(
    (data: NotificationWSData): void => {
      if (data.resourceId) {
        queryClient.invalidateQueries({
          queryKey: ['notifications'],
        });
        if (count !== undefined) {
          setNotificationsCount(count + 1);
        }
      }
    },
    [queryClient, count, setNotificationsCount],
  );

  useEffect(() => {
    if (user?.username && user?.tenant) {
      const channelName = `${user?.username}-${user?.tenant}`;
      const userChannel = pusher.subscribe(channelName);

      userChannel.bind(NOTIFICATION_PUSHER_EVENT, onMessage);

      return () => {
        pusher.unsubscribe(channelName);
      };
    }
  }, [onMessage, user]);
};
