import { Modal } from 'components/ui/atomic-components';
import { Form } from 'components/ui/atomic-components/form';
import { useEffect, useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { EditConfirmModal } from './edit-confirm-modal';
import { UpdateFromDataForm } from './form';
import { useEditDerivedListRecon } from './hooks/use-edit-derived-list-recon';
import { useSetupDerivedListRecon } from './hooks/use-setup-derived-list-recon';
import { type FormProps } from './types';
import { generateReconRequest } from './utils/generate-recon-request';
import { setInitialValues } from './utils/set-initial-values';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    max-height: 600px;
    overflow: auto;
  }
`;

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const UpdateFromDataModal = ({ open, setOpen }: Props): ReactElement => {
  const listId = useListStoreContext((s) => s.id);
  const reconConfig = useListStoreContext((s) => s.reconciliationConfig);
  const setupDerivedListReconMutation = useSetupDerivedListRecon();
  const editDerivedListReconMutation = useEditDerivedListRecon();
  const [dimsForm] = Form.useForm<FormProps>();
  const [initialFormValues, setInitialFormValues] = useState<Partial<FormProps>>({});
  const [formValues, setFormValues] = useState<FormProps>();
  const [editConfirmModalVisible, setEditConfirmModalVisible] = useState(false);

  const resetForms = () => {
    dimsForm.resetFields();
  };

  const handleSave = async () => {
    const data = await dimsForm.validateFields();

    setOpen(false);
    setEditConfirmModalVisible(true);
    setFormValues(data);
  };

  const onConfirmEdit = async () => {
    if (!formValues) {
      return;
    }

    if (reconConfig) {
      await editDerivedListReconMutation.mutateAsync({
        listId,
        request: generateReconRequest(formValues),
      });
    } else {
      await setupDerivedListReconMutation.mutateAsync({
        listId,
        request: generateReconRequest(formValues),
      });
    }

    setEditConfirmModalVisible(false);
    resetForms();
  };

  useEffect(() => {
    if (reconConfig) {
      const formInitialValues = setInitialValues(reconConfig);

      setInitialFormValues(formInitialValues);

      dimsForm.setFieldsValue(formInitialValues);
    } else {
      setInitialFormValues({});
      setFormValues(undefined);
      dimsForm.resetFields();
    }
  }, [reconConfig, dimsForm]);

  return (
    <>
      <StyledModal
        cancelText={<FormattedMessage id="cancel" />}
        destroyOnClose
        okText={<FormattedMessage id="save" />}
        open={open}
        title={<FormattedMessage id="lists.reconciliation.modal.title" />}
        onCancel={() => {
          setOpen(false);
          resetForms();
        }}
        onOk={handleSave}
      >
        <UpdateFromDataForm form={dimsForm} initialValues={initialFormValues} />
      </StyledModal>

      <EditConfirmModal
        isLoading={
          setupDerivedListReconMutation.isPending || editDerivedListReconMutation.isPending
        }
        open={editConfirmModalVisible}
        onClose={() => setEditConfirmModalVisible(false)}
        onConfirmEdit={onConfirmEdit}
      />
    </>
  );
};
