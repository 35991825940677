import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { DtqlQueryApi, type UnderlyingDataRequest } from 'data/big-query';
import { SummarizeByNone } from './underlying-data-drawer/summarize';

const isValid = (underlyingDataRequest: UnderlyingDataRequest) =>
  !!underlyingDataRequest.metricName &&
  !!underlyingDataRequest.startDate &&
  !!underlyingDataRequest.endDate &&
  underlyingDataRequest.summarizeBy === SummarizeByNone;

export const useBreakupQueryDrawerHook = (underlyingDataRequest: UnderlyingDataRequest) => {
  const {
    data: breakupData,
    isFetching: breakupQueryIsLoading,
    isError: breakupQueryIsError,
    error: breakupError,
  } = useQuery({
    queryKey: ['breakup-query', underlyingDataRequest],
    queryFn: () => DtqlQueryApi.getBreakup(underlyingDataRequest),
    enabled: isValid(underlyingDataRequest),
    placeholderData: keepPreviousData,
  });

  const breakupErrorMessage = [400, 403].includes(breakupError?.request.status)
    ? breakupError?.response?.data?.message
    : null;

  return { breakupData, breakupQueryIsLoading, breakupQueryIsError, breakupErrorMessage };
};
