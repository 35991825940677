import { useQuery } from '@tanstack/react-query';
import { UsersApi } from '../api';

export const useUsersByQuery = (searchQuery?: string) =>
  useQuery({
    queryKey: ['users', searchQuery],
    queryFn: () => UsersApi.getUsers(searchQuery),
    enabled: !!searchQuery,
    staleTime: Infinity,
  });

export const useUsers = () =>
  useQuery({
    queryKey: ['users'],
    queryFn: () => UsersApi.getAllUsers(),
    staleTime: Infinity,
  });
