/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @calm/react-intl/missing-formatted-message */
import { useMutation } from '@tanstack/react-query';
// eslint-disable-next-line no-restricted-imports
import { Input } from 'antd';
import { ReactComponent as LayoutIcon } from 'assets/v2/layout-sidebar.svg';
import axios from 'axios';
import { type ReactElement, useState, useEffect } from 'react';
import styled from 'styled-components';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { Space } from '../atomic-components';
import { Button } from '../atomic-components/button';
import { Drawer } from '../atomic-components/drawer';
import { useTesseractCellDebugToolStore } from './store';

const { TextArea } = Input;

const ResponseWrapper = styled.div`
  width: 100%;
  height: calc(100% - 258px);
  overflow: hidden;

  pre {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
`;

export const TesseractCellDebugTool = (): ReactElement => {
  const data = useTesseractCellDebugToolStore((state) => state.data);
  const setTessDebugData = useTesseractCellDebugToolStore((state) => state.setData);

  const [response, setResponse] = useState<{ preHtml: any }>({ preHtml: '' });
  const [modalPlacement, setModalPlacement] = useState<'right' | 'bottom'>('right');

  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      return axios.post('v1/debug/debug-cell', formatValueForApi(data)).then(({ data }) => data);
    },

    onSuccess: (response) => {
      setResponse(response);
    },

    onError: defaultApiErrorHandler,
  });

  const formatValueForRender = (data: any) => {
    if (typeof data === 'string') {
      return data;
    }

    return JSON.stringify(data, null, 2);
  };

  const formatValueForApi = (data: any) => {
    if (typeof data === 'string') {
      return JSON.parse(data);
    }

    return data;
  };

  const togglePlacement = () => {
    setModalPlacement((prev) => {
      if (prev === 'bottom') {
        return 'right';
      }

      return 'bottom';
    });
  };

  useEffect(() => {
    setResponse({ preHtml: '' });
  }, [data]);

  return (
    <Drawer
      cancelText="cancel"
      closable
      mask={false}
      okText="Debug"
      open={!!data}
      placement={modalPlacement}
      title={
        <Space>
          Inspect Tess underlying data
          <Button
            icon={<LayoutIcon />}
            size="extraSmall"
            type="text"
            onClick={() => togglePlacement()}
          />
        </Space>
      }
      width={640}
      onClose={() => setTessDebugData(false)}
    >
      Input:
      <TextArea
        rows={8}
        value={formatValueForRender(data)}
        onChange={({ target }) => {
          setTessDebugData(target.value);
        }}
      />
      <br />
      <br />
      <Button loading={isPending} type="primary" onClick={() => mutate()}>
        Run
      </Button>
      <br />
      <br />
      Response:
      <ResponseWrapper>
        <pre dangerouslySetInnerHTML={{ __html: response.preHtml }} />
      </ResponseWrapper>
    </Drawer>
  );
};
