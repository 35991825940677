import { Spinner as AtomicSpinner } from 'components/ui/atomic-components';
import { AgGrid } from 'components/ui/atomic-components/grid';
import { checkIfInPrintMode } from 'data/boards';
import styled, { css } from 'styled-components';
import { SingleLineEllipsis } from 'styles/typography';

export default {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};
  `,

  ContentWrapper: styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: ${({ theme }) => theme.spacing[8]};
  `,

  Label: styled.div`
    ${SingleLineEllipsis};

    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[8]};
  `,

  LabelText: styled.span`
    ${SingleLineEllipsis};
  `,
  Spinner: styled(AtomicSpinner)`
    justify-content: flex-start;
    width: ${({ theme }) => theme.iconSize[16]};
  `,
  OptionsWrapper: styled.div`
    display: none;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[2]};
  `,
  StyledGrid: styled(AgGrid)<{
    $disableColumnHeaderColor?: boolean;
    $altRowColor?: string;
    $hideGridBorder?: boolean;
  }>`
    height: 100%;
    border-left: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    border-right: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    border-bottom: 1px solid ${({ theme }) => theme.colors.outlineDefault};

    &&& .ag-header-cell {
      border-top: 1px solid ${({ theme }) => theme.colors.outlineDefault};
      border-bottom: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    }

    .ag-center-cols-viewport,
    .ag-sticky-top-viewport {
      .compact-no-row-summary.ag-row-group-expanded div {
        display: none;
      }
    }

    .alternate-row-color {
      background-color: ${({ $altRowColor }) => $altRowColor};
    }

    .ag-header-row {
      border: none;
      background-color: ${({ theme, $disableColumnHeaderColor }) => {
        return $disableColumnHeaderColor ? theme.colors.gray00 : theme.colors.bgMedium;
      }};
    }

    .grid--blank-row {
      .ag-cell:not(.ag-cell-range-selected) {
        border-width: 0;

        &.ag-cell-range-right:not(.ag-cell-range-left) {
          border-right-width: 1px;
        }
      }

      .header-cell-click-listener,
      .drilldown-icon-wrapper,
      .metric-level-pivot-icon {
        display: none;
      }
    }

    .ag-row-hover.grid--blank-row {
      .ant-btn {
        display: flex;
      }
    }

    ${({ $hideGridBorder }) =>
      $hideGridBorder &&
      css`
        .ag-cell:not(.ag-cell-range-single-cell),
        .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
            .ag-cell-range-single-cell
          ) {
          border-right-width: 0;
        }

        .ag-row {
          border-bottom-width: 0;
        }
      `}

    .ag-header-group-cell.ag-header-group-cell-with-group.expandable-header[aria-expanded='true'] {
      --ag-header-cell-hover-background-color: ${({ theme, $disableColumnHeaderColor }) => {
        return $disableColumnHeaderColor ? theme.colors.gray00 : theme.colors.primary50;
      }};

      background-color: ${({ theme, $disableColumnHeaderColor }) => {
        return $disableColumnHeaderColor ? theme.colors.gray00 : theme.colors.primary50;
      }};
    }

    .ag-header-row:first-child
      .ag-header-group-cell.ag-header-group-cell-with-group.expandable-header[aria-expanded='true'] {
      border-top: 2px solid ${({ theme }) => theme.colors.primary500};
    }

    .ag-header-cell-comp-wrapper {
      justify-content: center;
    }

    .ag-header-cell-resize {
      cursor: col-resize;
    }

    .ag-header-cell-resize:hover::after {
      width: 3px;
      height: 100%;
      top: 0;
      background-color: ${({ theme }) => theme.colors.primary500};
      left: 0;
    }

    .plan-cell-not-editable {
      background-color: ${({ theme }) => theme.colors.bgLight};
    }

    &&&&& .comment-popover-open-cell-highlight {
      border-color: ${({ theme }) => theme.colors.yellow400};
      border-width: 2px;
    }

    ${() =>
      checkIfInPrintMode() &&
      css`
        font-family: inherit;

        --ag-font-size: 10px;
        --ag-cell-widget-spacing: ${({ theme }) => theme.spacing[2]};

        .ag-header-cell,
        .ag-header-group-cell {
          .ant-typography {
            font-size: 10px;
          }
        }

        &&& .ag-cell {
          padding-left: ${({ theme }) => theme.spacing[6]};
          padding-right: ${({ theme }) => theme.spacing[6]};
        }

        [col-id='ag-Grid-AutoColumn'] {
          .ag-group-value {
            line-height: 20px;
            margin-top: 4px;
            margin-bottom: 4px;
          }
        }
      `}
  `,
};
