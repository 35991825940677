import { useQuery } from '@tanstack/react-query';
import { ListsApi } from 'data/modelling/lists';
import { useListStoreContext } from 'store/lists';

export const useListReconMatchOptions = () => {
  const listId = useListStoreContext((s) => s.id);

  return useQuery({
    queryKey: ['list-recon-match-options', listId],
    queryFn: () => ListsApi.getListReconMatchOptions(listId),
  });
};
