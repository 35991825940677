import { type ColDef, type ColGroupDef } from 'ag-grid-community';
import { FrameType } from 'data/reports/types';

export const filterOutActualVersionColumns = (
  columns: (ColDef | ColGroupDef)[],
): (ColDef | ColGroupDef)[] => {
  if (!columns || columns.length === 0) {
    return columns;
  }

  if (
    (columns[0] as ColGroupDef).headerGroupComponentParams?.columnFrameType === FrameType.Version ||
    (columns[0] as ColDef).headerComponentParams?.columnFrameType === FrameType.Version
  ) {
    const filteredCols: (ColDef | ColGroupDef)[] = [];

    columns.forEach((el) => {
      const column = el as ColGroupDef;

      if (column.children?.length > 0 && column.columnGroupShow === 'open') {
        filteredCols.push(
          ...column.children.map((obj) => {
            return {
              ...obj,
              columnGroupShow: obj.columnGroupShow,
            };
          }),
        );
      }
    });

    return filteredCols;
  }

  return columns.map((el) => {
    const column = el as ColGroupDef;
    const filteredChildren = filterOutActualVersionColumns(column.children);

    if ((!filteredChildren || filteredChildren.length === 0) && column.children?.length > 0) {
      return {
        ...column.children[0],
        field: (column as ColDef).field,
        columnGroupShow: column.columnGroupShow,
        headerName: column.headerName,
        headerTooltip: column.headerTooltip,
        type: (column as ColDef).type,
      };
    }

    return {
      ...column,
      children: filteredChildren,
    };
  });
};
