import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Analytics } from 'config/analytics';
import { type IntegrationModalContent, IntegrationsApi } from 'data/integrations/fivetran';
import { IntegrationKeys } from 'data/integrations/fivetran/constants';
import { useNavigate } from 'react-router-dom';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useConnectInputFlow = (
  item: IntegrationModalContent,
  onConnectionSuccess: () => void,
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: Record<string, string>) =>
      IntegrationsApi.connectIntegration({ ...data, service: item.id }),

    onSuccess: async (data) => {
      Analytics.track('New source', {
        category: 'Data',
        meta: `Source name - ${item.displayName}`,
      });

      await queryClient.invalidateQueries({ queryKey: IntegrationKeys.ConnectedIntegrations });
      onConnectionSuccess();
      navigate(`/data/connected/${data?.data?.id}`);

      return;
    },

    onError: (error) => {
      defaultApiErrorHandler(error);
    },
  });
};
