import { useQuery } from '@tanstack/react-query';
import { DtqlQueryApi, type BreakupQueryDrawerParams } from 'data/big-query';
import { useState } from 'react';

export const useShowBreakup = () => {
  const [breakupQueryDrawerVisible, setBreakupQueryDrawerVisible] = useState(false);
  const [breakupQueryDrawerParams, setBreakupQueryDrawerParams] =
    useState<BreakupQueryDrawerParams>();

  const { data: metricNamesSupportingBreakup = {} } = useQuery({
    queryKey: ['breakup-available'],
    queryFn: () => DtqlQueryApi.getBreakupAvailable(),
    staleTime: Infinity,
  });

  const closeBreakupQueryDrawer = () => {
    setBreakupQueryDrawerVisible(false);
  };

  return {
    breakupQueryDrawerVisible,
    setBreakupQueryDrawerVisible,
    breakupQueryDrawerParams,
    setBreakupQueryDrawerParams,
    metricNamesSupportingBreakup,
    closeBreakupQueryDrawer,
  };
};
