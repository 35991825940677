import { type ExcelStyle } from 'ag-grid-community';
import { Currency, CurrencySymbolMapping } from 'data/currencies';
import { DataFormattingType, NegativeValueType, ZeroFormatType } from 'utils/data-formatter/types';

export const excelSupportedCurrencySymbols = [
  Currency.USD,
  Currency.EUR,
  Currency.GBP,
  Currency.INR,
];

const base: ExcelStyle[] = [
  {
    id: `type-${DataFormattingType.Number}`,
    numberFormat: { format: '0' },
  },
];

excelSupportedCurrencySymbols.forEach((currency) => {
  base.push({
    id: `type-${DataFormattingType.Currency}~currency-${currency}`,
    numberFormat: { format: `[$${CurrencySymbolMapping[currency as Currency]}]0` },
  });
});

// decimal format
const { length } = base;

for (let i = 1; i <= 4; i += 1) {
  for (let j = 0; j < length; j += 1) {
    const { id } = base[j];

    const format = base[j].numberFormat?.format || '';

    base.push({
      id: `${id}~decimals-${i}`,
      numberFormat: { format: format?.replace('0', `0.${'0'.repeat(i)}`) },
    });
  }
}

base.push({
  id: `type-${DataFormattingType.Percentage}`,
  numberFormat: { format: '0.00%' },
});

export const excelDataFormatStyles: ExcelStyle[] = [];

// appending negative and zero formats for types
base.forEach((item) => {
  const { id } = item;
  const format = item.numberFormat?.format || '';

  excelDataFormatStyles.push({
    id: `${id}~zero-${ZeroFormatType.Dash}~negative-${NegativeValueType.Brackets}`,
    numberFormat: { format: `${format};(${format});-` },
  });
  excelDataFormatStyles.push({
    id: `${id}~zero-${ZeroFormatType.Dash}~negative-${NegativeValueType.Sign}`,
    numberFormat: { format: `${format};-${format};-` },
  });

  excelDataFormatStyles.push({
    id: `${id}~zero-${ZeroFormatType.Number}~negative-${NegativeValueType.Brackets}`,
    numberFormat: { format: `${format};(${format});0` },
  });
  excelDataFormatStyles.push({
    id: `${id}~zero-${ZeroFormatType.Number}~negative-${NegativeValueType.Sign}`,
    numberFormat: { format: `${format};-${format};0` },
  });
});
