import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIsUsingConnectionParam } from 'store/connections';
import { GenericLeftPanel } from '../../integrations/generic/input';
import { FormConnectorStyles } from '../../integrations/styles';
import { type IntegrationInputFlowCompV2, type IntegrationInputFlowComp } from '../../types';
import { ConnectForm } from './connect-form';
import { GenericConnectForm } from './generic/generic-connect-form';

const { FullWidthWrapper, RightPanel, RightTitle } = FormConnectorStyles;

interface Props {
  flow: IntegrationInputFlowComp | IntegrationInputFlowCompV2;
  item: IntegrationModalContent;
  onConnectionSuccess: () => void;
}

export const InputsFlow = ({ flow, item, onConnectionSuccess }: Props): ReactElement => {
  const LeftPanel = flow.infoContent;
  const isUsingConnectionParam = useIsUsingConnectionParam();

  return (
    <FullWidthWrapper>
      {isUsingConnectionParam ? (
        <GenericLeftPanel flow={flow as IntegrationInputFlowCompV2} item={item} />
      ) : (
        // @ts-expect-error - If it's not a generic flow, it's a Component only
        <LeftPanel item={item} />
      )}

      <RightPanel>
        <RightTitle>
          <FormattedMessage
            id="integrations.sfdc.modal.title"
            values={{ name: item.displayName }}
          />
        </RightTitle>

        {isUsingConnectionParam ? (
          <GenericConnectForm
            flow={flow as IntegrationInputFlowCompV2}
            item={item}
            onConnectionSuccess={onConnectionSuccess}
          />
        ) : (
          <ConnectForm flow={flow} item={item} onConnectionSuccess={onConnectionSuccess} />
        )}
      </RightPanel>
    </FullWidthWrapper>
  );
};
