import { useContext } from 'react';
import { ReportChartContext, type ReportChartContextParams } from './chart-context';

export const useReportChartContext = (): ReportChartContextParams => {
  const context = useContext(ReportChartContext);

  if (!context) {
    throw new Error('useReportChartContext to be used within ReportChartContext');
  }

  return context;
};
