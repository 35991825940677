import { type BodyScrollEvent } from 'ag-grid-community';
import { defaultTheme } from 'styles/theme-old';

export const onBodyScroll = (event: BodyScrollEvent): void => {
  if (event.direction === 'horizontal') {
    const root =
      // dirty hack to get ag-grid body dom
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (event.api as any)?.gridBodyCon?.eBodyViewport || document.documentElement;

    if (event.left !== 0) {
      root.style.setProperty(
        '--ag-left-pinned-shadow',
        defaultTheme.boxShadow.gridLeftPinnedColumn,
      );
    } else {
      root.style.setProperty('--ag-left-pinned-shadow', 'none');
    }
  }
};
