import {
  type DataFormatType,
  type Metric,
  MetricTreeNodeTypes,
  type MetricWithHierarchy,
} from 'data/metrics/types';
import {
  PivotDimensionsSummaryType,
  PivotViewType,
  PIVOT_DIMENSION_METRIC_ROW_NAME,
} from 'data/page-template';
import { type FrameType } from 'data/reports/types';
import { isEmpty } from 'lodash';

export type HierarchyClanNode = {
  displayName: string;
  viewNodeType: MetricTreeNodeTypes | FrameType;
  name: string;
  value: string | null;
  displayValue: string | null;
};

interface Props {
  data: Metric[];
  hierarchy?: string[];
  hierarchyClan?: HierarchyClanNode[];
  rootMetricProperties?: Partial<Metric>;
  result?: MetricWithHierarchy[];
  indexHierarchy?: number[];
  metricInHierarchy?: string;
  options?: {
    viewType?: PivotViewType;
    dimensionSummary?: PivotDimensionsSummaryType;
  };
  metricsDataFormat?: Map<string | undefined, DataFormatType | undefined>;
}

export const transformDataForGrid = ({
  data,
  metricsDataFormat,
  options = {
    viewType: PivotViewType.Compact,
    dimensionSummary: PivotDimensionsSummaryType.Top,
  },
  hierarchy = [],
  hierarchyClan = [],
  rootMetricProperties = {},
  result = [],
  indexHierarchy = [],
  metricInHierarchy,
}: Props): MetricWithHierarchy[] => {
  data?.forEach((metric: Metric, idx: number) => {
    const metricHierarchy: string[] = [
      ...hierarchy,
      ([
        MetricTreeNodeTypes.Metric,
        MetricTreeNodeTypes.SectionMetric,
        MetricTreeNodeTypes.Section,
        MetricTreeNodeTypes.Blank,
        MetricTreeNodeTypes.TempRow,
      ].includes(metric.viewNodeType)
        ? metric.name
        : metric.value || '*') || `<(blank)${idx}>`, // create a string for the blank value node
    ];
    const viewHierarchyNode: HierarchyClanNode[] = [
      ...hierarchyClan,
      {
        displayName: metric.displayName,
        viewNodeType: metric.viewNodeType,
        name: metric.name,
        value: metric.value || '*',
        displayValue: metric.displayValue || '(Blank)',
      },
    ];
    const currentRowDimensions: Record<string, string | null> = {};

    for (const { name, value, viewNodeType } of viewHierarchyNode) {
      if (viewNodeType === MetricTreeNodeTypes.Metric) {
        currentRowDimensions[PIVOT_DIMENSION_METRIC_ROW_NAME] = name;
      } else {
        currentRowDimensions[name] = value;
      }
    }

    if (options?.dimensionSummary === PivotDimensionsSummaryType.Bottom) {
      // rootMetricProperties props like timeAggregation are only available at the root node
      metric.pivotData &&
        transformDataForGrid({
          data: metric.pivotData,
          options,
          hierarchy: metricHierarchy,
          hierarchyClan: viewHierarchyNode,
          rootMetricProperties: isEmpty(rootMetricProperties)
            ? { timeAggregation: metric.timeAggregation }
            : rootMetricProperties,
          indexHierarchy: [...indexHierarchy, idx],
          metricInHierarchy: getMetricInHierarchy(metric, metricInHierarchy),
          result,
          metricsDataFormat,
        });
    }

    if (checkIfToPushMetricNodeToResult({ viewType: options.viewType })) {
      const currentMetricInHierarchy = getMetricInHierarchy(metric, metricInHierarchy);

      result.push({
        ...metric,
        ...rootMetricProperties,
        displayValue: metric.displayValue || '(Blank)',
        dataFormat: metricsDataFormat?.get(currentMetricInHierarchy),
        hierarchy: metricHierarchy,
        hierarchyClan: viewHierarchyNode,
        isLeaf: !metric.pivotData?.length,
        metricInHierarchy: currentMetricInHierarchy,
        rowDimensions: currentRowDimensions,
      });
    }

    if (options?.dimensionSummary !== PivotDimensionsSummaryType.Bottom) {
      // rootMetricProperties props like timeAggregation are only available at the root node
      metric.pivotData &&
        transformDataForGrid({
          data: metric.pivotData,
          options,
          hierarchy: metricHierarchy,
          hierarchyClan: viewHierarchyNode,
          rootMetricProperties: isEmpty(rootMetricProperties)
            ? { timeAggregation: metric.timeAggregation }
            : rootMetricProperties,
          indexHierarchy: [...indexHierarchy, idx],
          metricInHierarchy: getMetricInHierarchy(metric, metricInHierarchy),
          result,
          metricsDataFormat,
        });
    }
  });

  return result;
};

export const checkIfToPushMetricNodeToResult = ({
  viewType,
  hideAggregatesFor,
  childDimensionName,
}: {
  viewType?: PivotViewType;
  hideAggregatesFor?: string[];
  childDimensionName?: string;
}): boolean => {
  if (viewType === PivotViewType.Compact) {
    return true;
  }

  const includeAggregate = !hideAggregatesFor?.includes(childDimensionName as string);

  return includeAggregate;
};

const getMetricInHierarchy = (metric: Metric, metricInHierarchy?: string) => {
  return [MetricTreeNodeTypes.Metric, MetricTreeNodeTypes.SectionMetric].includes(
    metric.viewNodeType,
  )
    ? metric.name
    : metricInHierarchy;
};
