import { type ColumnGroupShowType } from 'ag-grid-community';

export const generateColId = (
  columnDimensions: Record<string, string>,
  columnGroupShow: ColumnGroupShowType,
): string => {
  const colPathDims = { ...columnDimensions };

  const gridColumnIdentifierPath = Object.values(colPathDims);

  if (columnGroupShow === 'closed') {
    gridColumnIdentifierPath.push(columnGroupShow);
  }

  return gridColumnIdentifierPath.join('~~');
};
