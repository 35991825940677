import { type OktaAuth } from '@okta/okta-auth-js';
import { type QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie';
import { type LDClient } from 'launchdarkly-react-client-sdk';
import { handle401Response } from './utils/handle-401-response';
import { sendPageContextViaHeader } from './utils/send-page-context-in-header';

axios.defaults.baseURL = '/drive/api/';

export const setupAxios = (
  oktaAuth: OktaAuth,
  queryClient: QueryClient,
  ldClient?: LDClient,
): void => {
  axios.interceptors.request.use(async (config) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const accessToken = await (oktaAuth as any).getOrRenewAccessToken();

    const samlCookie = Cookies.get('dt-sm');

    // Do not send Authorization header for public endpoints
    if (!config.url?.startsWith('v1/public/')) {
      if (samlCookie) {
        // TODO(tark)
        config.headers.Authorization = 'Basic dXNlcjo=';
      } else {
        config.headers.Authorization = `Bearer ${accessToken || ''}`;
      }
    }

    return config;
  });

  // Send page context in header
  axios.interceptors.request.use(sendPageContextViaHeader, (error) => Promise.reject(error));

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        handle401Response({ error, oktaAuth, queryClient, ldClient });
      }

      throw error;
    },
  );
};
