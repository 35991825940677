import { useBoardQueryParams } from 'data/boards';
import { useReportChartContext } from 'data/boards/context';
import { type Currency } from 'data/currencies';
import { useBoardContext } from '../../../contexts';

export const useChartCurrency = (): Currency | undefined => {
  const { board } = useBoardContext();
  const [query] = useBoardQueryParams();
  const { chart } = useReportChartContext();

  return chart?.attributes?.currency || query.currency || board?.attributes?.currency;
};
