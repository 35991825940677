import { ReactComponent as Lock12Icon } from 'assets/v2/lock_12.svg';
import { ReactComponent as World12Icon } from 'assets/v2/world_12.svg';
import { IconShell, Tooltip } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  isPrivateComment: boolean;
}

export const AccessIcon = ({ isPrivateComment }: Props): ReactElement => {
  return (
    <>
      <Tooltip
        placement="left"
        title={
          isPrivateComment ? <FormattedMessage id="private" /> : <FormattedMessage id="public" />
        }
      >
        <IconShell color="iconDefault" icon={isPrivateComment ? Lock12Icon : World12Icon} />
      </Tooltip>

      {!isPrivateComment && <FormattedMessage id="conversations.visible_to_all" />}
    </>
  );
};
