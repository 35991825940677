import { useBoardContext } from 'components/modules/boards-v2/custom-board/contexts';
import { type Currency } from 'data/currencies';
import { useBoardQueryParams } from './use-board-query-params';

export const useBoardCurrency = (): Currency | undefined => {
  const [query] = useBoardQueryParams();
  const { board } = useBoardContext();

  return query.currency || board?.attributes?.currency;
};
