import { type GridApi } from 'ag-grid-community';
import { ReactComponent as DownloadIcon } from 'assets/v2/download.svg';
import { Button, IconShell, Pagination } from 'components/ui/atomic-components';
import { type BreakupQueryDrawerParams, DtqlQueryApi, type BigQueryResponse } from 'data/big-query';
import { useCurrency } from 'data/currencies/hooks/use-currency';
import { TimeDimensionName } from 'data/dimension';
import { PIVOT_DIMENSION_METRIC_ROW_NAME } from 'data/page-template';
import { SCENARIO } from 'data/scenarios';
import dayjs from 'dayjs';
import { useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { numberFormatter } from 'utils/data-formatter';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { downloadGridAsExcel } from 'utils/grid';
import { Grid } from '../grid';
import { SummaryTable } from '../summary-table';
import { ColumnSelector } from './column-selector';
import styles from './styles';
import { SummarizeByDimension, SummarizeByNone } from './summarize';
import { getPeriodDisplayValue } from './utils/get-period-display-value';

const {
  Bullet,
  DataCount,
  DataCountWrapper,
  Drawer,
  ErrorMessage,
  Footer,
  Header,
  HeaderLeftSection,
  HeaderRightSection,
  RowsColumnsText,
  Value,
  HeaderActionsDivider,
} = styles;

interface Props {
  breakupData?: BigQueryResponse<Record<string, string>>;
  breakupQueryIsLoading: boolean;
  breakupQueryIsError: boolean;
  breakupErrorMessage?: string | null;
  breakupQueryDrawerParams?: BreakupQueryDrawerParams;
  breakupQueryDrawerVisible: boolean;
  currentPage: number;
  closeBreakupQueryDrawer: () => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  summarizeBy: string;
  setSummarizeBy: (summarizeBy: string) => void;
}
export const UnderlyingDataDrawer = ({
  breakupData,
  summarizeBy,
  setSummarizeBy,
  breakupQueryIsLoading,
  breakupQueryIsError,
  breakupErrorMessage,
  breakupQueryDrawerParams,
  breakupQueryDrawerVisible,
  currentPage,
  closeBreakupQueryDrawer,
  setCurrentPage,
}: Props): ReactElement => {
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const currency = useCurrency();
  const [summaryConfig, setSummaryConfig] = useState<{
    gridApi?: GridApi;
    rowCount?: number;
    colCount?: number;
  }>({});

  const summarizeByDimensionPresent = !!summarizeBy && summarizeBy !== SummarizeByNone;
  const {
    f: dimensions = {},
    startDate,
    endDate,
    metricName,
    displayName,
    displayPeriodKey,
    granularity,
    version,
    value,
    metricType,
    metricDataFormat,
  } = breakupQueryDrawerParams || {};

  delete dimensions?.[TimeDimensionName];
  delete dimensions?.[PIVOT_DIMENSION_METRIC_ROW_NAME];
  delete dimensions?.[SCENARIO];

  // TODO(arnav): metricType, metricDataFormat, pointValue should be mandatory parameters to BreakupQueryDrawerParams once sheets are moved to porsche.
  const formattedValue =
    metricType && value !== undefined
      ? numberFormatter({
          value: String(value),
          type: metricType,
          ...metricDataFormat,
          currency: metricDataFormat?.currency || currency,
        })
      : undefined;

  const [selectedColumns, setSelectedColumns] = useState<string[] | null>(null);

  const exportResultData = async () => {
    setDownloadIsLoading(true);
    if (summarizeByDimensionPresent) {
      if (summaryConfig.gridApi) {
        downloadGridAsExcel({
          gridApi: summaryConfig.gridApi,
          fileName: `${displayName}-${summarizeBy}-${dayjs().format('YYYY-MM-DD_HHmmss')}`,
        });
      }
    } else {
      await DtqlQueryApi.downloadBreakupAsExcel({
        metricName,
        dimensions,
        startDate,
        endDate,
        granularity,
        version,
      }).catch(defaultApiErrorHandler);
    }
    setDownloadIsLoading(false);
  };

  const numRows = summarizeByDimensionPresent ? summaryConfig.rowCount : breakupData?.rowCount;
  const numCols = summarizeByDimensionPresent
    ? summaryConfig.colCount
    : breakupData?.columns?.length;

  return (
    <Drawer
      getContainer={document.body.querySelector('.fullscreen') ?? document.body}
      open={breakupQueryDrawerVisible}
      size="lg"
      title={displayName}
      onClose={closeBreakupQueryDrawer}
    >
      <Header>
        <HeaderLeftSection>
          <Value>{formattedValue}</Value>
          <span>
            {getPeriodDisplayValue({ startDate, endDate, granularity, displayPeriodKey })}
          </span>
        </HeaderLeftSection>

        {(summarizeByDimensionPresent || !!breakupData?.rows?.length) && (
          <HeaderRightSection>
            <ColumnSelector
              columnsList={breakupData?.columns}
              disabled={summarizeByDimensionPresent}
              selectedColumns={selectedColumns || breakupData?.columns || []}
              onApply={(cols) => setSelectedColumns(cols)}
            />

            <HeaderActionsDivider />
            <SummarizeByDimension
              dimensions={dimensions}
              metricName={metricName}
              setSummarizeBy={setSummarizeBy}
              summarizeBy={summarizeBy}
            />
            <HeaderActionsDivider />

            <Button
              icon={<IconShell icon={DownloadIcon} />}
              loading={downloadIsLoading}
              type="text"
              onClick={exportResultData}
            />
          </HeaderRightSection>
        )}
      </Header>

      {breakupQueryIsError && (
        <ErrorMessage>
          {breakupErrorMessage ?? <FormattedMessage id="error.unexpected" />}
        </ErrorMessage>
      )}

      {summarizeByDimensionPresent && (
        <SummaryTable
          dimensions={dimensions}
          endDate={endDate}
          granularity={granularity}
          metricName={metricName}
          setSummaryConfig={setSummaryConfig}
          startDate={startDate}
          summarizeBy={summarizeBy}
          version={version}
        />
      )}

      {!breakupQueryIsError && !summarizeByDimensionPresent && (
        <Grid
          breakupData={breakupData}
          isLoading={breakupQueryIsLoading}
          selectedColumns={selectedColumns || breakupData?.columns}
        />
      )}

      <Footer>
        <DataCountWrapper>
          <DataCount>{numRows}</DataCount>&nbsp;
          <RowsColumnsText>
            <FormattedMessage id="rows" />
          </RowsColumnsText>
          &nbsp;
          <Bullet>&bull;</Bullet>&nbsp;<DataCount>{numCols}</DataCount>&nbsp;
          <RowsColumnsText>
            <FormattedMessage id="columns" />
          </RowsColumnsText>
        </DataCountWrapper>

        <HeaderActionsDivider />
        {breakupData && !summarizeByDimensionPresent && !breakupQueryIsLoading && (
          <Pagination
            currentPage={currentPage}
            pageSize={breakupData?.pageSize ?? 1000}
            setPage={setCurrentPage}
            total={breakupData?.rowCount ?? 0}
          />
        )}
      </Footer>
    </Drawer>
  );
};
