import { type CellStyle } from 'ag-grid-community';
import { type TableFormattingRuleStyle } from 'data/boards/charts/types/table';
import { type MetricWithHierarchy } from 'data/metrics';
import { generateDataLookupKey } from 'data/reports/utils';
import { type MutableRefObject } from 'react';
import { transformIntoStyleType } from '../create-view-column-defs/utils/style-getter';

interface Props {
  data: MetricWithHierarchy;
  styleMap: MutableRefObject<Record<string, TableFormattingRuleStyle>>;
  rowsOrder: string[];
}

export const getRowAutoColumnCellStyle = ({
  data,
  styleMap,
  rowsOrder,
}: Props): CellStyle | undefined => {
  const key = generateDataLookupKey({
    dimensions: data.rowDimensions as Record<string, string>,
    rowsOrder,
    columnsOrder: [],
  });

  return transformIntoStyleType(styleMap.current[key]);
};
