import styled from 'styled-components';
import { NotificationTypeFilter } from './notification-type-filter';
import { ToggleUnreadOnlyNotifications } from './toggle-unread-only-notifications';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[16]}`};
  gap: ${({ theme: { spacing } }) => spacing[12]};
  min-width: 256px;
`;

export const FilterPopoverContent = () => {
  return (
    <Wrapper>
      <NotificationTypeFilter />
      <ToggleUnreadOnlyNotifications />
    </Wrapper>
  );
};
