import { ActionBar } from 'components/ui/atomic-components';
import styled, { css } from 'styled-components';

const ShadowHeight = 16;
const ContainerHeight = 65;

type Container = {
  $bordered?: boolean;
  $height?: number;
  $shadowOnScroll?: boolean;
  $highlighted?: boolean;
};

export const HeaderStyles = {
  Background: styled.div`
    background-color: ${({ theme }) => theme.colors.gray00};
    content: '';
    margin-bottom: -82px;
    top: 0;
    left: 0;
    width: 100%;
    height: 82px;
    z-index: 11;
  `,
  Wrapper: styled.div<{ highlighted?: boolean }>`
    ${({ highlighted }) =>
      highlighted &&
      css`
        padding: ${({ theme }) => theme.spacing[16]};
        background: ${({ theme }) => theme.colors.primary100};
      `};
  `,
  Container: styled(ActionBar)<Container>`
    background: ${({ theme: { colors }, $highlighted }) => {
      return $highlighted ? colors.primary200 : colors.gray00;
    }};
    border-radius: ${({ theme: { borderRadius }, $highlighted }) => {
      return $highlighted ? borderRadius.m : 0;
    }};
    display: block;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.zIndex[20] + 1};
    padding: 0;

    ${({ $bordered, $highlighted, theme: { colors } }) =>
      $bordered &&
      !$highlighted &&
      css`
        border-bottom: solid 1px ${colors.outlineDefault};
      `};

    > div {
      padding: ${({ theme: { spacing } }) => `${spacing[16]} ${spacing[24]}`};
      position: sticky;
      top: 0;
      min-height: 65px;
      border-bottom: solid 1px transparent;
    }

    ${({ $shadowOnScroll }) =>
      $shadowOnScroll &&
      css`
        height: ${ContainerHeight + ShadowHeight}px;
        top: -${ShadowHeight}px;
        backface-visibility: hidden;

        &::before,
        &::after {
          content: '';
          display: block;
          height: ${ShadowHeight}px;
          position: sticky;
          -webkit-position: sticky;
        }

        &::before {
          top: ${ContainerHeight - ShadowHeight}px;
          box-shadow: ${({ theme }) => theme.shadow.softMicro};
        }

        &::after {
          background: linear-gradient(${({ theme }) => theme.colors.gray00} 10%, transparent);
          top: 0;
          z-index: ${({ theme: { zIndex } }) => zIndex[1000]};
        }

        & > div {
          height: ${ContainerHeight}px;
          margin-top: -${ShadowHeight}px;

          &::before {
            position: absolute;
            background-color: ${({ theme }) => theme.colors.gray00};
            height: ${ShadowHeight}px;
            left: 0;
            right: 0;
            top: 68px;
            content: '';
          }
        }
      `};
  `,
};
