import { AsyncSelectOld, type AsyncSelectProps } from 'components/ui/atomic-components';
import { KEYBOARD_DEBOUNCE_INTERVAL } from 'config/constants';
import { MetricDomain, type MetricDropdownOption } from 'data/metrics';
import { type Filter, SearchApi } from 'data/search';
// https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
import debounce from 'debounce-promise';
// https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
import { type FC, type RefObject, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { defaultApiErrorHandler } from 'utils/error-handler';

const MetricSelector = styled(AsyncSelectOld)`
  margin-bottom: ${({ theme }) => theme.spacing[8]};

  .react-select__indicator {
    display: none;
  }
`;

export const QueryableMetricSelectDropdown: FC<
  React.PropsWithChildren<
    AsyncSelectProps<MetricDropdownOption> & {
      selectRef?: RefObject<unknown>;
      addedMetrics?: string[];
      metricDomain?: MetricDomain;
      filters: Filter[];
    }
  >
> = ({
  className,
  components,
  controlShouldRenderValue = false,
  isDisabled,
  menuPortalTarget,
  placeholder,
  selectRef,
  size,
  styles,
  value,
  addedMetrics,
  metricDomain = MetricDomain.Monitoring,
  filters,
  onBlur,
  onChange,
  onKeyDown,
}) => {
  const intl = useIntl();

  const noOptionsMessage = ({ inputValue }: { inputValue: string }) => {
    return !inputValue
      ? null
      : intl.formatMessage({
          id: 'metrics.no_metric_placeholder',
        });
  };

  const promiseOptions = useCallback(
    async (inputValue: string) => {
      const searchRequest = {
        query: inputValue,
        filters,
      };

      try {
        return (await SearchApi.advancedSearch(searchRequest)).map(
          (item) =>
            ({
              name: item.metricName,
              displayName: item.displayName,
              label: item.displayName,
              value: item.metricName,
            } as MetricDropdownOption),
        );
      } catch (err) {
        defaultApiErrorHandler(err);

        return Promise.reject();
      }
    },
    [filters],
  );

  const loadOptions = debounce(promiseOptions, KEYBOARD_DEBOUNCE_INTERVAL, {
    leading: true,
  });

  const customPlaceholder = placeholder || <FormattedMessage id="metrics.chart_type_placeholder" />;

  return (
    <MetricSelector<FC<AsyncSelectProps<MetricDropdownOption>>>
      ref={selectRef}
      autoFocus
      cacheOptions
      className={className}
      components={components}
      controlShouldRenderValue={controlShouldRenderValue}
      filterOption={(option) => !(addedMetrics || []).includes(option.value)}
      getOptionLabel={(option) => option.displayName}
      isDisabled={isDisabled}
      isMulti={false}
      loadOptions={loadOptions}
      menuPortalTarget={menuPortalTarget}
      noOptionsMessage={noOptionsMessage}
      placeholder={customPlaceholder}
      size={size}
      styles={styles}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};
