import { type ConversationAccessType, type Conversation } from 'data/conversations';
import { useUsers } from 'data/users';
import { isEmpty, keyBy } from 'lodash';
import { useMemo, type ReactElement } from 'react';
import styled from 'styled-components';
import { getUserFullName } from 'utils';
import { useUser } from 'utils/hooks';
import { AddComment } from './add-comment';
import { PopoverConversationElement } from './popover-conversation-element';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 356px;
`;

const ConversationElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 450px;
  overflow: auto;
  padding-bottom: ${({ theme }) => theme.spacing[8]};
`;

interface Props {
  data: Conversation[];
  hideAccessSelector?: boolean;
  onAddComment?: (params: {
    comment: string;
    members: string[];
    access: ConversationAccessType;
  }) => void;
}

export const ConversationPopoverContent = ({
  data,
  hideAccessSelector,
  onAddComment,
}: Props): ReactElement => {
  const { data: users = [] } = useUsers();
  const { user: currentUser } = useUser();

  const usersMap = useMemo(() => keyBy(users, 'username'), [users]);

  return (
    <Wrapper>
      <AddComment
        currentUserName={getUserFullName(currentUser)}
        hideAccessSelector={hideAccessSelector}
        onAddComment={onAddComment}
      />

      {!isEmpty(data) && (
        <ConversationElementsWrapper>
          {data.map((conversationItem) => (
            <PopoverConversationElement
              key={conversationItem.id}
              conversationItem={conversationItem}
              hideAccessSelector={hideAccessSelector}
              usersMap={usersMap}
            />
          ))}
        </ConversationElementsWrapper>
      )}
    </Wrapper>
  );
};
