import { DateTimeFormats } from 'config/constants';
import { Granularity } from 'data';
import { ActualsTillDatePreset } from 'data/modelling/model';
import { ACTUAL } from 'data/versions';
import dayjs from 'dayjs';

export const constructUnderlyingDataEndDate = ({
  actualsTillDate,
  actualsTillDateOption,
  endDate,
  granularity,
  showFutureActuals,
  version,
}: {
  actualsTillDate?: string;
  actualsTillDateOption?: ActualsTillDatePreset;
  endDate: string;
  granularity?: Granularity;
  showFutureActuals: boolean;
  version: string;
}): string => {
  if (
    version !== ACTUAL ||
    !endDate ||
    (!actualsTillDate && !actualsTillDateOption) ||
    // If granularity is aggregate like Q, Y, YTD or undefined (when no t present), use actuals till & current month to calculate endDate
    (granularity &&
      [Granularity.Daily, Granularity.Weekly, Granularity.Monthly].includes(granularity))
  ) {
    return endDate;
  }
  const actualsTillDayJs = getActualsTillDayJs({
    actualsTillDate,
    actualsTillDateOption,
  });

  const endDateDayJs = dayjs(endDate);

  if (showFutureActuals) {
    return (
      dayjs.min(actualsTillDayJs, endDateDayJs)?.endOf('month')?.format(DateTimeFormats.APIDate) ??
      endDate
    );
  }

  return (
    dayjs
      .min(actualsTillDayJs, endDateDayJs, dayjs())
      ?.endOf('month')
      ?.format(DateTimeFormats.APIDate) ?? endDate
  );
};

const getActualsTillDayJs = ({
  actualsTillDate,
  actualsTillDateOption,
}: {
  actualsTillDate?: string;
  actualsTillDateOption?: ActualsTillDatePreset;
}) => {
  if (actualsTillDateOption === ActualsTillDatePreset.CustomRange && actualsTillDate) {
    return dayjs(actualsTillDate);
  }

  if (actualsTillDateOption === ActualsTillDatePreset.LastMonth) {
    return dayjs().subtract(1, 'month');
  }

  return dayjs(); // Current month
};
