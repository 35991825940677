import { type RowDragCallbackParams, type ColDef } from 'ag-grid-community';
import { useSupportedDateFormats } from 'data/date-format/hooks/use-supported-date-formats';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useListStoreContext, useListStoreInstance } from 'store/lists';
import { defaultTheme } from 'styles/theme';
import { hasListAccess } from 'utils/rbac/has-list-access';
import { IdCellRenderer } from '../../cell-components';
import { type ListGridRow } from '../../types';
import { createColDef } from './utils';
import { IndexColumnWidth } from './utils/constants';

export const useGridColumnDefs = (): ColDef[] => {
  const intl = useIntl();

  const store = useListStoreInstance();
  const config = useListStoreContext((s) => s.config);
  const reportListColumnOrder = useListStoreContext((s) => s.reportListColumnOrder);
  const isReadOnly = useListStoreContext((s) => s.isReadOnly);
  const embedPlace = useListStoreContext((s) => s.embedPlace);
  const accessAction = useListStoreContext((s) => s.accessAction);
  const isDerivedList = useListStoreContext((s) => s.isDerivedList);
  const setSelectedRowIds = useListStoreContext((s) => s.setSelectedRowIds);
  const [defaultDateFormat] = useSupportedDateFormats();

  const canReorderRows =
    !isDerivedList && hasListAccess(accessAction, isReadOnly, config.backedByBQ).reorderRows;

  useEffect(() => setSelectedRowIds([]), [setSelectedRowIds]);

  return useMemo(() => {
    const colDefs = (
      (embedPlace === 'report' ? reportListColumnOrder : config?.columnOrder) || []
    ).map((colName) => {
      return createColDef({
        config,
        colName,
        store,
        isReadOnly,
        embedPlace,
        defaultDateFormat,
      });
    });

    return [
      {
        colId: 'row-index',
        headerName: '',
        valueGetter: ({ node }) => {
          return String((node?.rowIndex || 0) + 1);
        },
        lockPinned: true,
        pinned: 'left',
        width: IndexColumnWidth,
        maxWidth: IndexColumnWidth,
        resizable: false,
        suppressSizeToFit: true,
        suppressFillHandle: true,
        suppressNavigable: true,
        cellClass: 'row-index-cell',
        rowDrag: (params: RowDragCallbackParams<ListGridRow>) => {
          return canReorderRows && !!params.data?.backendId;
        },
        rowDragText: ({ rowNodes }) => {
          const count = rowNodes?.length;
          const text = intl.formatMessage({ id: 'lists.rows.reorder.drag_text' }, { count });

          if (count === 1) {
            return text.slice(0, -1);
          }

          return text;
        },
        cellStyle: { color: defaultTheme.colors.textPlaceholder },
        cellRenderer: IdCellRenderer,
      },
      ...colDefs,
    ] as ColDef[];
  }, [
    config,
    store,
    isReadOnly,
    embedPlace,
    canReorderRows,
    intl,
    defaultDateFormat,
    reportListColumnOrder,
  ]);
};
