import { generateDataLookupKey } from './generate-data-lookup-key';

interface Props {
  dataLookupMap: Record<string, number | null>;
  dimensions: Record<string, string>;
  rowsOrder: string[];
  columnsOrder: string[];
  firstVersion?: string;
}

export const getDataValue = ({
  dataLookupMap,
  dimensions,
  rowsOrder,
  columnsOrder,
  firstVersion,
}: Props): number | null => {
  const key = generateDataLookupKey({
    dimensions,
    rowsOrder,
    columnsOrder,
    firstVersion,
  });

  return dataLookupMap?.[key] ?? null;
};
