import { type MetricDimensionOverrides } from 'data/boards/charts/types';
import { TableChartRowType } from 'data/boards/charts/types/table';
import {
  PIVOT_DIMENSION_METRIC_ROW_NAME,
  PivotDimensionsSummaryType,
  PivotViewType,
} from 'data/page-template';
import { type FrameElement, FrameType, type TableRowData } from 'data/reports/types';
import { generateDataLookupKey } from 'data/reports/utils';
import { VERSION } from 'data/versions';
import { isEmpty } from 'lodash';
import { generateRandomNumber } from 'utils';
import { constructCurrentLevelDimObj } from 'utils/grid/table-view';
import { checkIfToPushMetricNodeToResult, type HierarchyClanNode } from 'utils/tree-table';

interface Props {
  rows: FrameElement[];
  viewType?: PivotViewType;
  dimensionSummary?: PivotDimensionsSummaryType;
  hideAggregatesFor?: string[];
  dataLookupMap: Record<string, number | null>;
  hierarchy?: string[];
  hierarchyClan?: HierarchyClanNode[];
  metricInHierarchy?: string | null;
  rowDimensions?: Record<string, string>;
  indexHierarchy?: number[];
  result?: TableRowData[];
  hideRowColumnMap?: Record<string, boolean>;
  rowsOrder?: string[];
  metricDimensionOverrides?: MetricDimensionOverrides;
}

export const generateRowData = ({
  rows,
  viewType,
  dimensionSummary,
  hideAggregatesFor,
  dataLookupMap,
  hideRowColumnMap,
  rowsOrder,
  metricDimensionOverrides,
  hierarchy = [],
  hierarchyClan = [],
  metricInHierarchy = null,
  rowDimensions = {},
  indexHierarchy = [],
  result = [],
}: Props): TableRowData[] => {
  (rows || []).forEach((row, idx) => {
    const rowKey =
      row.type === FrameType.Time ? `${row.key}-${row.period}` : `${row.dimensionName}-${row.key}`;
    const currentHierarchy = [...hierarchy, rowKey];

    const currentHierarchyClan = [
      ...hierarchyClan,
      {
        displayName: row.displayName,
        name: row.dimensionName,
        value: row.key,
        viewNodeType: row.type,
        displayValue: row.displayName,
        dataFormattingType: row.type,
      },
    ];

    const currentRowDimensions = constructCurrentLevelDimObj(row, rowDimensions);

    const currentIndexHierarchy = [...indexHierarchy, idx];

    const currentMetricInHierarchy = row.type === FrameType.Metric ? row.key : metricInHierarchy;

    const overriddenRows =
      metricDimensionOverrides?.[currentRowDimensions[PIVOT_DIMENSION_METRIC_ROW_NAME]]?.rows ||
      rowsOrder;

    const key = generateDataLookupKey({
      dimensions: currentRowDimensions,
      rowsOrder: overriddenRows || [],
      columnsOrder: [...(overriddenRows?.includes(VERSION) ? [] : [VERSION])],
      firstVersion: '',
    });

    if (hideRowColumnMap?.[key]) {
      return;
    }

    if (dimensionSummary === PivotDimensionsSummaryType.Bottom) {
      generateRowData({
        rows: row.children,
        viewType,
        dimensionSummary,
        hideAggregatesFor,
        hideRowColumnMap,
        rowsOrder,
        dataLookupMap,
        hierarchy: currentHierarchy,
        hierarchyClan: currentHierarchyClan,
        metricInHierarchy: currentMetricInHierarchy,
        rowDimensions: currentRowDimensions,
        indexHierarchy: currentIndexHierarchy,
        result,
      });
    }

    if (
      checkIfToPushMetricNodeToResult({
        viewType,
        hideAggregatesFor,
        childDimensionName: row.children?.[0]?.dimensionName,
      })
    ) {
      const isLeafRow = !row?.children?.length;

      result.push({
        dataLookupMap,
        hierarchy: currentHierarchy,
        hierarchyClan: currentHierarchyClan,
        name: row.dimensionName,
        displayValue: row.displayName,
        isLeaf: isLeafRow,
        isDataLeaf: viewType === PivotViewType.Table ? isLeafRow : !!row.isDataLeaf,
        isMasked: !!row.isMasked,
        value: row.key,
        viewNodeType: row.type,
        metricInHierarchy: currentMetricInHierarchy,
        rowDimensions: currentRowDimensions,
        indexHierarchy: currentIndexHierarchy,
        hideSummary: hideAggregatesFor?.includes(row.dimensionName),
      });
    }

    if (dimensionSummary !== PivotDimensionsSummaryType.Bottom) {
      generateRowData({
        rows: row.children,
        viewType,
        dimensionSummary,
        hideAggregatesFor,
        hideRowColumnMap,
        rowsOrder,
        dataLookupMap,
        hierarchy: currentHierarchy,
        hierarchyClan: currentHierarchyClan,
        metricInHierarchy: currentMetricInHierarchy,
        rowDimensions: currentRowDimensions,
        indexHierarchy: currentIndexHierarchy,
        result,
      });
    }
  });

  return result;
};

export const addBlankRowsInFrame = (
  frameRows: FrameElement[],
  blankRowsPlacement?: string[],
  dimName?: string,
): FrameElement[] => {
  if (isEmpty(blankRowsPlacement) || frameRows[0]?.type !== FrameType.Metric) {
    return frameRows;
  }

  let noOfMetricsFound = 0;
  const updatedFrame: FrameElement[] = [];

  blankRowsPlacement?.forEach((item) => {
    if (item === TableChartRowType.Element) {
      const frameVal = frameRows[noOfMetricsFound];

      if (frameVal) {
        noOfMetricsFound += 1;

        updatedFrame.push(frameVal);
      }
    } else if (item === TableChartRowType.Blank) {
      updatedFrame.push({
        key: `${TableChartRowType.Blank}-${generateRandomNumber()}`,
        type: FrameType.Blank,
        dimensionName: dimName,
        displayName: TableChartRowType.Blank,
        children: [],
        isDataLeaf: true,
        isMasked: false,
      } as FrameElement);
    }
  });

  // in case blankRowsPlacement is out of sync with metrics, add remaining metrics in frame to updatedFrame
  if (noOfMetricsFound < frameRows.length) {
    for (let i = noOfMetricsFound; i < frameRows.length; i += 1) {
      const frameVal = frameRows[i];

      if (frameVal) {
        updatedFrame.push(frameVal);
      }
    }
  }

  return updatedFrame;
};
