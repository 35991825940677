import { css } from 'styled-components';

export const Caption = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.caption};
      line-height: ${theme.lineHeight.caption};
    `;
  }}
`;

export const Footnote = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.footnote};
      line-height: ${theme.lineHeight.footnote};
    `;
  }}
`;

export const H1 = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.h1};
      font-weight: ${theme.fontWeight.normal};
      line-height: ${theme.lineHeight.h1};
      margin-bottom: 0;
    `;
  }};
`;

export const H2 = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.h2};
      font-weight: ${theme.fontWeight.normal};
      line-height: ${theme.lineHeight.h2};
      margin-bottom: 0;
    `;
  }};
`;

export const H3 = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.h3};
      font-weight: ${theme.fontWeight.normal};
      line-height: ${theme.lineHeight.h3};
      margin-bottom: 0;
    `;
  }};
`;

export const H4 = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.h4};
      font-weight: ${theme.fontWeight.normal};
      line-height: ${theme.lineHeight.h4};
      margin-bottom: 0;
    `;
  }};
`;

export const H5 = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.h5};
      font-weight: ${theme.fontWeight.normal};
      line-height: ${theme.lineHeight.h5};
      margin-bottom: 0;
    `;
  }};
`;

export const H6 = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.h6};
      font-weight: ${theme.fontWeight.semiBold};
      line-height: ${theme.lineHeight.h6};
      margin-bottom: 0;
    `;
  }};
`;

export const P = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.paragraph};
      line-height: ${theme.lineHeight.paragraph};
      margin: 0;
    `;
  }};
`;

export const SingleLineEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TwoLineEllipsis = css`
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* stylelint-enable */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 3em;
`;

export const FontXxs = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.xxs};
      line-height: ${theme.lineHeight.xxs};
      letter-spacing: -0.165px;
    `;
  }};
`;

export const FontXs = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.xs};
      line-height: ${theme.lineHeight.xs};
      letter-spacing: -0.18px;
    `;
  }};
`;

export const FontS = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.s};
      line-height: ${theme.lineHeight.s};
      letter-spacing: -0.195px;
    `;
  }};
`;

export const FontM = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.m};
      line-height: ${theme.lineHeight.m};
      letter-spacing: -0.225px;
    `;
  }};
`;

export const FontL = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.l};
      line-height: ${theme.lineHeight.l};
      letter-spacing: -0.27px;
    `;
  }};
`;

export const FontXl = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.xl};
      line-height: ${theme.lineHeight.xl};
      letter-spacing: -0.3px;
    `;
  }};
`;

export const FontXxl = css`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSize.xxl};
      line-height: ${theme.lineHeight.xxl};
      letter-spacing: -0.36px;
    `;
  }};
`;

export const FontFormula = css`
  ${({ theme }) => {
    return css`
      font-family: 'IBM Plex Mono', monospace;
      font-size: ${theme.fontSize.xs};
      line-height: ${theme.lineHeight.xxs};
      font-weight: ${theme.fontWeight.regular};
    `;
  }};
`;
