import { useOktaAuth } from '@okta/okta-react';
import { SignInWidget } from 'components/modules/login/sign-in-widget';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { type FC } from 'react';
import { Navigate } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';

export const Login: FC<React.PropsWithChildren<unknown>> = () => {
  const { authState } = useOktaAuth();
  const client = useLDClient();

  const [{ gsheetExtensionLink }] = useQueryParams({
    gsheetExtensionLink: StringParam,
  });

  if (!client) {
    return <></>;
  }

  if (authState?.isAuthenticated && gsheetExtensionLink?.endsWith('.googleusercontent.com')) {
    window?.opener?.postMessage(authState?.accessToken?.accessToken, gsheetExtensionLink);
  }

  if (authState?.isAuthenticated) {
    return <Navigate to={{ pathname: '/' }} />;
  }

  return <SignInWidget />;
};
