import { type Dimension, TimeDimensionName } from 'data/dimension';
import { type PivotDimensionRows, PIVOT_DIMENSION_METRIC_ROW_NAME } from 'data/page-template';
import { VERSION } from 'data/versions';
import { type IntlShape } from 'react-intl';
import { formatName } from 'utils/data-formatter';

export const constructPivotDimensionsFromRowLabels = (
  rowLabels: PivotDimensionRows,
  intl: IntlShape,
): Dimension[] => {
  return rowLabels
    .map((dimName) => {
      if (dimName === PIVOT_DIMENSION_METRIC_ROW_NAME) {
        return {
          name: PIVOT_DIMENSION_METRIC_ROW_NAME,
          displayName: intl.formatMessage({
            id: 'metrics.title',
          }),
          displayNamePlural: intl.formatMessage({
            id: 'planning.metric.all_metrics',
          }),
        } as Dimension;
      }

      if (dimName === TimeDimensionName) {
        return {
          name: TimeDimensionName,
          displayName: intl.formatMessage({
            id: 'time',
          }),
          displayNamePlural: intl.formatMessage({
            id: 'time_aggregate',
          }),
        } as Dimension;
      }

      if (dimName === VERSION) {
        return {
          name: VERSION,
          displayName: intl.formatMessage({
            id: 'model.versions.title',
          }),
        } as Dimension;
      }

      return {
        name: dimName,
        displayName: formatName(dimName),
        displayNamePlural: formatName(dimName),
      } as Dimension;
    })
    .filter((dim) => !!dim);
};
