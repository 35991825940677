import { createContext } from 'react';
import { type DetailedAxiosError } from 'utils/error-handler';

export interface ConnectorAuthContextParams {
  handleError: (error: DetailedAxiosError, instance: number) => void;
  errorModalOpen: boolean;
  closeModal: () => void;
  relogin: () => void;
}

export const ConnectorAuthContext = createContext<ConnectorAuthContextParams>(
  {} as ConnectorAuthContextParams,
);
