import { type InputRef } from 'antd';
import { ReactComponent as CircleArrowUpIcon } from 'assets/v2/circle-arrow-up-filled.svg';
import { ReactComponent as StopIcon } from 'assets/v2/player-stop.svg';
import { IconShell, Input } from 'components/ui/atomic-components';
import { useEffect, useState, type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useChatWidgetStore } from 'store/ai-chat-widget';
import styled from 'styled-components';
import { FontM } from 'styles/typography';
import { FooterLoader } from './loader';

const { TextArea } = Input;

const Wrapper = styled.div`
  width: 100%;
  padding: ${({ theme: { spacing } }) => `${spacing[12]} ${spacing[16]} ${spacing[16]}`};
  position: relative;
`;

const StyledInput = styled(TextArea)`
  ${FontM};

  padding: ${({ theme }) => theme.spacing[12]};
  padding-right: 44px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  color: ${({ theme }) => theme.colors.gray800};
  z-index: ${({ theme }) => theme.zIndex[10]};
`;

const Icon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 28px;
  top: 48%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: ${({ theme }) => theme.zIndex[10]};
`;

interface Props {
  isOpen: boolean;
  onSubmit: (message: string) => void;
  isLoading: boolean;
  inputRef: React.RefObject<InputRef>;
}

export const Footer = ({ isLoading, inputRef, isOpen, onSubmit }: Props): ReactElement => {
  const intl = useIntl();

  const [inputValue, setInputValue] = useState('');
  const isLastMessageStreaming = useChatWidgetStore((s) => s.isLastMessageStreaming);
  const pauseLastMessageStream = useChatWidgetStore((s) => s.pauseLastMessageStreaming);

  const onSubmitMessage = () => {
    if (!inputValue || isLoading) {
      return;
    }

    pauseLastMessageStream();
    setInputValue('');
    onSubmit(inputValue);
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 250);
    }
  }, [inputRef, isOpen]);

  return (
    <Wrapper>
      <StyledInput
        ref={inputRef}
        autoComplete="off"
        autoSize={{ minRows: 1, maxRows: 3 }}
        placeholder={intl.formatMessage({ id: 'drive_ai.input.placeholder' })}
        size="large"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onPressEnter={(e) => {
          if (e.shiftKey) {
            return;
          }

          e.preventDefault();
          onSubmitMessage();
        }}
      />

      {!isLastMessageStreaming && (
        <Icon onClick={onSubmitMessage}>
          <IconShell
            color={inputValue && !isLoading ? 'iconPrimary' : 'gray300'}
            icon={CircleArrowUpIcon}
          />
        </Icon>
      )}

      {isLastMessageStreaming && (
        <Icon onClick={pauseLastMessageStream}>
          <IconShell color={'textBody2'} icon={StopIcon} />
        </Icon>
      )}

      <FooterLoader isLoading={isLoading} />
    </Wrapper>
  );
};
