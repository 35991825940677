import { ReactComponent as CircleCheckedFilledIcon } from 'assets/v2/circle-checked-filled.svg';
import { ReactComponent as TickIcon } from 'assets/v2/tick.svg';
import { Button, IconShell, Tooltip } from 'components/ui/atomic-components';
import { type ID } from 'data';
import { useConversationMutations } from 'data/conversations/hooks';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  conversationId: ID;
  isResolved?: boolean;
}

export const ResolveElement = ({ conversationId, isResolved }: Props): ReactElement => {
  const { updateConversationMutation } = useConversationMutations();

  return (
    <Tooltip
      title={
        isResolved ? (
          <FormattedMessage id="conversation.reopen" />
        ) : (
          <FormattedMessage id="conversations.resolve" />
        )
      }
    >
      <Button
        icon={
          isResolved ? (
            <IconShell color="green500" icon={CircleCheckedFilledIcon} />
          ) : (
            <IconShell icon={TickIcon} />
          )
        }
        size="extraSmall"
        type="text"
        onClick={(event) => {
          event.stopPropagation();

          updateConversationMutation.mutateAsync({ id: conversationId, resolved: !isResolved });
        }}
      />
    </Tooltip>
  );
};
