import { type DirectoryTreeNodeDataType } from 'components/ui/atomic-components/directory-tree/types';
import { Analytics } from 'config/analytics';
import { useCreateBoardMutation } from 'data/boards';
import { DateFilterPresetName } from 'data/bootstrap/types';
import { type Dispatch, type SetStateAction } from 'react';
import { getFolderIdFromKey } from '../sub-components/utils';

interface Props {
  setSelectedTreeNode?: Dispatch<SetStateAction<DirectoryTreeNodeDataType | null>>;
}

interface ReturnType {
  isCreateNewResourceLoading: boolean;
  handleConfirmCreateResource: (directoryTreeNode?: DirectoryTreeNodeDataType) => Promise<void>;
}

export const useFolderCreateMenuItem = ({ setSelectedTreeNode }: Props): ReturnType => {
  const createBoardMutation = useCreateBoardMutation();

  const handleConfirm = async (directoryTreeNode?: DirectoryTreeNodeDataType) => {
    await createBoardMutation.mutateAsync({
      folderId: directoryTreeNode ? getFolderIdFromKey(directoryTreeNode.key) : undefined,
      attributes: {
        draft: true,
        dateOption: DateFilterPresetName.LastTwelveMonths,
      },
    });

    Analytics.track('New report', {
      category: 'Reports',
      isNew: true,
    });

    setSelectedTreeNode?.(null);
  };

  return {
    isCreateNewResourceLoading: createBoardMutation.isPending,
    handleConfirmCreateResource: handleConfirm,
  };
};
