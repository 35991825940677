import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import { oktaSignInConfig } from 'config/okta';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { type FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { StringParam, useQueryParams } from 'use-query-params';
import { hideEmailFieldInWidget } from './utils';

import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const OktaSignInWidget: FC<React.PropsWithChildren<unknown>> = () => {
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef<HTMLDivElement | null>(null);

  const [{ state, otp, gsheetExtensionLink }] = useQueryParams({
    state: StringParam,
    otp: StringParam,
    gsheetExtensionLink: StringParam,
  });

  const { customOktaWidgetIdps } = useFlags();

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const widget = new OktaSignIn({
      ...oktaSignInConfig(customOktaWidgetIdps?.idps),
      state: state || undefined,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      otp,
    });

    widget
      .showSignIn({
        el: widgetRef.current as unknown as string,
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (gsheetExtensionLink && gsheetExtensionLink.endsWith('.googleusercontent.com')) {
          window?.opener?.postMessage(res?.tokens?.accessToken?.accessToken, gsheetExtensionLink);
        }
        oktaAuth.handleLoginRedirect(res.tokens);
      })
      .catch((error) => {
        // This function is invoked with errors the widget cannot recover from:
        // Known errors: CONFIG_ERROR, UNSUPPORTED_BROWSER_ERROR
        throw error;
      });

    widget.on('afterRender', () => {
      if (customOktaWidgetIdps?.hideEmail) {
        hideEmailFieldInWidget(widgetRef);
      }
    });

    return () => widget.remove();
  }, [oktaAuth, state, otp, gsheetExtensionLink, customOktaWidgetIdps]);

  return <Wrapper ref={widgetRef} />;
};
