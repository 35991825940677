import { type ID } from 'data';
import { type Comment } from 'data/conversations';
import { type ReactElement } from 'react';
import styled from 'styled-components';
import { MoreActionsMenu } from '../../conversation-popover-content/more-actions-menu';
import { ResolveElement } from '../../conversation-popover-content/popover-conversation-element/resolve-element';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};
`;

interface Props {
  conversationId: ID;
  isResolved?: boolean;
  comment: Comment;
  onClickMenu?: (key: string) => void;
}

export const TopRightContent = ({
  conversationId,
  isResolved,
  comment,
  onClickMenu,
}: Props): ReactElement => {
  return (
    <Wrapper>
      <ResolveElement conversationId={conversationId} isResolved={isResolved} />
      <MoreActionsMenu comment={comment} onClick={onClickMenu} />
    </Wrapper>
  );
};
