import { COLOR_SCALE_BUCKETS_COUNT } from 'components/ui/atomic-components/style-picker-v2/constants';
import { type TableFormattingRuleScale } from 'data/boards/charts/types/table';
import { isNull, isUndefined } from 'lodash';

export const getBackgroundColor = (scale: TableFormattingRuleScale, value: number): string => {
  const { min, max, minColor } = scale;

  if (isNull(min) || isUndefined(min) || isNull(max) || isUndefined(max) || !scale.colorStrip) {
    return minColor;
  }
  const { colorStrip } = scale;
  const index = Math.round(((value - min) * COLOR_SCALE_BUCKETS_COUNT) / (max - min));

  if (index < 0) {
    return colorStrip[0];
  }
  if (index > COLOR_SCALE_BUCKETS_COUNT - 1) {
    return colorStrip[COLOR_SCALE_BUCKETS_COUNT - 1];
  }

  return colorStrip[index];
};
