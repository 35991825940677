import { ChartType, type Chart, type PreviewChartRequest } from 'data/boards/charts/types';
import { DateFilterPresetName } from 'data/bootstrap/types';
import { TimeDimensionName, type DimensionQueryParamsObject } from 'data/dimension';
import { PIVOT_DIMENSION_METRIC_ROW_NAME } from 'data/page-template';
import { ACTUAL, VERSION } from 'data/versions';

export const generatePreviewRequest = ({
  summarizeBy,
  chart,
  metricName,
  startDate,
  endDate,
  granularity,
  version,
  dimensions,
}: {
  summarizeBy: string;
  chart?: Chart;
  metricName?: string;
  startDate?: string;
  endDate?: string;
  granularity?: string;
  version?: string;
  dimensions: DimensionQueryParamsObject;
}): PreviewChartRequest => {
  const chartFilters: DimensionQueryParamsObject = {
    ...chart?.attributes?.dimMap,
    ...dimensions,
    [VERSION]: [version || ACTUAL],
  };
  const filteredDimensions = [
    ...(chart?.attributes?.filteredDimensions || []),
    VERSION,
    TimeDimensionName,
    ...Object.keys(dimensions),
  ];
  const rows = [summarizeBy];
  let columns = chart?.attributes?.rows || [];

  !columns.includes(VERSION) && columns.push(VERSION);
  columns = (chart?.attributes?.rows || []).filter((d) =>
    [PIVOT_DIMENSION_METRIC_ROW_NAME, VERSION].includes(d),
  );

  return {
    boardId: chart?.boardId,
    chart: {
      id: chart?.id,
      name: chart?.name,
      description: chart?.description,
      type: ChartType.Table,
      attributes: {
        ...chart?.attributes,
        dimMap: chartFilters,
        startDate: startDate || chart?.attributes?.startDate,
        endDate: endDate || chart?.attributes?.endDate,
        dateOption: DateFilterPresetName.CustomRange,
        columns,
        granularity: granularity || chart?.attributes?.granularity,
        rows,
        filteredDimensions,
        metricNames: [metricName],
        versionNames: [version || ACTUAL],
      },
    },
  } as PreviewChartRequest;
};
