import { ReactComponent as ChevronLeftIcon } from 'assets/v2/chevron-left.svg';
import { ReactComponent as CloseIcon } from 'assets/v2/close.svg';
import { Button, IconShell } from 'components/ui/atomic-components';
import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState, type FC } from 'react';
import { useConnectionActions, useIsUsingConnectionParam } from 'store/connections';
import { styles } from '../styles';
import { AuthFlowSelector } from './auth-flows/auth-flow-selector';
import { ComponentFlow } from './auth-flows/component-flow';
import { InputsFlow } from './auth-flows/inputs-flow';
import { OauthFlow } from './auth-flows/oauth-flow';
import { IntegrationAuthFlowMap } from './render';
import { type IntegrationAuthFlow } from './types';

const { PopoverTitle, TitleWrapper, ComponentWrapper } = styles;

export const ConnectorContent: FC<{
  item: IntegrationModalContent | null;
  onClose: () => void;
  callbackUrl: string;
  onConnectionSuccess: () => void;
}> = ({ item, onClose, callbackUrl, onConnectionSuccess }) => {
  const ldClient = useLDClient();

  const [selectedAuthFlow, setSelectedAuthFlow] = useState<IntegrationAuthFlow>();
  const isUsingConnectionParam = useIsUsingConnectionParam();
  const { setIsUsingConnectionParam } = useConnectionActions();

  useEffect(() => {
    if (item?.name) {
      let authFlows: IntegrationAuthFlow[] | undefined;

      if (item.connectionParam?.connectionParamConfig) {
        authFlows = item.connectionParam.connectionParamConfig;
        setIsUsingConnectionParam(true);
      } else {
        setIsUsingConnectionParam(false);
        authFlows = IntegrationAuthFlowMap[item.name];
        authFlows = authFlows.filter((flow) => !flow.hidden?.({ ldClient }));
      }

      if (authFlows?.length === 1) {
        setSelectedAuthFlow(authFlows[0]);
      } else {
        setSelectedAuthFlow(undefined);
      }
    }
  }, [item?.connectionParam, item?.name, ldClient, setIsUsingConnectionParam]);

  if (!item || !item.name) {
    return <></>;
  }

  const getAuthFlows = () => {
    if (item.connectionParam?.connectionParamConfig) {
      return item.connectionParam.connectionParamConfig;
    }

    return IntegrationAuthFlowMap[item.name];
  };

  return (
    <>
      <PopoverTitle>
        <TitleWrapper>
          <Button
            icon={<IconShell color="textBody2" icon={ChevronLeftIcon} size="md" />}
            size="small"
            type="text"
            onClick={() => onClose()}
          />
          {item.displayName}
        </TitleWrapper>
        <Button
          icon={<IconShell color="textBody2" icon={CloseIcon} size="md" />}
          size="small"
          type="text"
          onClick={() => onClose()}
        />
      </PopoverTitle>
      <ComponentWrapper>
        {IntegrationAuthFlowMap[item.name]?.length > 1 && !selectedAuthFlow && (
          <AuthFlowSelector flows={getAuthFlows()} onSelect={(flow) => setSelectedAuthFlow(flow)} />
        )}

        {selectedAuthFlow?.type === 'oauth' && <OauthFlow flow={selectedAuthFlow} item={item} />}

        {selectedAuthFlow?.type === 'component' && (
          <ComponentFlow
            callbackUrl={callbackUrl}
            flow={selectedAuthFlow}
            item={item}
            onConnectionSuccess={onConnectionSuccess}
          />
        )}

        {(selectedAuthFlow?.type === 'inputs' ||
          (isUsingConnectionParam && selectedAuthFlow?.type === 'input')) && (
          <InputsFlow
            flow={selectedAuthFlow}
            item={item}
            onConnectionSuccess={onConnectionSuccess}
          />
        )}
      </ComponentWrapper>
    </>
  );
};
