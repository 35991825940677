import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/ui/atomic-components';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { type List, ListsApi } from 'data/modelling/lists';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useListMutations = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { listId } = useParams() as { listId: string };

  const createListMutation = useMutation({
    mutationFn: (list: Partial<List>) => ListsApi.createList(list),

    onSuccess: (list) => {
      queryClient.setQueryData(['lists'], (existingLists?: List[]) => [
        ...(existingLists || []),
        list,
      ]);

      notification.success({ message: intl.formatMessage({ id: 'lists.toasts.create.success' }) });

      navigate(`/lists/${list.id}`);
    },

    onError: defaultApiErrorHandler,
  });

  const updateListMutation = useMutation({
    mutationFn: ({ id, list }: { id: number; list: Partial<List>; skipToast?: boolean }) =>
      ListsApi.editList(id, list),

    onSuccess: (_, { skipToast }) => {
      if (!skipToast) {
        notification.success({
          message: intl.formatMessage({
            id: 'lists.toasts.update_success',
          }),
        });
      }
    },

    onError: defaultApiErrorHandler,
  });

  const deleteListMutation = useMutation({
    mutationFn: (id: number) => ListsApi.deleteList(id),

    onSuccess: (_, id) => {
      queryClient.setQueryData(['lists'], (existingLists?: (List | DimensionGroup)[]) =>
        (existingLists || []).filter((list) => {
          if (list.id === id && !(list as DimensionGroup)?.uniqueKeyColumns) {
            return false;
          }

          return true;
        }),
      );

      notification.success({
        message: intl.formatMessage({
          id: 'lists.toasts.delete_success',
        }),
      });

      if (id === Number(listId)) {
        navigate('/lists');
      }
    },

    onError: defaultApiErrorHandler,
  });

  return {
    createListMutation,
    updateListMutation,
    deleteListMutation,
  };
};
