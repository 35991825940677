import { ReactComponent as HistoryIcon } from 'assets/v2/history_12.svg';
import { IconShell } from 'components/ui/atomic-components';
import { type ReactNode, type PropsWithChildren, type ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';
import { FontXs, FontS, FontXxs } from 'styles/typography';

type Size = 'extraSmall' | 'small' | 'medium';
type Type = 'neutral' | 'primary' | 'failure' | 'alert' | 'success' | 'critical' | 'inverted-red';

const Wrapper = styled.div<{ size: Size; type: Type; bg: boolean; icon: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  border-radius: ${({ theme }) => theme.borderRadius.xxl};
  cursor: pointer;
  justify-content: center;

  --size-extra-small: 16px;
  --size-small: 20px;
  --size-medium: 24px;

  ${switchProp('size', {
    extraSmall: css`
      ${FontXxs}

      height: var(--size-extra-small);
      min-width: var(--size-extra-small);
      padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
    `,
    small: css`
      ${FontXs}

      height: var(--size-small);
      min-width: var(--size-small);
      padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[6]}`};
    `,
    medium: css`
      ${FontS}

      height: var(--size-medium);
      min-width: var(--size-medium);
      padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[8]}`};
    `,
  })}

  ${switchProp('type', {
    neutral: css`
      background-color: ${({ theme }) => theme.colors.bgMedium};
      color: ${({ theme }) => theme.colors.textBody2};
    `,
    primary: css`
      background-color: ${({ theme }) => theme.colors.bgPrimaryLight};
      color: ${({ theme }) => theme.colors.textPrimary};
    `,
    failure: css`
      background-color: ${({ theme }) => theme.colors.negative100};
      color: ${({ theme }) => theme.colors.textNegative};
    `,
    alert: css`
      background-color: ${({ theme }) => theme.colors.yellow100};
      color: ${({ theme }) => theme.colors.yellow700};
    `,

    success: css`
      background-color: ${({ theme }) => theme.colors.green100};
      color: ${({ theme }) => theme.colors.green700};
    `,

    critical: css`
      background-color: ${({ theme }) => theme.colors.orange100};
      color: ${({ theme }) => theme.colors.orange700};
    `,

    'inverted-red': css`
      background-color: ${({ theme }) => theme.colors.red500};
      color: ${({ theme }) => theme.colors.textNone};
    `,
  })}

  ${switchProp('bg', {
    false: css`
      background-color: unset;
    `,
  })}

  ${switchProp('icon', {
    true: css`
      font-weight: ${({ theme }) => theme.fontWeight.regular};
    `,
    false: css`
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    `,
  })}
`;

interface Props {
  size?: Size;
  type?: Type;
  bg?: boolean;
  onClick?: () => void;
  icon?: boolean;
  message?: ReactNode;
}

const getIconColor = (type: Type) => {
  switch (type) {
    case 'neutral':
      return 'textBody2';
    case 'primary':
      return 'textPrimary';
    case 'failure':
      return 'textNegative';
    case 'alert':
      return 'yellow700';
    case 'success':
      return 'green700';
    case 'critical':
      return 'orange700';
    case 'inverted-red':
      return 'textNone';
    default:
      return 'iconPrimary';
  }
};

export const Badge = ({
  children,
  icon = false,
  type = 'neutral',
  size = 'medium',
  bg = true,
  onClick,
}: PropsWithChildren<Props>): ReactElement => {
  return (
    <Wrapper bg={bg} icon={icon} size={size} type={type} onClick={onClick}>
      {icon && <IconShell color={getIconColor(type)} icon={HistoryIcon} size="sm" />}
      {children}
    </Wrapper>
  );
};
