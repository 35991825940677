import { UsersApi } from 'data/users';
import { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useUser } from 'utils/hooks';
import { VIEW_AS_USER } from './use-view-as-user';

export const useViewAsUserQueryParam = (): void => {
  const { isDtUser } = useUser();
  const [{ viewAsUser }] = useQueryParams({
    viewAsUser: StringParam,
  });

  useEffect(() => {
    if (viewAsUser && isDtUser) {
      UsersApi.setViewAsUser(viewAsUser).then(() => {
        localStorage.setItem(VIEW_AS_USER, viewAsUser);
        const url = new URL(window.location.href);

        url.searchParams.delete('viewAsUser');
        window.location.href = url.toString();
      });
    }
  }, [viewAsUser, isDtUser]);
};
