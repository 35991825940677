// eslint-disable-next-line filenames/match-regex
import type OktaAuth from '@okta/okta-auth-js';
import { type QueryClient } from '@tanstack/react-query';
import { signout, SIGN_OUT_URL } from 'data/users';
import { type LDClient } from 'launchdarkly-react-client-sdk';
import { type DetailedAxiosError } from 'utils/error-handler';

export const handle401Response = ({
  oktaAuth,
  error,
  queryClient,
  ldClient,
}: {
  error: DetailedAxiosError;
  oktaAuth: OktaAuth;
  queryClient: QueryClient;
  ldClient?: LDClient;
}): void => {
  const isSamlLoginWidget = ldClient?.variation('samlLoginWidget');

  if (isSamlLoginWidget) {
    window.location.replace('/login');

    return;
  }

  if (error?.response?.config.url === SIGN_OUT_URL) {
    return;
  }

  signout(oktaAuth);
};
