import { type ValueGetterFunc, type ValueGetterParams } from 'ag-grid-community';
import { MASKING_PLACEHOLDER } from 'data/constants';
import { type Metric } from 'data/metrics';
import { PIVOT_DIMENSION_METRIC_ROW_NAME, type Pivot } from 'data/page-template';
import { type FrameElement, FrameType } from 'data/reports/types';
import { getDataValue, getDimsInRowHierarchy } from 'data/reports/utils';
import { SCENARIO } from 'data/scenarios';
import { VERSION } from 'data/versions';
import { type Dictionary } from 'lodash';
import { getStatisticalValue } from 'utils/grid/table-view';
import { parseStatisticColumnKey } from '../../utils/statistic-column-key-utils';

export const valueGetter = ({
  column,
  columnDimensions,
  metricsMap,
  firstVersion,
  firstScenario,
  pivot,
}: {
  column: FrameElement;
  columnDimensions: Record<string, string>;
  metricsMap: Dictionary<Metric>;
  firstVersion: string;
  firstScenario?: string;
  pivot: Pivot;
}): ValueGetterFunc => {
  return ({ data }: ValueGetterParams) => {
    if (!data.hierarchyClan) {
      return null;
    }

    const { rowDimensions, dataLookupMap, isMasked } = data;

    if (column.isMasked || isMasked) {
      return MASKING_PLACEHOLDER;
    }

    const dimsInHierarchy: string[] =
      getDimsInRowHierarchy({
        hierarchyClanList: data?.hierarchyClan,
        rowDimensions: pivot.dimensions.rows,
      }) || [];

    const dataValue = getDataValue({
      dataLookupMap,
      dimensions: { ...rowDimensions, ...columnDimensions },
      rowsOrder: dimsInHierarchy || [],
      columnsOrder: pivot.dimensions.columns || [],
      firstVersion,
    });

    if (column.type !== FrameType.Statistic) {
      return dataValue;
    }

    if (!column.statistic) {
      return null;
    }

    const dimensions = { ...rowDimensions, ...columnDimensions };

    const metricName = dimensions[PIVOT_DIMENSION_METRIC_ROW_NAME];
    const metric = metricsMap[metricName];

    const { base: baselineVersion, current: currentVersion } = parseStatisticColumnKey(
      dimensions[VERSION],
    );

    const { base: baselineScenario, current: currentScenario } = parseStatisticColumnKey(
      dimensions[SCENARIO],
    );

    // case when both version and scenario statistics are present
    if (currentVersion && currentScenario) {
      const referenceDataValue = getDataValue({
        dataLookupMap,
        dimensions: { ...dimensions, [VERSION]: baselineVersion, [SCENARIO]: baselineScenario },
        rowsOrder: dimsInHierarchy || [],
        columnsOrder: pivot.dimensions.columns || [],
        firstVersion,
      });

      const currentDataValue = getDataValue({
        dataLookupMap,
        dimensions: { ...dimensions, [VERSION]: currentVersion, [SCENARIO]: currentScenario },
        rowsOrder: dimsInHierarchy || [],
        columnsOrder: pivot.dimensions.columns || [],
        firstVersion,
      });

      return getStatisticalValue({
        statistic: column.statistic,
        baselineColName: baselineVersion,
        comparedColName: currentVersion,
        baselineNumber: referenceDataValue,
        comparedNumber: currentDataValue,
        direction: metric?.dataFormat?.direction,
      });
    }

    // case when only version statistics are present
    if (currentVersion) {
      const referenceDataOverrideDimensions: Record<string, string> = {
        [VERSION]: baselineVersion,
      };

      // ENG-45760
      if (!dimensions[SCENARIO] && pivot.dimensions.columns?.includes(SCENARIO) && firstScenario) {
        referenceDataOverrideDimensions[SCENARIO] = firstScenario;
      }

      const referenceDataValue = getDataValue({
        dataLookupMap,
        dimensions: { ...dimensions, ...referenceDataOverrideDimensions },
        rowsOrder: dimsInHierarchy || [],
        columnsOrder: pivot.dimensions.columns || [],
        firstVersion,
      });

      const currentDataValue = getDataValue({
        dataLookupMap,
        dimensions: { ...dimensions, [VERSION]: currentVersion },
        rowsOrder: dimsInHierarchy || [],
        columnsOrder: pivot.dimensions.columns || [],
        firstVersion,
      });

      return getStatisticalValue({
        statistic: column.statistic,
        baselineColName: baselineVersion,
        comparedColName: currentVersion,
        baselineNumber: referenceDataValue,
        comparedNumber: currentDataValue,
        direction: metric?.dataFormat?.direction,
      });
    }

    // case when only scenario statistics are present
    if (currentScenario) {
      const referenceDataValue = getDataValue({
        dataLookupMap,
        dimensions: { ...dimensions, [SCENARIO]: baselineScenario },
        rowsOrder: dimsInHierarchy || [],
        columnsOrder: pivot.dimensions.columns || [],
        firstVersion,
      });

      const currentDataValue = getDataValue({
        dataLookupMap,
        dimensions: { ...dimensions, [SCENARIO]: currentScenario },
        rowsOrder: dimsInHierarchy || [],
        columnsOrder: pivot.dimensions.columns || [],
        firstVersion,
      });

      return getStatisticalValue({
        statistic: column.statistic,
        baselineColName: baselineScenario,
        comparedColName: currentScenario,
        baselineNumber: referenceDataValue,
        comparedNumber: currentDataValue,
        direction: metric?.dataFormat?.direction,
      });
    }

    return null;
  };
};
