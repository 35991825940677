import {
  type CellClassParams,
  type ColDef,
  type ColGroupDef,
  type ColumnGroupShowType,
} from 'ag-grid-community';
import { generateNumberFormatClass } from 'components/modules/modelling/modules/detail-v2/table/utils/generate-number-format-class';
import { GroupChildHeader } from 'components/ui/atomic-components/grid/group-child-header';
import { Analytics } from 'config/analytics';
import { type FormattedPeriod, Granularity, type ID } from 'data';
import { type BreakupMetric, type BreakupQueryDrawerParams } from 'data/big-query';
import { checkIfInPrintMode } from 'data/boards';
import { ChartType } from 'data/boards/charts/types';
import { type TableFormattingRuleStyle } from 'data/boards/charts/types/table';
import { getCellIdenitifierFromGridData } from 'data/conversations/utils';
import { type Currency } from 'data/currencies';
import { type Dimension, DimensionAllMarker, TimeDimensionName } from 'data/dimension';
import { type DataFormatType, type Metric } from 'data/metrics';
import { type ActualsTillDatePreset } from 'data/modelling/model';
import {
  type Pivot,
  PivotViewType,
  PIVOT_DIMENSION_METRIC_ROW_NAME,
  PivotDimensionsSummaryType,
} from 'data/page-template';
import { getFirstFilteredVersion } from 'data/page-template/pivots/utils';
import { type FrameElement, FrameType } from 'data/reports/types';
import { constructTimeDimKey, generateDataLookupKey } from 'data/reports/utils';
import { VERSION } from 'data/versions';
import { type Dictionary, isEmpty, keyBy } from 'lodash';
import { type Dispatch, type SetStateAction, type MutableRefObject } from 'react';
import { type IntlShape } from 'react-intl';
import { useReportsStore } from 'store/reports';
import { getChartMetricType } from 'utils/charts/get-chart-metric-type';
import { type DataFormattingType } from 'utils/data-formatter/types';
import { checkIfPeriodIsToExpand } from 'utils/date';
import {
  constructCurrentLevelDimObj,
  constructPivotDimensionsFromRowLabels,
} from 'utils/grid/table-view';
import { isPeriodInPastPresentOrFuture } from 'utils/periods';
import { getStatisticalColLabel } from '../../line-or-bar-chart/utils/stat/get-statistical-col-label';
import { generateColId } from '../../planTable/create-view-column-defs/utils/generate-col-id';
import { ColumnGroupHeader } from '../column-group-header';
import { constructUnderlyingDataDimsForTable } from '../utils/construct-underlying-data-dims';
import {
  constructUnderlyingDataParams,
  hideShowUnderlyingDataForCell,
} from '../utils/construct-underlying-data-params';
import { CommentCell } from './cell-renderers/comment-cell';
import {
  generateTableViewHeaderColumnOptions,
  getCellApplicableFilters,
  getCollapsedStateColumn,
  styleGetter,
  valueFormatter,
  valueGetter,
} from './utils';
import { getDecendantRowIndexHierarchyToShowHeader } from './utils/';

interface Props {
  columns: FrameElement[];
  pivot: Pivot;
  granularDimensions: Dimension[];
  intl: IntlShape;
  periodRanges: FormattedPeriod[];
  metricsMap: Dictionary<Metric>;
  tenantCurrency: Currency;
  chartCurrency?: Currency;
  styleMap: MutableRefObject<Record<string, TableFormattingRuleStyle>>;
  hideRowColumnMap?: Record<string, boolean>;
  actualsTillDate?: string;
  actualsTillDateOption?: ActualsTillDatePreset;
  chartDataFormat?: { [key: string]: DataFormatType };
  chartMetricTypeMap?: Record<string, DataFormattingType>;
  hideAggregatesFor: string[];
  defaultChartFilters?: Record<string, string[]>;
  boardDims: Record<string, string[]>;
  chartFixedFilters: Record<string, string[]>;
  chartFilterDimNames: string[];
  defaultStartDate?: string;
  defaultEndDate?: string;
  metricNamesSupportingBreakup?: Record<string, BreakupMetric>;
  incompletePeriodsShowForecast: boolean;
  isReadOnlyUser: boolean;
  tempDrilldownDisabled: boolean;
  showFutureActuals: boolean;
  columnWidthPreference: Record<string, number>;
  setBreakupQueryDrawerParams?: Dispatch<SetStateAction<BreakupQueryDrawerParams | undefined>>;
  setBreakupQueryDrawerVisible?: Dispatch<SetStateAction<boolean>>;
  repeatRowLabels: boolean;
  chartId: ID;
  isRenderedInAiChat?: boolean;
  isGroupOpenByDefault: (id: string) => boolean;
  columnsOrder?: string[];
  scenarioNames?: string[];
}

export const createViewColumnDefs = ({
  isGroupOpenByDefault,
  columns,
  pivot,
  granularDimensions,
  intl,
  periodRanges,
  metricsMap,
  tenantCurrency,
  chartCurrency,
  styleMap,
  hideRowColumnMap,
  actualsTillDate,
  actualsTillDateOption,
  chartDataFormat,
  chartMetricTypeMap,
  hideAggregatesFor,
  defaultChartFilters,
  boardDims,
  chartFixedFilters,
  chartFilterDimNames,
  defaultStartDate,
  defaultEndDate,
  metricNamesSupportingBreakup,
  incompletePeriodsShowForecast,
  isReadOnlyUser,
  tempDrilldownDisabled,
  showFutureActuals,
  columnWidthPreference,
  setBreakupQueryDrawerParams,
  setBreakupQueryDrawerVisible,
  repeatRowLabels,
  chartId,
  isRenderedInAiChat,
  columnsOrder,
  scenarioNames,
}: Props): { tableHeaders: ColDef[]; dataHeaders: (ColDef | ColGroupDef)[] } => {
  // oldChartBaselineVersion is actually baselineVersion in chart attributes -> tableChartConfig which is deprecated. If versionToBaselineMap is not available then use oldChartBaselineVersion
  const {
    versionToBaselineMap,
    baselineVersion: oldChartBaselineVersion,
    baselineScenario,
  } = pivot;
  const firstVersion = getFirstFilteredVersion(pivot);

  const periodRangesMap = keyBy(periodRanges, (p) => {
    if (p.type === Granularity.Yearly) {
      return constructTimeDimKey(`${p.startDate}`, 'Y');
    }
    if (p.type === Granularity.Weekly) {
      return constructTimeDimKey(`${p.startDate}`, 'W');
    }
    if (p.type !== Granularity.Monthly) {
      const periodType = p.key.split(' ')[0]; //Q1 '23 => Q1;

      return constructTimeDimKey(`${p.startDate}`, periodType);
    }

    return `${p.startDate}`;
  });

  const cellApplicableFilters = getCellApplicableFilters({
    boardDims,
    defaultChartFilters,
    chartFixedFilters,
    chartFilterDimNames,
  });

  const generateColDef = ({
    column,
    currLevelColumns,
    prevLevelsColumnDimensions,
    columnGroupShow = 'open',
    baselineVersion,
  }: {
    column: FrameElement;
    currLevelColumns: FrameElement[];
    prevLevelsColumnDimensions: Record<string, string>;
    columnGroupShow: ColumnGroupShowType;
    baselineVersion?: string | null;
  }): ColDef | ColGroupDef => {
    const columnDimensions = constructCurrentLevelDimObj(
      column,
      prevLevelsColumnDimensions,
      baselineVersion,
      baselineScenario,
    );

    const colId = generateColId(columnDimensions, columnGroupShow);

    const period = periodRangesMap?.[columnDimensions.t] || {};

    const isInPrintMode = checkIfInPrintMode();

    const isOpenByDefault = isGroupOpenByDefault(colId);

    const openByDefault =
      isOpenByDefault ||
      isInPrintMode ||
      (column.type === FrameType.Time &&
        checkIfPeriodIsToExpand({
          period,
          columnStructure: columns,
        }));
    const headerClass = column.children.length > 1 ? 'expandable-header' : '';

    const commonColDefs = {
      colId,
      field: column.key,
      groupId: columnDimensions[column.dimensionName],
      resizable: true,
      columnGroupShow,
      headerName: column.displayName,
      headerTooltip: getTooltipText(column, currLevelColumns, baselineVersion),
      minWidth: isInPrintMode ? 60 : 120,
      maxWidth: 1024,
      suppressSpanHeaderHeight: true,
      cellRenderer: CommentCell,
      cellClassRules: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'comment-popover-open-cell-highlight': (params: CellClassParams) => {
          const { openConversationCellIdentifier } = useReportsStore.getState();
          const gridCellIdentifier = getCellIdenitifierFromGridData(params.colDef, params.node);

          return openConversationCellIdentifier === gridCellIdentifier;
        },
      },
    };

    if (isEmpty(column.children)) {
      return {
        ...commonColDefs,
        width: !openByDefault ? columnWidthPreference[colId] || 120 : undefined, //setting width preference only hidden columns in column groups, visible columns width set by autoSizeStrategy
        headerClass: period.headerCellClass,
        headerComponent: GroupChildHeader,
        headerComponentParams: {
          columnFrameType: column.type,
          firstVersion,
          baselineVersion,
          baselineScenario,
          columnType: column.statistic || VERSION,
          colDims: columnDimensions,
          startDate: period.startDate,
          endDate: period.endDate,
          type: period.type,
          pageFilters: cellApplicableFilters,
          periodDisplayValue: period.value,
          periodType: period.type,
          periodKey: period.key,
          columnDimensionName: column.dimensionName,
          chartId,
          isMasked: column.isMasked,
        },
        cellStyle: styleGetter({
          columnDimensions,
          firstVersion,
          pivot,
          styleMap,
        }),
        valueGetter: valueGetter({
          column,
          columnDimensions,
          metricsMap,
          firstVersion,
          pivot,
          firstScenario: scenarioNames?.[0],
        }),
        valueFormatter: valueFormatter({
          periodRangesMap,
          column,
          columnDimensions,
          metricsMap,
          tenantCurrency,
          chartDataFormat,
          chartCurrency,
          chartMetricTypeMap,
        }),
        onCellDoubleClicked: (event) => {
          const { node, value, colDef } = event;

          const cellDims = {
            ...node?.data?.rowDimensions,
            ...columnDimensions,
          };

          const metricName = cellDims[PIVOT_DIMENSION_METRIC_ROW_NAME];
          const versionName = cellDims[VERSION];
          const timeDimensionValue = cellDims[TimeDimensionName];

          const skipShowingUnderlyingData = hideShowUnderlyingDataForCell({
            metricNamesSupportingBreakupRef: { current: metricNamesSupportingBreakup },
            metricName,
            version: versionName,
            columnDef: colDef,
            value,
            timeDimensionValue,
            disableForReadOnlyUser: isReadOnlyUser ? tempDrilldownDisabled : false,
          });

          if (skipShowingUnderlyingData) {
            return;
          }

          const underlyingDataDims = constructUnderlyingDataDimsForTable({
            cellDims,
            boardDims,
            chartDims: defaultChartFilters ?? {},
            chartLevelFixedFilters: chartFixedFilters,
            chartFilterDimNames,
          });

          const showUnderlyingDataParams = constructUnderlyingDataParams({
            actualsTillDate,
            actualsTillDateOption,
            cellDims,
            underlyingDataDims,
            defaultStartDate,
            defaultEndDate,
            periodRanges,
            metricsMap,
            showFutureActuals,
            value,
          });

          Analytics.track('Show underlying data', {
            category: 'Reports',
            meta: `chartType: ${ChartType.Table}; doubleclick`,
          });
          setBreakupQueryDrawerParams?.(showUnderlyingDataParams);
          setBreakupQueryDrawerVisible?.(true);
        },
        cellClass: ({ data }) => {
          const result = [period.cellClass || 'ag-right-aligned-cell'];

          const { rowDimensions } = data;
          const allDims = {
            ...rowDimensions,
            ...columnDimensions,
          };
          const metricName = allDims[PIVOT_DIMENSION_METRIC_ROW_NAME];
          const metricInfo = metricsMap[metricName];
          const dataFormat = chartDataFormat?.[metricName] || metricInfo?.dataFormat || {};
          const metricType = getChartMetricType(metricInfo?.type, chartMetricTypeMap?.[metricName]);

          const numberFormatClass = generateNumberFormatClass({
            dataFormat,
            metricType,
            currency:
              chartCurrency ||
              chartDataFormat?.[metricName]?.currency ||
              metricInfo?.dataFormat?.currency ||
              tenantCurrency,
          });

          if (numberFormatClass) {
            result.push(numberFormatClass);
          }

          return result;
        },
      };
    }

    return {
      ...commonColDefs,
      openByDefault,
      headerGroupComponent: ColumnGroupHeader,
      headerGroupComponentParams: {
        columnFrameType: column.type,
        period,
        columnDimensionName: column.dimensionName,
      },
      headerClass,
      children: createViewColumnDefsHelper({
        columns: column.children,
        periodRangesMap,
        prevLevelsColumnDimensions: columnDimensions,
        actualsTillDate,
        incompletePeriodsShowForecast,
      }),
    };
  };

  const createViewColumnDefsHelper = ({
    columns,
    periodRangesMap,
    prevLevelsColumnDimensions = {},
    actualsTillDate,
    incompletePeriodsShowForecast,
  }: {
    columns: FrameElement[];
    periodRangesMap: Dictionary<FormattedPeriod>;
    prevLevelsColumnDimensions?: Record<string, string>;
    actualsTillDate?: string;
    incompletePeriodsShowForecast: boolean;
  }): (ColDef | ColGroupDef)[] => {
    if (isEmpty(columns)) {
      return [];
    }

    const colDefs: (ColDef | ColGroupDef)[] = [];

    const { dimensionName } = columns[0];
    const hideAggregate = hideAggregatesFor.includes(dimensionName);

    const visibleColumns = [];

    for (const column of columns) {
      if (hideAggregate && column.key === DimensionAllMarker) {
        continue;
      }

      const baselineVersion = versionToBaselineMap?.[column.key] ?? oldChartBaselineVersion;

      const dimensions = constructCurrentLevelDimObj(
        column,
        prevLevelsColumnDimensions,
        baselineVersion,
        baselineScenario,
      );

      const key = generateDataLookupKey({
        dimensions,
        rowsOrder: [...(columnsOrder?.includes(VERSION) ? [] : [VERSION])],
        firstVersion: '',
        columnsOrder: columnsOrder || [],
      });

      if (hideRowColumnMap?.[key]) {
        continue;
      }

      colDefs.push(
        generateColDef({
          column,
          currLevelColumns: columns,
          prevLevelsColumnDimensions,
          columnGroupShow: 'open',
          baselineVersion,
        }),
      );

      visibleColumns.push(column);
    }

    const timeDimensionValue = prevLevelsColumnDimensions[TimeDimensionName];
    const period = timeDimensionValue ? periodRangesMap[timeDimensionValue] : null;
    const periodRangeState = period ? isPeriodInPastPresentOrFuture(period, actualsTillDate) : null;

    const isNonMonthlyWithActualTillDate = actualsTillDate
      ? period?.type !== Granularity.Monthly
      : true;

    const collapsedStateColumn = getCollapsedStateColumn(
      visibleColumns,
      periodRangeState,
      !hideAggregate,
      incompletePeriodsShowForecast
        ? isNonMonthlyWithActualTillDate //if actualsTillDate is set, skip for monthly
        : false,
    );

    if (!isEmpty(prevLevelsColumnDimensions) && !isEmpty(visibleColumns)) {
      const closedColDef = generateColDef({
        column: collapsedStateColumn,
        currLevelColumns: visibleColumns,
        prevLevelsColumnDimensions,
        columnGroupShow: 'closed',
      });

      colDefs.push(closedColDef);
    }

    return colDefs;
  };

  const tableViewHeaderColDefs: ColDef[] = [];

  if (pivot?.viewType === PivotViewType.Table && granularDimensions && intl) {
    const pivotHeaderDimensions = constructPivotDimensionsFromRowLabels(
      pivot.dimensions?.rows || [],
      intl,
    );

    const decendantRowIndexHierarchyToShowHeader = getDecendantRowIndexHierarchyToShowHeader({
      pivotHeaderDimensions,
      hideAggregatesFor,
      showSummaryOn: pivot.showTotals,
    });

    pivotHeaderDimensions.forEach((dimension, idx) => {
      const dimensionSummary = hideAggregatesFor.includes(dimension.name)
        ? PivotDimensionsSummaryType.None
        : pivot.showTotals;

      tableViewHeaderColDefs.push(
        generateTableViewHeaderColumnOptions({
          dimension,
          columnIndex: idx,
          pivot,
          styleMap,
          repeatRowLabels,
          dimensionSummary,
          decendantRowIndexHierarchyToShowHeader: decendantRowIndexHierarchyToShowHeader(idx),
          isRenderedInAiChat,
        }),
      );
    });
  }

  return {
    tableHeaders: tableViewHeaderColDefs,
    dataHeaders: createViewColumnDefsHelper({
      columns,
      periodRangesMap,
      actualsTillDate,
      incompletePeriodsShowForecast,
    }),
  };
};

const getTooltipText = (
  column: FrameElement,
  allColumns: FrameElement[],
  baselineVersion?: string | null,
) => {
  if (column.dimensionName === VERSION && column.type === FrameType.Statistic) {
    const baselineVersionDisplayName = allColumns.find(
      (col) => col.key === baselineVersion,
    )?.displayName;
    const comparedVersionDisplayName = allColumns.find(
      (col) => col.key === column.key,
    )?.displayName;

    if (baselineVersionDisplayName && comparedVersionDisplayName) {
      return getStatisticalColLabel(
        baselineVersionDisplayName,
        comparedVersionDisplayName,
        column.statistic,
      );
    }
  }

  return column.displayName;
};
