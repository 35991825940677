import styled from 'styled-components';
import { SingleLineEllipsis } from 'styles/typography';

const IconOptions = styled.div`
  display: none;
  position: relative;
  top: 1px;
  gap: ${({ theme }) => theme.spacing[4]};

  :has(.ant-dropdown-open, .ant-popover-open, .underlying-data-column-filter) {
    display: flex;
  }
`;

const IconTextWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};
  overflow: hidden;

  > svg {
    margin-top: ${({ theme }) => theme.spacing[4]};
  }
`;

export default {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};
    position: relative;
    overflow: hidden;

    :hover {
      ${IconOptions} {
        display: flex;
      }

      ${IconTextWrapper} {
        max-width: calc(100% - 56px);
      }
    }
  `,

  IconTextWrapper,

  ColTitle: styled.span`
    ${SingleLineEllipsis}
  `,

  IconOptions,
};
