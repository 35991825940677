import { defaultTheme } from 'styles/theme';

export const RECENT_STYLES_MAX_COUNT = 5;
export const RECENT_STYLES_STORAGE_KEY = 'recent-styles-selection';
export const PREVIEW_CHAR = 'A';

const { colors } = defaultTheme;

export const standardStyles = [
  [
    { fontColor: colors.primary600, backgroundColor: colors.gray00 },
    { fontColor: colors.magenta600, backgroundColor: colors.gray00 },
    { fontColor: colors.blue600, backgroundColor: colors.gray00 },
    { fontColor: colors.aqua600, backgroundColor: colors.gray00 },
    { fontColor: colors.green600, backgroundColor: colors.gray00 },
    { fontColor: colors.pink600, backgroundColor: colors.gray00 },
    { fontColor: colors.yellow600, backgroundColor: colors.gray00 },
    { fontColor: colors.orange600, backgroundColor: colors.gray00 },
    { fontColor: colors.red600, backgroundColor: colors.gray00 },
    { fontColor: colors.textTitle1, backgroundColor: colors.gray00 },
  ],
  [
    { fontColor: colors.primary600, backgroundColor: colors.primary50 },
    { fontColor: colors.magenta600, backgroundColor: colors.magenta100 },
    { fontColor: colors.blue500, backgroundColor: colors.blue100 },
    { fontColor: colors.aqua600, backgroundColor: colors.aqua100 },
    { fontColor: colors.green600, backgroundColor: colors.green100 },
    { fontColor: colors.pink600, backgroundColor: colors.pink100 },
    { fontColor: colors.yellow600, backgroundColor: colors.yellow100 },
    { fontColor: colors.orange600, backgroundColor: colors.orange100 },
    { fontColor: colors.red600, backgroundColor: colors.red100 },
    { fontColor: colors.textTitle1, backgroundColor: colors.gray50 },
  ],
  [
    { fontColor: colors.textTitle1, backgroundColor: colors.primary300 },
    { fontColor: colors.textTitle1, backgroundColor: colors.magenta300 },
    { fontColor: colors.textTitle1, backgroundColor: colors.blue300 },
    { fontColor: colors.textTitle1, backgroundColor: colors.aqua300 },
    { fontColor: colors.textTitle1, backgroundColor: colors.green300 },
    { fontColor: colors.textTitle1, backgroundColor: colors.pink300 },
    { fontColor: colors.textTitle1, backgroundColor: colors.yellow300 },
    { fontColor: colors.textTitle1, backgroundColor: colors.orange300 },
    { fontColor: colors.textTitle1, backgroundColor: colors.red300 },
    { fontColor: colors.textTitle1, backgroundColor: colors.gray300 },
  ],
  [
    { fontColor: colors.textNone, backgroundColor: colors.primary600 },
    { fontColor: colors.textNone, backgroundColor: colors.magenta600 },
    { fontColor: colors.textNone, backgroundColor: colors.blue600 },
    { fontColor: colors.textNone, backgroundColor: colors.aqua600 },
    { fontColor: colors.textNone, backgroundColor: colors.green600 },
    { fontColor: colors.textNone, backgroundColor: colors.pink600 },
    { fontColor: colors.textNone, backgroundColor: colors.yellow600 },
    { fontColor: colors.textNone, backgroundColor: colors.orange600 },
    { fontColor: colors.textNone, backgroundColor: colors.red600 },
    { fontColor: colors.textNone, backgroundColor: colors.gray600 },
  ],
];

export const COLOR_SCALE_BUCKETS_COUNT = 5;

export const defaultFormattingRule = {
  style: { backgroundColor: defaultTheme.colors.blue100, fontColor: defaultTheme.colors.blue500 },
};
