/* eslint-disable filenames/match-regex */
import { type ThemeConfig } from 'antd/es/config-provider/context';
import { defaultTheme } from './theme';

export const antdTheme: ThemeConfig = {
  token: {
    colorPrimary: defaultTheme.colors.primary500,
    colorTextBase: defaultTheme.colors.textBody1,
    borderRadius: parseInt(defaultTheme.borderRadius.m),

    fontSize: 13,
    fontSizeSM: 12,
    fontSizeLG: 15,
    lineHeightSM: 1.6,
    lineHeight: 1.4,
    lineHeightLG: 1.088,
    fontFamily: defaultTheme.font.text,
  },

  components: {
    Button: {
      colorBgContainerDisabled: defaultTheme.colors.buttonOutlineFillDisabled,
      colorBgTextActive: defaultTheme.colors.gray00,
      colorBgTextHover: defaultTheme.colors.buttonFillHoverGhost,
      colorBorder: defaultTheme.colors.gray300,
      colorLink: defaultTheme.colors.primary500,
      colorLinkHover: defaultTheme.colors.textBody2,
      colorLinkActive: defaultTheme.colors.primary700,
      colorPrimaryActive: defaultTheme.colors.buttonFillSelectedPrimary,
      colorPrimaryBorder: defaultTheme.colors.primary100,
      colorPrimaryHover: defaultTheme.colors.buttonFillHoverPrimary,
      colorText: defaultTheme.colors.textTitle2,
      colorTextDisabled: defaultTheme.colors.textDisabled,
      colorError: defaultTheme.colors.textNegative,
      controlHeight: 36,
      controlHeightSM: 28,
      marginXS: 4,
      controlOutline: 'none',
      paddingXS: 8,
      paddingContentHorizontal: 12,
      borderRadius: parseInt(defaultTheme.borderRadius.s),
      borderRadiusSM: parseInt(defaultTheme.borderRadius.xs),
      borderRadiusLG: parseInt(defaultTheme.borderRadius.s),
    },
    Drawer: {
      colorSplit: defaultTheme.colors.gray300,
    },
    Input: {
      colorBorder: defaultTheme.colors.outlineDefault,
      colorPrimaryHover: defaultTheme.colors.outlineColored,
      colorText: defaultTheme.colors.textBody1,
      colorTextPlaceholder: defaultTheme.colors.textPlaceholder,
    },
    InputNumber: {
      colorBorder: defaultTheme.colors.outlineDefault,
      colorPrimaryHover: defaultTheme.colors.outlineColored,
      colorText: defaultTheme.colors.textBody1,
      colorTextPlaceholder: defaultTheme.colors.textPlaceholder,
    },
    Form: {
      colorTextHeading: defaultTheme.colors.gray600,
    },
    Notification: {
      colorText: defaultTheme.colors.textBody2,
      colorTextHeading: defaultTheme.colors.textTitle2,
      fontFamily: defaultTheme.font.text,
    },
    Tree: {
      controlHeightSM: 32,
      colorText: defaultTheme.colors.textTitle1,
      fontSize: 13,
      paddingXS: parseInt(defaultTheme.spacing[6]),
      marginXXS: 0,
      borderRadius: 0,
      borderRadiusSM: 2,
      controlInteractiveSize: 12,
    },
    Menu: {
      controlHeightLG: 32,
      paddingXL: 16,
      fontSize: 13,
      lineHeight: 1.235,
      borderRadiusLG: parseInt(defaultTheme.borderRadius.xs),
      itemBorderRadius: parseInt(defaultTheme.borderRadius.xs),
      subMenuItemBorderRadius: parseInt(defaultTheme.borderRadius.xs),
      boxShadowSecondary: defaultTheme.shadow.softMicro,
      controlItemBgActive: defaultTheme.colors.bgMedium,
      itemSelectedBg: defaultTheme.colors.bgMedium,
      itemActiveBg: defaultTheme.colors.bgMedium,
      subMenuItemBg: defaultTheme.colors.gray00,
      itemSelectedColor: defaultTheme.colors.textTitle1,
      itemHoverBg: defaultTheme.colors.bgMedium,
      itemColor: defaultTheme.colors.textTitle1,
      itemHoverColor: defaultTheme.colors.textTitle1,
    },
    Modal: {
      motionDurationMid: '0.2s',
      motionDurationSlow: '0.16s',
    },
    DatePicker: {
      controlHeight: 36,
      controlHeightSM: 28,
      lineHeight: 1.235,
      fontWeightStrong: defaultTheme.fontWeight.medium,
      sizePopupArrow: 0,
      colorError: defaultTheme.colors.textNegative,
      colorIcon: defaultTheme.colors.iconDefault,
      colorIconHover: defaultTheme.colors.iconDefault,
      colorText: defaultTheme.colors.textTitle2,
      colorTextDisabled: defaultTheme.colors.textDisabled,
      colorBorder: defaultTheme.colors.gray200,
      colorBgContainerDisabled: defaultTheme.colors.gray200,
      cellBgDisabled: defaultTheme.colors.gray00,
      boxShadowSecondary: defaultTheme.shadow.hardSmall,
      cellWidth: 28,
      withoutTimeCellHeight: 24,
      padding: parseInt(defaultTheme.spacing[4]),
      paddingXS: parseInt(defaultTheme.spacing[4]),
      cellHoverBg: defaultTheme.colors.bgLight,
      cellHoverWithRangeBg: defaultTheme.colors.primary100,
      borderRadiusSM: parseInt(defaultTheme.borderRadius.xs),
    },
    Popover: {
      zIndexPopup: defaultTheme.zIndex[1000],
    },

    Steps: {
      navArrowColor: 'transparent',
      customIconFontSize: 13,
      customIconSize: 16,
      iconSize: 20,
      iconSizeSM: 16,
      colorText: defaultTheme.colors.textBody1,
      marginXS: parseInt(defaultTheme.spacing[4]),
      fontWeightStrong: 500,
    },

    Carousel: {
      dotHeight: 6,
      dotWidth: 10,
    },
  },
};
