import { type ICellRendererParams } from 'ag-grid-community';
import { GridCommentCell } from 'components/ui/collaboration/grid/grid-comment-cell';
import { useGridCellConversations } from 'components/ui/collaboration/grid/hooks';
import { type ReactElement } from 'react';
import { useReportsStore } from 'store/reports';
import styled from 'styled-components';

const Cell = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const CommentCell = (props: ICellRendererParams): ReactElement => {
  const openConversationCellIdentifier = useReportsStore((s) => s.openConversationCellIdentifier);
  const setOpenConversationCellIdentifier = useReportsStore(
    (s) => s.setOpenConversationCellIdentifier,
  );

  const { hasComments, cellIdentifier } = useGridCellConversations(props.colDef || {}, props.node);

  const refreshCell = () => {
    props.api?.refreshCells({ rowNodes: [props.node], columns: [props.colDef?.colId as string] });
  };

  const handleMarkerClick = () => {
    setOpenConversationCellIdentifier(openConversationCellIdentifier ? undefined : cellIdentifier);
    refreshCell();
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      return;
    }

    const isAddNewMemberModalOpen = !!document.querySelector('.add-new-members-confirmation-modal');
    const isDeleteConfirmationModalOpen = !!document.querySelector(
      '.comment-deletion-confirmation-modal',
    );

    if (isAddNewMemberModalOpen || isDeleteConfirmationModalOpen) {
      return;
    }

    setOpenConversationCellIdentifier(undefined);
    refreshCell();
  };

  if (!hasComments && openConversationCellIdentifier !== cellIdentifier) {
    return <Cell>{props.valueFormatted}</Cell>;
  }

  return (
    <GridCommentCell
      {...props}
      cellIdentifier={cellIdentifier}
      isPopoverOpen={cellIdentifier === openConversationCellIdentifier}
      onClickMarker={handleMarkerClick}
      onOpenChange={handleOpenChange}
    >
      <Cell>{props.valueFormatted}</Cell>
    </GridCommentCell>
  );
};
