import { IconShell } from 'components/ui/atomic-components';
import styled from 'styled-components';
import { FontXs } from 'styles/typography';

export const styles = {
  Container: styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme: { spacing } }) => spacing[16]};
    justify-content: space-between;
    width: 100%;
  `,

  Label: styled.div`
    ${FontXs}

    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.textBody1};
    margin-bottom: ${({ theme }) => theme.spacing[0]};
  `,

  UnreadBadge: styled.div<{ show: boolean }>`
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.primary500};
    border-radius: ${({ theme }) => theme.borderRadius.full};
    opacity: ${({ show }) => (show ? 1 : 0)};
  `,

  NotificationTypeIcon: styled.div`
    position: absolute;
    top: 17px;
    left: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.borderRadius.full};
    padding: ${({ theme }) => theme.spacing[4]};
  `,

  Icon: styled(IconShell)`
    width: 14px;
    height: 14px;
  `,

  Wrapper: styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing[16]};
    padding-left: ${({ theme }) => theme.spacing[0]};
    gap: ${({ theme }) => theme.spacing[12]};
    cursor: pointer;

    .notification-type-icon {
      background: ${({ theme }) => theme.colors.gray00};
    }

    :hover {
      background: ${({ theme }) => theme.colors.bgLight};

      .notification-type-icon {
        background: ${({ theme }) => theme.colors.bgLight};
      }
    }
  `,

  SubWrapper: styled.div`
    display: flex;
  `,

  Status: styled.div`
    padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[6]} 0 ${theme.spacing[8]}`};
  `,
};

export const WIDTH = '360px';
export const HEIGHT_TITLE_CONTENT = '328px';
export const HEIGHT_WITHOUT_TITLE = '620px';
