import { type Rule } from 'antd/es/form';
import { Button, Form, Input } from 'components/ui/atomic-components';
import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { type IntegrationInputFlowCompV2 } from '../../../types';
import { useConnectInputFlow } from '../hooks/use-connect-input-flow';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacing[8]};
  gap: ${({ theme }) => theme.spacing[16]};
  background: ${({ theme }) => theme.colors.gray100};
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

interface Props {
  flow: IntegrationInputFlowCompV2;
  onConnectionSuccess: () => void;
  item: IntegrationModalContent;
}

export const GenericConnectForm = ({ flow, item, onConnectionSuccess }: Props): ReactElement => {
  const [form] = Form.useForm();
  const { mutate, isPending } = useConnectInputFlow(item, onConnectionSuccess);

  const onFinish = async () => {
    const data = await form.validateFields();

    flow.input.forEach((input) => {
      const value = data[input.name];

      if (input.outputFormatter && value) {
        data[input.name] = input.outputFormatter(value);
      }
    });

    const processedData = Object.entries(data).map(([name, value]) => ({
      name,
      value,
    }));

    // Todo: Improve typing
    // @ts-expect-error - This is the new format for generic input flows
    mutate({ inputFields: processedData });
  };

  return (
    <Wrapper>
      <span>{flow.inputTitle}</span>

      <StyledForm form={form} onFinish={onFinish}>
        {flow.input.map((input) => {
          const rules: Rule[] = [{ required: true, message: `Please enter ${input.label}` }];

          if (input.pattern) {
            rules.push({
              pattern: new RegExp(input.pattern),
              message: `Please enter a valid ${input.label}`,
            });
          }

          return (
            <Form.Item key={input.name} label={input.label} name={input.name} rules={rules}>
              <Input placeholder={input.placeholder} type={input.type} />
            </Form.Item>
          );
        })}

        <Form.Item>
          <Button $mt={12} block htmlType="submit" loading={isPending} type="primary">
            <FormattedMessage id="submit" />
          </Button>
        </Form.Item>
      </StyledForm>
    </Wrapper>
  );
};
