import { type Notification } from 'data/notifications/types';
import dayjs from 'dayjs';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import styled from 'styled-components';
import { FontS } from 'styles/typography';

const mentionRegex = /<mention id="[^"]*">(.+?)<\/mention>/g;
const MentionSpan =
  '<span style="background: #EFF9FE; padding: 2px 1px; border-radius: 2px;">$1</span> ';

const Subject = styled.div`
  ${FontS}

  color: ${({ theme }) => theme.colors.textTitle1};
`;

const Body = styled.div`
  color: ${({ theme }) => theme.colors.textLabel};
`;
const RelativeTime = styled.div``;

export const b = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const NotificationContent = ({ notification }: { notification: Notification }) => {
  return (
    <div>
      <Subject>
        <ReactMarkdown components={{ b }} rehypePlugins={[rehypeRaw]}>
          {notification.attributes.subject}
        </ReactMarkdown>
      </Subject>
      <Body>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {notification.attributes.body?.replace(mentionRegex, MentionSpan)}
        </ReactMarkdown>
      </Body>

      <RelativeTime>{dayjs(notification.createdAt).fromNow()}</RelativeTime>
    </div>
  );
};
