import { type NotificationType } from 'data/notifications/types';
import { create } from 'zustand';

type NotificationStore = {
  shouldFetchUnread: boolean;
  fetchNotificationType: NotificationType;
  showNotificationsPopover: boolean;
  notificationsCount: number | undefined;

  actions: {
    setShouldFetchUnread: (shouldFetchUnread: boolean) => void;
    setShowNotificationsPopover: (showNotificationsPopover: boolean) => void;
    setFetchNotificationType: (fetchNotificationType: NotificationType | undefined) => void;
    setNotificationsCount: (notificationsCount: number | undefined) => void;
  };
};

const useNotificationStore = create<NotificationStore>()((set) => ({
  shouldFetchUnread: false,
  fetchNotificationType: undefined,
  showNotificationsPopover: false,
  notificationsCount: undefined,

  actions: {
    setShouldFetchUnread: (shouldFetchUnread: boolean) => {
      set({ shouldFetchUnread });
    },
    setFetchNotificationType: (fetchNotificationType: NotificationType | undefined) => {
      set({ fetchNotificationType });
    },
    setShowNotificationsPopover: (showNotificationsPopover: boolean) => {
      set({ showNotificationsPopover });
    },
    setNotificationsCount: (notificationsCount: number | undefined) => {
      set({ notificationsCount });
    },
  },
}));

export const useShouldFetchUnread = () => useNotificationStore((state) => state.shouldFetchUnread);

export const useFetchNotificationType = () =>
  useNotificationStore((state) => state.fetchNotificationType);

export const useShowNotificationsPopover = () =>
  useNotificationStore((state) => state.showNotificationsPopover);

export const useNotificationsCount = () =>
  useNotificationStore((state) => state.notificationsCount);

export const useNotificationActions = () => useNotificationStore((state) => state.actions);
