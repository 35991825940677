import Bugsnag from '@bugsnag/js';
import { Spinner } from 'components/ui/atomic-components/spinner';
import { useBootstrap } from 'data/bootstrap';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { type FC, type ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUser } from 'utils/hooks/use-user';
import zipy from 'zipyai';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Loader = () => (
  <Wrapper>
    <Spinner />
  </Wrapper>
);

const switcher = (
  hasClientIdentified: boolean,
  isBootstrapDataFetched: boolean,
  children: ReactNode,
  status: 'success' | 'error' | 'pending',
) => {
  switch (status) {
    case 'success':
      return hasClientIdentified && isBootstrapDataFetched ? <>{children}</> : <Loader />;
    case 'error':
      zipy.logException(new Error('Error fetching user'));

      return <></>;
    case 'pending':
    default:
      return <Loader />;
  }
};

export const Halo: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { status, user } = useUser();
  const client = useLDClient();
  const [hasClientIdentified, setHasClientIdentified] = useState(false);

  const { isFetched: isBootstrapDataFetched } = useBootstrap();

  useEffect(() => {
    if (status === 'success') {
      client?.identify(
        {
          kind: 'multi',
          user: {
            key: user.username,
            kind: 'user',
            email: user.username,
            tenant: user.tenant,
            domain: window.location.origin,
          },
          subdomain: {
            key: window.location.hostname,
            kind: 'subdomain',
          },
        },
        user.username,
        () => setHasClientIdentified(true),
      );

      Bugsnag.setUser(user.username, user.username, `${user.firstName} ${user.lastName || ''}`);
      Bugsnag.addMetadata('tenant', {
        id: user.tenant,
        domain: window.location.hostname,
      });
      Bugsnag.setContext(`tenantId:${user.tenant}`);
      Bugsnag.setContext(`tenantName:${window.location.hostname}`);
    }
  }, [client, status, user]);

  return switcher(hasClientIdentified, isBootstrapDataFetched, children, status);
};
