import { useForm } from 'antd/es/form/Form';
import { Avatar, Form } from 'components/ui/atomic-components';
import { ConversationAccessType } from 'data/conversations';
import { type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useUser } from 'utils/hooks';
import { MentionsEditor } from '../../mentions-editor';
import { getAllMentions } from '../utils';
import { FooterContent } from './footer-content';
import { type FormType } from './types';

const Wrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing[12]};
  gap: ${({ theme }) => theme.spacing[8]};
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  align-self: flex-start;
`;

const EditorWrapper = styled.div`
  flex: 1;
`;

interface Props {
  currentUserName: string;
  hideAccessSelector?: boolean;
  onAddComment?: (params: {
    comment: string;
    members: string[];
    access: ConversationAccessType;
  }) => void;
}

export const AddComment = ({
  currentUserName,
  hideAccessSelector,
  onAddComment,
}: Props): ReactElement => {
  const intl = useIntl();
  const {
    user: { username: currentUser },
  } = useUser();

  const [form] = useForm<FormType>();

  const onPost = async () => {
    const { message, access } = await form.validateFields();

    const members = access === ConversationAccessType.Private ? getAllMentions(message) : [];

    if (access === ConversationAccessType.Private && !members.includes(currentUser)) {
      members.push(currentUser);
    }

    form.resetFields();

    onAddComment?.({ comment: message, members, access });
  };

  return (
    <Form form={form} initialValues={{ message: '', access: ConversationAccessType.Public }}>
      <Wrapper>
        <StyledAvatar name={currentUserName} size="medium" />
        <EditorWrapper>
          <Form.Item name="message" noStyle>
            <MentionsEditor
              footerContent={
                <FooterContent
                  form={form}
                  hideAccessSelector={hideAccessSelector}
                  onPost={onPost}
                />
              }
              placeholder={intl.formatMessage({ id: 'conversations.leave_a_comment' })}
              onChange={(e) => form.setFieldValue('message', e.target.value)}
            />
          </Form.Item>
        </EditorWrapper>
      </Wrapper>
    </Form>
  );
};
