import { type ID, type Period } from 'data';
import { type Folder } from 'data/folder';
import { type DataFormattingType } from 'utils/data-formatter/types';
import { type ModelDimension } from '../dimension';
import { type Module } from '../modules';

export interface Model {
  planId: number;
  currency: string;
  description: string;
  duration: string;
  endDate: string;
  name: string;
  period: Period;
  startDate: string;
  modules: Module[];
  dimensions: ModelDimension[];
  folders: Folder[];
}

export interface GroupedMetrics extends CheckboxGroupedListItemBase {
  children: {
    name: string;
    displayName: string;
    type: DataFormattingType;
  }[];
  displayName: string;
}

export interface CheckboxGroupedListItemBase {
  name: string;
  displayName: string;
  children: CheckboxGroupedChildren[];
}

export interface CheckboxGroupedChildren {
  name: string;
  displayName: string;
}

export interface ModuleVisualiserData {
  moduleDisplayName: string;
  moduleMetrics: {
    calculated: boolean;
    connectedMetrics: string[];
    metricDisplayName: string;
    metricName: string;
  }[];
  moduleName: string;
}

export enum ModelDependents {
  Dimension = 'DIMENSION',
  DimensionValue = 'DIMENSION_VALUE',
  DimensionProperty = 'DIMENSION_PROPERTY',
  Metric = 'METRIC',
  Module = 'MODULE',
}

export interface ModelDependentRequestData {
  name: string;
  value?: string;
  property?: string;
  type: ModelDependents;
}

export interface ModelDependentResponseData {
  name: string;
  displayName: string;
  type: DataFormattingType;
  moduleName: string;
  moduleDisplayName: string;
}

export interface ModelUniqueKey {
  isCompositeKey: boolean;
  fqName: string;
  keyColumns: string[];
  tableName: string;
  type: 'LIST' | 'DIMENSION_GROUP';
  id: ID;
}

export interface ModelUniqueKeysResponse {
  keys: ModelUniqueKey[];
}

export enum ActualsTillDatePreset {
  Default = 'DEFAULT',
  CustomRange = 'CUSTOM_RANGE',
  CurrentMonth = 'CURRENT_MONTH',
  LastMonth = 'LAST_MONTH',
}

export interface DefaultActualsTill {
  actualsTillDateForModels: string | null;
  actualsTillDateForReports: string | null;
}
