import localforage from 'localforage';

export const loadLocalSummarizeBy = async (
  id?: number,
): Promise<Record<string, string> | undefined> => {
  if (!id) {
    return;
  }
  const data =
    ((await localforage.getItem('summarizeBy')) as Record<number, Record<string, string>>) || {};

  return data?.[id];
};

export const saveLocalSummarizeBy = async ({
  id,
  summarizeObj,
}: {
  id?: number;
  summarizeObj: Record<string, string>;
}): Promise<void> => {
  if (!id) {
    return;
  }
  const data =
    ((await localforage.getItem('summarizeBy')) as Record<number, Record<string, string>>) || {};

  data[id] = summarizeObj;
  await localforage.setItem('summarizeBy', data);

  return;
};

export const deleteLocalSummarizeBy = async ({ id }: { id?: number }): Promise<void> => {
  if (!id) {
    return;
  }
  const data =
    ((await localforage.getItem('summarizeBy')) as Record<number, Record<string, string>>) || {};

  delete data[id];
  await localforage.setItem('summarizeBy', data);

  return;
};
