import { FormattedMessage } from 'react-intl';
import { ChartAggregationType, ChartType } from './charts/types';
import { ChartColors, GaugeChartColors } from './colors';

export const DISPLAY_LABEL_LIMIT = 12;

export const ChartAggregationTypeMapping = {
  [ChartAggregationType.Cumulative]: 'cumulative',
  [ChartAggregationType.Linear]: 'linear',
};

export type ChartTypes = 'column' | 'line' | 'gauge' | 'scorecard' | 'table';

export const ChartTypeMapping: {
  [key: string]: ChartTypes;
} = {
  [ChartType.Bar]: 'column',
  [ChartType.Line]: 'line',
  [ChartType.Gauge]: 'gauge',
  [ChartType.Scorecard]: 'scorecard',
  [ChartType.Table]: 'table',
};

export const ChartColorsList: {
  plan: string;
  actual: string;
}[] = Object.keys(ChartColors).map((color) => ({
  plan: ChartColors[color][0],
  actual: ChartColors[color][1],
}));

export const GaugeChartColorsList: {
  plan: string;
  actual: string;
}[] = Object.keys(GaugeChartColors).map((color) => ({
  plan: GaugeChartColors[color][0],
  actual: GaugeChartColors[color][1],
}));

export const LineBarChartColors = Object.keys(ChartColors).reduce(
  (acc: string[], color) => [...acc, ChartColors[color][1], ChartColors[color][0]],
  [],
);

export const chartTypeOptions = [
  {
    label: <FormattedMessage id="charts.type.bar" />,
    value: ChartType.Bar,
    description: <FormattedMessage id="charts.type.bar.description" />,
  },
  {
    label: <FormattedMessage id="charts.type.line" />,
    value: ChartType.Line,
    description: <FormattedMessage id="charts.type.line.description" />,
  },
  {
    label: <FormattedMessage id="charts.type.gauge" />,
    value: ChartType.Gauge,
    description: <FormattedMessage id="charts.type.gauge.description" />,
  },
  {
    label: <FormattedMessage id="charts.type.scorecard" />,
    value: ChartType.Scorecard,
    description: <FormattedMessage id="charts.type.scorecard.description" />,
  },
  {
    label: <FormattedMessage id="charts.type.table" />,
    value: ChartType.Table,
    description: <FormattedMessage id="charts.type.table.description" />,
  },
];

export enum PercentComparisionPeriods {
  WowWowPercent = 'WOW_WOW_%',
  MomMomPercent = 'MOM_MOM_%',
  QoqMomPercent = 'QOQ_MOM_%',
  QoqQoqPercent = 'QOQ_QOQ_%',
  YoyMomPercent = 'YOY_MOM_%',
  YoyQoqPercent = 'YOY_QOQ_%',
  YoyYoyPercent = 'YOY_YOY_%',
}

export enum ComparisionPeriods {
  WowWow = 'WOW_WOW',
  MomMom = 'MOM_MOM',
  QoqMom = 'QOQ_MOM',
  QoqQoq = 'QOQ_QOQ',
  YoyMom = 'YOY_MOM',
  YoyQoq = 'YOY_QOQ',
  YoyYoy = 'YOY_YOY',
}
