import { type Currency } from 'data/currencies';

interface Props {
  chartCurrency?: Currency;
  chartDataFormatCurrency?: Currency;
  metricCurrency?: Currency;
  tenantCurrency: Currency;
}

export const getChartMetricCurrency = ({
  chartCurrency,
  chartDataFormatCurrency,
  metricCurrency,
  tenantCurrency,
}: Props): Currency => {
  return chartCurrency || chartDataFormatCurrency || metricCurrency || tenantCurrency;
};
