import { type ReactElement, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { isDetailedAxiosError } from 'utils/error-handler';
import { getAssetUrl } from 'utils/get-asset-url';
import { Empty } from '../atomic-components/empty';

interface Props {
  error?: unknown;
  title?: JSX.Element;
}
const Description = styled.div`
  color: ${({ theme }) => theme.colors.textBody2};
`;

const Title = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const DefaultErrorComp = ({ error, title }: Props): ReactElement => {
  let message: ReactNode = <FormattedMessage id="error.unexpected" />;
  let titleMessage = <FormattedMessage id="something_went_wrong" />;
  let image = <img alt={''} src={getAssetUrl('no-results-found.png')} />;

  if (isDetailedAxiosError(error)) {
    const errorMessage = error?.response?.data?.message;

    if (error?.response?.status === 403) {
      titleMessage = <FormattedMessage id="403.access_denied" />;
      message = errorMessage || <FormattedMessage id="403.sub_title" />;
      image = <img alt={''} src={getAssetUrl('no_access.webp')} width={70} />;
    } else if (errorMessage) {
      message = errorMessage;
    } else if (error.response?.status === 404) {
      message = <FormattedMessage id="error.404" />;
    }
  }

  return (
    <Empty
      description={<Description>{message}</Description>}
      image={image}
      title={<Title>{title ?? titleMessage}</Title>}
    />
  );
};
