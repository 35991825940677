import { useQueryClient, useMutation } from '@tanstack/react-query';
import { notification } from 'components/ui/atomic-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { b } from 'styles/html-elements';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { type Atleast } from 'utils/typescript';
import { ModelVersionApi } from '../../../api';
import { type ModelVersion } from '../../../types';
import { appendVersion } from '../utils';

export const useCreateVersionMutation = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();

  const createModelVersion = useMutation({
    mutationFn: ({ displayName, description, snapshot }: Atleast<ModelVersion, 'displayName'>) => {
      return ModelVersionApi.saveAsNewVersion({ displayName, description, snapshot });
    },

    onSuccess: (data: ModelVersion) => {
      notification.success({
        message: intl.formatMessage({
          id: 'model.versions.save_as.success.title',
        }),
        description: (
          <FormattedMessage
            id="model.versions.save_as.success.desc"
            values={{
              value: data.displayName,
              b,
            }}
          />
        ),
      });

      appendVersion(queryClient);
    },

    onError: defaultApiErrorHandler,
  });

  return { createModelVersion };
};
