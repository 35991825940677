import { type ColumnGroup, type ICellRendererParams } from 'ag-grid-community';
import { Typography } from 'components/ui/atomic-components';
import { useState, type ReactElement } from 'react';
import styled from 'styled-components';
import { ColumnAccordion } from './column-accordion';

const { Paragraph } = Typography;

const HeaderDisplayNameWrapper = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.textBody2};

  &.ant-typography {
    margin-bottom: ${({ theme }) => theme.spacing[0]};
  }
`;

const Wrapper = styled.div<{
  $isExpandable: boolean;
}>`
  height: 100%;
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing[12]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  cursor: ${({ $isExpandable }) => ($isExpandable ? 'pointer' : 'initial')};
  gap: ${({ theme }) => theme.spacing[4]};
`;

interface Props extends ICellRendererParams {
  expandable?: boolean;
  displayName: string;
  columnGroup: ColumnGroup;
  setExpanded: (no: boolean) => boolean;
}

export const ColumnGroupHeader = ({
  displayName,
  setExpanded,
  columnGroup,
  expandable = true,
  context,
}: Props): ReactElement => {
  const { groupHeaderColMaxNoOfRowsOfText } = context || {};

  const [isExpanded, setIsExpanded] = useState(columnGroup.isExpanded());

  const isExpandable =
    Boolean(displayName) && (columnGroup.getChildren()?.length || 0) > 2 && expandable;

  const onHeaderClick = () => {
    if (isExpandable) {
      setExpanded(!isExpanded);
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <Wrapper $isExpandable={isExpandable} onClick={onHeaderClick}>
      {isExpandable && <ColumnAccordion expanded={isExpanded} />}
      <HeaderDisplayNameWrapper ellipsis={{ rows: groupHeaderColMaxNoOfRowsOfText || 1 }}>
        {displayName}
      </HeaderDisplayNameWrapper>
    </Wrapper>
  );
};
