/* eslint-disable filenames/match-regex */
import { css } from 'styled-components';
import { FontXs } from 'styles/typography';

export const DropdownStyles = css`
  .ant-dropdown,
  .ant-dropdown-menu-submenu {
    .ant-dropdown-menu {
      display: flex;
      flex-direction: column;
      border-radius: ${({ theme }) => theme.borderRadius.m};
      box-shadow: ${({ theme }) => theme.shadow.softMedium};
      border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
      gap: ${({ theme }) => theme.spacing[4]};
      min-width: 160px;
      overflow: auto;

      .ant-dropdown-menu-item {
        min-height: 32px;
        padding: 0 ${({ theme }) => theme.spacing[6]};
        color: ${({ theme }) => theme.colors.textTitle1};
        flex-shrink: 0;

        :hover {
          background-color: ${({ theme }) => theme.colors.bgMedium};
        }

        .ant-dropdown-menu-item-icon {
          margin-right: ${({ theme }) => theme.spacing[6]};
          color: ${({ theme }) => theme.colors.textBody2};
        }
      }

      .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled {
        color: ${({ theme }) => theme.colors.textDisabled};

        .ant-dropdown-menu-item-icon {
          color: ${({ theme }) => theme.colors.iconDisabled};
        }
      }

      .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:not(.ant-dropdown-menu-item-disabled) {
        color: ${({ theme }) => theme.colors.textNegative};

        :hover {
          color: ${({ theme }) => theme.colors.textNegative};
          background-color: ${({ theme }) => theme.colors.bgMedium};
        }

        .ant-dropdown-menu-item-icon {
          color: ${({ theme }) => theme.colors.textNegative};
        }
      }

      .ant-dropdown-menu-item-divider {
        margin: 0 -${({ theme }) => theme.spacing[4]};
        background-color: ${({ theme }) => theme.colors.outlineDefault};
      }

      .ant-dropdown-menu-item-selected {
        background: ${({ theme }) => theme.colors.bgMedium};

        .dropdown-item-label-icon {
          display: block;
        }
      }

      .ant-dropdown-menu-item-group {
        .ant-dropdown-menu-item-group-title {
          ${FontXs};

          padding: ${({ theme: { spacing } }) => `${spacing[4]} ${spacing[8]}`};
          color: ${({ theme }) => theme.colors.textLabel};
          font-weight: ${({ theme }) => theme.fontWeight.medium};
        }

        .ant-dropdown-menu-item-group-list {
          margin: 0;
        }
      }

      .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
        color: ${({ theme }) => theme.colors.textTitle1};
      }

      .ant-dropdown-menu-submenu-title {
        height: 32px;
        padding: 0 ${({ theme }) => theme.spacing[6]};
        color: ${({ theme }) => theme.colors.textTitle1};
        display: flex;
        align-items: center;

        :hover {
          background-color: ${({ theme }) => theme.colors.bgMedium};
        }

        .ant-dropdown-menu-item-icon {
          margin-right: ${({ theme }) => theme.spacing[6]};
          color: ${({ theme }) => theme.colors.textBody2};
        }

        svg:last-of-type:not(.ant-dropdown-menu-item-icon) {
          margin-left: ${({ theme }) => theme.spacing[6]};
          color: ${({ theme }) => theme.colors.iconDefault};
        }
      }

      .ant-dropdown-menu-title-content {
        font-size: ${({ theme }) => theme.fontSize.s};
      }
    }

    .ant-dropdown-menu-sub {
      max-height: min(50vh, 300px);
    }
  }

  .ant-dropdown {
    animation-timing-function: ease;
    transform-origin: 0% 0%;

    &.ant-slide-up-appear.ant-slide-up-appear-active,
    &.ant-slide-up-enter.ant-slide-up-enter-active {
      animation-name: dropdown-open-up;
    }

    &.ant-slide-down-appear.ant-slide-down-appear-active,
    &.ant-slide-down-enter.ant-slide-down-enter-active {
      animation-name: dropdown-open-down;
    }

    &.ant-slide-up-leave.ant-slide-up-leave-active {
      animation-name: dropdown-close-up;
    }

    &.ant-slide-down-leave.ant-slide-down-leave-active {
      animation-name: dropdown-close-down;
    }

    @keyframes dropdown-open-up {
      0% {
        transform: scale(0.97) translateY(-4px);
        opacity: 0;
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    @keyframes dropdown-open-down {
      0% {
        transform: scale(0.97) translateY(8px);
        opacity: 0;
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    @keyframes dropdown-close-up {
      0% {
        transform: scale(1);
        opacity: 1;
      }

      100% {
        transform: scale(0.97) translateY(-4px);
        opacity: 0;
      }
    }

    @keyframes dropdown-close-down {
      0% {
        transform: scale(1);
        opacity: 1;
      }

      100% {
        transform: scale(0.97) translateY(4px);
        opacity: 0;
      }
    }
  }

  .ant-dropdown-menu-submenu .ant-dropdown-menu {
    margin-left: ${({ theme }) => theme.spacing[8]};
  }
`;
