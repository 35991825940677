/* eslint-disable @calm/react-intl/missing-formatted-message */
import { type IntegrationModalContent } from 'data/integrations/fivetran';
import { type ReactElement } from 'react';
import { getAssetUrl } from 'utils';
import { type IntegrationInputFlowCompV2 } from '../../../types';
import { FormConnectorStyles, PopupModalStyles } from '../../styles';

const { StyledP } = PopupModalStyles;
const { LeftPanel, Title, ImageContainer } = FormConnectorStyles;

export const GenericLeftPanel = ({
  item,
  flow,
}: {
  item: IntegrationModalContent;
  flow: IntegrationInputFlowCompV2;
}): ReactElement => {
  return (
    <LeftPanel>
      <ImageContainer alt="" src={getAssetUrl(`integrations/${item.name.toLowerCase()}.png?v5`)} />
      <Title>{item.displayName}</Title>

      <StyledP>{item.description}</StyledP>
    </LeftPanel>
  );
};
