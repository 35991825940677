import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/ui/atomic-components';
import { ListsApi, type ReconciliationConfig } from 'data/modelling/lists';
import { useIntl } from 'react-intl';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useEditDerivedListRecon = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ listId, request }: { listId: number; request: Partial<ReconciliationConfig> }) =>
      ListsApi.editDerivedListRecon({ listId, request }),

    onSuccess: (_, { listId }) => {
      queryClient.invalidateQueries({
        queryKey: ['lists', listId],
      });

      notification.success({
        message: intl.formatMessage({ id: 'lists.update_from_data.success' }),
      });
    },

    onError: defaultApiErrorHandler,
  });
};
