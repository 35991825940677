import { useContext } from 'react';
import { UnderlyingDataContext, type UnderlyingDataContextParams } from './index';

export const useUnderlyingDataContext = (): UnderlyingDataContextParams => {
  const context = useContext(UnderlyingDataContext);

  if (!context) {
    throw new Error('useUnderlyingDataContext to be used within Underlying data drawer context');
  }

  return context;
};
