import { AgGrid } from 'components/ui/atomic-components';
import styled from 'styled-components';

export const styles = {
  GridWrapper: styled.div`
    width: 100%;
    height: 400px;
    margin-top: ${({ theme }) => theme.spacing[16]};
  `,

  Grid: styled(AgGrid)`
    width: calc(100% - 2px);

    --ag-font-size: ${({ theme }) => theme.fontSize.xs};
    --ag-cell-horizontal-padding: ${({ theme }) => theme.spacing[12]};

    .ag-header-row {
      border-top: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    }

    && .ag-header-cell {
      --ag-cell-horizontal-padding: 0;
    }

    .ag-header {
      border-bottom: 1px solid ${({ theme }) => theme.colors.outlineDefault};
    }

    .ag-center-cols-container,
    .ag-header-container {
      border-left: 1px solid ${({ theme }) => theme.colors.gray200};
    }

    .ag-row-last:not(.ag-full-width-row) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
    }

    .row-edits-disabled {
      pointer-events: none;

      --ag-data-color: ${({ theme: { colors } }) => colors.textPlaceholder};
    }

    .row-with-error {
      background: ${({ theme: { colors } }) => colors.red100};

      :hover {
        background: ${({ theme: { colors } }) => colors.red100};
      }
    }

    .cellupdated {
      border-left: 2px solid ${({ theme: { colors } }) => colors.gray500};
    }
  `,
};
