import { useQuery } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ModelDimensionApi } from 'data/modelling/dimension/api';

export const useApplicableColumns = (dimensionNames: string[]) => {
  const query = useQuery({
    queryKey: ['dimension-table-columns', dimensionNames],
    queryFn: () => ModelDimensionApi.getTableDetails(dimensionNames),
    staleTime: StaleTime.Short,
  });

  return query;
};
