import { ComputedComparisons } from 'data';
import { TimeValueKeyDelimiter } from 'data/reports/utils';

export const isComparisonColumn = (timeDimensionValue?: string): boolean => {
  const columnTimeValuePrefix = timeDimensionValue?.split(TimeValueKeyDelimiter)?.[0];

  return [
    ComputedComparisons.WoW,
    ComputedComparisons.MoM,
    ComputedComparisons.QoQ,
    ComputedComparisons.YoY,
  ].some((c) => columnTimeValuePrefix?.includes(c));
};
