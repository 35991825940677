import {
  type CellClassParams,
  type ColDef,
  type ValueFormatterParams,
  type ValueGetterParams,
} from 'ag-grid-community';
import { checkIfInPrintMode } from 'data/boards';
import { type TableFormattingRuleStyle } from 'data/boards/charts/types/table';
import { type Dimension } from 'data/dimension';
import { type PivotDimensionsSummaryType, type Pivot } from 'data/page-template';
import { type MutableRefObject } from 'react';
import { headerCellValueGetter } from 'utils/grid/table-view';
import { getRowAutoColumnCellStyle } from '../../row-group-header/row-auto-column-cell-style';

export const generateTableViewHeaderColumnOptions = ({
  dimension,
  columnIndex,
  pivot,
  styleMap,
  repeatRowLabels,
  dimensionSummary,
  decendantRowIndexHierarchyToShowHeader,
  isRenderedInAiChat,
}: {
  dimension: Pick<Dimension, 'name' | 'displayName' | 'displayNamePlural' | 'children'>;
  columnIndex: number;
  pivot: Pivot;
  styleMap: MutableRefObject<Record<string, TableFormattingRuleStyle>>;
  repeatRowLabels: boolean;
  dimensionSummary?: PivotDimensionsSummaryType;
  decendantRowIndexHierarchyToShowHeader?: number[];
  isRenderedInAiChat?: boolean;
}): ColDef => {
  const isInPrintMode = checkIfInPrintMode();

  return {
    colId: dimension.name,
    headerName: dimension.displayName,
    // if pinned columns take more width than available viewport, then columns are shuffled and are unpinned by ag grid
    pinned: columnIndex < 3 && !isRenderedInAiChat ? 'left' : null,
    resizable: true,
    minWidth: 160,
    maxWidth: 600,
    editable: false,
    suppressFillHandle: true,
    autoHeight: isInPrintMode,
    wrapText: isInPrintMode,
    valueGetter: (params: ValueGetterParams) =>
      headerCellValueGetter({
        params,
        dimension,
        columnIndex,
        repeatRowLabels,
        dimensionSummary,
        decendantRowIndexHierarchyToShowHeader,
      }) || '',
    valueFormatter: (params: ValueFormatterParams) => params.value,
    cellStyle: (params: CellClassParams) =>
      getRowAutoColumnCellStyle({
        styleMap,
        data: params.data,
        rowsOrder: pivot.dimensions.rows,
      }),
  };
};
