import { useMutation } from '@tanstack/react-query';
import { DimensionGroupApi } from 'data/modelling/dimension-group/api';
import { ListsApi } from 'data/modelling/lists';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useAccessControlMutation = (props?: { isDerivedList?: boolean }) => {
  return useMutation({
    mutationFn: ({
      listId,
      listName,
      roleIds,
      isAccessControlled,
    }: {
      listId: number;
      listName: string;
      roleIds: number[];
      isAccessControlled: boolean;
    }) =>
      (props?.isDerivedList ? DimensionGroupApi : ListsApi).setAccessControl({
        listId,
        listName,
        roleIds,
        isAccessControlled,
      }),

    onError: defaultApiErrorHandler,
  });
};
