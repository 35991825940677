import { useQuery } from '@tanstack/react-query';
import { useViewAsUser } from 'components/modules/app-wrapper/hooks/use-view-as-user';
import { type User, UsersApi } from 'data/users';

export const useUser = () => {
  const { username: viewAsUserEmail } = useViewAsUser();
  const {
    data: user = {} as User,
    status,
    isLoading,
  } = useQuery({
    queryKey: ['current-user'],
    queryFn: () => UsersApi.currentUser(),
    staleTime: Infinity,
  });

  const isDtUser =
    user?.username?.endsWith('@drivetrain.ai') &&
    (viewAsUserEmail ? viewAsUserEmail?.endsWith('@drivetrain.ai') : true);

  return {
    status,
    isLoading,
    user,
    isDtUser,
  };
};
