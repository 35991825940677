import {
  type ValueGetterParams,
  type ColDef,
  type ValueFormatterParams,
  type GridApi,
  type GridReadyEvent,
} from 'ag-grid-community';
import { ReactComponent as ExternalLinkIcon } from 'assets/v2/external-link.svg';
import { IconShell, Spinner, Tooltip } from 'components/ui/atomic-components';
import { DateTimeFormats } from 'config/constants';
import { type BigQueryResponse, QueryTableColumnDataType } from 'data/big-query';
import { useCurrency } from 'data/currencies/hooks/use-currency';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState, type ReactElement } from 'react';
import styled from 'styled-components';
import { isNumericBigQueryType } from 'utils/big-query/is-numeric-big-query-type';
import { numberFormatter } from 'utils/data-formatter';
import { DataFormattingType } from 'utils/data-formatter/types';
import { maskedColumnDef } from 'utils/grid';
import { BREAK_UP_URL_FIELD_NAME, CLICKTHROUGH_URL_FIELD_NAME } from '../constants';
import { ColumnHeader } from './column-header';
import styles from './styles';

const CellHeight = 32;
const { StyledGrid } = styles;

const PopoutLink = styled.a`
  color: ${({ theme }) => theme.colors.black300};
  cursor: 'pointer';
`;

interface Props {
  breakupData: BigQueryResponse<Record<string, string>> | undefined;
  isLoading: boolean;
  selectedColumns?: string[];
}

export const Grid = ({ breakupData, isLoading, selectedColumns }: Props): ReactElement => {
  const currency = useCurrency();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const colDefs: ColDef[] = [];
  const columnMeta = breakupData?.columnMeta;
  const loadingOverlayComponent = useCallback(() => {
    return breakupData ? <div></div> : <Spinner />;
  }, [breakupData]);

  const valueFormatter = ({ value, colDef: { field } }: ValueFormatterParams) => {
    if (!field) {
      return value;
    }
    const type = breakupData?.columnMeta[field]?.type;

    // For string and id fields don't format cells.
    if (
      type === QueryTableColumnDataType.String ||
      field.toLowerCase() === 'id' ||
      field.toLowerCase().endsWith('_id')
    ) {
      return value;
    }

    // Format dates and timestamps.
    if (
      type &&
      [QueryTableColumnDataType.Date, QueryTableColumnDataType.Timestamp].includes(type) &&
      dayjs(value, DateTimeFormats.APIDate, true).isValid()
    ) {
      return dayjs(value).format(DateTimeFormats.HumanReadableFullYearDate);
    }

    // Format numeric data.
    if (type && isNumericBigQueryType(type)) {
      if (field.toLowerCase().endsWith('_percent')) {
        return numberFormatter({
          value,
          type: DataFormattingType.Percentage,
          currency,
        });
      }

      return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
      }).format(Number(value));
    }

    return value;
  };

  const popoutLinkRenderer = (params: { value: string }) => {
    if (!params.value) {
      return <></>;
    }

    return (
      <Tooltip placement="bottomRight">
        <PopoutLink href={params.value} target="_blank">
          <IconShell color="gray400" icon={ExternalLinkIcon} />
        </PopoutLink>
      </Tooltip>
    );
  };

  if (
    breakupData?.columns.includes(BREAK_UP_URL_FIELD_NAME) ||
    breakupData?.columns.includes(CLICKTHROUGH_URL_FIELD_NAME)
  ) {
    colDefs.push({
      cellRenderer: popoutLinkRenderer,
      valueGetter: ({ data }: ValueGetterParams) => {
        return data?.[CLICKTHROUGH_URL_FIELD_NAME] || data?.[BREAK_UP_URL_FIELD_NAME];
      },
      headerName: '',
      pinned: 'left',
      width: 40,
      suppressMenu: true,
      sortable: false,
    });
  }

  (selectedColumns || [])
    .filter((col) => ![BREAK_UP_URL_FIELD_NAME, CLICKTHROUGH_URL_FIELD_NAME].includes(col))
    .forEach((col) => {
      const isMaskedColumn = breakupData?.maskedColumns?.includes(col);

      colDefs.push({
        field: col,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          colName: col,
          columnType: columnMeta?.[col]?.type ?? QueryTableColumnDataType.String,
          isMaskedColumn,
        },
        valueFormatter,
        resizable: true,
        cellClass:
          columnMeta?.[col]?.type && isNumericBigQueryType(columnMeta[col].type)
            ? 'ag-right-aligned-cell'
            : undefined,
        ...(isMaskedColumn ? maskedColumnDef : {}),
      });
    });

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    gridApi?.setGridOption('loading', isLoading);
  }, [gridApi, isLoading]);

  return (
    <StyledGrid
      cellSelection
      columnDefs={colDefs}
      headerHeight={CellHeight}
      loadingOverlayComponent={loadingOverlayComponent}
      rowData={breakupData?.rows}
      rowHeight={CellHeight}
      onGridReady={onGridReady}
    />
  );
};
