import { type ItemType } from 'components/ui/atomic-components/menu';
import { type OptionBaseOld } from 'components/ui/atomic-components/select';
import { type AreaConfig } from 'components/ui/chart-components-v2/chart-types/area-chart/types';
import { type ID } from 'data';
import { type VisualQueryFilterRule } from 'data/big-query';
import {
  type SeriesColors,
  type Chart,
  type ChartType,
  type MetricDimensionOverrides,
  type CustomColumn,
} from 'data/boards/charts/types';
import { type StackingType } from 'data/boards/charts/types/bar-combo';
import { type YAxisConfig, type MetricComboConfig } from 'data/boards/charts/types/combo';
import { type PieChartType } from 'data/boards/charts/types/pie';
import {
  type TableColumnHeaderWrap,
  type TableChartCellSize,
  type TableChartRowType,
  type TableFormattingRule,
} from 'data/boards/charts/types/table';
import { type DimensionQueryParamsObject } from 'data/dimension';
import { type PivotDimensionsSummaryType, type PivotViewType } from 'data/page-template';
import { type VaryingColGroup } from 'data/page-template/grid';
import { create } from 'zustand';
import { type OptionTypeCustom } from '../left-section/data-tab/list/types';
import { getInitialFieldValues } from './utils';

export interface ChartConfigStore {
  currentlyEditingChartId: number | null;
  chartMap?: { [chartId: number]: Chart };
  isChartSaving: boolean;

  metrics?: OptionTypeCustom[];
  type?: ChartType;
  rowLabels?: string[];
  columnLabels?: string[];
  versionLabels?: string[];
  scenarioLabels?: string[];
  stackingType?: StackingType;
  pieChartType?: PieChartType;
  labelType?: OptionBaseOld;
  legendPosition?: boolean;
  overlayPlanOnActual?: boolean;
  timeSummary?: OptionBaseOld;
  seriesColorMap?: string;
  seriesColors?: SeriesColors[];
  versionToBaselineMap?: Record<string, string> | null;
  baselineVersion?: string | null;
  compareStatistic?: VaryingColGroup;
  showCompareStatisticAs?: ('magnitude' | 'percentage')[];
  baselineScenario?: string | null;
  scenarioCompareStatistic?: VaryingColGroup;
  showScenarioCompareStatisticAs?: ('magnitude' | 'percentage')[];
  comboConfig?: MetricComboConfig;
  waterfallInvertMetricsMap?: Record<string, boolean>;
  waterfallShowConnectingLines?: boolean;
  waterfallShowZeroValues?: boolean;
  waterfallStartingBlock?: OptionBaseOld;
  waterfallSubTotals?: ItemType[];
  waterfallEndingBlock?: OptionBaseOld;
  sparklineColor?: string;
  showSparkline?: boolean;
  showVerticalGridLines?: boolean;
  tableChartViewType?: PivotViewType;
  tableChartCellSize?: TableChartCellSize;
  tableChartCellEditable?: boolean;
  tableRowSummaryPosition?: PivotDimensionsSummaryType;
  tableRowsExpanded?: boolean;
  tableRepeatRowLabels?: boolean;
  tableColumnsExpanded?: boolean;
  tableShowEmptyRows?: boolean;
  tableExpandedRowNodeIds?: string[];
  tableExpandedColNodeIds?: string[];
  tableAlternateRowColor?: string;
  tableColoredColumnHeader?: boolean;
  tableShowCellGrids?: boolean;
  tableFormattingRules?: TableFormattingRule[];
  tableBlankRowsPlacement?: { [key: string]: TableChartRowType[] };
  tableColumnHeaderWrap?: TableColumnHeaderWrap;
  listId?: ID;
  listCellsEditable?: boolean;
  listColumnOrder?: string[];
  listFilters?: VisualQueryFilterRule[];
  listLockedColumns?: string[];
  axisConfig?: YAxisConfig;
  areaChartConfig?: AreaConfig;
  fixedFilters?: DimensionQueryParamsObject;
  metricDimensionOverrides?: MetricDimensionOverrides;
  hideAggregatesFor?: string[];
  metricNameAlias?: { [key: string]: string };
  customColumns?: CustomColumn[];

  setCurrentlyEditingChartId: (id: number | null) => void;
  setChartInMap: (chartId: number, chart: Chart) => void;
  resetChartMap: () => void;
  resetFields: () => void;
  setFieldsValue: (values: Record<string, unknown>) => void;
  setIsChartSaving: (value: boolean) => void;

  setMetrics: (metrics: OptionTypeCustom[]) => void;
  setType: (type?: ChartType) => void;
  setRowLabels: (rowLabels: string[]) => void;
  setColumnLabels: (columnLabels: string[]) => void;
  setVersionLabels: (versionLabels: string[]) => void;
  setScenarioLabels: (scenarioLabels: string[]) => void;
  setStackingType: (stackingType: StackingType) => void;
  setPieChartType: (pieChartType: PieChartType) => void;
  setLabelType: (labelType: OptionBaseOld) => void;
  setLegendPosition: (legendPosition: boolean) => void;
  setOverlayPlanOnActuals: (overlayPlanOnActual: boolean) => void;
  setTimeSummary: (timeSummary: OptionBaseOld) => void;
  setSeriesColorMap: (seriesColorMap: string) => void;
  setSeriesColors: (seriesColors: SeriesColors[]) => void;
  setVersionToBaselineMap: (versionToBaselineMap: Record<string, string> | null) => void;
  setBaselineVersion: (baselineVersion: string | null) => void;
  setCompareStatistic: (compareStatistic: VaryingColGroup) => void;
  setShowCompareStatisticAs: (showCompareStatisticAs: ('magnitude' | 'percentage')[]) => void;
  setBaselineScenario: (baselineScenario: string | null) => void;
  setScenarioCompareStatistic: (scenarioCompareStatistic: VaryingColGroup) => void;
  setShowScenarioCompareStatisticAs: (
    showscenarioCompareStatisticAs: ('magnitude' | 'percentage')[],
  ) => void;
  setComboConfig: (comboConfig: MetricComboConfig) => void;
  setAxisConfig: (axisConfig?: YAxisConfig) => void;
  setWaterfallInvertMetricsMap: (waterfallInvertMetricsMap: Record<string, boolean>) => void;
  setWaterfallShowConnectingLines: (waterfallShowConnectingLines: boolean) => void;
  setWaterfallShowZeroValues: (waterfallShowZeroValues: boolean) => void;
  setWaterfallStartingBlock: (waterfallStartingBlock: OptionBaseOld) => void;
  setWaterfallSubTotals: (waterfallSubTotals: ItemType[]) => void;
  setWaterfallEndingBlock: (waterfallEndingBlock: OptionBaseOld) => void;
  setSparklineColor: (sparklineColor: string) => void;
  setShowSparkline: (showSparkline: boolean) => void;
  setShowVerticalGridLines: (showVerticalGridLines: boolean) => void;
  setTableChartViewType: (tableChartViewType: PivotViewType) => void;
  setTableChartCellSize: (tableChartCellSize: TableChartCellSize) => void;
  setTableChartCellEditable: (tableChartCellEditable: boolean) => void;
  setTableRowSummaryPosition: (tableRowSummaryPosition: PivotDimensionsSummaryType) => void;
  setTableRowsExpanded: (tableRowsExpanded: boolean) => void;
  setTableShowEmptyRows: (tableShowEmptyRows: boolean) => void;
  setTableExpandedRowNodeIds: (tableExpandedRowNodeIds: string[]) => void;
  setTableExpandedColNodeIds: (tableExpandedColNodeIds: string[]) => void;
  setTableRepeatRowLabels: (tableRepeatRowLabels: boolean) => void;
  setTableColumnsExpanded: (tableColumnsExpanded: boolean) => void;
  setTableAlternateRowColor: (tableAlternateRowColor?: string) => void;
  setTableColoredColumnHeader: (tableColoredColumnHeader: boolean) => void;
  setTableShowCellGrids: (tableShowCellGrids: boolean) => void;
  setTableFormattingRules: (tableFormattingRules: TableFormattingRule[]) => void;
  setTableBlankRowsPlacement: (tableBlankRowsPlacement: {
    [key: string]: TableChartRowType[];
  }) => void;
  setTableColumnHeaderWrap: (wrap: TableColumnHeaderWrap) => void;
  setMetricDimensionOverrides: (metricDimensionOverrides: MetricDimensionOverrides) => void;
  setHideAggregatesFor: (hideAggregatesFor: string[]) => void;

  setListId: (id: ID) => void;
  setListCellsEditable: (val: boolean) => void;
  setAreaChartConfig: (config: AreaConfig) => void;
  setFixedFilters: (filters?: DimensionQueryParamsObject) => void;
  setMetricNameAlias: (newMap?: { [key: string]: string }) => void;
  setCustomColumns: (columns: CustomColumn[]) => void;
}

export const useChartConfigStore = create<ChartConfigStore>()((set, get) => ({
  currentlyEditingChartId: null,
  isChartSaving: false,

  setCurrentlyEditingChartId: (id) => set({ currentlyEditingChartId: id }),
  setChartInMap: (chartId, chart) => set({ chartMap: { ...get().chartMap, [chartId]: chart } }),
  resetChartMap: () => set({ chartMap: undefined }),
  setFieldsValue: (values) => set({ ...values }),
  resetFields: () => set({ ...getInitialFieldValues() }),
  setIsChartSaving: (value) => set({ isChartSaving: value }),

  setMetrics: (metrics) => set({ metrics }),
  setType: (type) => set({ type }),
  setRowLabels: (rowLabels) => set({ rowLabels }),
  setColumnLabels: (columnLabels) => set({ columnLabels }),
  setVersionLabels: (versionLabels) => set({ versionLabels }),
  setScenarioLabels: (scenarioLabels) => set({ scenarioLabels }),
  setStackingType: (stackingType) => set({ stackingType }),
  setPieChartType: (pieChartType) => set({ pieChartType }),
  setLabelType: (labelType) => set({ labelType }),
  setLegendPosition: (legendPosition) => set({ legendPosition }),
  setOverlayPlanOnActuals: (overlayPlanOnActual) => set({ overlayPlanOnActual }),
  setTimeSummary: (timeSummary) => set({ timeSummary }),
  setSeriesColorMap: (seriesColorMap) => set({ seriesColorMap }),
  setSeriesColors: (seriesColors) => set({ seriesColors }),
  setVersionToBaselineMap: (versionToBaselineMap) => set({ versionToBaselineMap }),
  setBaselineVersion: (baselineVersion) => set({ baselineVersion }),
  setCompareStatistic: (compareStatistic) => set({ compareStatistic }),
  setShowCompareStatisticAs: (showCompareStatisticAs) => set({ showCompareStatisticAs }),
  setBaselineScenario: (baselineScenario) => set({ baselineScenario }),
  setScenarioCompareStatistic: (scenarioCompareStatistic) => set({ scenarioCompareStatistic }),
  setShowScenarioCompareStatisticAs: (showScenarioCompareStatisticAs) =>
    set({ showScenarioCompareStatisticAs }),
  setComboConfig: (comboConfig) => set({ comboConfig }),
  setAxisConfig: (axisConfig) => set({ axisConfig }),
  setWaterfallInvertMetricsMap: (waterfallInvertMetricsMap) => set({ waterfallInvertMetricsMap }),
  setWaterfallShowConnectingLines: (waterfallShowConnectingLines) =>
    set({ waterfallShowConnectingLines }),
  setWaterfallShowZeroValues: (waterfallShowZeroValues) => set({ waterfallShowZeroValues }),
  setWaterfallStartingBlock: (waterfallStartingBlock) => set({ waterfallStartingBlock }),
  setWaterfallSubTotals: (waterfallSubTotals) => set({ waterfallSubTotals }),
  setWaterfallEndingBlock: (waterfallEndingBlock) => set({ waterfallEndingBlock }),
  setSparklineColor: (sparklineColor) => set({ sparklineColor }),
  setShowSparkline: (showSparkline) => set({ showSparkline }),
  setShowVerticalGridLines: (showVerticalGridLines) => set({ showVerticalGridLines }),
  setTableChartViewType: (tableChartViewType) => set({ tableChartViewType }),
  setTableChartCellSize: (tableChartCellSize) => set({ tableChartCellSize }),
  setTableChartCellEditable: (tableChartCellEditable) => set({ tableChartCellEditable }),
  setTableRowSummaryPosition: (tableRowSummaryPosition) => set({ tableRowSummaryPosition }),
  setTableShowEmptyRows: (tableShowEmptyRows) => set({ tableShowEmptyRows }),
  setTableRowsExpanded: (tableRowsExpanded) => set({ tableRowsExpanded }),
  setTableExpandedRowNodeIds: (tableExpandedRowNodeIds) => set({ tableExpandedRowNodeIds }),
  setTableExpandedColNodeIds: (tableExpandedColNodeIds) => set({ tableExpandedColNodeIds }),
  setTableRepeatRowLabels: (tableRepeatRowLabels) => set({ tableRepeatRowLabels }),
  setTableColumnsExpanded: (tableColumnsExpanded) => set({ tableColumnsExpanded }),
  setTableAlternateRowColor: (tableAlternateRowColor) => set({ tableAlternateRowColor }),
  setTableColoredColumnHeader: (tableColoredColumnHeader) => set({ tableColoredColumnHeader }),
  setTableShowCellGrids: (tableShowCellGrids) => set({ tableShowCellGrids }),
  setTableFormattingRules: (tableFormattingRules) => set({ tableFormattingRules }),
  setTableBlankRowsPlacement: (tableBlankRowsPlacement) => set({ tableBlankRowsPlacement }),
  setTableColumnHeaderWrap: (tableColumnHeaderWrap) => set({ tableColumnHeaderWrap }),
  setMetricDimensionOverrides: (metricDimensionOverrides) => set({ metricDimensionOverrides }),
  setHideAggregatesFor: (hideAggregatesFor) => set({ hideAggregatesFor }),

  setListId: (listId) => set({ listId }),
  setListCellsEditable: (val) => set({ listCellsEditable: val }),
  setAreaChartConfig: (config) => set({ areaChartConfig: config }),
  setFixedFilters: (config) => set({ fixedFilters: config }),
  setMetricNameAlias: (newMap?: { [key: string]: string }) => set({ metricNameAlias: newMap }),
  setCustomColumns: (columns) => set({ customColumns: columns }),
}));
