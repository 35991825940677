import { useMutation } from '@tanstack/react-query';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { IntegrationsApi } from '../api';

export const useMailMutation = (onSuccess: () => void) => {
  const { mutate: sendMail, isPending } = useMutation({
    mutationFn: (id: number) => IntegrationsApi.contactUs(id),
    onSuccess,
    onError: defaultApiErrorHandler,
  });

  return {
    sendMail,
    isPending,
  };
};
