import { type ListUpdate, ListUpdateType } from 'data/modelling/lists';
import { type ListGridRow } from '../../../../grid/types';

export const constructEntityColumnValue = ({
  entityColumnName,
  listUpdate,
  matchedRow,
}: {
  entityColumnName: string;
  listUpdate: ListUpdate;
  matchedRow?: ListGridRow;
}) => {
  if (listUpdate.cardType === ListUpdateType.Create) {
    return listUpdate.data?.[entityColumnName] ?? '';
  }

  if (!listUpdate.rowIdentifier) {
    return '';
  }

  return String(matchedRow?.data?.[entityColumnName] ?? '');
};
