import { type ID } from 'data';
import {
  type IntegrationConnectedItem,
  type IntegrationModalContent,
  IntegrationProvider,
} from 'data/integrations/fivetran';
import { useGetAllConnections } from 'data/integrations/fivetran/hooks/use-get-all-connections';
import { type FC, type ReactNode, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { defaultApiErrorHandler, type DetailedAxiosError } from 'utils/error-handler';
import {
  gsheetLogin,
  salesforceLogin,
  quickbooksLogin,
  xeroLogin,
} from '../../common/connectors-auth';
import { ConnectorAuthContext } from './connector-auth-context';
import { useCheckIfConnectorTokenValid } from './hooks/use-check-if-token-valid';

const TokenExpiryMessage = 'Refresh token expired';

type ReauthEnabledConnector =
  | IntegrationProvider.GoogleSheets
  | IntegrationProvider.Salesforce
  | IntegrationProvider.Quickbooks
  | IntegrationProvider.SalesforceSandbox
  | IntegrationProvider.Xero;

const loginActionLookup: {
  [key in ReauthEnabledConnector]: ({
    item,
    instanceId,
    isSandboxTenant,
  }: {
    item: IntegrationModalContent;
    instanceId?: number;
    isSandboxTenant?: boolean;
  }) => void;
} = {
  [IntegrationProvider.GoogleSheets]: gsheetLogin,
  [IntegrationProvider.Salesforce]: salesforceLogin,
  [IntegrationProvider.Quickbooks]: quickbooksLogin,
  [IntegrationProvider.SalesforceSandbox]: salesforceLogin,
  [IntegrationProvider.Xero]: xeroLogin,
};

export const ConnectorAuthContextProvider: FC<
  React.PropsWithChildren<{
    children: ReactNode;
    instance?: IntegrationConnectedItem;
  }>
> = ({ children, instance }) => {
  const [authError, setAuthError] = useState(false);

  const { instanceId: currentInstanceIdInUrl } = useParams() as { instanceId: string };

  const { data } = useGetAllConnections();

  const relogin = useCallback(() => {
    const connectorItem = (data || []).find((item) => item.name === instance?.name);

    if (!connectorItem) {
      return;
    }

    const login = loginActionLookup[instance?.name as ReauthEnabledConnector];

    if (login) {
      login({
        item: connectorItem,
        instanceId: instance?.id,
        isSandboxTenant: false,
      });
    }
  }, [data, instance?.id, instance?.name]);

  const handleError = useCallback(
    (errorResp: DetailedAxiosError, errorInstanceId: ID) => {
      if (String(errorInstanceId) !== String(currentInstanceIdInUrl)) {
        return;
      }

      if (errorResp?.response?.data?.message === TokenExpiryMessage) {
        setAuthError(true);
      } else {
        defaultApiErrorHandler(errorResp);
      }
    },
    [currentInstanceIdInUrl],
  );

  useCheckIfConnectorTokenValid({ instance, handleError });

  const contextParams = useMemo(
    () => ({
      handleError,
      errorModalOpen: authError,
      closeModal: () => setAuthError(false),
      relogin,
    }),
    [authError, handleError, relogin],
  );

  return (
    <ConnectorAuthContext.Provider value={contextParams}>{children}</ConnectorAuthContext.Provider>
  );
};
