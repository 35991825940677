import { useContext } from 'react';
import { PageTemplateContext, type PageTemplateContextProps } from './provider';

export const useCurrentPageTemplate = (): PageTemplateContextProps => {
  const context = useContext(PageTemplateContext);

  if (!context) {
    throw new Error('useCurrentPageTemplate to be used within PageTemplateContext');
  }

  return context;
};
