import { Drawer as AntdDrawer } from 'components/ui/atomic-components';
import styled from 'styled-components';
import { FontM } from 'styles/typography';

export default {
  Drawer: styled(AntdDrawer)`
    .ant-drawer-body {
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    .ant-drawer-header {
      padding: ${({ theme: { spacing } }) => `${spacing[24]} ${spacing[16]} ${spacing[16]}`};
      margin-bottom: 0;
    }
  `,

  DataCount: styled.span`
    color: ${({ theme }) => theme.colors.gray600};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,

  RowsColumnsText: styled.span`
    color: ${({ theme }) => theme.colors.gray400};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,

  Bullet: styled.span`
    color: ${({ theme }) => theme.colors.gray400};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `,

  HeaderLeftSection: styled.div`
    ${FontM}

    display: flex;
  `,

  HeaderRightSection: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  DataCountWrapper: styled.div`
    margin-right: ${({ theme }) => theme.spacing[8]};
    white-space: pre;
  `,

  HeaderActionsDivider: styled.div`
    height: 16px;
    width: 1px;
    margin-right: ${({ theme }) => theme.spacing[8]};
    margin-left: ${({ theme }) => theme.spacing[8]};
    background-color: ${({ theme }) => theme.colors.gray200};
  `,

  ErrorMessage: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  `,

  Header: styled.div`
    padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[16]}`};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,

  Value: styled.div`
    margin-right: ${({ theme }) => theme.spacing[8]};
    padding-right: ${({ theme }) => theme.spacing[8]};
    border-right: 1px solid ${({ theme }) => theme.colors.gray200};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.textTitle2};
  `,

  Footer: styled.div`
    padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[16]}`};
    display: flex;
    justify-content: right;
    align-items: center;
  `,
};
