import { MoreActionsDropdown } from 'components/ui/atomic-components';
import { type Comment } from 'data/conversations';
import { type ReactElement } from 'react';
import { useMenuItems } from './hooks';

interface Props {
  comment: Comment;
  isReply?: boolean;
  onClick?: (key: string) => void;
}

export const MoreActionsMenu = ({ comment, onClick }: Props): ReactElement => {
  const menuItems = useMenuItems({ comment, onMenuItemClick: onClick });

  return (
    <MoreActionsDropdown
      menu={{ items: menuItems }}
      size="extraSmall"
      onButtonClick={(event) => event.stopPropagation()}
    />
  );
};
