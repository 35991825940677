import { useQueryClient } from '@tanstack/react-query';
import { type ColDef, type ColumnGroupOpenedEvent } from 'ag-grid-community';
import { useChartConfigStore } from 'components/modules/boards-v2/custom-board/pivot-slider/store';
import { BoardsApi, useBoardQueryParams, useEditModeQueryParam } from 'data/boards';
import { type Chart } from 'data/boards/charts/types';
import { debounce } from 'lodash';
import { useCallback, useMemo } from 'react';

const DEBOUNCE_TIME = 100;

export const usePreserveColumnExpansion = (
  chart: Chart,
): {
  isGroupOpenByDefault: (id: string) => boolean;
  handleColGroupOpen: ({ columnGroup }: ColumnGroupOpenedEvent) => void;
} => {
  const [isInEditMode] = useEditModeQueryParam();

  const [query] = useBoardQueryParams();
  const queryClient = useQueryClient();
  const setTableExpandedColNodeIds = useChartConfigStore(
    (state) => state.setTableExpandedColNodeIds,
  );
  const tableColsExpanded = !!chart?.attributes?.tableChartConfig?.columnsExpanded;

  const expandedColNodeIdsSet = useMemo(() => {
    const set = new Set<string>(chart?.attributes?.tableChartConfig?.expandedColNodeIds ?? []);

    setTableExpandedColNodeIds(Array.from(set));

    return set;
  }, [chart?.attributes?.tableChartConfig?.expandedColNodeIds, setTableExpandedColNodeIds]);

  const debouncedSetExpandedColNodeIds = useMemo(
    () =>
      debounce(() => {
        const idsArray = Array.from(expandedColNodeIdsSet);
        const updatedChart = {
          ...chart,
          attributes: {
            ...chart?.attributes,
            tableChartConfig: {
              ...chart?.attributes?.tableChartConfig,
              expandedColNodeIds: idsArray,
            },
          },
        };

        BoardsApi.addEditChart({
          id: chart?.boardId,
          chart: updatedChart,
          type: 'updated',
        }).then(() => {
          // Mutating the old state will not trigger the rerender
          queryClient.setQueryData<Chart>(['charts', chart?.id, query], (oldChart) => {
            if (oldChart?.attributes?.tableChartConfig?.expandedColNodeIds) {
              oldChart.attributes.tableChartConfig.expandedColNodeIds = idsArray;
            }

            return oldChart;
          });

          setTableExpandedColNodeIds(idsArray);
        });
      }, DEBOUNCE_TIME),
    [chart, expandedColNodeIdsSet, query, queryClient, setTableExpandedColNodeIds],
  );

  const isGroupOpenByDefault = useCallback(
    (id: string) => {
      if (tableColsExpanded) {
        return true;
      }

      return id ? expandedColNodeIdsSet.has(id) : false;
    },
    [tableColsExpanded, expandedColNodeIdsSet],
  );

  const handleColGroupOpen = useCallback(
    ({ columnGroup }: ColumnGroupOpenedEvent) => {
      if (!columnGroup) {
        return;
      }

      const colDef = columnGroup?.getColGroupDef() as ColDef;
      const isExpanded = columnGroup?.isExpanded();

      if (!colDef.colId || !isInEditMode) {
        return;
      }

      if (isExpanded) {
        expandedColNodeIdsSet.add(colDef.colId);
      } else {
        expandedColNodeIdsSet.delete(colDef.colId);
      }

      debouncedSetExpandedColNodeIds();
    },
    [debouncedSetExpandedColNodeIds, expandedColNodeIdsSet, isInEditMode],
  );

  return {
    isGroupOpenByDefault,
    handleColGroupOpen,
  };
};
