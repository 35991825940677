// eslint-disable-next-line no-restricted-imports
import { type ButtonProps as AntButtonProps, Button as AntdButton } from 'antd';
import { ReactComponent as ArrowDownIcon } from 'assets/v2/chevron-down_10.svg';
import {
  type ForwardRefExoticComponent,
  type RefAttributes,
  type PropsWithChildren,
  type ReactElement,
} from 'react';
import styled, { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import { FontXs, FontXxs } from 'styles/typography';

type ButtonProps = Omit<AntButtonProps, 'size' | 'type'> & {
  size?: 'small' | 'middle';
  type?: 'default' | 'ghost';
};

const StyledButton = styled(AntdButton)`
  /* stylelint-disable no-duplicate-selectors */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;

  .arrow-down-icon {
    color: ${({ theme }) => theme.colors.textPlaceholder};
    margin-left: ${({ theme }) => theme.spacing[4]};
  }

  ${switchProp(prop('size', 'small'), {
    middle: css`
      ${FontXs};

      & {
        height: 28px;
      }

      &.ant-btn-icon-only {
        width: 28px;
      }
    `,
    small: css`
      ${FontXxs};

      & {
        height: 24px;
      }

      &.ant-btn-icon-only {
        width: 24px;
      }
    `,
  })};

  ${switchProp(prop('type', 'default'), {
    default: css`
      color: ${({ theme }) => theme.colors.textBody1};
      border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
      border-radius: ${({ theme }) => theme.borderRadius.s};
      padding: ${({ theme: { spacing } }) => `${spacing[4]} ${spacing[8]}`};

      :not(:disabled):not(:active):hover {
        background: ${({ theme }) => theme.colors.bgLight};
        color: ${({ theme }) => theme.colors.textBody1};
        border: 1px solid ${({ theme }) => theme.colors.outlineDefault};

        .arrow-down-icon {
          color: ${({ theme }) => theme.colors.textBody2};
        }
      }

      :not(:disabled):not(.ant-btn-disabled):active {
        background: ${({ theme }) => theme.colors.bgLight};
        color: ${({ theme }) => theme.colors.textBody1};
        border: 1px solid ${({ theme }) => theme.colors.outlineDefault};

        .arrow-down-icon {
          color: ${({ theme }) => theme.colors.textBody2};
        }
      }

      &[disabled] {
        opacity: 0.5;
        color: ${({ theme }) => theme.colors.textTitle1};
        background: ${({ theme }) => theme.colors.gray00};

        .filter-value {
          color: ${({ theme }) => theme.colors.textTitle1};
        }
      }

      &:not(:disabled):focus-visible {
        outline: 1px solid #e5e7ebcc;
        outline-offset: 2px;
      }
    `,

    ghost: css`
      padding: ${({ theme: { spacing } }) => `${spacing[2]} 0`};
      border-radius: ${({ theme }) => theme.borderRadius.xs};
      color: ${({ theme }) => theme.colors.textBody1};

      :hover,
      :active {
        .arrow-down-icon {
          color: ${({ theme }) => theme.colors.textBody2};
        }
      }

      :not(:disabled):focus-visible {
        outline: none;
      }

      &[disabled] {
        opacity: 0.5;
        color: ${({ theme }) => theme.colors.textTitle1};
        background: ${({ theme }) => theme.colors.gray00};

        .filter-value {
          color: ${({ theme }) => theme.colors.textTitle1};
        }
      }
    `,
  })};
` as ForwardRefExoticComponent<ButtonProps & RefAttributes<HTMLButtonElement>>;

const Value = styled.div.attrs({
  className: 'filter-value',
})`
  margin-left: ${({ theme }) => theme.spacing[4]};
`;

export const FilterDropdownButton = ({
  children,
  showArrow = true,
  ...rest
}: PropsWithChildren<ButtonProps & { showArrow?: boolean }>): ReactElement => {
  if (!children && !showArrow) {
    return <StyledButton {...rest} />;
  }

  return (
    <StyledButton {...rest}>
      {children}

      {showArrow && <ArrowDownIcon className="arrow-down-icon" />}
    </StyledButton>
  );
};

FilterDropdownButton.Value = Value;

export const FilterButton = styled(FilterDropdownButton)`
  ${switchProp(prop('type', 'default'), {
    default: css`
      border-style: dashed;

      :not(:disabled):not(:active):hover {
        border-style: dashed;
      }

      :not(:disabled):not(.ant-btn-disabled):active {
        border-style: dashed;
      }

      &.ant-btn-icon-only {
        padding: ${({ theme }) => theme.spacing[4]};
      }
    `,
  })};
`;
