/* eslint-disable @typescript-eslint/naming-convention */
import { type GridOptions } from 'ag-grid-community';
import { GRID_AUTO_COLUMN_ID } from 'components/ui/atomic-components/grid/constants';
import { checkIfInPrintMode } from 'data/boards';
import { PivotViewType } from 'data/page-template';
import { FrameType } from 'data/reports/types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { GridConfiguration } from 'utils/tree-table';
import { GridHeaderHeight } from '../../common/table/constants';

export const useGetGridConfigs = ({
  viewType,
  tableRowsExpanded,
}: {
  viewType?: PivotViewType;
  tableRowsExpanded: boolean;
}): GridOptions => {
  const isInPrintMode = checkIfInPrintMode();
  const intl = useIntl();

  const gridOptions: GridOptions = useMemo(
    () => ({
      ...GridConfiguration,
      ...{
        defaultColDef: {
          menuTabs: [],
          resizable: false,
          sortable: false,
          suppressMovable: true,
        },
        stopEditingWhenCellsLoseFocus: true,
        treeData: viewType !== PivotViewType.Table,
        groupHeaderHeight: GridHeaderHeight,
        headerHeight: GridHeaderHeight,
        groupDefaultExpanded: tableRowsExpanded || isInPrintMode ? -1 : 0,
        rowClassRules: {
          'compact-no-row-summary': ({ data }) =>
            viewType !== PivotViewType.Table && data.hideSummary,
          'alternate-row-color': (params) =>
            Boolean(params.node.rowIndex != null && params.node.rowIndex % 2 === 0),
          'grid--blank-row': (params) => params.data.viewNodeType === FrameType.Blank,
        },
        processCellForClipboard: ({ column, node, value }) => {
          const blankDimensionValueLabel = intl.formatMessage({ id: 'dimensions.blank' });

          if (column?.getColId() === GRID_AUTO_COLUMN_ID) {
            return node?.data.displayValue || blankDimensionValueLabel;
          }

          return value;
        },
      },
    }),
    [tableRowsExpanded, viewType, isInPrintMode, intl],
  );

  return gridOptions;
};
