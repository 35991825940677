import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { type MetricApplicableDimension } from 'data/dimension';
import { SCENARIO, type Scenario } from 'data/scenarios';
import { useEffect } from 'react';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { MetricsApi } from './api';
import { MetricStoreKeys } from './constants';

interface Props {
  metricName?: string;
  fetchDimensions?: boolean;
}

export const useMetricDefinition = ({ metricName = '', fetchDimensions = true }: Props) => {
  return useQuery({
    queryKey: ['metric-definition', metricName],
    queryFn: () => MetricsApi.getDefinition(metricName, fetchDimensions),
    enabled: Boolean(metricName),
  });
};

export const useMetrics = () => {
  return useQuery({
    queryKey: MetricStoreKeys.AllRecords(),
    queryFn: () => MetricsApi.getMetrics(),
  });
};

export const useMetricInfoQuery = (metrics: { displayName: string; name: string }[]) =>
  useQuery({
    queryKey: ['metrics-info', metrics.map((metric) => metric.name).join(',')],

    queryFn: () =>
      Promise.allSettled(metrics.map((metric) => MetricsApi.fetchInfo(metric.name))).then((data) =>
        data.map((d) => (d.status === 'fulfilled' ? d.value : {})),
      ),
  });

export const useGetMetricSpaces = (metricName?: string) => {
  const query = useQuery({
    queryKey: ['metric-dimensions', metricName],

    queryFn: () => {
      if (!metricName) {
        return {};
      }

      return MetricsApi.fetchSpaces(metricName);
    },

    enabled: !!metricName,
    staleTime: StaleTime.Shortest,
  });

  useEffect(() => {
    if (query.error) {
      defaultApiErrorHandler(query.error);
    }
  }, [query.error]);

  return query as UseQueryResult<
    { dimensions: MetricApplicableDimension[]; scenarios?: Scenario[] },
    unknown
  >;
};

export const useGetMetricDimensionValues = (metricName: string, dimensionName: string) => {
  const query = useQuery({
    queryKey: ['metric-dimension-values', metricName, dimensionName],

    queryFn: () => {
      if (!metricName || !dimensionName || dimensionName === SCENARIO) {
        return [];
      }

      return MetricsApi.fetchDimensionValues(metricName, dimensionName);
    },

    enabled: !!metricName && !!dimensionName,
    staleTime: StaleTime.Shortest,
  });

  useEffect(() => {
    if (query.error) {
      defaultApiErrorHandler(query.error);
    }
  }, [query.error]);

  return query;
};
