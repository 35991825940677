import { convertToSingleValueFilters } from 'components/ui/collaboration/grid/utils';
import { intersection } from 'lodash';

interface Props {
  defaultChartFilters?: Record<string, string[]>;
  boardDims?: Record<string, string[]>;
  chartFixedFilters?: Record<string, string[]>;
  chartFilterDimNames?: string[];
}

export const getCellApplicableFilters = ({
  boardDims = {},
  defaultChartFilters = {},
  chartFixedFilters = {},
  chartFilterDimNames = [],
}: Props): Record<string, string> => {
  const filters = { ...boardDims, ...defaultChartFilters };

  for (const [key, values] of Object.entries(chartFixedFilters)) {
    if (filters[key]) {
      filters[key] = intersection(values, filters[key]);
    } else {
      filters[key] = values;
    }
  }

  for (const [dimName, dimValue] of Object.entries(boardDims)) {
    if (!filters[dimName] && !chartFilterDimNames.includes(dimName)) {
      filters[dimName] = dimValue;
    }
  }

  return convertToSingleValueFilters(filters);
};
