import { useQuery } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ModelDimensionApi } from 'data/modelling/dimension/api';
import { useEffect } from 'react';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useSelectedColumnValues = (colName?: string) => {
  const query = useQuery({
    queryKey: ['selected-column-values', colName],

    queryFn: () => {
      if (!colName) {
        throw 'invalid request';
      }

      return ModelDimensionApi.getColumnValues(colName);
    },

    staleTime: StaleTime.Short,
    enabled: Boolean(colName),
  });

  useEffect(() => {
    if (query.error) {
      defaultApiErrorHandler(query.error);
    }
  }, [query.error]);

  return query;
};
