import { excelSupportedCurrencySymbols } from 'components/ui/atomic-components/grid/utils/data-format-excel-styles';
import { type Currency } from 'data/currencies';
import { type DataFormatType } from 'data/metrics';
import { DataFormattingType, NegativeValueType, ZeroFormatType } from 'utils/data-formatter/types';

export const generateNumberFormatClass = ({
  dataFormat,
  metricType,
  currency,
}: {
  dataFormat?: DataFormatType;
  metricType: DataFormattingType;
  currency: Currency;
}): string => {
  if (!dataFormat) {
    return '';
  }

  let numberFormatClass = `type-${metricType}`;

  if (
    metricType === DataFormattingType.Currency &&
    excelSupportedCurrencySymbols.includes(dataFormat?.currency || currency)
  ) {
    numberFormatClass += `~currency-${dataFormat?.currency || currency}`;
  }
  if (
    [DataFormattingType.Number, DataFormattingType.Currency].includes(metricType) &&
    dataFormat?.digitsAfterDecimal
  ) {
    numberFormatClass += `~decimals-${dataFormat.digitsAfterDecimal}`;
  }
  numberFormatClass += `~zero-${dataFormat?.zeroFormat || ZeroFormatType.Dash}`;
  numberFormatClass += `~negative-${dataFormat?.negativeValueFormat || NegativeValueType.Brackets}`;

  return numberFormatClass;
};
