export const getAllMentions = (message: string): string[] => {
  const mentionTagRegex = /<mention id='(.*?)'+>(.*?)<\/mention>/g;
  const mentions: string[] = [];

  const matches = message.matchAll(mentionTagRegex);

  for (const match of matches) {
    if (!mentions.includes(match[1])) {
      mentions.push(match[1]);
    }
  }

  return mentions;
};
