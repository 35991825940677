import { useBoardContext } from 'components/modules/boards-v2/custom-board/contexts';
import { useBoardQueryParams } from 'data/boards/hooks/use-board-query-params';
import { type DimensionQueryParamsObject } from 'data/dimension';

export const useBoardFilters = (): DimensionQueryParamsObject => {
  const [query] = useBoardQueryParams();
  const { board: boardData } = useBoardContext();

  const boardFilter = query?.f ?? boardData?.attributes?.dimMap;

  return { ...boardFilter };
};
