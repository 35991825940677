import { type ReactNode, useEffect, useRef, useState, type ReactElement } from 'react';
import styled from 'styled-components';
import { StyledMargins, type StyledMarginsProps } from 'styles/styled-atoms';

const Wrapper = styled.div<StyledMarginsProps>`
  ${StyledMargins};

  height: ${({ $mt }) => `calc(100% - ${$mt || 0}px)`};
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: ${({ theme }) => theme.colors.gray00};
  opacity: 0.5;
  z-index: ${({ theme }) => theme.zIndex[10]};
  cursor: wait;
`;

interface Props extends StyledMarginsProps {
  isFetching: boolean;
  children: (height: number) => ReactNode;
}

export const ChartWrapper = ({ $mt, isFetching, children }: Props): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const elementDom = ref.current;

    const observer = new ResizeObserver((entries) => {
      const { height } = entries[0].contentRect;

      setHeight(height);
    });

    if (elementDom) {
      observer.observe(elementDom);
    }

    return () => {
      elementDom && observer.unobserve(elementDom);
    };
  }, []);

  return (
    <Wrapper ref={ref} $mt={$mt}>
      {isFetching && <Overlay />}

      {children(height)}
    </Wrapper>
  );
};
