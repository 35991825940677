import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/ui/atomic-components';
import { type List } from 'data/modelling/lists';
import { DIM_GROUP_ID_PREFIX } from 'data/modelling/lists/constants';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { DimensionGroupApi } from '../api';
import { type DimensionGroup } from '../types';

export const useDimensionGroupMutations = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const addDimensionGroup = useMutation({
    mutationFn: (dimensionGroup: Partial<DimensionGroup>) =>
      DimensionGroupApi.createRecord(dimensionGroup),

    onSuccess: (dimGroup) => {
      if (dimGroup.uniquenessCheckFailed) {
        return;
      }

      queryClient.setQueryData(['lists'], (existingLists?: (List | DimensionGroup)[]) => [
        ...(existingLists || []),
        dimGroup,
      ]);

      notification.success({
        message: intl.formatMessage({
          id: 'lists.toasts.create.success',
        }),
      });

      navigate(`/lists/${DIM_GROUP_ID_PREFIX}${dimGroup.id}`);
    },

    onError: defaultApiErrorHandler,
  });

  const updateDimensionGroup = useMutation({
    mutationFn: (dimensionGroup: Partial<DimensionGroup>) =>
      DimensionGroupApi.updateRecord(dimensionGroup),

    onSuccess: async (dimGroup: DimensionGroup) => {
      if (dimGroup.uniquenessCheckFailed) {
        return;
      }

      queryClient.invalidateQueries({
        queryKey: ['dimension-groups', dimGroup.id],
      });

      queryClient.setQueryData<(List | DimensionGroup)[]>(['lists'], (old) => {
        return (old || []).map((dim: List | DimensionGroup) => {
          if (dim?.id === dimGroup.id && (dim as DimensionGroup)?.uniqueKeyColumns) {
            return dimGroup as DimensionGroup;
          }

          return dim;
        });
      });

      notification.success({
        message: intl.formatMessage({
          id: 'lists.toasts.update_success',
        }),
      });

      navigate(`/lists/${DIM_GROUP_ID_PREFIX}${dimGroup.id}`);
    },

    onError: defaultApiErrorHandler,
  });

  const deleteDimensionGroup = useMutation({
    mutationFn: (dimensionGroup: DimensionGroup) =>
      DimensionGroupApi.deleteRecord(dimensionGroup.id),

    onSuccess: (_, dimensionGroup) => {
      queryClient.setQueryData(['lists'], (existingLists?: (List | DimensionGroup)[]) =>
        existingLists?.filter((g) => {
          if (g.id === dimensionGroup.id && (g as DimensionGroup)?.uniqueKeyColumns) {
            return false;
          }

          return true;
        }),
      );

      queryClient.removeQueries({
        queryKey: ['dimension-groups', dimensionGroup.id],
      });

      notification.success({
        message: intl.formatMessage({
          id: 'lists.toasts.delete_success',
        }),
      });

      navigate('/lists');
    },

    onError: defaultApiErrorHandler,
  });

  return {
    addDimensionGroup,
    updateDimensionGroup,
    deleteDimensionGroup,
  };
};
