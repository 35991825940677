import axios from 'axios';
import { type ID } from 'data';
import {
  type BigQueryResponse,
  type BigQueryResponseSchema,
  type BigQueryTableSchema,
} from 'data/big-query';
import { type AlertSeverityType, type MetricAlert } from 'data/metric-alerts/types';
import { type ModelDimension } from 'data/modelling/dimension';
import { type DatasetPreviewPayload } from 'data/transformations/hooks/use-fetch-dataset-preview';
import { stringifyUrl } from 'query-string';
import { downloadFromUrl } from 'utils';
import {
  type ConnectArgs,
  type DataEntityType,
  type DataSetTableSelection,
  type GProfileDetails,
  type GSheetRangeInfo,
  type IntegrationConnectedItem,
  type IntegrationConnectionItem,
  type IntegrationDataSetListItem,
} from './types';

export const IntegrationsApi = {
  getAllConnections: (): Promise<IntegrationConnectionItem[]> =>
    axios.get('v1/integrations/connections').then(({ data }) => {
      return data.data;
    }),

  datasetsList: (): Promise<IntegrationDataSetListItem[]> =>
    axios.get('v1/integrations/tables').then(({ data }) => data.data),

  getConnectionTables: (id: ID, entityType?: DataEntityType): Promise<DataSetTableSelection> =>
    axios
      .get(`v1/integrations/connection-instances/${id}/tables`, {
        params: {
          entityType,
        },
      })
      .then(({ data }) => data.data),

  updateConnectionTables: (id: ID, updatedSelection: DataSetTableSelection): Promise<void> =>
    axios
      .patch(`v1/integrations/connection-instances/${id}/tables`, updatedSelection)
      .then(({ data }) => data.data),

  reAuthorize: (
    id: ID,
    code: string,
    redirectUrl: string,
    realmId?: string | null,
  ): Promise<{ data: { id: ID } }> =>
    axios
      .patch(`v1/integrations/connection-instances/${id}/re-authorize`, {
        code,
        redirectUrl,
        realmId,
      })
      .then(({ data }) => data),

  getTableData: (
    instanceId: ID,
    tableName: string,
  ): Promise<BigQueryResponse<Record<string, string>>> =>
    axios
      .get(`v1/integrations/connection-instances/${instanceId}/tables/${tableName}`)
      .then(({ data }) => data),

  updateTable: (
    instanceId: ID,
    tableName: string,
    payload?: Partial<BigQueryResponse<Record<string, string>>>,
  ): Promise<void> =>
    axios.patch(`v1/integrations/connection-instances/${instanceId}/tables/${tableName}`, payload),

  updateTableDescription: (
    instanceId: ID,
    tableName: string,
    description?: string,
  ): Promise<void> =>
    axios.patch(`v1/integrations/connection-instances/${instanceId}/tables/${tableName}`, {
      description,
    }),
  deleteTable: (instanceId: ID, tableName: string): Promise<void> =>
    axios
      .delete(`v1/integrations/connection-instances/${instanceId}/tables/${tableName}`)
      .then(({ data }) => data),

  connectIntegration: (args: ConnectArgs): Promise<{ data: { id: ID } }> =>
    axios
      .post(`v1/integrations/connections/${args.service}/connect`, {
        ...args,
      })
      .then(({ data }) => data),

  getConnectedIntegrations: async (deb?: string | null): Promise<IntegrationConnectedItem[]> => {
    const apiPath = stringifyUrl({
      url: 'v1/integrations/connection-instances',
      query: {
        deb,
      },
    });

    return await axios.get(apiPath).then(({ data }) => data.data);
  },

  deleteConnectedIntegration: (id: ID): Promise<void> =>
    axios.delete(`v1/integrations/connection-instances/${id}`).then(({ data }) => data),

  resyncConnectedIntegration: (id: ID): Promise<void> =>
    axios.post(`v1/integrations/connection-instances/${id}/sync`).then(({ data }) => data),

  resyncConnected: ({
    connectionInstanceIds,
    syncAll,
  }: {
    connectionInstanceIds: ID[];
    syncAll: boolean;
  }): Promise<void> =>
    axios
      .post('v1/integrations/connection-instances/sync', { connectionInstanceIds, syncAll })
      .then(({ data }) => data),

  stopSyncConnected: (): Promise<void> =>
    axios.post('v1/integrations/connection-instances/sync/stop'),

  makeConnection: (id: ID): Promise<IntegrationConnectedItem> =>
    axios
      .post(`v1/integrations/connections/${id}/connection-instances`)
      .then(({ data }) => data.data),

  OAuthIntegration: ({
    code,
    redirectUrl,
    instanceId,
  }: {
    code: string;
    redirectUrl: string;
    instanceId: ID;
  }): Promise<GProfileDetails> =>
    axios
      .post(`v1/integrations/connection-instances/${instanceId}/oauth`, { code, redirectUrl })
      .then(({ data }) => data),

  getSheetIDRanges: ({
    instanceId,
    sheetId,
  }: {
    instanceId: ID;
    sheetId: string;
  }): Promise<GSheetRangeInfo> =>
    axios
      .get(`v1/integrations/sheets/${sheetId}?instanceId=${instanceId}`)
      .then(({ data }) => data),

  connectSheetIntegration: ({
    instanceId,
    googleSheetId,
    googleSourceSheetId,
    formatType,
  }: {
    instanceId: ID;
    googleSheetId: string;
    googleSourceSheetId?: string;
    formatType?: string;
  }): Promise<void> =>
    axios
      .post(`v1/integrations/connection-instances/${instanceId}/connect`, {
        googleSheetId,
        googleSourceSheetId,
        formatType,
      })
      .then(({ data }) => data),

  connectOneDriveIntegration: ({
    instanceId,
    sourceDocumentId,
    formatType,
  }: {
    instanceId: ID;
    sourceDocumentId: string;
    formatType?: string;
  }): Promise<void> =>
    axios
      .post(`v1/integrations/connection-instances/${instanceId}/connect`, {
        sourceDocumentId,
        formatType,
      })
      .then(({ data }) => data),

  contactUs: (id: number): Promise<void> =>
    axios.post(`v1/integrations/connections/${id}/contact-us`).then(({ data }) => data),

  getInstanceSchema: (id: number): Promise<BigQueryResponseSchema> =>
    axios.get(`v1/integrations/connection-instances/${id}/schema`).then(({ data }) => data),

  getTableSchema: (tableName: string): Promise<BigQueryTableSchema> =>
    axios.get(`v1/datasets/${tableName}/schema`).then(({ data }) => data),

  getTableMeta: (tableName: string): Promise<IntegrationDataSetListItem> =>
    axios.get(`v1/integrations/tables/${tableName}`).then(({ data }) => data),

  getColumnValues: ({
    tableName,
    columnName,
  }: {
    tableName: string;
    columnName: string;
  }): Promise<{ [key: string]: ModelDimension }> =>
    axios.get(`v1/datasets/${tableName}/${columnName}/values`).then(({ data }) => data),

  downloadCsv: ({
    tableName,
    fileName,
    payload,
  }: {
    tableName: string;
    fileName: string;
    payload: DatasetPreviewPayload;
  }): Promise<void> =>
    axios
      .post(`v1/datasets/${tableName}/download`, payload)
      .then(async (response: { data: { url: string } }) => {
        downloadFromUrl({ url: response.data.url, fileName: `${fileName}.csv` });
      }),

  linkUnderlyingData: ({
    link,
    id,
    tableName,
  }: {
    id: string;
    tableName: string;
    link: string;
  }): Promise<void> =>
    axios.post(`v1/integrations/connection-instances/${id}/tables/${tableName}/underlying-data`, {
      link,
    }),

  getTableAlert: (tableName: string): Promise<MetricAlert> =>
    axios.get(`v1/integrations/datasets/${tableName}/alerts`).then(({ data }) => data),

  updateTableAlert: (
    tableName: string,
    request: {
      recipients: string[];
      thresholdValue: number;
      condition: string;
      severity: AlertSeverityType;
    },
  ): Promise<void> => axios.patch(`v1/integrations/datasets/${tableName}/alerts`, request),
};
