import { type Rule } from 'antd/es/form';
import { Form, Input } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { type IntegrationInputForm } from '../../../types';

interface Props {
  input: IntegrationInputForm;
}

export const FormInput = ({ input }: Props): ReactElement => {
  const rules: Rule[] = [];

  if (!input.optional) {
    rules.push({ required: true });
  }
  if (input.pattern) {
    // @ts-expect-error - This is will always be a RegExp for non generic input flows
    rules.push({ pattern: input.pattern });
  }

  return (
    <Form.Item label={input.label} name={input.name} rules={rules} validateTrigger="onBlur">
      <Input placeholder={input.placeholder} />
    </Form.Item>
  );
};
