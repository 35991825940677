import { type UnderlyingDataRequest, type SortOrder } from 'data/big-query';
import { type Dispatch, type SetStateAction, createContext } from 'react';

export interface UnderlyingDataContextParams extends UnderlyingDataRequest {
  columnFilters: Record<string, string[]>;
  setColumnFilters: Dispatch<SetStateAction<Record<string, string[]>>>;
  setSortOrder: Dispatch<SetStateAction<SortOrder | undefined>>;
  setSortedColumn: Dispatch<SetStateAction<string | undefined>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const UnderlyingDataContext = createContext<UnderlyingDataContextParams>(
  {} as UnderlyingDataContextParams,
);
