import { VersionAccessModes } from 'components/modules/modelling/model/version-view-banner/constants';
import { isInVersionMode } from 'components/modules/modelling/model/version-view-banner/utils';
import { SearchBox } from 'components/ui/atomic-components/searchbox';
import { KEYBOARD_DEBOUNCE_INTERVAL } from 'config/constants';
import { AccessAction } from 'data/roles/permissions';
import { debounce } from 'lodash';
import { useRef, type ReactElement } from 'react';
import { useListStoreContext, useListStoreInstance } from 'store/lists';
import { hasListAccess } from 'utils/rbac/has-list-access';
import { GridTopBar } from '../../grid-top-bar';
import { getFilteredRows } from '../grid/hooks/use-grid-row-data/utils';
import { AccessVisibilityInfo } from './access-visibility';
import { MoreActions } from './more-actions';
import { SensitiveInfoWarning } from './sensitive-info-warning';
import { UploadCSV } from './upload-csv';

export const SecondaryTopBar = ({
  reconciliationEnabled,
}: {
  reconciliationEnabled: boolean;
}): ReactElement => {
  const listDisplayName = useListStoreContext((s) => s.displayName);
  const accessAction = useListStoreContext((s) => s.accessAction);
  const { selectiveAccessColumns, backedByBQ } = useListStoreContext((s) => s.config);
  const setSearchQuery = useListStoreContext((s) => s.setSearchQuery);
  const store = useListStoreInstance();

  const isReadOnlyTopBar =
    accessAction !== AccessAction.FullAccess ||
    isInVersionMode([VersionAccessModes.View, VersionAccessModes.ScopedEdit]);

  const showUploadCsv =
    selectiveAccessColumns?.length === 0 &&
    hasListAccess(accessAction, false, backedByBQ).uploadCsv &&
    !isReadOnlyTopBar;

  const handleSearchQueryChange = useRef(
    debounce((e) => {
      const { rows, columnFilters, config, setFilteredRows } = store.getState();

      setSearchQuery(e.target.value);
      setFilteredRows(
        getFilteredRows({
          rowData: rows,
          columnFilters,
          columnNames: config?.columnOrder || [],
          columnTypeMap: config?.columnTypeMap || {},
          searchQuery: e.target.value,
        }),
      );
    }, KEYBOARD_DEBOUNCE_INTERVAL),
  ).current;

  return (
    <GridTopBar
      isReadOnly={isReadOnlyTopBar}
      listName={listDisplayName || ''}
      reconciliationEnabled={reconciliationEnabled}
      rightItems={
        <>
          <AccessVisibilityInfo />

          <SensitiveInfoWarning />

          {showUploadCsv && <UploadCSV />}
          <SearchBox $inputType="ghost" onChange={handleSearchQueryChange} />

          <MoreActions />
        </>
      }
    />
  );
};
