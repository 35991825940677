import { useQuery } from '@tanstack/react-query';
import { RolesApi } from '../api';

export const useRoles = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['roles'],
    queryFn: () => RolesApi.getAllRoles(),
  });

  return {
    roles: data,
    isLoading,
  };
};
