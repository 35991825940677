import { type EditorView } from '@codemirror/view';
import { useQueryClient } from '@tanstack/react-query';
import { type ColDef } from 'ag-grid-community';
import { useForm } from 'antd/es/form/Form';
import { ReactComponent as PlusIcon } from 'assets/v2/plus.svg';
import { isAxiosError } from 'axios';
import { Button, Popover } from 'components/ui/atomic-components';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { Analytics } from 'config/analytics';
import { QueryTableColumnDataType } from 'data/big-query';
import { trimFormula } from 'data/formula-editor/utils';
import { useState, type ReactElement, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { queueMacroTask } from 'utils/queue-macro-task';
import { hasListAccess } from 'utils/rbac/has-list-access';
import { ColumnHeader } from '../cell-components/column-header';
import { ColumnEditor } from '../column-editor';
import { type ColumnType } from '../column-editor/column-type-select/utils';
import { DataColumnDefaultWidth } from '../hooks/use-grid-column-defs/utils/constants';
import { useColumnMutations } from '../hooks/use-grid-crud/use-column-mutations';
import { addListColumnInCache } from './utils';

const StyledButton = styled(Button)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 0;
  padding: ${({ theme: { spacing } }) => `${spacing[8]} 0 0`};
`;

const Wrapper = styled.div`
  height: 100%;
  width: 36px;
  border: 1px solid ${({ theme }) => theme.colors.outlineDefault};
`;

const RestrictedUserWrapper = styled.div`
  height: 100%;
  width: 1px;
  border-right: 1px solid ${({ theme }) => theme.colors.outlineDefault};
`;

interface Props {
  inferGridContainerWidth: () => void;
}

export const AddListColumn = ({ inferGridContainerWidth }: Props): ReactElement => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);
  const { onAddColumn } = useColumnMutations();
  const { mutateAsync: onAddNewColumn, isPending } = onAddColumn;

  const [form] = useForm<{
    name: string;
    type: ColumnType;
    formula?: {
      formulaText: string | undefined;
      query: string | undefined;
    };
    error?: string;
    aiEnabledFormula?: boolean;
  }>();

  const editorViewRef = useRef<EditorView | null>(null);
  const setEditorView = (editorView: EditorView | null) => (editorViewRef.current = editorView);

  const newColumnText = intl.formatMessage({ id: 'lists.property.name.placeholder' });

  const listId = useListStoreContext((s) => s.id);
  const addListColumnToStore = useListStoreContext((s) => s.addNewColumn);
  const gridApi = useListStoreContext((s) => s.gridApi);
  const isReadOnly = useListStoreContext((s) => s.isReadOnly);
  const isDerivedList = useListStoreContext((s) => s.isDerivedList);
  const embedPlace = useListStoreContext((s) => s.embedPlace);
  const accessAction = useListStoreContext((s) => s.accessAction);
  const config = useListStoreContext((s) => s.config);

  const onConfirm = () => {
    form.validateFields().then(async (data) => {
      const { type: typeOption, name, aiEnabledFormula } = data;

      const dateFormat =
        typeOption.key === QueryTableColumnDataType.Date ? typeOption.dateFormat : undefined;

      const requestBody = {
        columnName: name,
        type: typeOption.key,
        dateFormat,
      } as {
        columnName: string;
        type?: QueryTableColumnDataType;
        formula?: string;
        dateFormat?: string;
        query?: string;
      };

      if (typeOption.key === 'FORMULA') {
        const key = aiEnabledFormula ? 'query' : 'formula';

        const trimmedFormula = trimFormula(editorViewRef.current?.state?.doc?.toString() ?? '');

        requestBody[key] = trimmedFormula;
        delete requestBody.type;
      }

      try {
        const colAddResponse = await onAddNewColumn(requestBody);

        Analytics.track('Column added', {
          category: 'Lists',
          meta: `Column type - ${requestBody.type}`,
        });

        setIsOpen(false);

        if (typeOption.key === 'FORMULA') {
          queryClient.invalidateQueries({
            queryKey: ['lists', listId],
          });
        }

        addListColumnToStore({
          columnName: colAddResponse.name,
          type: typeOption.key as QueryTableColumnDataType,
          dateFormat,
        });

        addListColumnInCache({
          queryClient,
          listId,
          columnName: colAddResponse.name,
          type: typeOption.key as QueryTableColumnDataType,
          dateFormat,
        });

        queueMacroTask(() => {
          gridApi?.ensureColumnVisible(colAddResponse.name);
        });
      } catch (e) {
        if (isAxiosError(e)) {
          form.setFieldValue(
            'error',
            intl.formatMessage({
              id: aiEnabledFormula
                ? 'formula_bar.error.error_in_prompt'
                : 'formula_bar.error.error_in_formula',
            }),
          );
        }
      }
    });
  };

  const addTempColumn = () => {
    if (gridApi?.isDestroyed()) {
      return;
    }

    const currentColDefs = gridApi?.getColumnDefs();

    gridApi?.setGridOption('columnDefs', [
      ...(currentColDefs ?? []),
      {
        colId: 'temp-col',
        minWidth: 140,
        width: DataColumnDefaultWidth,
        editable: false,
        headerClass: 'temp-column',
        headerComponent: ColumnHeader,
        headerComponentParams: {
          displayName: newColumnText,
          hideOptions: true,
          columnType: QueryTableColumnDataType.String,
          isDataDerivedColumn: false,
        },
      },
    ]);

    inferGridContainerWidth();
    gridApi?.ensureColumnVisible('temp-col');
    setIsOpen(true);
  };

  const removeTempColDefs = () => {
    if (gridApi?.isDestroyed()) {
      return;
    }

    const currentColDefs = gridApi?.getColumnDefs();

    gridApi?.setGridOption(
      'columnDefs',
      (currentColDefs ?? []).filter((c: ColDef) => c.colId !== 'temp-col'),
    );
    queueMacroTask(() => inferGridContainerWidth());
  };

  if (
    embedPlace === 'report' ||
    !hasListAccess(accessAction, isReadOnly, config.backedByBQ).addColumn ||
    isDerivedList
  ) {
    return <RestrictedUserWrapper />;
  }

  return (
    <Wrapper>
      <Popover
        align={{ targetOffset: [DataColumnDefaultWidth, 0] }}
        cancelText={<FormattedMessage id="cancel" />}
        confirmLoading={isPending}
        confirmText={<FormattedMessage id="add" />}
        content={
          <ColumnEditor
            editorViewRef={editorViewRef}
            form={form}
            setEditorView={setEditorView}
            onConfirm={onConfirm}
          />
        }
        destroyTooltipOnHide
        open={isOpen}
        placement="leftTop"
        onCancel={() => {
          setIsOpen(false);
          removeTempColDefs();
        }}
        onConfirm={onConfirm}
      >
        <StyledButton disabled={isOpen} type="text" onClick={addTempColumn}>
          <IconShell color="primary500" icon={PlusIcon} />
        </StyledButton>
      </Popover>
    </Wrapper>
  );
};
