import { useMutation, useQueryClient } from '@tanstack/react-query';
import { KEYBOARD_DEBOUNCE_INTERVAL } from 'config/constants';
import { type ID } from 'data';
import {
  ListsApi,
  type ListUpdateRequest,
  type ListUpdate,
  ListUpdateActionType,
} from 'data/modelling/lists';
import { debounce } from 'lodash';
import { useRef } from 'react';
import { useListStoreContext } from 'store/lists';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useListUpdateActionMutation = () => {
  const listId = useListStoreContext((s) => s.id);

  const queryClient = useQueryClient();

  const queryKey = ['list-updates', listId];

  const refetchLists = useRef(
    debounce(() => {
      queryClient.invalidateQueries({
        queryKey: ['lists', listId],
      });
    }, KEYBOARD_DEBOUNCE_INTERVAL),
  ).current;

  return useMutation({
    mutationFn: ({
      isGroupUpdate,
      updateId,
      request,
    }: {
      isGroupUpdate: boolean;
      updateId: ID;
      request: ListUpdateRequest;
    }) => {
      return isGroupUpdate
        ? ListsApi.takeActionOnGroupListUpdate(listId, request)
        : ListsApi.takeActionOnListUpdate(listId, updateId, request);
    },

    onMutate: async ({ updateId }) => {
      await queryClient.cancelQueries({ queryKey });

      const previousUpdates = queryClient.getQueryData(queryKey);

      queryClient.setQueryData<ListUpdate[]>(queryKey, (state?: ListUpdate[]) => {
        return state?.filter((update) => update.id !== updateId);
      });

      return { previousUpdates };
    },

    onSuccess: (_, { request }) => {
      if (request.action === ListUpdateActionType.Accept) {
        refetchLists();
      }
    },

    onError: (e, _, context) => {
      defaultApiErrorHandler(e);

      queryClient.setQueryData(queryKey, context?.previousUpdates);
    },
  });
};
