import { type RowDragEndEvent } from 'ag-grid-community';
import { useRowMutations } from 'components/modules/modelling/lists/hooks/use-row-mutations';
import { type RowReorderRequest } from 'data/modelling/lists';
import { isEmpty } from 'lodash';
import { useRef } from 'react';
import { useListStoreInstance } from 'store/lists';
import { queueMacroTask } from 'utils/queue-macro-task';
import { type ListGridRow } from '../../types';
import { getRowBackendIds } from './utils';

export const useReorderRow = (): { onRowDragEnd: (params: RowDragEndEvent) => Promise<void> } => {
  const listStore = useListStoreInstance();
  const { reorderRowMutation } = useRowMutations();

  const queueRef = useRef<RowReorderRequest[]>([]);

  const fireRequestOneByOne = async () => {
    const queue = queueRef.current;

    if (isEmpty(queue) || reorderRowMutation.isPending) {
      return;
    }

    const requestToFire = queue[0];

    queueRef.current = queue.slice(1);

    await reorderRowMutation.mutateAsync({
      id: listStore.getState().id,
      reorderRequest: requestToFire,
    });

    fireRequestOneByOne();
  };

  const getTargetRowId = (overIndex: number, updatedRows: ListGridRow[]) => {
    if (overIndex === 0) {
      return null;
    }

    let index = overIndex - 1;
    let targetRowId;

    // if row has no backendId pick row before it
    while (index >= 0 && updatedRows[index]) {
      if (updatedRows[index].backendId) {
        targetRowId = updatedRows[index].backendId;
        break;
      }
      index -= 1;
    }

    return targetRowId || null;
  };

  const onRowDragEnd = async ({ overIndex, node, api }: RowDragEndEvent): Promise<void> => {
    const { selectedRowIds, rows, updateRows, setFilteredRows, setSelectedRowIds } =
      listStore.getState();

    const updatedRows: ListGridRow[] = [];

    api?.forEachNode((node) => updatedRows.push(node.data));

    const targetRowId = getTargetRowId(overIndex, updatedRows);

    const movedRows = !isEmpty(selectedRowIds)
      ? getRowBackendIds(selectedRowIds, rows)
      : [node.data.backendId];

    // When a group of rows are dragged and placed in their original position, skip making an api call
    if (movedRows.includes(targetRowId)) {
      return;
    }

    const reorderRequest = { targetRowId, movedRows };

    queueRef.current.push(reorderRequest);

    setFilteredRows(updatedRows);
    movedRows.forEach((id) => {
      const removeIndex = rows.findIndex((row) => row.id === id);
      const rowEl = rows.splice(removeIndex, 1)[0];
      const addIndex = rows.findIndex((row) => row.id === targetRowId);

      rows.splice(addIndex + 1, 0, rowEl);
    });
    updateRows(rows);

    queueMacroTask(() => api.refreshCells({ columns: ['row-index'] })); // to update row number column

    setSelectedRowIds([]);
    api?.deselectAll();

    fireRequestOneByOne();
  };

  return { onRowDragEnd };
};
