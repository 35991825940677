import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'components/ui/atomic-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { b } from 'styles/html-elements';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { ModelVersionApi } from '../../../api';
import { type ModelVersion } from '../../../types';
import { ModelVersionStoreKeys } from '../../constants';

export const useArchiveVersionMutation = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const archiveModelVersion = useMutation({
    mutationFn: (version: ModelVersion) => ModelVersionApi.archiveVersion(version.id),

    onSuccess: (data) => {
      // BE sends updated order with archived versions at the bottom. Hence need to invalidate and refetch.
      queryClient.invalidateQueries({ queryKey: ModelVersionStoreKeys.findVersionsWithArchived() });
      queryClient.invalidateQueries({
        queryKey: ModelVersionStoreKeys.findVersionsWithCurrentActual(),
      });

      notification.success({
        message: intl.formatMessage({
          id: 'model.versions.archive.success.title',
        }),
        description: (
          <FormattedMessage
            id="model.versions.archive.success.desc"
            values={{ value: data.displayName, b }}
          />
        ),
      });
    },

    onError: defaultApiErrorHandler,
  });

  return { archiveModelVersion };
};
