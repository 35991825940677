import { useMutation } from '@tanstack/react-query';
import { notification } from 'components/ui/atomic-components';
import { useIntl } from 'react-intl';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { ListsApi } from '../api';

export const useListTriggerDatasetBuildMutation = () => {
  const intl = useIntl();

  return useMutation({
    mutationFn: ({ listId }: { listId: number }) => ListsApi.triggerDatasetBuild(listId),
    onSuccess: () => {
      notification.success({
        message: intl.formatMessage({ id: 'lists.trigger_dataset_build.success' }),
      });
    },
    onError: defaultApiErrorHandler,
  });
};
