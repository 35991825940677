import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotificationActions } from 'store/notifications';
import { NotificationsApi } from '../api';

export const useDeleteAll = () => {
  const queryClient = useQueryClient();
  const { setNotificationsCount } = useNotificationActions();

  return useMutation({
    mutationFn: async () => {
      await NotificationsApi.deleteAll();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications'],
      });
      setNotificationsCount(undefined);
    },
  });
};
