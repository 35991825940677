import { ReactComponent as InboxIcon } from 'assets/v2/inbox.svg';
import { ReactComponent as DeleteIcon } from 'assets/v2/trash.svg';
import { IconShell, type ItemType, MoreActionsDropdown } from 'components/ui/atomic-components';
import { useDeleteAll } from 'data/notifications/hooks/use-delete-notification';
import { useMarkAsRead } from 'data/notifications/hooks/use-mark-as-read-notification';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const ItemWrapper = styled.div`
  width: 200px;
`;

export const NotificationsMoreActions = () => {
  const { mutate: markAllAsRead } = useMarkAsRead();
  const { mutate: deleteAllMutation } = useDeleteAll();

  const items: ItemType[] = [
    {
      key: 'mark-all-as-read',
      icon: <IconShell icon={InboxIcon} />,
      title: '',
      label: (
        <ItemWrapper>
          <FormattedMessage id="notification.more_action.mark_all_as_read" />
        </ItemWrapper>
      ),
      onClick: () => {
        markAllAsRead(undefined);
      },
    },
    {
      key: 'divider',
      type: 'divider',
    },
    {
      key: 'delete-all',
      icon: <IconShell icon={DeleteIcon} />,
      title: '',
      label: (
        <ItemWrapper>
          <FormattedMessage id="notification.more_action.delete_all" />
        </ItemWrapper>
      ),
      onClick: () => {
        deleteAllMutation();
      },
    },
  ];

  return <MoreActionsDropdown menu={{ items }} size="small" />;
};
