import { type ReactNode } from 'react';
import styled from 'styled-components';

const MediumB = styled.b`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const b = ((text: string): ReactNode => <MediumB>{text}</MediumB>) as unknown as ReactNode;

export const br = ((): ReactNode => <br />) as unknown as ReactNode;
