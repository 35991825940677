import { useQuery, useQueryClient } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { FoldersApi } from '../api';
import { StoreKeys } from '../constants';
import { type FolderType } from '../types';

export const useHierarchialResourcesByType = (folderType: FolderType) => {
  const query = useQuery({
    queryKey: StoreKeys.SingleRecordByType(folderType),
    queryFn: () => FoldersApi.findQuery(folderType),
    staleTime: StaleTime.Short,
  });

  return query;
};

export const useInvalidateHierarchialResourcesByType = (folderType: FolderType) => {
  const queryClient = useQueryClient();

  const invalidate = () => {
    return queryClient.invalidateQueries({ queryKey: StoreKeys.SingleRecordByType(folderType) });
  };

  return { invalidate };
};
