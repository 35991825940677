import { IntegrationProvider } from '../types';

export const NonEditableIntegration = [
  IntegrationProvider.DrivetrainDatasets,
  IntegrationProvider.DrivetrainLists,
];

export const ConnectorsToNotShowAddData = [
  IntegrationProvider.Quickbooks,
  IntegrationProvider.Xero,
];

export const DatasetNamePrefix = 'datasets.';
