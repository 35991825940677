import { isAxiosError, type AxiosError } from 'axios';
import { notification } from 'components/ui/atomic-components/notification';

export type DetailedAxiosError = AxiosError<{ message?: string }>;

export const isDetailedAxiosError = (error: unknown): error is DetailedAxiosError => {
  return isAxiosError(error) && error.response?.data !== undefined;
};

export const getApiError = (errorResp: DetailedAxiosError | unknown): string => {
  let message = 'Something unexpected happened. Please try again later.';

  if (isAxiosError(errorResp)) {
    if (errorResp?.response?.data?.message) {
      message = errorResp?.response?.data?.message;
    } else if (errorResp?.response?.status === 404) {
      message = 'Resource not found';
    }
  }

  return message;
};

export const defaultApiErrorHandler = (errorResp: DetailedAxiosError | unknown): void => {
  notification.error({
    message: getApiError(errorResp),
  });
};
