import { useQuery } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ModelVersionApi } from '../api';
import { ModelVersionStoreKeys } from './constants';

export const useModelVersion = ({
  includeCurrent = false,
  includeActual = false,
  includeArchived = false,
}: {
  includeCurrent?: boolean;
  includeActual?: boolean;
  includeArchived?: boolean;
} = {}) => {
  return useQuery({
    queryKey: [...ModelVersionStoreKeys.QueryKey, includeCurrent, includeActual, includeArchived],
    queryFn: () => ModelVersionApi.getVersions({ includeCurrent, includeActual, includeArchived }),
    staleTime: StaleTime.Long,
  });
};
