import { useQuery } from '@tanstack/react-query';
import { StringParam, useQueryParam } from 'use-query-params';
import { IntegrationsApi } from '../api';
import { IntegrationKeys } from '../constants';
import { IntegrationConnectedItemStatus } from '../types';

const SHORT_INTERVAL = 2 * 5000; //10 sec
const LONG_INTERVAL = 900 * 1000; //15 mins

export const useGetConnectedIntegrations = () => {
  const [deb] = useQueryParam('deb', StringParam);

  return useQuery({
    queryKey: IntegrationKeys.ConnectedIntegrations,

    queryFn: async () => {
      const data = await IntegrationsApi.getConnectedIntegrations(deb);

      return data;
    },

    refetchInterval: (query) => {
      if (
        query.state.data &&
        query.state.data.every((item) => item.status === IntegrationConnectedItemStatus.Connected)
      ) {
        return LONG_INTERVAL;
      }

      return SHORT_INTERVAL;
    },
  });
};
