import { getChartMetricCurrency } from 'components/ui/chart-components-v2/chart-types/utils';
import { type Currency } from 'data/currencies';
import { type DataFormatType } from 'data/metrics';

export const getChartDataFormat = ({
  chartCurrency,
  chartLevelFormat,
  metricLevelFormat,
  tenantCurrency,
}: {
  chartLevelFormat?: DataFormatType;
  metricLevelFormat?: DataFormatType;
  chartCurrency?: Currency;
  tenantCurrency: Currency;
}): DataFormatType | undefined => {
  const chartMetricCurrency = getChartMetricCurrency({
    chartCurrency,
    chartDataFormatCurrency: chartLevelFormat?.currency,
    metricCurrency: metricLevelFormat?.currency,
    tenantCurrency,
  });

  return {
    ...(chartLevelFormat ?? metricLevelFormat),
    currency: chartMetricCurrency,
  };
};
