import { useArchiveVersionMutation } from './__mutations/use-archive-version.mutation';
import { useCreateVersionMutation } from './__mutations/use-create-version-mutation';
import { useDeleteVersionMutation } from './__mutations/use-delete-version.mutation';
import { usePublishVersionMutation } from './__mutations/use-publish-version-mutation';
import { useUnArchiveVersionMutation } from './__mutations/use-unarchive-version.mutation';
import { useUpdateVersionMutation } from './__mutations/use-update-version-mutation';

export const useModelVersionMutations = () => {
  const { createModelVersion } = useCreateVersionMutation();
  const { publishModelVersion } = usePublishVersionMutation();
  const { archiveModelVersion } = useArchiveVersionMutation();
  const { unArchiveModelVersion } = useUnArchiveVersionMutation();
  const { deleteModelVersion } = useDeleteVersionMutation();
  const { updateModelVersion } = useUpdateVersionMutation();

  return {
    archiveModelVersion,
    createModelVersion,
    deleteModelVersion,
    publishModelVersion,
    updateModelVersion,
    unArchiveModelVersion,
  };
};
