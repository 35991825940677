import { type ICellRendererParams } from 'ag-grid-community';
import { Button } from 'components/ui/atomic-components';
import { DateTimeFormats } from 'config/constants';
import { useCurrency } from 'data/currencies/hooks/use-currency';
import { type TraceOutputTerm } from 'data/formula-trace/types';
import dayjs from 'dayjs';
import { useMemo, type ReactElement } from 'react';
import { numberFormatter } from 'utils/data-formatter';
import {
  BlankValueType,
  DataFormattingType,
  DisplayUnitType,
  NegativeValueType,
  ZeroFormatType,
} from 'utils/data-formatter/types';
import { useFormulaTraceStore } from '../../store';
import { determineType } from '../../utils/determine-value-type';

export const ComponentValue = ({ value, data }: ICellRendererParams): ReactElement => {
  const currency = useCurrency();
  const traceNode = useFormulaTraceStore((state) => state.traceNode);
  const setTraceNode = useFormulaTraceStore((state) => state.setTraceNode);

  const formattedValue = useMemo(() => {
    const valueType = determineType(value);

    if (valueType === 'number') {
      return numberFormatter({
        value,
        type: data.term?.dataFormat?.type || DataFormattingType.Number,
        digitsAfterDecimal: data.term?.dataFormat?.digits_after_decimal || 0,
        currency: data.term?.dataFormat?.currency || currency,
        blankValueFormat: data.term?.dataFormat?.blank_value_format || BlankValueType.Zero,
        displayUnit: data.term?.dataFormat?.display_unit || DisplayUnitType.Full,
        negativeValueFormat: data.term?.dataFormat?.negative_value_format || NegativeValueType.Sign,
        zeroFormat: data.term?.dataFormat?.zero_format || ZeroFormatType.Number,
      });
    } else if (valueType === 'date') {
      return dayjs(value).format(DateTimeFormats.HumanReadableDate);
    }

    return value;
  }, [currency, data.term?.dataFormat, value]);

  return (
    <Button
      type="link"
      onClick={() => {
        const term = data.term as TraceOutputTerm;

        if (term && traceNode?.payload) {
          setTraceNode({
            payload: traceNode?.payload,
            term,
            previousNode: traceNode,
            isLoading: false,
          });
        }
      }}
    >
      {formattedValue}
    </Button>
  );
};
