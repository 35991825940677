import { ReactComponent as FilterIcon } from 'assets/v2/filter.svg';
import { Button, IconShell, Popover } from 'components/ui/atomic-components';
import { useUnderlyingDataContext } from 'components/ui/breakup-query/context/use-underlying-data-context';
import { type QueryTableColumnDataType } from 'data/big-query';
import { isEmpty } from 'lodash';
import { type ReactElement, useState } from 'react';
import { Content } from './content';

export const Filter = ({
  colName,
  type,
}: {
  colName: string;
  type: QueryTableColumnDataType;
}): ReactElement => {
  const { columnFilters, setColumnFilters, setCurrentPage } = useUnderlyingDataContext();

  const [open, setOpen] = useState(false);

  const filterPresentForColumn = !!columnFilters?.[colName];

  const onApply = (selectedFilterValues: string[]) => {
    const updatedFilter = {
      ...columnFilters,
    };

    if (!isEmpty(selectedFilterValues)) {
      updatedFilter[colName] = selectedFilterValues;
    } else {
      delete updatedFilter[colName];
    }

    setColumnFilters(updatedFilter);
    setCurrentPage(1);

    setOpen(false);
  };

  return (
    <Popover
      content={<Content colName={colName} type={type} onApply={onApply} />}
      destroyTooltipOnHide
      open={open}
      placement="bottomRight"
      onOpenChange={(v) => setOpen(v)}
    >
      <Button
        className={filterPresentForColumn ? 'underlying-data-column-filter' : ''}
        icon={
          <IconShell
            color="iconDefault"
            fill={filterPresentForColumn ? 'iconDefault' : undefined}
            icon={FilterIcon}
            size="sm"
          />
        }
        size="extraSmall"
        type="text"
      />
    </Popover>
  );
};
