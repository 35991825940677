import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Analytics } from 'config/analytics';
import { ID } from 'data';
import { type IntegrationConnectionItem, IntegrationsApi } from 'data/integrations/fivetran';
import { IntegrationKeys } from 'data/integrations/fivetran/constants';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { getCallbackUrl } from '../../../../../common/util';

export const useCommonLoginFlow = (item: IntegrationConnectionItem) => {
  const navigate = useNavigate();

  const { instanceId: currentInstanceIdInUrl } = useParams() as { instanceId: string };

  const [queryParams] = useQueryParams({
    code: StringParam,
    realmId: StringParam,
    region: StringParam,
    codeVerifier: StringParam,
    reAuth: StringParam,
  });
  const queryClient = useQueryClient();

  const {
    mutate: connectService,
    isPending,
    isError,
  } = useMutation({
    mutationFn: ({
      code,
      realmId,
      region,
      codeVerifier,
      reAuth,
    }: {
      code: string;
      realmId?: string | null;
      region?: string | null;
      codeVerifier?: string | null;
      reAuth?: string | null;
    }) => {
      if (reAuth) {
        return IntegrationsApi.reAuthorize(
          ID(currentInstanceIdInUrl),
          code,
          getCallbackUrl(),
          realmId,
        );
      }

      return IntegrationsApi.connectIntegration({
        redirectUrl: getCallbackUrl(),
        service: item.id,
        code,
        realmId,
        region,
        codeVerifier,
      });
    },

    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: IntegrationKeys.ConnectedIntegrations,
      });
      navigate(`/data/connected/${data?.data?.id}?pending=add-data`);
    },

    onError: defaultApiErrorHandler,
  });

  const debouncedConnect = useRef(
    debounce(({ code, realmId, region, codeVerifier, reAuth }) => {
      connectService({ code, realmId, region, codeVerifier, reAuth });

      Analytics.track('New source', {
        category: 'Data',
        meta: `Source name - ${item.displayName}`,
      });
    }, 200),
  );

  useEffect(() => {
    const { code, realmId, region, codeVerifier, reAuth } = queryParams;

    if (item && code && !isError) {
      // react 18 useEffect runs twice, debounced only called once
      debouncedConnect.current({ code, realmId, region, codeVerifier, reAuth });
    }
  }, [connectService, item, queryParams, isError]);

  return {
    isLoading: isPending,
  };
};
