import { Avatar, Button, Popover } from 'components/ui/atomic-components';
import { useState, type ReactElement } from 'react';
import styled from 'styled-components';
import { getUserFullName } from 'utils';
import { useUser } from 'utils/hooks';
import { LeftNavWrapper } from '../left-nav-item';
import { Content } from './content';

const Wrapper = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserProfileButton = (): ReactElement => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { user } = useUser();

  return (
    <Popover
      content={<Content close={() => setIsPopoverOpen(false)} />}
      open={isPopoverOpen}
      placement="rightBottom"
      trigger={['hover']}
      onOpenChange={setIsPopoverOpen}
    >
      <LeftNavWrapper>
        <Wrapper>
          <Button
            icon={<Avatar name={getUserFullName(user)} size="large" tooltip="" />}
            type="text"
          />
        </Wrapper>
      </LeftNavWrapper>
    </Popover>
  );
};
