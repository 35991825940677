import { UsersApi, useUsers } from 'data/users';
import Cookies from 'js-cookie';

export const VIEW_AS_USER = 'view-as-user';

export const useViewAsUser = (): {
  userFullName?: string;
  exitViewAsUserMode: () => void;
  username?: string;
} => {
  const viewAsUserEmail = localStorage.getItem(VIEW_AS_USER) ?? '';
  const { data: allUsers = [] } = useUsers();
  const user = allUsers.find((u) => u.username === viewAsUserEmail);
  const name = user ? `${user.firstName} ${user.lastName}` : viewAsUserEmail;

  const exitViewAsUserMode = async () => {
    await UsersApi.exitViewAsUser();
    Cookies.remove(VIEW_AS_USER, { path: '/', domain: `.${window.location.hostname}` });
    localStorage.removeItem(VIEW_AS_USER);
    window.location.reload();
  };

  return {
    userFullName: name,
    exitViewAsUserMode,
    username: viewAsUserEmail,
  };
};
