import { useMutation } from '@tanstack/react-query';
import { notification } from 'components/ui/atomic-components';
import { ListsApi, type RowReorderRequest } from 'data/modelling/lists';
import { useIntl } from 'react-intl';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useRowMutations = () => {
  const intl = useIntl();

  const reorderRowMutation = useMutation({
    mutationFn: ({ id, reorderRequest }: { id: number; reorderRequest: RowReorderRequest }) =>
      ListsApi.reorderRows(id, reorderRequest),

    onSuccess: () => {
      notification.success({
        message: intl.formatMessage({
          id: 'lists.toasts.rows.update_success',
        }),
      });
    },

    onError: defaultApiErrorHandler,
  });

  return {
    reorderRowMutation,
  };
};
