import { useQuery } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ModelDimensionApi } from 'data/modelling/dimension/api';

export const useDimensions = () => {
  const allDimensionsQuery = useQuery({
    queryKey: ['dimensions'],
    queryFn: () => ModelDimensionApi.findAll(),
    staleTime: StaleTime.Short,
  });

  return allDimensionsQuery;
};
