import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ListRow, ListsApi } from 'data/modelling/lists';
import { useListStoreContext } from 'store/lists';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { updateRowValuesInCacheOnAddRow } from './utils';

export const useCreateDeleteMutations = () => {
  const queryClient = useQueryClient();
  const activeListId = useListStoreContext((s) => s.id);

  const onCreateRow = useMutation({
    mutationFn: (rowToBeAdded?: Partial<ListRow>) => {
      return ListsApi.addRow(activeListId, rowToBeAdded);
    },

    onSuccess: (_, rowToBeAdded) => {
      updateRowValuesInCacheOnAddRow({ listId: activeListId, queryClient, rowToBeAdded });
    },

    onError: (err) => {
      defaultApiErrorHandler(err);
    },
  });

  const onUpdateRowEntry = useMutation({
    mutationFn: (listNode: Partial<ListRow>) => {
      return ListsApi.updateRow(activeListId, listNode);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['list-row-values', String(activeListId)],
      });
    },

    onError: (err) => {
      defaultApiErrorHandler(err);
    },
  });

  const onDeleteRow = useMutation({
    mutationFn: (rowIds: number[]) => ListsApi.deleteRow(activeListId, rowIds),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['list-row-values', String(activeListId)],
      });
    },

    onError: (err) => {
      defaultApiErrorHandler(err);
    },
  });

  return {
    onCreateRow,
    onUpdateRowEntry,
    onDeleteRow,
  };
};
