import { type ColDef, type ColGroupDef } from 'ag-grid-community';
import { useChartCurrency } from 'components/modules/boards-v2/custom-board/charts-grid/grid-item/hooks/use-chart-currency';
import { useBoardContext } from 'components/modules/boards-v2/custom-board/contexts';
import { type FormattedPeriod } from 'data';
import { type BreakupQueryDrawerParams, type BreakupMetric } from 'data/big-query';
import { useBoardQueryParams } from 'data/boards';
import { type Chart } from 'data/boards/charts/types';
import { type TableFormattingRuleStyle } from 'data/boards/charts/types/table';
import { useReportChartContext } from 'data/boards/context';
import { useBoardDateSelection } from 'data/boards/hooks/use-board-date-selection';
import { useCurrency } from 'data/currencies/hooks/use-currency';
import { type Metric } from 'data/metrics';
import { type Pivot } from 'data/page-template';
import { type FrameElement } from 'data/reports/types';
import { ACTUAL } from 'data/versions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty, type Dictionary } from 'lodash';
import { type MutableRefObject, useMemo, type Dispatch, type SetStateAction } from 'react';
import { useIntl } from 'react-intl';
import { useReportsStore } from 'store/reports';
import { useRefSync } from 'utils/hooks';
import { createViewColumnDefs } from '../create-view-column-defs';
import { filterOutActualVersionColumns } from '../utils/filter-version-in-col';
import { usePreserveColumnExpansion } from './use-preserve-column-expansion';

interface Props {
  columnFrameWithStatisticColumns: FrameElement[];
  pivot: Pivot;
  chart: Chart;
  periods: FormattedPeriod[];
  metricsMap: Dictionary<Metric>;
  styleMap: MutableRefObject<Record<string, TableFormattingRuleStyle>>;
  hideRowColumnMap?: Record<string, boolean>;
  metricNamesSupportingBreakup?: Record<string, BreakupMetric>;
  setBreakupQueryDrawerParams?: Dispatch<SetStateAction<BreakupQueryDrawerParams | undefined>>;
  setBreakupQueryDrawerVisible?: Dispatch<SetStateAction<boolean>>;
  repeatRowLabels: boolean;
  columnsOrder?: string[];
  scenarioNames?: string[];
}

export const useCreateColumnDefs = ({
  columnFrameWithStatisticColumns,
  pivot,
  chart,
  periods,
  metricsMap,
  styleMap,
  hideRowColumnMap,
  metricNamesSupportingBreakup,
  setBreakupQueryDrawerParams,
  setBreakupQueryDrawerVisible,
  repeatRowLabels,
  columnsOrder,
  scenarioNames,
}: Props): {
  colDefs: (ColDef | ColGroupDef)[];
} => {
  const intl = useIntl();
  const tenantCurrency = useCurrency();
  const chartCurrency = useChartCurrency();
  const { isGroupOpenByDefault } = usePreserveColumnExpansion(chart);

  const {
    incompletePeriodsShowForecast,
    allowReadOnlyUsersToSeeUnderlyingAndDrilldown,
    showFutureActualsTess: showFutureActuals,
  } = useFlags();

  const { board: boardData } = useBoardContext();
  const { isRenderedInAiChat } = useReportChartContext();
  const [query] = useBoardQueryParams();
  const boardPeriod = useBoardDateSelection();

  const boardFilters = useRefSync(query?.f || {});

  const actualsTillDate = boardData?.attributes?.actualsTillDate;
  const actualsTillDateOption = boardData?.attributes?.actualsTillDateOption;

  return useMemo(() => {
    const columnWidthPreference = {
      ...chart.attributes?.tableChartConfig?.columnWidthPreference,
      ...useReportsStore.getState().chartColumnWidthMap?.[chart.id],
    };

    const { tableHeaders, dataHeaders } = createViewColumnDefs({
      isGroupOpenByDefault,
      columns: columnFrameWithStatisticColumns,
      pivot,
      granularDimensions: chart.granularDimensions || [],
      intl,
      periodRanges: periods,
      metricsMap,
      tenantCurrency,
      chartCurrency,
      styleMap,
      hideRowColumnMap,
      actualsTillDate,
      actualsTillDateOption,
      chartDataFormat: chart.attributes?.dataFormat,
      chartMetricTypeMap: chart.attributes?.metricTypeMap,
      hideAggregatesFor: chart.attributes?.hideAggregatesFor || [],
      boardDims: {
        ...(boardData?.attributes?.dimMap ?? {}),
        ...(boardFilters.current ?? {}),
      },
      defaultChartFilters: {
        ...chart.attributes?.dimMap,
      },
      chartFixedFilters: {
        ...(chart.attributes?.fixedFilters ?? {}),
      },
      chartFilterDimNames: chart.attributes?.filteredDimensions ?? [],
      defaultStartDate: (chart.attributes?.startDate || boardPeriod.startDate) as string,
      defaultEndDate: (chart.attributes?.endDate || boardPeriod.endDate) as string,
      metricNamesSupportingBreakup,
      incompletePeriodsShowForecast,
      isReadOnlyUser: !boardData?.editable,
      tempDrilldownDisabled: !allowReadOnlyUsersToSeeUnderlyingAndDrilldown,
      showFutureActuals,
      columnWidthPreference,
      setBreakupQueryDrawerParams,
      setBreakupQueryDrawerVisible,
      repeatRowLabels,
      chartId: chart.id,
      isRenderedInAiChat,
      columnsOrder,
      scenarioNames,
    });

    const shouldFilterOutActualsCols =
      pivot.dimensions.versions?.length === 1 && pivot.dimensions.versions[0] === ACTUAL;
    const actualsFilteredOutColumns = shouldFilterOutActualsCols
      ? filterOutActualVersionColumns(dataHeaders || []).filter(
          (col) => col.columnGroupShow !== 'closed',
        )
      : dataHeaders;

    return {
      colDefs: [
        ...tableHeaders,
        ...(!isEmpty(actualsFilteredOutColumns) ? actualsFilteredOutColumns : dataHeaders),
      ],
    };
  }, [
    chart.attributes?.tableChartConfig?.columnWidthPreference,
    chart.attributes?.dataFormat,
    chart.attributes?.metricTypeMap,
    chart.attributes?.hideAggregatesFor,
    chart.attributes?.dimMap,
    chart.attributes?.fixedFilters,
    chart.attributes?.filteredDimensions,
    chart.attributes?.startDate,
    chart.attributes?.endDate,
    chart.id,
    chart.granularDimensions,
    isGroupOpenByDefault,
    columnFrameWithStatisticColumns,
    pivot,
    intl,
    periods,
    metricsMap,
    tenantCurrency,
    chartCurrency,
    styleMap,
    hideRowColumnMap,
    actualsTillDate,
    actualsTillDateOption,
    boardData?.attributes?.dimMap,
    boardData?.editable,
    boardFilters,
    boardPeriod.startDate,
    boardPeriod.endDate,
    metricNamesSupportingBreakup,
    incompletePeriodsShowForecast,
    allowReadOnlyUsersToSeeUnderlyingAndDrilldown,
    showFutureActuals,
    setBreakupQueryDrawerParams,
    setBreakupQueryDrawerVisible,
    repeatRowLabels,
    isRenderedInAiChat,
    columnsOrder,
    scenarioNames,
  ]);
};
