import { useQuery } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { type List } from 'data/modelling/lists';
import { DimensionGroupApi } from '../api';
import { constructListObjFromDimGroup } from '../utils';

export const useDimensionGroup = (dimensionGroupId?: number) => {
  const dimensionGroupQuery = useQuery({
    queryKey: ['dimension-groups', dimensionGroupId],

    queryFn: () => {
      if (!dimensionGroupId) {
        return {} as List;
      }

      return DimensionGroupApi.findRecord(dimensionGroupId).then((data) => {
        return constructListObjFromDimGroup(data);
      });
    },

    staleTime: StaleTime.Shortest,
    enabled: !!dimensionGroupId,
  });

  return dimensionGroupQuery;
};
