import { ReactComponent as AiArrowIcon } from 'assets/v2/ai-arrow.svg';
import { IconShell } from 'components/ui/atomic-components/icon-shell';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState, type ReactElement } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { LeftNavWrapper } from '../left-nav-item';
import { ChatBox } from './chatbox';
import { type ChatOpenState } from './chatbox/types';

const Wrapper = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.div`
  padding: ${({ theme }) => theme.spacing[4]};
`;

export const AiChatBoxButton = (): ReactElement => {
  const { driveAiChatbox } = useFlags();

  const [chatOpenState, setChatOpenState] = useState<ChatOpenState>('closed');

  const onClick = () => {
    setChatOpenState((state) => {
      if (state === 'closed') {
        return 'open';
      } else if (state === 'minimised') {
        return 'open';
      }

      return 'closed';
    });
  };

  useHotkeys(['ctrl+k', 'meta+k'], () => setChatOpenState(() => 'open'), [setChatOpenState]);

  if (!driveAiChatbox) {
    return <></>;
  }

  return (
    <>
      <LeftNavWrapper onClick={onClick}>
        <Wrapper>
          <Icon>
            <IconShell icon={AiArrowIcon} />
          </Icon>
          <span>
            <FormattedMessage id="drive_ai.title" />
          </span>
        </Wrapper>
      </LeftNavWrapper>

      <ChatBox
        chatOpenState={chatOpenState}
        onClose={() => setChatOpenState('closed')}
        onMinimise={() => setChatOpenState('minimised')}
        onOpen={() => setChatOpenState('open')}
      />
    </>
  );
};
