import { ReactComponent as EditIcon } from 'assets/v2/edit.svg';
import { IconText, Modal } from 'components/ui/atomic-components';
import { Form } from 'components/ui/atomic-components/form';
import { useDimensionGroupMutations } from 'data/modelling/dimension-group/hooks/use-dimension-group-mutations';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { type Dispatch, type SetStateAction, type ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DimensionGroupCreateEditForm } from '../../../crud-components/dimension-group-create-edit-form';
import { type FormProps } from '../../../crud-components/dimension-group-create-edit-form/types';
import { constructPayload, setInitialValues } from './utils';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: ${({ theme }) => theme.spacing[16]};
    max-height: 600px;
    overflow: auto;
  }
`;

interface Props {
  dimensionGroup: DimensionGroup;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditDimensionGroupModal = ({ dimensionGroup, open, setOpen }: Props): ReactElement => {
  const { updateDimensionGroup } = useDimensionGroupMutations();

  const [initialFormValues, setInitialFormValues] = useState<Partial<FormProps>>({});
  const [duplicatesData, setDuplicatesData] = useState<DimensionGroup | undefined>();

  const [form] = Form.useForm<FormProps>();

  const handleSave = async () => {
    const data = await form.validateFields();
    const payload = constructPayload(dimensionGroup.id, data, dimensionGroup);

    // if there are no changes in the fields, payload has only id
    if (Object.entries(payload).length === 1) {
      setDuplicatesData(undefined);
      setOpen(false);

      return;
    }

    const duplicatesData = await updateDimensionGroup.mutateAsync(payload);

    if (duplicatesData?.uniquenessCheckFailed) {
      setDuplicatesData(duplicatesData);
    } else {
      setDuplicatesData(undefined);
      setOpen(false);
    }
  };

  useEffect(() => {
    const formInitialValues = setInitialValues(dimensionGroup);

    setInitialFormValues(formInitialValues);

    form.setFieldsValue(formInitialValues);
  }, [dimensionGroup, form]);

  return (
    <StyledModal
      cancelText={<FormattedMessage id="cancel" />}
      confirmLoading={updateDimensionGroup.isPending}
      destroyOnClose
      okText={<FormattedMessage id="save" />}
      open={open}
      title={<IconText icon={EditIcon} text={<FormattedMessage id="lists.edit" />} />}
      onCancel={() => {
        setDuplicatesData(undefined);
        setOpen(false);
        form?.resetFields();
      }}
      onOk={handleSave}
    >
      <DimensionGroupCreateEditForm
        duplicatesData={duplicatesData}
        editMode
        form={form}
        initialValues={initialFormValues}
      />
    </StyledModal>
  );
};
