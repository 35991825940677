import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotificationActions, useNotificationsCount } from 'store/notifications';
import { NotificationsApi } from '../api';

export const useMarkAsRead = () => {
  const queryClient = useQueryClient();
  const { setNotificationsCount } = useNotificationActions();
  const count = useNotificationsCount();

  return useMutation({
    mutationFn: async (notificationId?: number) => {
      await NotificationsApi.markAsRead(notificationId);
    },
    onSuccess: (_data, notificationId) => {
      queryClient.invalidateQueries({
        queryKey: ['notifications'],
      });
      if (notificationId && count && count > 0) {
        setNotificationsCount((count as number) - 1);
      } else {
        setNotificationsCount(0);
      }
    },
  });
};
