import { Analytics } from 'config/analytics';
import {
  type IntegrationConnectionItem,
  type IntegrationModalContent,
  type MergeConnectArgs,
} from 'data/integrations/fivetran';
import { useMailMutation } from 'data/integrations/fivetran/hooks';
import { type ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useRefSync } from 'utils/hooks';
import { getCallbackUrl } from '../../common/util';
import { MergeWidget } from '../merge-widget';
import { AllConnectorsList } from './all-connectors-list';
import { ConnectorContent } from './popup-modal';
import { useMergeNavigate } from './popup-modal/integrations/hooks/use-merge-navigate';
import { useConnectorQueryParams } from './popup-modal/integrations/hooks/use-update-query-params';
import { PremiumModal } from './popup-modal/premium/premium-modal';
import { styles } from './styles';

const { PopoverWrapper } = styles;

export const AllConnectorsPopup = ({
  data,
  isParentOpen,
  setOpen,
}: {
  data?: IntegrationConnectionItem[];
  isParentOpen: boolean;
  setOpen: (open: boolean) => void;
}): ReactElement => {
  const items = useMemo(() => data || [], [data]);
  const [queryParams] = useQueryParams({
    code: StringParam,
    service: StringParam,
  });

  const [, setQueryParams] = useConnectorQueryParams();
  const { connectService, LoadingComponent } = useMergeNavigate();

  const [activeItem, setActiveItem] = useState<IntegrationModalContent | null>(null);
  const activeItemRef = useRefSync(activeItem);

  const [showPopupModal, setShowPopupModal] = useState(false);
  const [showMailerModal, setShowMailerModal] = useState(false);

  const onSuccess = () => {
    setShowMailerModal(true);
  };
  const { sendMail, isPending } = useMailMutation(onSuccess);

  useEffect(() => {
    const { service, code } = queryParams;
    const item = items.find((item) => item && item.id === Number(service));

    if (item && code) {
      setActiveItem(item);
      setShowPopupModal(true);
    }
  }, [items, queryParams]);

  const [mergeToken, setMergeToken] = useState<string | undefined>('');
  const mergeIntegrationIdRef = useRef('');

  const onMergeDevSuccess = useCallback(
    (publicToken: string) => {
      if (activeItemRef.current) {
        connectService({
          redirectUrl: getCallbackUrl(),
          service: activeItemRef.current?.id,
          code: mergeToken,
          publicToken,
          mergeIntegrationId: mergeIntegrationIdRef.current,
        } as MergeConnectArgs);

        Analytics.track('New source', {
          category: 'Data',
          meta: `Source name - ${activeItemRef.current.displayName}`,
        });
      }
    },
    [activeItemRef, mergeToken, connectService],
  );

  const onMergeDevExit = useCallback(() => {
    setMergeToken(undefined);
  }, []);

  const nonPremiumPopupVisible = showPopupModal && isParentOpen && !activeItem?.isPremium;

  return (
    <PopoverWrapper>
      {LoadingComponent}

      {!nonPremiumPopupVisible && (
        <AllConnectorsList
          items={items}
          mergeIntegrationIdRef={mergeIntegrationIdRef}
          setActiveItem={setActiveItem}
          setMergeToken={setMergeToken}
          setOpen={setOpen}
          setShowPopupModal={setShowPopupModal}
        />
      )}

      {nonPremiumPopupVisible && (
        <ConnectorContent
          callbackUrl={activeItem ? getCallbackUrl() : ''}
          item={activeItem}
          onClose={() => {
            setShowPopupModal(false);
            setQueryParams({});
          }}
          onConnectionSuccess={() => {
            setShowPopupModal(false);
            setQueryParams({});
            setOpen(false);
          }}
        />
      )}

      {showPopupModal && isParentOpen && activeItem?.isPremium && (
        <PremiumModal
          callbackUrl={activeItem ? getCallbackUrl() : ''}
          isSendMailLoading={isPending}
          item={activeItem}
          mailSent={showMailerModal}
          raiseMailRequest={sendMail}
          onClose={() => {
            setShowPopupModal(false);
            setQueryParams({});
            setShowMailerModal(false);
          }}
        />
      )}

      {mergeToken && (
        <MergeWidget
          key={mergeToken}
          mergeToken={mergeToken}
          onExit={onMergeDevExit}
          onSuccess={onMergeDevSuccess}
        />
      )}
    </PopoverWrapper>
  );
};
