import { useQuery } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { ModelApi } from 'data/modelling/model';

export const useGetPlanMetrics = (options?: { enabled?: boolean }) => {
  const { data: metrics = [], isLoading } = useQuery({
    queryKey: ['model-metrics'],
    queryFn: () => ModelApi.findMetrics(),
    staleTime: StaleTime.Short,
    enabled: options?.enabled ?? true,
  });

  return {
    metrics,
    isLoading,
  };
};
