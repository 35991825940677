import { useQuery, useQueryClient } from '@tanstack/react-query';
import { StaleTime } from 'config/query-client';
import { type ID } from 'data';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { defaultApiErrorHandler } from 'utils/error-handler';
import { ConversationApi } from '../api';
import { ConversationsStoreKeys } from '../constants';
import { type Conversation, type ContainerType } from '../types';
import { getConversationIdentifier } from '../utils';

export const useConversations = (container: ContainerType, containerId: ID, disabled?: boolean) => {
  const queryClient = useQueryClient();

  const loadConversationsData = async () => {
    const data = await ConversationApi.findAll(container, containerId);

    queryClient.setQueriesData(
      { queryKey: ConversationsStoreKeys.AllRecords({ container, containerId }) },
      [],
    );

    if (!isEmpty(data)) {
      data.forEach((conv) => {
        const identifier = getConversationIdentifier(conv);

        queryClient.setQueryData(
          ConversationsStoreKeys.AllRecordsWithIdentifier({ container, containerId, identifier }),
          (state: Conversation[] = []) => [...state, conv],
        );
      });
    }

    return data;
  };

  const query = useQuery({
    queryKey: ConversationsStoreKeys.AllRecords({ container, containerId }),
    queryFn: loadConversationsData,
    staleTime: StaleTime.Long,
    enabled: !disabled,
  });

  useEffect(() => {
    if (query.error) {
      defaultApiErrorHandler(query.error);
    }
  }, [query.error]);

  return query;
};

export const useConversationsWithIdentifier = (
  container: ContainerType,
  containerId: ID,
  identifier: string,
) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ConversationsStoreKeys.AllRecordsWithIdentifier({
      container,
      containerId,
      identifier,
    }),

    queryFn: () =>
      queryClient.getQueryData<Conversation[]>(
        ConversationsStoreKeys.AllRecordsWithIdentifier({ container, containerId, identifier }),
      ) ?? [],
    staleTime: StaleTime.Long,
  });
};
