import { useMutation } from '@tanstack/react-query';
import { notification } from 'components/ui/atomic-components';
import { ListsApi } from 'data/modelling/lists';
import { useIntl } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import { defaultApiErrorHandler } from 'utils/error-handler';

export const useRefreshRecons = () => {
  const intl = useIntl();
  const listId = useListStoreContext((s) => s.id);

  return useMutation({
    mutationFn: () => ListsApi.updateReconciliations(listId),

    onSuccess: () => {
      notification.success({
        message: intl.formatMessage({ id: 'lists.refresh_recon.success' }),
      });
    },

    onError: defaultApiErrorHandler,
  });
};
