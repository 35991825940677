import { Popover } from 'components/ui/atomic-components/popover';
import { FilterDropdownButton } from 'components/ui/dropdown-button';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { styles } from '../../styles';

const { Container, Label } = styles;

const FilterButton = styled(FilterDropdownButton)`
  flex-grow: 1;
`;

const FilterValue = styled(FilterDropdownButton.Value)`
  flex-grow: 1;
  text-align: left;
`;

export const NotificationTypeFilter = () => {
  const [showPopover, setShowPopover] = useState(false);

  return (
    <Container>
      <Label>
        <FormattedMessage id="notification.filter.type" />
      </Label>
      <Popover
        /* TODO: Add content */
        content={null}
        open={showPopover}
        placement="bottomLeft"
        trigger={['click']}
        onOpenChange={(v) => setShowPopover(v)}
      >
        <FilterButton disabled>
          <FilterValue>
            <FormattedMessage id="notification.filter.type.all" />
          </FilterValue>
        </FilterButton>
      </Popover>
    </Container>
  );
};
