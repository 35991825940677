import { constructStatisticColumnKey } from 'components/ui/chart-components-v2/chart-types/table/utils/statistic-column-key-utils';
import { DimensionAllMarker, TimeDimensionName } from 'data/dimension';
import { FrameType, type FrameElement } from 'data/reports/types';
import { constructTimeDimKey } from 'data/reports/utils';
import { SCENARIO } from 'data/scenarios';
import { VERSION } from 'data/versions';

export const constructCurrentLevelDimObj = (
  frameElement: FrameElement,
  prevLevelRowDims?: Record<string, string>,
  baselineVersion?: string | null,
  baselineScenario?: string | null,
): Record<string, string> => {
  const currentLevelDimObj: Record<string, string> = {
    ...prevLevelRowDims,
    [frameElement.dimensionName]: frameElement.key,
  };

  if (
    frameElement.dimensionName === TimeDimensionName &&
    frameElement.period &&
    frameElement.key !== DimensionAllMarker
  ) {
    currentLevelDimObj[TimeDimensionName] = constructTimeDimKey(
      frameElement.key,
      frameElement.period,
    );
  } else if (frameElement?.type === FrameType.Statistic && frameElement?.statistic) {
    if (
      !!currentLevelDimObj[VERSION] &&
      baselineVersion &&
      frameElement.dimensionName === VERSION
    ) {
      currentLevelDimObj[VERSION] = constructStatisticColumnKey({
        base: baselineVersion,
        current: frameElement.key,
        statistic: frameElement.statistic,
      });
    } else if (
      !!currentLevelDimObj[SCENARIO] &&
      baselineScenario &&
      frameElement.dimensionName === SCENARIO
    ) {
      currentLevelDimObj[SCENARIO] = constructStatisticColumnKey({
        base: baselineScenario,
        current: frameElement.key,
        statistic: frameElement.statistic,
      });
    }
  }

  return currentLevelDimObj;
};
