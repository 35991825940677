import { ReactComponent as CaretLeftIcon } from 'assets/v2/caret-left.svg';
import { ReactComponent as CaretRightIcon } from 'assets/v2/caret-right.svg';
import { IconShell } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import styled from 'styled-components';

const ColumnAccordionWrapper = styled.div<{ expanded: boolean }>`
  display: flex;
  background-color: ${({ theme, expanded }) => (expanded ? theme.colors.gray00 : 'transparent')};
  border-radius: 2px;
  border: 1px solid
    ${({ theme, expanded }) => (expanded ? theme.colors.outlineDefault : 'transparent')};
  padding: 0 ${({ theme }) => theme.spacing[2]};
  flex-direction: ${({ expanded }) => (expanded ? 'row-reverse' : 'row')};

  svg {
    margin: 0 -${({ theme }) => theme.spacing[4]};
  }
`;

interface Props {
  expanded?: boolean;
}

export const ColumnAccordion = ({ expanded = false }: Props): ReactElement => {
  return (
    <ColumnAccordionWrapper expanded={expanded}>
      <IconShell color="iconDefault" icon={CaretLeftIcon} />
      <IconShell color="iconDefault" icon={CaretRightIcon} />
    </ColumnAccordionWrapper>
  );
};
