import { ReactComponent as ChevronLeftIcon } from 'assets/v2/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/v2/chevron-right.svg';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../button';
import { IconShell } from '../icon-shell';
import { Space } from '../space';
import styles from './styles';

const { PrimaryText, SecondaryText, Wrapper } = styles;

interface Props {
  currentPage: number;
  pageSize: number;
  setPage: (page: number) => void;
  total: number;
}

export const Pagination = ({ currentPage, pageSize, setPage, total }: Props): ReactElement => {
  const lastPage = Math.ceil(total / pageSize);
  const currentPageLastItemCount = currentPage * pageSize;
  const currentPageFirstItemCount = currentPageLastItemCount - pageSize + 1;
  const currentItemsText = `${currentPageFirstItemCount} - ${Math.min(
    currentPageLastItemCount,
    total,
  )}`;

  return (
    <Wrapper>
      <Space>
        <PrimaryText>
          <FormattedMessage id="pagination.showing" values={{ currentItems: currentItemsText }} />
        </PrimaryText>
        <SecondaryText>
          <FormattedMessage id="pagination.of_total" values={{ total }} />
        </SecondaryText>
      </Space>
      <Space>
        <Button
          disabled={currentPage === 1}
          icon={<IconShell icon={ChevronLeftIcon} />}
          size="small"
          onClick={() => setPage(currentPage - 1)}
        />
        <Button
          disabled={currentPage === lastPage}
          icon={<IconShell icon={ChevronRightIcon} />}
          size="small"
          onClick={() => setPage(currentPage + 1)}
        />
      </Space>
    </Wrapper>
  );
};
