import { ReactComponent as AscendingIcon } from 'assets/v2/sort-ascending.svg';
import { ReactComponent as DescendingIcon } from 'assets/v2/sort-descending.svg';
import { IconShell } from 'components/ui/atomic-components';
import { type QueryTableColumnDataType } from 'data/big-query';
import { type ReactElement } from 'react';
import { formatName } from 'utils/data-formatter';
import { useUnderlyingDataContext } from '../../context/use-underlying-data-context';
import { Filter } from './filter';
import { OverflowMenu } from './overflow-menu';
import styles from './styles';

const { Wrapper, ColTitle, IconOptions, IconTextWrapper } = styles;

interface Props {
  colName: string;
  columnType: QueryTableColumnDataType;
  isMaskedColumn: boolean;
}

export const ColumnHeader = ({ colName, columnType, isMaskedColumn }: Props): ReactElement => {
  const { sortedColumn, sortOrder } = useUnderlyingDataContext();

  return (
    <Wrapper>
      <IconTextWrapper>
        <ColTitle title={formatName(colName)}>{formatName(colName)}</ColTitle>

        {sortedColumn === colName && sortOrder && (
          <IconShell
            color="iconDefault"
            icon={sortOrder === 'ASC' ? AscendingIcon : DescendingIcon}
          />
        )}
      </IconTextWrapper>

      <IconOptions>
        {!isMaskedColumn && <Filter colName={colName} type={columnType} />}

        <OverflowMenu colName={colName} />
      </IconOptions>
    </Wrapper>
  );
};
