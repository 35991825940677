/* eslint-disable filenames/match-regex */
import { NotificationStyles } from 'components/ui/atomic-components/notification/styles';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { FontS, FontXs } from './typography';
import { BeamerGlobalStyles } from './vendor';
import { DatePickerStyle } from './vendor/antd/date-picker-v2';
import { DropdownStyles } from './vendor/antd/dropdown-v2';
import { MenuStyles } from './vendor/antd/menu';
import { ModalStyles } from './vendor/antd/modal-v2';
import { PopoverStyles } from './vendor/antd/popover-v2';
import { TooltipStyles } from './vendor/antd/tooltip-v2';
import { HighchartsGlobalStyles } from './vendor/highcharts';
import { TextSelectionStyles } from './vendor/selection';

export const GlobalStylesV2 = createGlobalStyle`
  ${normalize};

  html {
    font-size: 100%; /* 16px */
  }

  html,
  body {
    font-family: ${({ theme: { font } }) => font.text};
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.textBody1};
    background-color: ${({ theme: { colors } }) => colors.gray00};
    font-feature-settings: 'tnum' 0;
    overflow: hidden;
  }

  body {
    ${FontS};

    /* Font Smoothing  */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    
    * {
      /* gray300 with 80% opacity */
      scrollbar-color: rgb(209 213 219 / 80%) transparent;
      scrollbar-width: thin;
    }
  }

  #root {
    height: 100%;
    min-width: 768px;
    width: 100%;
  }

  .tsqd-parent-container {
    --tsqd-font-size: 1rem !important;
  }

  .optimus-layout-row-wrapper .optimus-layout-cell-wrapper {
    border:none;
  }

  @supports (font-variation-settings: normal) {
    html {
      font-family: ${({ theme }) => theme.font.text};
    }
  }

  /* fix for smaller screens ENG-34431 */
  @media (width <= ${({ theme }) => theme.screen.small}) {
    html {
      font-size: 84%;
    }
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  small {
    ${FontXs};
  }

  ${TooltipStyles};

  ${DropdownStyles}

  ${ModalStyles}

  ${NotificationStyles};
  
  ${PopoverStyles}

  ${DatePickerStyle};

  ${HighchartsGlobalStyles};

  ${MenuStyles};

  ${TextSelectionStyles};

  ${BeamerGlobalStyles}
`;
