import { ReactComponent as NoNotificationIcon } from 'assets/empty/notifications_empty.svg';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { br } from 'styles/html-elements';
import { FontM, FontS } from 'styles/typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[16]};
  height: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[4]};
  padding: ${({ theme }) => theme.spacing[16]};
`;

const Title = styled.span`
  ${FontM}

  color: ${({ theme }) => theme.colors.textTitle2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-align: center;
`;
const Description = styled.span`
  ${FontS}

  color: ${({ theme }) => theme.colors.textBody2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: center;
`;

const Placeholder = ({
  title,
  description,
}: {
  title: ReactElement;
  description: ReactElement;
}) => {
  return (
    <Wrapper>
      <NoNotificationIcon />

      <TextWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextWrapper>
    </Wrapper>
  );
};

export const NoNotificationPlaceholder = (): ReactElement => {
  return (
    <Placeholder
      description={<FormattedMessage id="notification.no_notification.desc" values={{ br }} />}
      title={<FormattedMessage id="notification.no_notification.title" />}
    />
  );
};

export const NoUnreadNotificationPlaceholder = (): ReactElement => {
  return (
    <Placeholder
      description={<FormattedMessage id="notification.no_unread_notification.desc" />}
      title={<FormattedMessage id="notification.no_unread_notification.title" />}
    />
  );
};
