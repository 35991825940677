import { type FormProps } from 'components/modules/modelling/lists/crud-components/dimension-group-pivot-key-form/types';
import { type ID } from 'data';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { isEqual } from 'lodash';
import { getColumnNameFromRawDimensionName } from 'utils/dimensions';
import { type Atleast } from 'utils/typescript';

export const constructPayload = (
  id: ID,
  formData: FormProps,
  currentDimGrp: DimensionGroup,
): Atleast<DimensionGroup, 'id'> => {
  const payload = {
    id,
  } as Atleast<DimensionGroup, 'id'>;

  const formUniqueKeyColumns = formData.uniqueKeyColumns.map((c) =>
    getColumnNameFromRawDimensionName(c.value),
  );

  if (!isEqual(formUniqueKeyColumns, currentDimGrp.uniqueKeyColumns)) {
    payload.uniqueKeyColumns = formUniqueKeyColumns;
  }

  return payload;
};
