import { ConfirmationModal } from 'components/ui/atomic-components';
import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useCleanupListRecon } from '../edit-input-json-modal/hooks/use-cleanup-list-recon';

interface Props {
  listId: number;
  open: boolean;
  onClose: () => void;
}

export const CleanupReconModal = ({ open, onClose, listId }: Props): ReactElement => {
  const cleanupReconMutation = useCleanupListRecon();

  return (
    <ConfirmationModal
      confirmLoading={cleanupReconMutation.isPending}
      open={open}
      title={<FormattedMessage id="lists.cleanup_recon.modal.title" />}
      type="delete"
      onCancel={onClose}
      onOk={() => cleanupReconMutation.mutateAsync({ listId }).then(onClose)}
    >
      <FormattedMessage id="lists.cleanup_recon.modal.message" />
    </ConfirmationModal>
  );
};
