import { type ID } from 'data';
import {
  type IntegrationConnectedItem,
  IntegrationProvider,
  IntegrationsApi,
} from 'data/integrations/fivetran';
import { useEffect } from 'react';
import { type DetailedAxiosError } from 'utils/error-handler';

const ConnectorsToCheckForTokenValidity = [IntegrationProvider.Xero];

export const useCheckIfConnectorTokenValid = ({
  instance,
  handleError,
}: {
  instance?: IntegrationConnectedItem;
  handleError: (errorResp: DetailedAxiosError, errorInstanceId: ID) => void;
}) => {
  useEffect(() => {
    if (ConnectorsToCheckForTokenValidity.includes(instance?.name as IntegrationProvider)) {
      const instanceId = instance?.id as number;

      IntegrationsApi.getConnectionTables(instanceId).catch((e) => handleError(e, instanceId));
    }
  }, [instance?.id, instance?.name, handleError]);
};
