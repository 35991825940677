import { type ValuesOf } from 'utils/typescript';

export const NotificationType = {
  HEALTH_RECORD: 'HEALTH_RECORD',
  CONVERSATION: 'CONVERSATION',
  TASK: 'TASK',
  ALL: undefined,
} as const;

export type NotificationWSData = {
  resourceId?: number;
};

export type NotificationType = ValuesOf<typeof NotificationType>;

export type NotificationAttributes = {
  subject: string;
  body: string | null;
  actionLink: string;
  from: string;
};

export const NotificationStatus = {
  // INACTIVE: 0,
  // DELETED: 2,
  // RESOLVED: 3,
  // REFINED_AWAY: 6,
  // ARCHIVE: 8,
  // INFO: 9,
  // ERROR: 10,
  ACTIVE: 1,
  READ: 11,
} as const;

export type NotificationStatusType = ValuesOf<typeof NotificationStatus>;

export type Notification = {
  notificationReceiptId: number;
  attributes: NotificationAttributes;
  status: NotificationStatusType;
  createdAt: string;
  type: NotificationType;
};
