import { FrameType } from 'data/reports/types';
import { SCENARIO } from 'data/scenarios';
import { VERSION } from 'data/versions';
import { type HierarchyClanNode } from 'utils/tree-table';

export const getDimsInRowHierarchy = ({
  hierarchyClanList,
  rowDimensions,
}: {
  hierarchyClanList: HierarchyClanNode[];
  rowDimensions: string[];
}): string[] => {
  const keyVisited = new Set();
  const dimsInHierarchy: string[] = [];

  (hierarchyClanList || []).forEach((dim: HierarchyClanNode) => {
    // Ignore Blank or Section row
    // Also remove duplicate dimensions in the hierarchy (Eg:- Metric and SectionMetric)
    if (
      !keyVisited.has(dim?.name) &&
      ![FrameType.Blank, FrameType.Section].includes(dim.viewNodeType as FrameType)
    ) {
      keyVisited.add(dim?.name);

      dimsInHierarchy.push(dim?.name);
    }
  });

  // version could be missing in dimsInHierarchy, if it is added in rows
  // This is to handle nodes above version in rows
  if (rowDimensions.includes(VERSION) && !dimsInHierarchy.includes(VERSION)) {
    dimsInHierarchy.push(VERSION);
  }

  if (rowDimensions.includes(SCENARIO) && !dimsInHierarchy.includes(SCENARIO)) {
    dimsInHierarchy.push(SCENARIO);
  }

  return dimsInHierarchy;
};
