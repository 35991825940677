import { Layout as LayoutAtom } from 'components/ui/atomic-components/layout';
import { LeftNavV2 } from 'components/ui/layout';
import { useIsInPrintMode } from 'data/boards';
import { useLiveConversations } from 'data/conversations/live-conversations-hook';
import { useLiveNotifications } from 'data/notifications/hooks/use-live-notifications';
import { type FC } from 'react';
import styled from 'styled-components';
import { VersionViewBanner } from '../modelling/model/version-view-banner';
import { BeamerWhatsNewWidget } from './beamer-what-new-widget';
import { G2ReviewPopup } from './g2-review';
import { useCheckIfInMaintenance } from './hooks/use-check-if-in-maintance';
import { useInitializeAnalytics } from './hooks/use-initialize-analytics';
import { usePreloadRoutes } from './hooks/use-preload-routes';
import { useUserSessionZipyAlerts } from './hooks/use-user-session-zipy-alerts';
import { useVersionUpdates } from './hooks/use-version-updates';
import { useViewAsUser } from './hooks/use-view-as-user';
import { useViewAsUserQueryParam } from './hooks/use-view-as-user-query-param';
import { ViewAsUserBanner } from './view-as-user-banner';

const Layout = styled(LayoutAtom)<{ $isViewAsUserMode: boolean }>`
  height: 100%;
  border: ${({ $isViewAsUserMode, theme }) => {
    if ($isViewAsUserMode) {
      return `6px solid ${theme.colors.orange400}`;
    }

    return 'none';
  }};
`;

export const AppWrapper: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const isInPrintMode = useIsInPrintMode();
  const { userFullName, exitViewAsUserMode } = useViewAsUser();

  useCheckIfInMaintenance();

  useInitializeAnalytics();
  usePreloadRoutes();

  useLiveConversations();
  useVersionUpdates();
  useLiveNotifications();

  useUserSessionZipyAlerts();

  useViewAsUserQueryParam();

  return (
    <Layout $isViewAsUserMode={!!userFullName}>
      {!!userFullName && (
        <ViewAsUserBanner exitViewAsUserMode={exitViewAsUserMode} userFullName={userFullName} />
      )}
      <VersionViewBanner />
      {!isInPrintMode && <LeftNavV2 />}

      {children}

      <G2ReviewPopup />
      <BeamerWhatsNewWidget />
    </Layout>
  );
};
