import { type GridApi } from 'ag-grid-community';
import { ChartWrapper } from 'components/modules/boards-v2/custom-board/charts-grid/grid-item/chart-renderer/chart-wrapper';
import { usePreviewChart } from 'components/modules/boards-v2/custom-board/charts-grid/grid-item/hooks/use-preview-chart';
import { Spinner } from 'components/ui/atomic-components';
import { useReportChartContext } from 'data/boards/context';
import { type DimensionQueryParamsObject } from 'data/dimension';
import { type ReactElement } from 'react';
import { TableChart } from './grid';
import { generatePreviewRequest } from './utils';

export const SummaryTable = ({
  summarizeBy,
  startDate,
  endDate,
  granularity,
  metricName,
  version,
  dimensions,
  setSummaryConfig,
}: {
  summarizeBy: string;
  startDate?: string;
  endDate?: string;
  granularity?: string;
  metricName?: string;
  version?: string;
  dimensions: DimensionQueryParamsObject;
  setSummaryConfig: React.Dispatch<
    React.SetStateAction<{
      gridApi?: GridApi;
      rowCount?: number;
      colCount?: number;
    }>
  >;
}): ReactElement => {
  const { chart } = useReportChartContext();

  const { data: chartData, isFetching } = usePreviewChart(
    generatePreviewRequest({
      summarizeBy,
      chart,
      metricName,
      startDate,
      endDate,
      granularity,
      version,
      dimensions,
    }),
  );

  return (
    <ChartWrapper isFetching={isFetching}>
      {() => {
        if (!chartData) {
          return <Spinner size="medium" />;
        }

        return <TableChart chartData={chartData} setSummaryConfig={setSummaryConfig} />;
      }}
    </ChartWrapper>
  );
};
