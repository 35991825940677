import { type QueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';
import { deleteItemFromItemsCache, updateItemInItemsCache } from 'utils/react-query';
import { type ModelVersion } from '../../types';
import { ModelVersionStoreKeys } from '../constants';

export const appendVersion = (queryClient: QueryClient): void => {
  queryClient.invalidateQueries({ queryKey: ModelVersionStoreKeys.findVersionsWithArchived() });
  queryClient.invalidateQueries({
    queryKey: ModelVersionStoreKeys.findVersionsWithCurrentActual(),
  });
};

export const updateVersion = (queryClient: QueryClient, data: ModelVersion): void => {
  const updatedVersion: Partial<ModelVersion> = omit(data, [
    'isSCCL',
    'createdAt',
    'status',
    'archived',
  ]);

  updateItemInItemsCache(
    queryClient,
    ModelVersionStoreKeys.findVersionsWithArchived(),
    updatedVersion,
  );
  updateItemInItemsCache(
    queryClient,
    ModelVersionStoreKeys.findVersionsWithCurrentActual(),
    updatedVersion,
  );
};

export const removeVersion = (queryClient: QueryClient, deletedVersion: ModelVersion): void => {
  deleteItemFromItemsCache(
    queryClient,
    ModelVersionStoreKeys.findVersionsWithArchived(),
    deletedVersion,
  );
  deleteItemFromItemsCache(
    queryClient,
    ModelVersionStoreKeys.findVersionsWithCurrentActual(),
    deletedVersion,
  );
};
