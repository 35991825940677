import { type ColDef, type IRowNode } from 'ag-grid-community';
import { useConversationsContext } from 'components/ui/collaboration/context';
import { useConversationsWithIdentifier } from 'data/conversations/hooks';
import { getCellIdenitifierFromGridData } from 'data/conversations/utils';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

export interface CellConversationData {
  hasComments: boolean;
  cellIdentifier: string;
}

export const useGridCellConversations = (colDef: ColDef, node: IRowNode): CellConversationData => {
  const { containerId, containerType } = useConversationsContext();

  // Identifier for the grid cell irrespective of the order of dimensions
  const cellIdentifier = useMemo(
    () => getCellIdenitifierFromGridData(colDef, node),
    [colDef, node],
  );

  const { data } = useConversationsWithIdentifier(
    containerType,
    Number(containerId),
    cellIdentifier,
  );

  const openComments = data?.filter((comment) => !comment.resolved);

  return {
    hasComments: !isEmpty(openComments),
    cellIdentifier,
  };
};
