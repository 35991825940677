import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { BoardsApi, useBoardCurrency, useBoardQueryParams } from 'data/boards';
import { type Chart, type PreviewChartRequest } from 'data/boards/charts/types';
import { useBoardDateRange } from 'data/boards/hooks/use-board-date-range';
import { useBoardFilters } from 'data/boards/hooks/use-board-filters';
import { useBoardGranularity } from 'data/boards/hooks/use-current-board-granularity';
import { useSelectedBoardGranularity } from 'data/boards/hooks/use-selected-board-granularity';
import { TimeDimensionName } from 'data/dimension';
import { useFlags } from 'launchdarkly-react-client-sdk';

const getXAxisTitle = (chart: Chart): string => {
  if (
    chart?.attributes?.columns?.length === 1 &&
    chart?.attributes?.columns?.[0] !== TimeDimensionName
  ) {
    const xAxisDimension = (chart?.granularDimensions || []).find(
      (dim) => dim.name === chart?.attributes?.columns?.[0],
    );

    return xAxisDimension?.displayName || '';
  }

  return '';
};

export const usePreviewChart = (previewChartReq: PreviewChartRequest) => {
  const defaultGranularity = useBoardGranularity();
  const dateRange = useBoardDateRange();
  const boardFilters = useBoardFilters();
  const boardCurrency = useBoardCurrency();
  const boardGranularity = useSelectedBoardGranularity();
  const [query] = useBoardQueryParams();
  const { enableConsistentChartColorsForAutomation } = useFlags();

  return useQuery({
    queryKey: ['preview-chart', previewChartReq?.boardId, previewChartReq, query],

    queryFn: async () => {
      const granularity = previewChartReq?.chart?.attributes?.granularity || defaultGranularity;

      const data: Chart = await BoardsApi.getPreviewChart(
        {
          ...previewChartReq,
          granularity,

          boardFilters,
          boardStartDate: dateRange.startDate,
          boardEndDate: dateRange.endDate,
          boardDateOption: dateRange.dateOption,
          boardRelativeRange: dateRange.relativeRange,
          boardCurrency,
          boardGranularity,
        } as PreviewChartRequest,
        enableConsistentChartColorsForAutomation,
      );

      if (data) {
        data.xAxisTitle = getXAxisTitle(data);
      }

      return data;
    },

    enabled: Boolean(previewChartReq),
    placeholderData: keepPreviousData,
  });
};
