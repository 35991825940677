import { type RelativeRangeType } from 'components/ui/atomic-components/time-range-panel/v2/relative-panel/types';
import { type Currency } from 'data/currencies';

export enum DateFilterPresetName {
  CurrentMonth = 'CURRENT_MONTH',
  LastMonth = 'LAST_MONTH',
  CurrentQuarter = 'CURRENT_QUARTER',
  PreviousQuarter = 'LAST_QUARTER',
  CurrentYear = 'CURRENT_YEAR',
  YearTillDate = 'YEAR_TILL_DATE',
  LastTwelveMonths = 'LAST_TWELVE_MONTHS',
  CustomRange = 'CUSTOM_RANGE',
  RelativeRange = 'RELATIVE_RANGE',
}

export type DateFilterPreset = {
  label: string;
  isDefault?: boolean;
  value: [string, string];
  relativeRange?: RelativeRangeType;
  name: DateFilterPresetName;
};
export interface CurrencyOption {
  name: Currency;
  displayName: string;
}

export interface AccountBootstrapType {
  dateFilterOptions: DateFilterPreset[];
  dateFormats: string[];
  dateFormat: string;
  currency: Currency;
  currencies: CurrencyOption[];
  healthStatus?: string;
  tenantCurrencies: Currency[] | null;
  syncSchedule: string;
}
