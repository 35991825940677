import { type GridApi, type GridReadyEvent } from 'ag-grid-community';
import { ColumnGroupHeader } from 'components/ui/chart-components-v2/chart-types/table/column-group-header';
import { useCreateColumnDefs } from 'components/ui/chart-components-v2/chart-types/table/hooks';
import { useGetGridConfigs } from 'components/ui/chart-components-v2/chart-types/table/hooks/use-get-grid-configs';
import styles from 'components/ui/chart-components-v2/chart-types/table/styles';
import { generateRowData } from 'components/ui/chart-components-v2/chart-types/table/utils/generate-row-data';
import { type Chart } from 'data/boards/charts/types';
import { useModelVersion } from 'data/modelling/model/versions';
import { type Pivot } from 'data/page-template';
import { type TableRowData } from 'data/reports/types';
import { keyBy } from 'lodash';
import { type ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { scrollUsingHeader } from 'utils/grid/header-scroll';
import { getFormattedPeriods } from 'utils/grid/table-view';

interface Props {
  chartData: Chart;
  setSummaryConfig: React.Dispatch<
    React.SetStateAction<{
      gridApi?: GridApi;
      rowCount?: number;
      colCount?: number;
    }>
  >;
}

const BreakupSummaryGrid = styled(styles.StyledGrid)`
  .ag-row-hover {
    .drilldown-icon-wrapper,
    .metric-level-pivot-icon {
      display: none;
    }
  }
`;

export const TableChart = ({ chartData: chart, setSummaryConfig }: Props): ReactElement => {
  const { data: versions } = useModelVersion({
    includeArchived: true,
    includeActual: true,
    includeCurrent: true,
  });
  const [gridApi, setGridApi] = useState<GridApi>();

  const { metrics, periodRanges = [] } = chart;
  const metricsMap = useMemo(() => keyBy(metrics, 'name'), [metrics]);

  const pivot = {
    dimensions: {
      page: [],
      rows: chart.attributes?.rows,
      filters: chart.attributes?.dimMap,
      columns: chart.attributes?.columns,
      versions: chart.attributes?.versionNames,
    },
  } as Pivot;

  const gridOptions = useGetGridConfigs({
    tableRowsExpanded: false,
  });

  const periods = useMemo(
    () =>
      getFormattedPeriods({
        periods: periodRanges || [],
        cols: chart.attributes?.versionNames || [],
        versions,
      }),
    [periodRanges, chart.attributes?.versionNames, versions],
  );

  const rows = useMemo(() => {
    return generateRowData({
      rows: chart.frame?.rows || [],
      dataLookupMap: chart.data || {},
    });
  }, [chart.data, chart.frame?.rows]);

  const columnFrameWithStatisticColumns = chart.frame?.columns || [];
  const styleMap = useRef({});

  const { colDefs } = useCreateColumnDefs({
    columnFrameWithStatisticColumns,
    pivot,
    chart,
    periods,
    metricsMap,
    styleMap,
    repeatRowLabels: false,
  });

  const onGridReady = (params: GridReadyEvent) => {
    scrollUsingHeader(params.api.getGridId());
    setSummaryConfig({
      gridApi: params.api,
      rowCount: rows.length,
      colCount: colDefs.length + 1,
    });
    setGridApi(params.api);
  };

  useEffect(() => {
    setSummaryConfig({
      gridApi,
      rowCount: rows.length,
      colCount: colDefs.length + 1,
    });
  }, [colDefs.length, gridApi, rows.length, setSummaryConfig]);

  return (
    <BreakupSummaryGrid
      columnDefs={colDefs}
      components={{
        agColumnGroupHeader: ColumnGroupHeader,
      }}
      enableRangeSelection
      getRowId={({ data }) =>
        (data as TableRowData)?.hierarchy?.join('~~') +
        (data as TableRowData)?.indexHierarchy?.join('~')
      }
      gridOptions={gridOptions}
      noRowsOverlayComponent={() => <>{''}</>}
      rowData={rows}
      rowHeight={32}
      suppressGroupRowsSticky
      onGridReady={onGridReady}
    />
  );
};
