import { type InputRef } from 'antd';
import { Spinner, Tree } from 'components/ui/atomic-components';
import { useUnderlyingDataContext } from 'components/ui/breakup-query/context/use-underlying-data-context';
import { FilterPopupSkeleton } from 'components/ui/filter-popup-skeleton';
import { type QueryTableColumnDataType } from 'data/big-query';
import { type ReactElement, useMemo, useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { isNumericBigQueryType } from 'utils/big-query/is-numeric-big-query-type';
import { useGetFilterValues } from '../overflow-menu/use-get-filter-values';
import { WrapperStyles } from './styles';
import { formatTitle } from './utils';

const { LoadingOverlay, TopText } = WrapperStyles;

interface Props {
  colName: string;
  type: QueryTableColumnDataType;
  onApply: (values: string[]) => void;
}

export const Content = ({ colName, type, onApply }: Props): ReactElement => {
  const inputRef = useRef<InputRef>(null);
  const {
    dimensions,
    startDate,
    endDate,
    metricName,
    granularity,
    version,
    sortedColumn,
    sortOrder,
    columnFilters,
  } = useUnderlyingDataContext();
  const { columnFilterValues, filterValuesAreLoading } = useGetFilterValues({
    dimensions,
    startDate,
    endDate,
    metricName,
    granularity,
    version,
    sortedColumn,
    sortOrder,
    columnName: colName,
  });
  const uniqueColumnOptions = columnFilterValues.map((value) => ({
    key: value,
    title: formatTitle({ type, value }),
  }));

  const [selectedFilterValues, setSelectedFilterValues] = useState<string[]>(
    columnFilters[colName] ?? [],
  );

  const [searchQuery, setSearchQuery] = useState('');

  const filteredOptions = useMemo(
    () =>
      uniqueColumnOptions.filter((option) => {
        if (isNumericBigQueryType(type) && searchQuery !== '') {
          return String(option.title).toLowerCase().startsWith(searchQuery?.toLowerCase());
        }

        return String(option.title)?.toLowerCase().includes(searchQuery?.toLowerCase());
      }),
    [type, uniqueColumnOptions, searchQuery],
  );

  const isAllItemsChecked = selectedFilterValues.length === uniqueColumnOptions.length;

  const onSelectAll = () => {
    const updatedFilterValues = new Set(selectedFilterValues);

    filteredOptions.forEach((option) => {
      updatedFilterValues.add(option.key);
    });

    setSelectedFilterValues([...updatedFilterValues]);
  };

  const onClear = () => {
    const updatedFilterValues = new Set(selectedFilterValues);

    filteredOptions.forEach((option) => {
      updatedFilterValues.delete(option.key);
    });

    setSelectedFilterValues([...updatedFilterValues]);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  if (filterValuesAreLoading) {
    return (
      <LoadingOverlay>
        <Spinner size="small" />
      </LoadingOverlay>
    );
  }

  return (
    <FilterPopupSkeleton
      searchQuery={searchQuery}
      title={
        <TopText>
          <FormattedMessage id="filter_by_all" />
        </TopText>
      }
      onApply={() => onApply(isAllItemsChecked ? [] : selectedFilterValues)}
      onClear={onClear}
      onSearchChange={(val) => setSearchQuery(val)}
      onSelectAll={onSelectAll}
    >
      <Tree
        checkable
        checkedKeys={selectedFilterValues}
        checkStrictly
        height={200}
        treeData={filteredOptions}
        onCheck={(checked) => {
          if (Array.isArray(checked)) {
            setSelectedFilterValues(checked as string[]);
          } else {
            setSelectedFilterValues(checked.checked as string[]);
          }
        }}
        onSelect={(_, info) => {
          const nodeValue = info.node.key as string;

          if (!selectedFilterValues.includes(nodeValue)) {
            setSelectedFilterValues((prev) => [...prev, nodeValue]);
          } else {
            setSelectedFilterValues((prev) => prev.filter((value) => value !== nodeValue));
          }
        }}
      />
    </FilterPopupSkeleton>
  );
};
