import { ReactComponent as ArrowRightIcon } from 'assets/v2/arrow-right.svg';
import { Chips, IconShell } from 'components/ui/atomic-components';
import { EntityStatus, type ID } from 'data';
import { ListUpdateType, type ListUpdate } from 'data/modelling/lists';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { difference, isEmpty, keyBy, union } from 'lodash';
import { useMemo, type ReactElement } from 'react';
import { useListStoreContext } from 'store/lists';
import { formatName } from 'utils/data-formatter';
import { type ListGridRow } from '../../../../grid/types';
import { EssentialFields } from './essential-fields';
import { Styles } from './styles';
import { areValuesEqual, formatUpdatedValue } from './utils';

const { Update, UpdateKey, UpdateValue, ChipTextWrapper } = Styles;

interface Props {
  listUpdate: ListUpdate;
  isRecommendation: boolean;
  pickedRecommendationRowId?: ID | null;
  matchedRow?: ListGridRow;
}

export const DiffFields = ({
  listUpdate,
  isRecommendation,
  pickedRecommendationRowId,
  matchedRow,
}: Props): ReactElement => {
  const { enableReconV2 } = useFlags();
  const listRows = useListStoreContext((s) => s.rows);
  const listConfig = useListStoreContext((s) => s.config);
  const reconConfig = useListStoreContext((s) => s.reconciliationConfig);

  const previousData = useMemo(() => {
    const idToListRow = keyBy(listRows, 'backendId');

    if (listUpdate.cardType === ListUpdateType.Update && listUpdate.listRowId) {
      return idToListRow[listUpdate.listRowId]?.data || {};
    }

    if (listUpdate.cardType === ListUpdateType.Update && listUpdate.prevData) {
      return listUpdate.prevData;
    }

    if (isRecommendation && pickedRecommendationRowId) {
      return idToListRow[pickedRecommendationRowId]?.data || {};
    }

    return {};
  }, [
    isRecommendation,
    listRows,
    listUpdate.cardType,
    listUpdate.listRowId,
    listUpdate.prevData,
    pickedRecommendationRowId,
  ]);

  if (isRecommendation && !pickedRecommendationRowId) {
    return <EssentialFields listUpdate={listUpdate} matchedRow={matchedRow} />;
  }

  const updatedFields = [];
  // For auto recon info cards, diff is shown with value -> value where value is exactly same since updates are auto applied.
  // Hence for auto recon info cards, don't show updated fields as diff. Show only latest value, since this is tricky for BE to send prev data in this case.
  const updatedFieldsToShowWithoutDiff = [];

  if (enableReconV2) {
    for (const [column] of Object.entries(listUpdate.data)) {
      if (listUpdate.status === EntityStatus.Active) {
        updatedFields.push(column);
      } else {
        updatedFieldsToShowWithoutDiff.push(column);
      }
    }
  } else {
    for (const [column, updatedColumnValue] of Object.entries(listUpdate.data)) {
      const isPositionColumnInGroupedRecon =
        [
          reconConfig.positionStartDateCol ?? 'Position_Start_Date',
          reconConfig.positionEndDateCol ?? 'Position_End_Date',
        ].includes(column) &&
        listUpdate.cardType === ListUpdateType.Update &&
        !isEmpty(listUpdate.ids);

      if (
        !isEmpty(previousData) &&
        listConfig.columnOrder.includes(column) &&
        !isPositionColumnInGroupedRecon &&
        !areValuesEqual({
          columnTypeMap: listConfig.columnTypeMap,
          column,
          oldValue: previousData[column],
          newValue: updatedColumnValue,
        })
      ) {
        updatedFields.push(column);
      }
    }
  }
  const extraEssentialFields =
    updatedFieldsToShowWithoutDiff.length > 0
      ? union(reconConfig.essentialFields, updatedFieldsToShowWithoutDiff)
      : difference(reconConfig.essentialFields, updatedFields);

  return (
    <>
      {(updatedFields || []).map((key) => {
        const value = listUpdate.data[key] ?? matchedRow?.data?.[key];

        return (
          <Update key={key}>
            <UpdateKey>{`${formatName(key)}:`}</UpdateKey>

            <UpdateValue>
              <Chips bgColor="orange10" borderColor="orange200" size="small" textColor="orange700">
                <ChipTextWrapper>
                  {formatUpdatedValue({
                    columnTypeMap: listConfig.columnTypeMap,
                    column: key,
                    value: previousData[key],
                  })}
                </ChipTextWrapper>
              </Chips>

              <IconShell icon={ArrowRightIcon} size="xs" />

              <Chips bgColor="green10" borderColor="green200" size="small" textColor="green700">
                <ChipTextWrapper>
                  {formatUpdatedValue({
                    columnTypeMap: listConfig.columnTypeMap,
                    column: key,
                    value,
                  })}
                </ChipTextWrapper>
              </Chips>
            </UpdateValue>
          </Update>
        );
      })}

      {(extraEssentialFields || []).map((key) => {
        const value = listUpdate.data[key] ?? matchedRow?.data?.[key];

        return (
          <Update key={key}>
            <UpdateKey>{`${formatName(key)}:`}</UpdateKey>

            <UpdateValue>
              {formatUpdatedValue({ columnTypeMap: listConfig.columnTypeMap, column: key, value })}
            </UpdateValue>
          </Update>
        );
      })}
    </>
  );
};
