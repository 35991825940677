import { AsyncSwitcher } from 'components/ui/async-switcher';
import { useNotifications } from 'data/notifications/hooks/use-notifications';
import { NotificationType, type Notification } from 'data/notifications/types';
import { useFetchNotificationType, useShouldFetchUnread } from 'store/notifications';
import styled from 'styled-components';
import { NoNotificationPlaceholder, NoUnreadNotificationPlaceholder } from '../placeholder';
import { HEIGHT_WITHOUT_TITLE, WIDTH } from '../styles';
import { TaskNotification } from './notification/task-notification';

const Wrapper = styled.div`
  height: ${HEIGHT_WITHOUT_TITLE};
  width: ${WIDTH};
  overflow-y: auto;
`;

export const Content = () => {
  const shouldFetchUnread = useShouldFetchUnread();
  const fetchNotificationType = useFetchNotificationType();

  const notificationsQuery = useNotifications({
    shouldFetchUnread,
    type: fetchNotificationType,
  });

  return (
    <Wrapper>
      <AsyncSwitcher
        components={{
          Empty: shouldFetchUnread ? NoUnreadNotificationPlaceholder : NoNotificationPlaceholder,
        }}
        {...notificationsQuery}
      >
        {(data: Notification[]) => {
          return (
            <div>
              {data.map((notification) => {
                if (notification.type === NotificationType.TASK) {
                  return (
                    <TaskNotification
                      key={notification.notificationReceiptId}
                      notification={notification}
                    />
                  );
                }
              })}
            </div>
          );
        }}
      </AsyncSwitcher>
    </Wrapper>
  );
};
